import React from 'react'
import { all_routes } from '../../router/all_routes';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Securitysettings = () => {
  const routes = all_routes;
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="d-md-flex d-block align-items-center justify-content-between border-bottom pb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Paramètres Généraux</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="index">Tableau de Bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Paramètres</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Paramètres Généraux
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <div className="pe-1 mb-2">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Rafraîchir</Tooltip>}
                >
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white btn-icon me-1"
                  >
                    <i className="ti ti-refresh" />
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-2 col-xl-3">
              <div className="pt-3 d-flex flex-column list-group mb-4">
                <Link
                  to={routes.profilesettings}
                  className="d-block rounded p-2"
                >
                  Paramètres du Profil
                </Link>
                <Link
                  to={routes.securitysettings}
                  className="d-block rounded p-2 active"
                >
                  Paramètres de Sécurité
                </Link>
                <Link
                  to={routes.notificationssettings}
                  className="d-block rounded p-2"
                >
                  Notifications
                </Link>
                <Link to={routes.connectedApps} className="d-block rounded p-2">
                  Applications Connectées
                </Link>
              </div>
            </div>
            <div className="col-xxl-10 col-xl-9">
              <div className="border-start ps-3 flex-fill">
                <div className="d-flex align-items-center justify-content-between flex-wrap border-bottom mb-3 pt-3">
                  <div className="mb-3">
                    <h5>Paramètres de Sécurité</h5>
                    <p>Chargez votre photo et vos informations personnelles ici</p>
                  </div>
                </div>
                <div className="d-block">
                  <div className="d-flex justify-content-between align-items-center rounded flex-wrap bg-white border rounded p-3 pb-0 mb-3">
                    <div className="mb-3">
                      <h6>Mot de Passe</h6>
                      <p>Définissez un mot de passe unique pour protéger le compte</p>
                    </div>
                    <div className="mb-3">
                      <Link to="#" className="btn btn-outline-primary">
                        Changer le Mot de Passe
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center rounded flex-wrap bg-white border rounded p-3 pb-0 mb-3">
                    <div className="mb-3">
                      <h6>Deux Facteurs</h6>
                      <p>Recevez des codes par SMS ou email à chaque connexion</p>
                    </div>
                    <div className="mb-3">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="switch-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center rounded flex-wrap bg-white border rounded p-3 pb-0 mb-3">
                    <div className="mb-3">
                      <h6>Authentification Google</h6>
                      <p>Connectez-vous à Google</p>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <span className="badge badge-soft-success me-3">
                        <i className="ti ti-circle-filled fs-5 me-1" />
                        Connecté
                      </span>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="switch-sm2"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center rounded flex-wrap bg-white border rounded p-3 pb-0 mb-3">
                    <div className="mb-3">
                      <h6>Vérification du Numéro de Téléphone</h6>
                      <p>Le numéro de téléphone associé au compte</p>
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                      <p className="mb-3 me-3">+1 73649 72648</p>
                      <span className="badge badge-soft-success me-3 mb-3">
                        <i className="ti ti-checks me-1" />
                        Vérifié
                      </span>
                      <Link to="#" className="btn btn-light mb-3">
                        <i className="ti ti-edit me-2" />
                        Modifier
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center rounded flex-wrap bg-white border rounded p-3 pb-0 mb-3">
                    <div className="mb-3">
                      <h6>Adresse Email</h6>
                      <p>L'adresse email associée au compte</p>
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                      <p className="mb-3 me-3">admin@example.com</p>
                      <span className="badge badge-soft-success me-3 mb-3">
                        <i className="ti ti-checks me-1" />
                        Vérifié
                      </span>
                      <Link to="#" className="btn btn-light mb-3">
                        <i className="ti ti-edit me-2" />
                        Modifier
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center rounded flex-wrap bg-white border rounded p-3 pb-0 mb-3">
                    <div className="mb-3">
                      <h6>Gestion des Appareils</h6>
                      <p>Les appareils associés au compte</p>
                    </div>
                    <div className="mb-3">
                      <Link to="#" className="btn btn-outline-primary">
                        Gérer
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center rounded flex-wrap bg-white border rounded p-3 pb-0 mb-3">
                    <div className="mb-3">
                      <h6>Activité du Compte</h6>
                      <p>Les activités du compte</p>
                    </div>
                    <div className="mb-3">
                      <Link to="#" className="btn btn-outline-primary">
                        Voir
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center rounded flex-wrap bg-white border rounded p-3 pb-0 mb-3">
                    <div className="mb-3">
                      <h6>Désactiver le Compte</h6>
                      <p>
                        Cela désactivera votre compte. Votre compte sera réactivé lorsque
                        vous vous reconnecterez
                      </p>
                    </div>
                    <div className="mb-3">
                      <Link to="#" className="btn btn-outline-primary">
                        Désactiver
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center rounded flex-wrap bg-white border rounded p-3 pb-0 mb-3">
                    <div className="mb-3">
                      <h6>Supprimer le Compte</h6>
                      <p>Votre compte sera définitivement supprimé</p>
                    </div>
                    <div className="mb-3">
                      <Link
                        to="#"
                        className="btn btn-outline-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#delete-modal"
                      >
                        Supprimer
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="delete-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-body text-center">
                <span className="delete-icon">
                  <i className="ti ti-trash-x" />
                </span>
                <h4>Confirmer la Suppression</h4>
                <p>
                  Vous voulez supprimer tous les éléments marqués, cela ne peut pas être
                  annulé une fois supprimé.
                </p>
                <div className="d-flex justify-content-center">
                  <Link
                    to="#"
                    className="btn btn-light me-3"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </Link>
                  <button type="submit" className="btn btn-danger">
                    Oui, Supprimer
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Securitysettings

