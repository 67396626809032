export const classes = [
  {
    id: "C138038",
    class: "I",
    section: "A",
    noOfStudents: 30,
    noOfSubjects: 0o3,
    status: "Active",
    key:1
  },
];
