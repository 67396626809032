import React from "react";
import {
  bedcount,
  hostelType,
  roomtype,
} from "../../../core/common/selectoption/selectoption";
import CommonSelect from "../../../core/common/commonSelect";
import { Link } from "react-router-dom";

const HostelModal = () => {
  return (
    <>
      {/* Ajouter des chambres d'hébergement */}
      <div className="modal fade" id="add_hostel_rooms">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Ajouter une Chambre d'Hébergement</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Numéro de Chambre</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Nom de l'Hébergement</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Type de Chambre</label>
                      <CommonSelect
                        className="select"
                        options={roomtype}
                        defaultValue={undefined}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Nombre de Lits</label>
                      <CommonSelect
                        className="select"
                        options={bedcount}
                        defaultValue={undefined}
                      />
                    </div>
                    <div className="mb-0">
                      <label className="form-label">Coût par Lit</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                >
                  Ajouter la Chambre
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Ajouter des chambres d'hébergement */}

      {/* Modifier Chambre d'Hébergement */}
      <div className="modal fade" id="edit_hostel_rooms">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Modifier Chambre d'Hébergement</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Numéro de Chambre</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Numéro de Chambre"
                        defaultValue="A1"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Nom de l'Hébergement</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Nom de l'Hébergement"
                        defaultValue="Phoenix Residence"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Type de Chambre</label>
                      <CommonSelect
                        className="select"
                        options={roomtype}
                        defaultValue={roomtype[0]}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Nombre de Lits</label>
                      <CommonSelect
                        className="select"
                        options={bedcount}
                        defaultValue={bedcount[0]}
                      />
                    </div>
                    <div className="mb-0">
                      <label className="form-label">Coût par Lit</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Coût par Lit"
                        defaultValue="$200"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                >
                  Enregistrer les Modifications
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modifier Chambre d'Hébergement */}

      {/* Ajouter Type de Chambre */}
      <div className="modal fade" id="add_hostel_room_type">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Ajouter Type de Chambre</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Type de Chambre</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-0">
                      <label className="form-label">Coût par Lit</label>
                      <textarea
                        className="form-control"
                        rows={4}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                >
                  Ajouter le Type de Chambre
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Ajouter Type de Chambre */}

      {/* Modifier Type de Chambre */}
      <div className="modal fade" id="edit_hostel_room_type">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Modifier Type de Chambre</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Type de Chambre</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Type de Chambre"
                        defaultValue="Deux Lits"
                      />
                    </div>
                    <div className="mb-0">
                      <label className="form-label">Coût par Lit</label>
                      <textarea
                        className="form-control"
                        placeholder="Texte"
                        rows={4}
                        defaultValue={
                          "Profitez de la sérénité dans notre chambre à un lit, votre refuge tranquille pour une étude concentrée."
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                >
                  Enregistrer les Modifications
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modifier Type de Chambre */}

      {/* Ajouter Hébergement */}
      <div className="modal fade" id="add_hostel">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Ajouter Hébergement</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Nom de l'Hébergement</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Type d'Hébergement</label>
                      <CommonSelect
                        className="select"
                        options={hostelType}
                        defaultValue={undefined}
                      />
                    </div>
                    <div className="mb-0">
                      <label className="form-label">Coût Total</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                >
                  Ajouter l'Hébergement
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Ajouter Hébergement */}

      {/* Modifier Hébergement */}
      <div className="modal fade" id="edit_hostel">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Modifier Hébergement</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Nom de l'Hébergement</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Nom de l'Hébergement"
                        defaultValue="Résidence Phoenix"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Type d'Hébergement</label>
                      <CommonSelect
                        className="select"
                        options={hostelType}
                        defaultValue={hostelType[0]}
                      />
                    </div>
                    <div className="mb-0">
                      <label className="form-label">Coût Total</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Coût Total"
                        defaultValue="$500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                >
                  Enregistrer les Modifications
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modifier Hébergement */}
    </>
  );
};

export default HostelModal;
