import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";

const LibraryModal = () => {
  return (
    <>
      <>
        {/* Détails du Livre */}
        <div className="modal fade" id="book_details">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Voir les Détails</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <div className="modal-body">
                <div className="view-book">
                  <div className="view-book-title">
                    <h5>Détails de l'Emprunt</h5>
                  </div>
                  <div className="book-issue-details">
                    <div className="book-details-head">
                      <span className="text-primary">IB853629</span>
                      <h6>
                        <span>Date d'Emprunt :</span> 19 Mai 2024
                      </h6>
                    </div>
                    <ul className="book-taker-info">
                      <li>
                        <div className="d-flex align-items-center">
                          <span className="student-img">
                            <ImageWithBasePath
                              src="assets/img/students/student-01.jpg"
                              className="img-fluid rounded-circle"
                              alt="Img"
                            />
                          </span>
                          <h6>
                            Janet <br /> III, A
                          </h6>
                        </div>
                      </li>
                      <li>
                        <span>Numéro d'Inscription</span>
                        <h6>35010</h6>
                      </li>
                      <li>
                        <span>Nom du Livre</span>
                        <h6>Echoes of Eternity</h6>
                      </li>
                      <li>
                        <span>Numéro du Livre</span>
                        <h6>501</h6>
                      </li>
                      <li>
                        <span>Date Limite</span>
                        <h6>19 Mai 2024</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Détails du Livre */}
      </>

      {/* Ajouter un Membre */}
      <div className="modal fade" id="add_library_members">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Ajouter un Membre</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Nom</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Numéro de Carte</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date d'Adhésion</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-0">
                      <label className="form-label">Numéro de Téléphone</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                >
                  Ajouter un Membre
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Ajouter un Membre */}
      {/* Modifier un Membre */}
      <div className="modal fade" id="edit_library_members">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Modifier un Membre</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrer le Nom"
                        defaultValue="James"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Numéro de Carte</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrer le Numéro de Carte"
                        defaultValue={501}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrer l'Email"
                        defaultValue="james@example.com"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Date d'Adhésion</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrer la Date d'Adhésion"
                        defaultValue="22 Avr 2024"
                      />
                    </div>
                    <div className="mb-0">
                      <label className="form-label">Numéro de Téléphone</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrer le Numéro de Téléphone"
                        defaultValue="+1 78429 82414"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                >
                  Sauvegarder les Modifications
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modifier un Membre */}

      <>
        {/* Ajouter un Livre */}
        <div className="modal fade" id="add_library_book">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Ajouter un Livre</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Nom du Livre</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Numéro du Livre</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Numéro de Rack</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Éditeur</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Auteur</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Date de Publication</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Catégorie</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="mb-0">
                        <label className="form-label">Quantité</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </Link>
                  <Link
                    to="#"
                    data-bs-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Ajouter le Livre
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Ajouter un Livre */}
      </>
    </>
  );
};

export default LibraryModal;