import ImageWithBasePath from "../../core/common/imageWithBasePath";
import { Link } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import CommonSelect from "../../core/common/commonSelect";
import {
  assigned,
  internetCategory,
  markAs,
  priorityList,
  staffName,
  statusOption,
  ticketDate,
} from "../../core/common/selectoption/selectoption";
import PredefinedDateRanges from "../../core/common/datePicker";
import TooltipOption from "../../core/common/tooltipOption";

const Tickets = () => {
  const route = all_routes;

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
  <div className="content pb-lg-4 pb-2">
    <div className="row">
      {/* Page Header */}
      <div className="col-md-12">
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <div className="my-auto mb-2">
            <h3 className="page-title mb-1">Billets</h3>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={route.adminDashboard}>Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">Support</li>
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                >
                  Billets
                </li>
              </ol>
            </nav>
          </div>
          <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
            <TooltipOption />
            <div className="mb-2">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#add_ticket"
                className="btn btn-primary"
              >
                <i className="ti ti-square-rounded-plus me-2" />
                Ajouter un nouveau billet
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Header */}
    </div>
    <div className="d-flex align-items-center justify-content-between flex-wrap p-3 bg-white pb-0 mb-4">
      <div className="dropdown me-2 mb-3">
        <Link
          to="#"
          className="dropdown-toggle text-default fw-medium d-inline-flex align-items-center p-1 border-0 fs-18 fw-semibold"
          data-bs-toggle="dropdown"
        >
          Tous les billets
        </Link>
        <ul className="dropdown-menu p-3">
          <li>
            <Link to="#" className="dropdown-item rounded-1">
              Ouvert
            </Link>
          </li>
          <li>
            <Link to="#" className="dropdown-item rounded-1">
              En cours
            </Link>
          </li>
          <li>
            <Link to="#" className="dropdown-item rounded-1">
              Fermé
            </Link>
          </li>
          <li>
            <Link to="#" className="dropdown-item rounded-1">
              Rouvert
            </Link>
          </li>
        </ul>
      </div>
      <div className="d-flex align-items-center flex-wrap">
        <div className="input-icon-start mb-3 me-2 position-relative">
          <PredefinedDateRanges />
        </div>
        <div className="dropdown mb-3 me-2">
          <Link
            to="#"
            className="btn btn-outline-light bg-white dropdown-toggle"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
          >
            <i className="ti ti-filter me-2" />
            Filtrer
          </Link>
          <div className="dropdown-menu drop-width">
            <form action="tickets">
              <div className="d-flex align-items-center border-bottom p-3">
                <h4>Filtrer</h4>
              </div>
              <div className="p-3 pb-0 border-bottom">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Attribué à</label>
                      <CommonSelect
                        className="select"
                        options={assigned}
                        defaultValue={undefined}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Sélectionner une date</label>
                      <CommonSelect
                        className="select"
                        options={ticketDate}
                        defaultValue={undefined}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Statut</label>
                      <CommonSelect
                        className="select"
                        options={priorityList}
                        defaultValue={undefined}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 d-flex align-items-center justify-content-end">
                <Link to="#" className="btn btn-light me-3">
                  Réinitialiser
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                >
                  Appliquer
                </Link>
              </div>
            </form>
          </div>
        </div>
        <div className="d-flex align-items-center bg-white border rounded-2 p-1 mb-3 me-2">
          <Link
            to={route.tickets}
            className="active btn btn-icon btn-sm me-1 primary-hover"
          >
            <i className="ti ti-list-tree" />
          </Link>
          <Link
            to={route.ticketGrid}
            className="btn btn-icon btn-sm bg-light primary-hover"
          >
            <i className="ti ti-grid-dots" />
          </Link>
        </div>
        <div className="dropdown mb-3">
          <Link
            to="#"
            className="btn btn-outline-light bg-white dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            <i className="ti ti-sort-ascending-2 me-2" />
            Trier de A-Z
          </Link>
          <ul className="dropdown-menu p-3">
            <li>
              <Link to="#" className="dropdown-item rounded-1 active">
                Ascendant
              </Link>
            </li>
            <li>
              <Link to="#" className="dropdown-item rounded-1">
                Descendant
              </Link>
            </li>
            <li>
              <Link to="#" className="dropdown-item rounded-1">
                Consulté récemment
              </Link>
            </li>
            <li>
              <Link to="#" className="dropdown-item rounded-1">
                Ajouté récemment
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="row">
      {/* Tickets */}
      <div className="col-xl-8 col-xxl-9">
        {/* Ticket List */}
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between flex-wrap p-3 pb-0">
            <h5 className="text-primary mb-3">Support IT</h5>
            <div className="d-flex align-items-center mb-1">
              <span className="badge badge-danger d-inline-flex align-items-center mb-2 me-4">
                <i className="ti ti-circle-filled fs-5 me-1" />
                Élevé
              </span>
              <div className="mb-2">
                <CommonSelect
                  className="select"
                  options={markAs}
                  defaultValue={markAs[1]}
                />
              </div>
            </div>
          </div>
          <div className="card-body p-3 pb-0">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className="d-flex align-items-center flex-wrap">
                <span className="avatar avatar-xxl me-2 mb-3">
                  <ImageWithBasePath
                    src="assets/img/students/student-11.jpg"
                    alt="img"
                  />
                </span>
                <div className="mb-3">
                  <span className="badge bg-pending rounded-pill mb-1">
                    #TK0003
                  </span>
                  <div className="d-flex align-items-center mb-2">
                    <h5 className="fw-semibold me-2">
                      <Link to={route.ticketDetails}>Problème de portable</Link>
                    </h5>
                    <span className="badge bg-outline-danger d-flex align-items-center ms-1">
                      <i className="ti ti-circle-filled fs-5 me-1" />
                      Ouvert
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-wrap">
                    <p className="d-flex align-items-center me-2 mb-1">
                      <ImageWithBasePath
                        src="assets/img/teachers/teacher-02.jpg"
                        className="avatar avatar-xs rounded-circle me-2"
                        alt="img"
                      />
                      Attribué à
                      <span className="text-dark ms-1"> Daniel</span>
                    </p>
                    <p className="d-flex align-items-center mb-1 me-2">
                      <i className="ti ti-calendar-bolt me-1" />
                      Mis à jour il y a 22 heures
                    </p>
                    <p className="d-flex align-items-center mb-1">
                      <i className="ti ti-message-share me-1" />9 Commentaires
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#ticket_replies"
                  className="btn btn-primary"
                >
                  Voir les réponses
                </Link>
              </div>
            </div>
          </div>
          <div className="card-body p-3 pb-0">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className="d-flex align-items-center flex-wrap">
                <span className="avatar avatar-xxl me-2 mb-3">
                  <ImageWithBasePath
                    src="assets/img/students/student-12.jpg"
                    alt="img"
                  />
                </span>
                <div className="mb-3">
                  <span className="badge bg-pending rounded-pill mb-1">
                    #TK0002
                  </span>
                  <div className="d-flex align-items-center mb-2">
                    <h5 className="fw-semibold me-2">
                      <Link to={route.ticketDetails}>Problème de connexion</Link>
                    </h5>
                    <span className="badge bg-outline-success d-flex align-items-center ms-1">
                      <i className="ti ti-circle-filled fs-5 me-1" />
                      Fermé
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-wrap">
                    <p className="d-flex align-items-center me-2 mb-1">
                      <ImageWithBasePath
                        src="assets/img/teachers/teacher-01.jpg"
                        className="avatar avatar-xs rounded-circle me-2"
                        alt="img"
                      />
                      Attribué à
                      <span className="text-dark ms-1"> Paula</span>
                    </p>
                    <p className="d-flex align-items-center mb-1 me-2">
                      <i className="ti ti-calendar-bolt me-1" />
                      Mis à jour il y a 2 jours
                    </p>
                    <p className="d-flex align-items-center mb-1">
                      <i className="ti ti-message-share me-1" />6 Commentaires
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#ticket_replies"
                  className="btn btn-primary"
                >
                  Voir les réponses
                </Link>
              </div>
            </div>
          </div>
          <div className="card-body p-3 pb-0">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className="d-flex align-items-center flex-wrap">
                <span className="avatar avatar-xxl me-2 mb-3">
                  <ImageWithBasePath
                    src="assets/img/students/student-10.jpg"
                    alt="img"
                  />
                </span>
                <div className="mb-3">
                  <span className="badge bg-pending rounded-pill mb-1">
                    #TK0001
                  </span>
                  <div className="d-flex align-items-center mb-2">
                    <h5 className="fw-semibold me-2">
                      <Link to={route.ticketDetails}>Demande de remboursement</Link>
                    </h5>
                    <span className="badge bg-outline-warning d-flex align-items-center ms-1">
                      <i className="ti ti-circle-filled fs-5 me-1" />
                      En cours
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-wrap">
                    <p className="d-flex align-items-center me-2 mb-1">
                      <ImageWithBasePath
                        src="assets/img/teachers/teacher-03.jpg"
                        className="avatar avatar-xs rounded-circle me-2"
                        alt="img"
                      />
                      Attribué à
                      <span className="text-dark ms-1"> John</span>
                    </p>
                    <p className="d-flex align-items-center mb-1 me-2">
                      <i className="ti ti-calendar-bolt me-1" />
                      Mis à jour il y a 5 jours
                    </p>
                    <p className="d-flex align-items-center mb-1">
                      <i className="ti ti-message-share me-1" />12 Commentaires
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#ticket_replies"
                  className="btn btn-primary"
                >
                  Voir les réponses
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Ticket List */}
        {/* Pagination */}
        <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
          <div>
            <ul className="pagination mb-0">
              <li className="page-item">
                <Link to="#" className="page-link">
                  <i className="ti ti-chevron-left" />
                </Link>
              </li>
              <li className="page-item active">
                <Link to="#" className="page-link">
                  1
                </Link>
              </li>
              <li className="page-item">
                <Link to="#" className="page-link">
                  2
                </Link>
              </li>
              <li className="page-item">
                <Link to="#" className="page-link">
                  3
                </Link>
              </li>
              <li className="page-item">
                <Link to="#" className="page-link">
                  4
                </Link>
              </li>
              <li className="page-item">
                <Link to="#" className="page-link">
                  <i className="ti ti-chevron-right" />
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <p className="mb-0">Affichage 1-3 sur 12 éléments</p>
          </div>
        </div>
        {/* /Pagination */}
      </div>
      {/* /Tickets */}
      {/* Sidebar */}
      <div className="col-xl-4 col-xxl-3">
        {/* Upcoming Events */}
        <div className="card">
          <div className="card-header p-3 d-flex align-items-center justify-content-between flex-wrap border-bottom">
            <h5 className="mb-0">Prochaines Événements</h5>
          </div>
          <div className="card-body p-3">
            <div className="d-flex align-items-center mb-3">
              <span className="avatar avatar-md me-3 bg-light">
                <ImageWithBasePath
                  src="assets/img/events/event-1.jpg"
                  alt="img"
                />
              </span>
              <div>
                <h5 className="text-dark mb-1">
                  <Link to={route.ticketDetails}>
                    Conférence annuelle sur la technologie
                  </Link>
                </h5>
                <p className="text-muted mb-0">
                  25 août 2024 - 10h00
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              <span className="avatar avatar-md me-3 bg-light">
                <ImageWithBasePath
                  src="assets/img/events/event-2.jpg"
                  alt="img"
                />
              </span>
              <div>
                <h5 className="text-dark mb-1">
                  <Link to={route.ticketDetails}>
                    Formation en développement web
                  </Link>
                </h5>
                <p className="text-muted mb-0">
                  5 septembre 2024 - 14h00
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              <span className="avatar avatar-md me-3 bg-light">
                <ImageWithBasePath
                  src="assets/img/events/event-3.jpg"
                  alt="img"
                />
              </span>
              <div>
                <h5 className="text-dark mb-1">
                  <Link to={route.ticketDetails}>
                    Séminaire sur la gestion de projet
                  </Link>
                </h5>
                <p className="text-muted mb-0">
                  12 septembre 2024 - 11h00
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* /Upcoming Events */}
      </div>
      {/* /Sidebar */}
    </div>
  </div>
</div>

      {/* /Page Wrapper */}

      <>
        {/* Add Ticket */}
        <div className="modal fade" id="add_ticket">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Ticket</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Title</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Event Category</label>
                        <CommonSelect
                          className="select"
                          options={internetCategory}
                          defaultValue={undefined}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Subject</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Assign To</label>
                        <CommonSelect
                          className="select"
                          options={staffName}
                          defaultValue={undefined}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Ticket Description</label>
                        <textarea className="form-control" defaultValue={""} />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Priority</label>
                        <CommonSelect
                          className="select"
                          options={priorityList}
                          defaultValue={undefined}
                        />
                      </div>
                      <div className="mb-0">
                        <label className="form-label">Status</label>
                        <CommonSelect
                          className="select"
                          options={statusOption}
                          defaultValue={undefined}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    data-bs-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Add Ticket
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Add Ticket */}
      </>
    </>
  );
};

export default Tickets;
