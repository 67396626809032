import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataTheme,
} from "../../data/redux/themeSettingSlice";
import ImageWithBasePath from "../imageWithBasePath";
import {
  setExpandMenu,
} from "../../data/redux/sidebarSlice";
import { useState } from "react";
import { all_routes } from "../../../feature-module/router/all_routes";

const HeaderMainDashboardMinistries = () => {
  const routes = all_routes;
  const dispatch = useDispatch();
  const dataTheme = useSelector((state: any) => state.themeSetting.dataTheme);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [menuState, setMenuState] = useState("main"); // "main" ou "academicYear" pour les sous-menus


  const onMouseEnter = () => {
    dispatch(setExpandMenu(true));
  };
  const onMouseLeave = () => {
    dispatch(setExpandMenu(false));
  };


  const handleToggleClick = () => {
    if (dataTheme === "default_data_theme") {
      dispatch(setDataTheme("dark_data_theme"));
    } else {
      dispatch(setDataTheme("default_data_theme"));
    }
  };

  const location = useLocation();
  const toggleNotification = () => {
    setNotificationVisible(!notificationVisible);
  };

  const [isFullscreen, setIsFullscreen] = useState(false);
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen().catch((err) => {});
        setIsFullscreen(true);
      }
    } else {
      if (document.exitFullscreen) {
        if (document.fullscreenElement) {
          document.exitFullscreen().catch((err) => {});
        }
        setIsFullscreen(false);
      }
    }
  };
  const handleMenuClick = (e : any) => {
    e.stopPropagation(); // Empêche la propagation du clic
  };

  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div
          className="header-left active"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <h3 style={{marginLeft: 50, marginTop: 10}}>Ministère</h3>
        </div>
        {/* /Logo */}
        <div className="header-user">
          <div className="nav user-menu">
            {/* Search */}
            <div className="nav-item nav-search-inputs me-auto">
              <div className="top-nav-search"></div>
            </div>
            {/* /Search */}
            <div className="d-flex align-items-center">
              <div className="pe-1">
                {!location.pathname.includes("layout-dark") && (
                  <Link
                    onClick={handleToggleClick}
                    to="#"
                    id="dark-mode-toggle"
                    className="dark-mode-toggle activate btn btn-outline-light bg-white btn-icon me-1"
                  >
                    <i
                      className={
                        dataTheme === "default_data_theme"
                          ? "ti ti-moon"
                          : "ti ti-brightness-up"
                      }
                    />
                  </Link>
                )}
              </div>
              <div
                className={`pe-1 ${
                  notificationVisible ? "notification-item-show" : ""
                }`}
                id="notification_item"
              >
                <Link
                  onClick={toggleNotification}
                  to="#"
                  className="btn btn-outline-light bg-white btn-icon position-relative me-1"
                  id="notification_popup"
                >
                  <i className="ti ti-bell" />
                  <span className="notification-status-dot" />
                </Link>
                <div className="dropdown-menu dropdown-menu-end notification-dropdown p-4">
                  <div className="d-flex align-items-center justify-content-between border-bottom p-0 pb-3 mb-3">
                    <h4 className="notification-title">Notifications (2)</h4>
                    <div className="d-flex align-items-center">
                      <Link to="#" className="text-primary fs-15 me-3 lh-1">
                        Marquer tout comme lu
                      </Link>
                      <div className="dropdown">
                        <Link
                          to="#"
                          className="bg-white dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          <i className="ti ti-calendar-due me-1" />
                          Aujourd'hui
                        </Link>
                        <ul className="dropdown-menu mt-2 p-3">
                          <li>
                            <Link to="#" className="dropdown-item rounded-1">
                              Cette semaine
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="dropdown-item rounded-1">
                              La semaine dernière
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="dropdown-item rounded-1">
                              La semaine dernière
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="noti-content">
                    <div className="d-flex flex-column">
                      <div className="border-bottom mb-3 pb-3">
                        <Link to={routes.activity}>
                          <div className="d-flex">
                            <span className="avatar avatar-lg me-2 flex-shrink-0">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-27.jpg"
                                alt="Profil"
                              />
                            </span>
                            <div className="flex-grow-1">
                              <p className="mb-1">
                                <span className="text-dark fw-semibold">
                                  Shawn
                                </span>{" "}
                                performance en Math est en dessous du seuil.
                              </p>
                              <span>À l'instant</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="border-bottom mb-3 pb-3">
                        <Link to={routes.activity} className="pb-0">
                          <div className="d-flex">
                            <span className="avatar avatar-lg me-2 flex-shrink-0">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-23.jpg"
                                alt="Profil"
                              />
                            </span>
                            <div className="flex-grow-1">
                              <p className="mb-1">
                                <span className="text-dark fw-semibold">
                                  Sylvia
                                </span>{" "}
                                a ajouté un rendez-vous à 14:00
                              </p>
                              <span>Il y a 10 minutes</span>
                              <div className="d-flex justify-content-start align-items-center mt-1">
                                <span className="btn btn-light btn-sm me-2">
                                  Refuser
                                </span>
                                <span className="btn btn-primary btn-sm">
                                  Approuver
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="border-bottom mb-3 pb-3">
                        <Link to={routes.activity}>
                          <div className="d-flex">
                            <span className="avatar avatar-lg me-2 flex-shrink-0">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-25.jpg"
                                alt="Profil"
                              />
                            </span>
                            <div className="flex-grow-1">
                              <p className="mb-1">
                                Nouveau dossier étudiant{" "}
                                <span className="text-dark fw-semibold">
                                  George
                                </span>{" "}
                                créé par{" "}
                                <span className="text-dark fw-semibold">
                                  Teressa
                                </span>
                              </p>
                              <span>Il y a 2 heures</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="border-0 mb-3 pb-0">
                        <Link to={routes.activity}>
                          <div className="d-flex">
                            <span className="avatar avatar-lg me-2 flex-shrink-0">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-01.jpg"
                                alt="Profil"
                              />
                            </span>
                            <div className="flex-grow-1">
                              <p className="mb-1">
                                Un nouveau dossier enseignant pour{" "}
                                <span className="text-dark fw-semibold">
                                  Elisa
                                </span>
                              </p>
                              <span>09:45 AM</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex p-0">
                    <Link to="#" className="btn btn-light w-100 me-2">
                      Annuler
                    </Link>
                    <Link to={routes.activity} className="btn btn-primary w-100">
                      Voir Tout
                    </Link>
                  </div>
                </div>
              </div>
              <div className="pe-1">
                <Link
                  onClick={toggleFullscreen}
                  to="#"
                  className="btn btn-outline-light bg-white btn-icon me-1"
                  id="btnFullscreen"
                >
                  <i className="ti ti-maximize" />
                </Link>
              </div>
              <div className="dropdown ms-1">
                <Link
                  to="#"
                  className="dropdown-toggle d-flex align-items-center"
                  data-bs-toggle="dropdown"
                >
                  <span className="avatar avatar-md rounded">
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-27.jpg"
                      alt="Img"
                      className="img-fluid"
                    />
                  </span>
                </Link>
                <div className="dropdown-menu">
                  <div className="d-block">
                    <div className="d-flex align-items-center p-2">
                      <span className="avatar avatar-md me-2 online avatar-rounded">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-27.jpg"
                          alt="img"
                        />
                      </span>
                      <div>
                        <h6>Kevin Larry</h6>
                        <p className="text-primary mb-0">Administrateur</p>
                      </div>
                    </div>
                    <hr className="m-0" />
                    <Link
                      className="dropdown-item d-inline-flex align-items-center p-2"
                      to={routes.login}
                    >
                      <i className="ti ti-login me-2" />
                      Déconnexion
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={() => setMenuState("main")}
          >
            <i className="fa fa-ellipsis-v" />
          </Link>
          <div className="dropdown-menu dropdown-menu-end">
            {menuState === "main" && (
              <>
                <Link className="dropdown-item" to="#" onClick={handleToggleClick}>
                  <i className="ti ti-moon me-2" />
                  Changer le thème
                </Link>
                <Link className="dropdown-item" to="#" onClick={e => {
                    handleMenuClick(e)
                    setMenuState("notifications")
                }}>
                  <i className="ti ti-bell me-2" />
                  Notifications
                  <span className="notification-status-dot" />
                </Link>
                <Link className="dropdown-item" to={routes.login}>
                  <i className="ti ti-login me-2" />
                  Déconnexion
                </Link>
              </>
            )}

            {menuState === "notifications" && (
              <>
                <Link 
                className="nav-link dropdown-toggle dropdown-item" 
                to="#"
                onClick={e => {
                  handleMenuClick(e);
                  setMenuState("main");
                  }}>
                  <i className="ti ti-arrow-left me-2" />
                  Retour
                </Link>
                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                  <div className="d-flex align-items-center justify-content-between border-bottom p-0 pb-2">
                    <h4 className="notification-title mb-0">Notifications (2)</h4>
                  </div>

                  <div className="noti-content">
                    <div className="d-flex flex-column">
                      <div className="border-bottom pb-2">
                        <div className="d-flex">
                          <span className="avatar me-2 flex-shrink-0">
                            <ImageWithBasePath src="assets/img/profiles/avatar-27.jpg" alt="Profil" />
                          </span>
                          <div className="flex-grow-1">
                            <p className="mb-1 text-truncate">
                              <span className="fw-semibold">Shawn</span> a un problème en Math.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="border-bottom pb-2">
                        <div className="d-flex">
                          <span className="avatar me-2 flex-shrink-0">
                            <ImageWithBasePath src="assets/img/profiles/avatar-23.jpg" alt="Profil" />
                          </span>
                          <div className="flex-grow-1">
                            <p className="mb-1 text-truncate">
                              <span className="fw-semibold">Sylvia</span> a ajouté un rendez-vous.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </>
            )}
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
      {/* /Header */}
    </>
  );
};

export default HeaderMainDashboardMinistries;