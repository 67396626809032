export const Etablissementlist = [
  {
    key: "1",
    name: "Lycée de la Réussite",
    type: "Centre",
    cadre: "Exotic",
    city: "Brazzaville",
    country: "Congo",
    status: "Actif",
    imgSrc: "assets/img/icons/school.svg"
  },
  {
    key: "2",
    name: "Institut Supérieur de Technologie",
    type: "Université",
    cadre: "Privé",
    city: "Pointe-Noire",
    country: "Congo",
    status: "Actif",
    imgSrc: "assets/img/icons/school.svg"
  },
  {
    key: "3",
    name: "Collège Les Savoirs",
    type: "Centre",
    cadre: "Public",
    city: "Brazzaville",
    country: "Congo",
    status: "Actif",
    imgSrc: "assets/img/icons/school.svg"
  },
  {
    key: "4",
    name: "École Ecole Excellence",
    type: "Ecole",
    cadre: "Privé",
    city: "Dolisie",
    country: "Congo",
    status: "Actif",
    imgSrc: "assets/img/icons/school.svg"
  },
  {
    key: "5",
    name: "Université Marien Ngouabi",
    type: "Université",
    cadre: "Public",
    city: "Brazzaville",
    country: "Congo",
    status: "Actif",
    imgSrc: "assets/img/icons/school.svg"
  },
  {
    key: "6",
    name: "Académie des Sciences et Technologies",
    type: "Centre",
    cadre: "Privé",
    city: "Brazzaville",
    country: "Congo",
    status: "Inactif",
    imgSrc: "assets/img/icons/school.svg"
  },
  {
    key: "7",
    name: "Lycée Moderne de Pointe-Noire",
    type: "Centre",
    cadre: "Public",
    city: "Pointe-Noire",
    country: "Congo",
    status: "Actif",
    imgSrc: "assets/img/icons/school.svg"
  },
  {
    key: "8",
    name: "École Privée Innovante",
    type: "Ecole",
    cadre: "Privé",
    city: "Owando",
    country: "Congo",
    status: "Inactif",
    imgSrc: "assets/img/icons/school.svg"
  },
  {
    key: "9",
    name: "Collège Technique Industriel",
    type: "Centre",
    cadre: "Public",
    city: "Brazzaville",
    country: "Congo",
    status: "Actif",
    imgSrc: "assets/img/icons/school.svg"
  },
  {
    key: "10",
    name: "Université du Congo",
    type: "Université",
    cadre: "Privé",
    city: "Brazzaville",
    country: "Congo",
    status: "Actif",
    imgSrc: "assets/img/icons/school.svg"
  }
];
