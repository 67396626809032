import React, { useState } from "react";
import {
  bloodGroup,
  Contract,
  gender,
  Hostel,
  Marital,
  PickupPoint,
  roomno,
  route,
  Shift,
  staffDepartment,
  staffrole,
  VehicleNumber,
} from "../../../../core/common/selectoption/selectoption";
import CommonSelect from "../../../../core/common/commonSelect";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { DatePicker } from "antd";
import { TagsInput } from "react-tag-input-component";

const EditStaff = () => {
  const routes = all_routes;
  const [owner, setOwner] = useState<string[]>([]);
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content content-two">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
  <div className="my-auto mb-2">
    <h3 className="mb-1">Modifier le Personnel</h3>
    <nav>
      <ol className="breadcrumb mb-0">
        <li className="breadcrumb-item">
          <Link to={routes.adminDashboard}>Tableau de Bord</Link>
        </li>
        <li className="breadcrumb-item">RH</li>
        <li className="breadcrumb-item active" aria-current="page">
          Modifier le Personnel
        </li>
      </ol>
    </nav>
  </div>
</div>

            {/* /Page Header */}
            <div className="row">
              <div className="col-md-12">
                <form >
                  {/* Personal Information */}
                  
                  
                  <div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-info-square-rounded fs-16" />
      </span>
      <h4 className="text-dark">Informations Personnelles</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="add-section">
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
              <i className="ti ti-photo-plus fs-16" />
            </div>
            <div className="profile-upload">
              <div className="profile-uploader d-flex align-items-center">
                <div className="drag-upload-btn mb-3">
                  Télécharger
                  <input
                    type="file"
                    className="form-control image-sign"
                    multiple
                  />
                </div>
                <Link to="#" className="btn btn-primary mb-3">
                  Supprimer
                </Link>
              </div>
              <p className="fs-12">
                Taille d'image max 4MB, Formats acceptés JPG, PNG, SVG
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-cols-xxl-5 row-cols-md-6">
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Prénom</label>
            <input
              type="text"
              className="form-control"
              defaultValue="Kevin"
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Nom</label>
            <input
              type="text"
              className="form-control"
              defaultValue="Larry"
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Rôle</label>
            <CommonSelect
              className="select"
              options={staffrole}
              defaultValue={staffrole[0]}
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Département</label>
            <CommonSelect
              className="select"
              options={staffDepartment}
              defaultValue={staffDepartment[0]}
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Désignation</label>
            <CommonSelect
              className="select"
              options={staffrole}
              defaultValue={staffrole[0]}
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Genre</label>
            <CommonSelect
              className="select"
              options={gender}
              defaultValue={gender[1]}
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Numéro de Contact Principal
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue="+1 63423 72397"
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Adresse Email</label>
            <input
              type="email"
              className="form-control"
              defaultValue="kevin@example.com"
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Groupe Sanguin</label>
            <CommonSelect
              className="select"
              options={bloodGroup}
              defaultValue={bloodGroup[0]}
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">État Civil</label>
            <CommonSelect
              className="select"
              options={Marital}
              defaultValue={Marital[0]}
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Nom du Père</label>
            <input
              type="text"
              className="form-control"
              defaultValue="Janet"
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Nom de la Mère</label>
            <input
              type="text"
              className="form-control"
              defaultValue="Daniel"
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Date de Naissance</label>
            <div className="input-icon position-relative">
              <span className="input-icon-addon">
                <i className="ti ti-calendar" />
              </span>
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                placeholder="Sélectionner une date"
              />
            </div>
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Date d'Embauche</label>
            <div className="input-icon position-relative">
              <span className="input-icon-addon">
                <i className="ti ti-calendar" />
              </span>
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                placeholder="Sélectionner une date"
              />
            </div>
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Langues Connues</label>
            <TagsInput
              value={owner}
              onChange={setOwner}
            />
          </div>
        </div>
        <div className="col-xxl-4 col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Qualification</label>
            <input
              type="text"
              className="form-control"
              defaultValue="M.Sc"
            />
          </div>
        </div>
        <div className="col-xxl-4 col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Expérience Professionnelle</label>
            <input
              type="text"
              className="form-control"
              defaultValue={5}
            />
          </div>
        </div>
        <div className="col-xxl-4  col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Remarque</label>
            <input
              type="text"
              className="form-control"
              defaultValue="Description"
            />
          </div>
        </div>
        <div className="col-xxl-6 col-xl-3  col-md-6">
          <div className="mb-3">
            <label className="form-label">Adresse</label>
            <input
              type="text"
              className="form-control"
              defaultValue="3495 Red Hawk Road, Buffalo Lake, MN 55314"
            />
          </div>
        </div>
        <div className="col-xxl-6 col-xl-3  col-md-6">
          <div className="mb-3">
            <label className="form-label">Adresse Permanente</label>
            <input
              type="text"
              className="form-control"
              defaultValue="3495 Red Hawk Road, Buffalo Lake, MN 55314"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>;


                  {/* H */}


                  {/* /Leaves */}
                  {/* Bank Details */}
                  <div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-users fs-16" />
      </span>
      <h4 className="text-map">Détails du Compte Bancaire</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Nom du Titulaire</label>
          <input
            type="text"
            className="form-control"
            defaultValue="Kevin"
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Numéro de Compte</label>
          <input
            type="text"
            className="form-control"
            defaultValue={178849035684}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Nom de la Banque</label>
          <input
            type="text"
            className="form-control"
            defaultValue="Bank of America"
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Code IFSC</label>
          <input
            type="text"
            className="form-control"
            defaultValue="BOA83209832"
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Nom de l'Agence</label>
          <input
            type="text"
            className="form-control"
            defaultValue="Cincinnati"
          />
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Détails du Compte Bancaire */}
{/* Informations sur le Transport */}
<div className="card">
  <div className="card-header bg-light d-flex align-items-center justify-content-between">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-bus-stop fs-16" />
      </span>
      <h4 className="text-dark">Informations sur le Transport</h4>
    </div>
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
      />
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Itinéraire</label>
          <CommonSelect
            className="select"
            options={route}
            defaultValue={route[0]}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Numéro du Véhicule</label>
          <CommonSelect
            className="select"
            options={VehicleNumber}
            defaultValue={VehicleNumber[0]}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Point de Ramassage</label>
          <CommonSelect
            className="select"
            options={PickupPoint}
            defaultValue={PickupPoint[0]}
          />
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Informations sur le Transport */}
{/* Informations sur l'Auberge */}
<div className="card">
  <div className="card-header bg-light d-flex align-items-center justify-content-between">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-building-fortress fs-16" />
      </span>
      <h4 className="text-dark">Informations sur l'Auberge</h4>
    </div>
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
      />
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Auberge</label>
          <CommonSelect
            className="select"
            options={Hostel}
            defaultValue={Hostel[0]}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Numéro de Chambre</label>
          <CommonSelect
            className="select"
            options={roomno}
            defaultValue={roomno[0]}
          />
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Informations sur l'Auberge */}
{/* Liens des Réseaux Sociaux */}
<div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-building fs-16" />
      </span>
      <h4 className="text-dark">Liens des Réseaux Sociaux</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">URL Facebook</label>
          <input type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">URL Twitter</label>
          <input type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">URL LinkedIn</label>
          <input type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">URL Instagram</label>
          <input type="text" className="form-control" />
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Liens des Réseaux Sociaux */}
{/* Documents */}
<div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-file fs-16" />
      </span>
      <h4 className="text-dark">Documents</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-lg-6">
        <div className="mb-2">
          <div className="mb-3">
            <label className="form-label">Télécharger le CV</label>
            <p>Taille d'image max 4MB, Format accepté PDF</p>
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <div className="btn btn-primary drag-upload-btn mb-2 me-2">
              <i className="ti ti-file-upload me-1" />
              Changer
              <input
                type="file"
                className="form-control image_sign"
                multiple
              />
            </div>
            <p className="mb-2">Resume.pdf</p>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="mb-2">
          <div className="mb-3">
            <label className="form-label">Télécharger la Lettre d'Embauche</label>
            <p>Taille d'image max 4MB, Format accepté PDF</p>
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <div className="btn btn-primary drag-upload-btn mb-2">
              <i className="ti ti-file-upload me-1" />
              Télécharger le Document
              <input
                type="file"
                className="form-control image_sign"
                multiple
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Documents */}
{/* Mot de Passe */}
<div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-file fs-16" />
      </span>
      <h4 className="text-dark">Mot de Passe</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Nouveau Mot de Passe</label>
          <input type="password" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Confirmer le Mot de Passe</label>
          <input type="password" className="form-control" />
        </div>
      </div>
    </div>
  </div>
</div>

                  {/* /Password */}
                  <div className="text-end">
                    <button type="button" className="btn btn-light me-3">
                      Annuler
                    </button>
                    <Link to={routes.staff} className="btn btn-primary">
                      Sauvegarder les changements
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </div>
  );
};

export default EditStaff;
