export const guardianListData = [
  {
    key: "1",
    id: "G153735",
    GuardianImage: "assets/img/guardians/guardian-01.jpg",
    ChildImage: "assets/img/students/student-01.jpg",
    name: "William",
    Addedon: "Ajouté le 25 mars 2024",
    Child: "Janet",
    class: "III, A",
    phone: "+1 82392 37359",
    email: "william@example.com",
  }  
];