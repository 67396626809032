export const teacherLists = [
    {
      key: "1",
      img: "assets/img/teachers/teacher-01.jpg",
      id: "T849127",
      name: "Teresa",
      class: "III A",
      subject: "Physics",
      email: "teresa@example.com",
      phone: "+1 82392 37359",
      dateofJoin: "25 Mar 2024",
      status: "Active",
      statusclass: "badge badge-soft-success",
    },
   
];