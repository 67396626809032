import React from "react";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import Table from "../../../../core/common/dataTable/index";
import { staffleave } from "../../../../core/data/json/staff-leave";
import { TableData } from "../../../../core/data/interface";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import CommonSelect from "../../../../core/common/commonSelect";
import { leaveType } from "../../../../core/common/selectoption/selectoption";
import { DatePicker } from "antd";

const StaffLeave = () => {
  const data = staffleave;
  const routes = all_routes;
  const getModalContainer = () => {
    const modalElement = document.getElementById('modal-datepicker');
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
   const getModalContainer2 = () => {
    const modalElement = document.getElementById('modal-datepicker2');
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const columns = [
    {
      title: "Type de Congé",
      dataIndex: "leaveType",
      sorter: (a: TableData, b: TableData) =>
        a.leaveType.length - b.leaveType.length,
    },
    {
      title: "Date de Congé",
      dataIndex: "leaveDate",
      sorter: (a: TableData, b: TableData) =>
        a.leaveDate.length - b.leaveDate.length,
    },
    {
      title: "Nombre de Jours",
      dataIndex: "noOfDays",
      sorter: (a: TableData, b: TableData) =>
        a.noOfDays.length - b.noOfDays.length,
    },
    {
      title: "Date de Demande",
      dataIndex: "appliedOn",
      sorter: (a: TableData, b: TableData) =>
        a.appliedOn.length - b.appliedOn.length,
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: (text: string) => (
        <>
          {text === "Approved" ? (
            <span className="badge badge-soft-success d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : (
            <span className="badge badge-soft-pending d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.status.length - b.status.length,
    },
  
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to={routes.editStaff}
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Modifier
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                  >
                    <i className="ti ti-trash-x me-2" />
                    Supprimer
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              {/* Page Header */}
              <div className="col-md-12">
                <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
                  <div className="my-auto mb-2">
                    <h3 className="page-title mb-1">Détails du Personnel</h3>
                    <nav>
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <Link to={routes.adminDashboard}>Tableau de Bord</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to={routes.studentList}>HRM</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Détails du Personnel
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="d-flex my-xl-auto right-content align-items-center  flex-wrap">
                    <Link
                      to={routes.editStaff}
                      className="btn btn-primary d-flex align-items-center mb-2"
                    >
                      <i className="ti ti-edit-circle me-2" />
                      Modifier le Personnel
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="col-xxl-3 col-lg-4 theiaStickySidebar">
                <div className="stickybar">
                  <div className="card border-white">
                    <div className="card-header">
                      <div className="d-flex align-items-center  row-gap-3">
                        <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-27.jpg"
                            className="img-fluid"
                            alt="img"
                          />
                        </div>
                        <div>
                          <span className="badge badge-soft-success d-inline-flex align-items-center mb-1">
                            <i className="ti ti-circle-filled fs-5 me-1" />
                            Actif
                          </span>
                          <h5 className="mb-1">Kevin Larry</h5>
                          <p className="text-primary m-0">AD1256589</p>
                          <p className="p-0">Rejoint le : 10 Mar 2024</p>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="mb-3">Informations de Base</h5>
                      <dl className="row mb-0">
                        <dt className="col-6 fw-medium text-dark mb-3">
                          ID du Personnel
                        </dt>
                        <dd className="col-6  mb-3">35013</dd>
                        <dt className="col-6 fw-medium text-dark mb-3">Genre</dt>
                        <dd className="col-6  mb-3">Homme</dd>
                        <dt className="col-6 fw-medium text-dark mb-3">
                          Poste
                        </dt>
                        <dd className="col-6  mb-3">25 Jan 2008</dd>
                        <dt className="col-6 fw-medium text-dark mb-3">
                          Département
                        </dt>
                        <dd className="col-6  mb-3">Responsable Technique</dd>
                        <dt className="col-6 fw-medium text-dark mb-3">
                          Date de Naissance
                        </dt>
                        <dd className="col-6  mb-3">Admin</dd>
                        <dt className="col-6 fw-medium text-dark mb-3">
                          Groupe Sanguin
                        </dt>
                        <dd className="col-6  mb-3">15 Août 1987</dd>
                        <dt className="col-6 fw-medium text-dark mb-3">
                          Groupe Sanguin
                        </dt>
                        <dd className="col-6  mb-3">O+</dd>
                        <dt className="col-6 fw-medium text-dark mb-3">
                          Langue Maternelle
                        </dt>
                        <dd className="col-6  mb-3">Anglais</dd>
                        <dt className="col-6 fw-medium text-dark mb-0">
                          Langue
                        </dt>
                        <dd className="col-6 text-dark mb-0">
                          <span className="badge badge-light text-dark me-2">
                            Anglais
                          </span>
                          <span className="badge badge-light text-dark">
                            Espagnol
                          </span>
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div className="card border-white">
                    <div className="card-body">
                      <h5 className="mb-3">Informations de Contact Principal</h5>
                      <div className="d-flex align-items-center mb-3">
                        <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                          <i className="ti ti-phone" />
                        </span>
                        <div>
                          <span className="fs-12 mb-1 fw-medium text-dark ">
                            Numéro de Téléphone
                          </span>
                          <p>+1 46548 84498</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                          <i className="ti ti-mail" />
                        </span>
                        <div>
                          <span className="fs-12 mb-1 fw-medium text-dark ">
                            Adresse Email
                          </span>
                          <p>jan@example.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-9 col-lg-8">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                      <li>
                        <Link
                          to={routes.staffDetails}
                          className="nav-link"
                        >
                          <i className="ti ti-info-square-rounded me-2" />
                          Détails de Base
                        </Link>
                      </li>
                      <li>
                        <Link to={routes.staffPayroll} className="nav-link ">
                          <i className="ti ti-file-dollar me-2" />
                          Paie
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={routes.staffLeave}
                          className="nav-link active"
                        >
                          <i className="ti ti-calendar-due me-2" />
                          Congés
                        </Link>
                      </li>
                      <li>
                        <Link to={routes.staffsAttendance} className="nav-link">
                          <i className="ti ti-calendar-due me-2" />
                          Présence
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="pills-leave"
                    role="tabpanel"
                    aria-labelledby="pills-leave"
                  >
                    <div className="row gx-3">
                      <div className="col-lg-6 col-xxl-3 d-flex">
                        <div className="card flex-fill">
                          <div className="card-body">
                            <h5 className="mb-2">Congés Médicaux (10)</h5>
                            <div className="d-flex align-items-center flex-wrap">
                              <p className="border-end pe-2 me-2 mb-0">
                                Utilisés : 5
                              </p>
                              <p className="mb-0">Disponibles : 5</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xxl-3 d-flex">
                        <div className="card flex-fill">
                          <div className="card-body">
                            <h5 className="mb-2">Congés Annuels (12)</h5>
                            <div className="d-flex align-items-center flex-wrap">
                              <p className="border-end pe-2 me-2 mb-0">
                                Utilisés : 1
                              </p>
                              <p className="mb-0">Disponibles : 11</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xxl-3 d-flex">
                        <div className="card flex-fill">
                          <div className="card-body">
                            <h5 className="mb-2">Congés Maternité (10)</h5>
                            <div className="d-flex align-items-center flex-wrap">
                              <p className="border-end pe-2 me-2 mb-0">
                                Utilisés : 0
                              </p>
                              <p className="mb-0">Disponibles : 10</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xxl-3 d-flex">
                        <div className="card flex-fill">
                          <div className="card-body">
                            <h5 className="mb-2">Congés Paternité (0)</h5>
                            <div className="d-flex align-items-center flex-wrap">
                              <p className="border-end pe-2 me-2 mb-0">
                                Utilisés : 0
                              </p>
                              <p className="mb-0">Disponibles : 0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      {/* Liste des Congés */}
                      <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                        <h4 className="mb-3">Congés</h4>
                        <Link
                          to="#"
                          data-bs-target="#apply_leave"
                          data-bs-toggle="modal"
                          className="btn btn-primary d-inline-flex align-items-center mb-3"
                        >
                          <i className="ti ti-calendar-event me-2" />
                          Demander un Congé
                        </Link>
                      </div>
                      <div className="card-body p-0 py-3">
                        <Table
                          columns={columns}
                          dataSource={data}
                          Selection={true}
                        />
                      </div>
                      {/* /Liste des Congés */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
        {/* Demander un Congé */}
        <div className="modal fade" id="apply_leave">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Demander un Congé</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form >
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-4">
                        <label className="form-label">Date du Congé</label>
                        <div className="date-pic">
                          <DatePicker
                            className="form-control datetimepicker"
                            format={{
                              format: "DD-MM-YYYY",
                              type: "mask",
                            }}
                            getPopupContainer={getModalContainer}
                            defaultValue=""
                            placeholder="16 Mai 2024"
                          />
                          <span className="cal-icon">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                      </div>
                      <div className="mb-4">
                        <label className="form-label">Type de Congé</label>
                        <CommonSelect
                          className="select"
                          options={leaveType}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="form-label">Début du Congé</label>
                        <div className="date-pic">
                          <DatePicker
                            className="form-control datetimepicker"
                            format={{
                              format: "DD-MM-YYYY",
                              type: "mask",
                            }}
                            getPopupContainer={getModalContainer2}
                            defaultValue=""
                            placeholder="16 Mai 2024"
                          />
                          <span className="cal-icon">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                      </div>
                      <div className="mb-4">
                        <label className="form-label">Fin du Congé</label>
                        <div className="date-pic">
                          <DatePicker
                            className="form-control datetimepicker"
                            format={{
                              format: "DD-MM-YYYY",
                              type: "mask",
                            }}
                            getPopupContainer={getModalContainer}
                            defaultValue=""
                            placeholder="16 Mai 2024"
                          />
                          <span className="cal-icon">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                      </div>
                      <div className="mb-4">
                        <label className="form-label">Type de Congé</label>
                        <div className="d-flex align-items-center check-radio-group">
                          <label className="custom-radio">
                            {/* <input type="radio" name="radio" defaultChecked="" /> */}
                            <span className="checkmark" />
                            Journée Complète
                          </label>
                          <label className="custom-radio">
                            <input type="radio" name="radio" />
                            <span className="checkmark" />
                            Première Moitié
                          </label>
                          <label className="custom-radio">
                            <input type="radio" name="radio" />
                            <span className="checkmark" />
                            Seconde Moitié
                          </label>
                        </div>
                      </div>
                      <div className="mb-4">
                        <label className="form-label">Nombre de Jours</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="mb-0">
                        <label className="form-label">Raisons</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </Link>
                  <Link to="#" className="btn btn-primary"  data-bs-dismiss="modal"> 
                    Demander un Congé
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Demander un Congé */}
        {/* Modal de Suppression */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form >
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirmer la Suppression</h4>
                  <p>
                    Vous souhaitez supprimer tous les éléments marqués, cela ne peut pas être annulé une fois supprimé.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Annuler
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Oui, Supprimer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Modal de Suppression */}
      </>
    </div>
  );
  
};

export default StaffLeave;
