import React, { useRef } from "react";
import { classRoutine } from "../../../core/data/json/class-routine";
import Table from "../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../core/common/datePicker";
import CommonSelect from "../../../core/common/commonSelect";
import {
  allClass,
  classSection,
  count,
  routinename,
  teacher,
  weak,
} from "../../../core/common/selectoption/selectoption";
import { TimePicker } from "antd";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import TooltipOption from "../../../core/common/tooltipOption";


const ClassRoutine = () => {
  const routes = all_routes;
  const isMinistryEtablissement = localStorage.getItem("ministry-etablissement") 
? JSON.parse(localStorage.getItem("ministry-etablissement") as string) 
: null;
  const data = classRoutine;
  const getModalContainer = () => {
    const modalElement = document.getElementById("modal-datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const getModalContainer2 = () => {
    const modalElement = document.getElementById("modal_datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const getModalContainer3= () => {
    const modalElement = document.getElementById("modal_datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const getModalContainer4= () => {
    const modalElement = document.getElementById("modal_datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
      sorter: (a: any, b: any) => a.id.length - b.id.length,
    },

    {
      title: "Classe",
      dataIndex: "class",
      sorter: (a: any, b: any) => a.class.length - b.class.length,
    },
    {
      title: "Section",
      dataIndex: "section",
      sorter: (a: any, b: any) => a.section.length - b.section.length,
    },
    {
      title: "Enseignant",
      dataIndex: "teacher",
      sorter: (a: any, b: any) => a.teacher.length - b.teacher.length,
    },
    {
      title: "Matière",
      dataIndex: "subject",
      sorter: (a: any, b: any) => a.subject.length - b.subject.length,
    },
    {
      title: "Jour",
      dataIndex: "day",
      sorter: (a: any, b: any) => a.day.length - b.day.length,
    },
    {
      title: "Heure de début",
      dataIndex: "startTime",
      sorter: (a: any, b: any) => a.startTime.length - b.startTime.length,
    },
    {
      title: "Heure de fin",
      dataIndex: "endTime",
      sorter: (a: any, b: any) => a.endTime.length - b.endTime.length,
    },
    {
      title: "Salle de classe",
      dataIndex: "classRoom",
      sorter: (a: any, b: any) => a.classRoom.length - b.classRoom.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <>
          {
            !isMinistryEtablissement &&
            <div className="dropdown">
            <Link
              to="#"
              className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="ti ti-dots-vertical fs-14" />
            </Link>
            <ul className="dropdown-menu dropdown-menu-right p-3">
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#edit_class_routine"
                >
                  <i className="ti ti-edit-circle me-2" />
                  Modifier
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#delete-modal"
                >
                  <i className="ti ti-trash-x me-2" />
                  Supprimer
                </Link>
              </li>
            </ul>
          </div>
          }
        </>
      ),
    },
  ];

  return (
    <div>
      <>
  {/* Page Wrapper */}
  <div className="page-wrapper">
    <div className="content">
      {/* Page Header */}
      <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
        <div className="my-auto mb-2">
          <h3 className="page-title mb-1">Emploi du Temps</h3>
          <nav>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to={routes.adminDashboard}>Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="#">Académique</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Emploi du Temps
              </li>
            </ol>
          </nav>
        </div>
        <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
          <TooltipOption />
          {
            !isMinistryEtablissement &&
            <div className="mb-2">
              <Link
                to="#"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#add_class_routine"
              >
                <i className="ti ti-square-rounded-plus-filled me-2" />
                Ajouter un emploi du temps
              </Link>
            </div>
          }
          
        </div>
      </div>
      {/* /Page Header */}
      {/* Liste des tuteurs */}
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
          <h4 className="mb-3">Emploi du Temps</h4>
          <div className="d-flex align-items-center flex-wrap">
            <div className="input-icon-start mb-3 me-2 position-relative">
              <PredefinedDateRanges />
            </div>
            <div className="dropdown mb-3 me-2">
              <Link
                to="#"
                className="btn btn-outline-light bg-white dropdown-toggle"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
              >
                <i className="ti ti-filter me-2" />
                Filtrer
              </Link>
              <div className="dropdown-menu drop-width" ref={dropdownMenuRef}>
                <form>
                  <div className="d-flex align-items-center border-bottom p-3">
                    <h4>Filtrer</h4>
                  </div>
                  <div className="p-3 border-bottom pb-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Classe</label>

                          <CommonSelect
                            className="select"
                            options={allClass}
                            defaultValue={allClass[0]}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Section</label>

                          <CommonSelect
                            className="select"
                            options={classSection}
                            defaultValue={classSection[0]}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Enseignant</label>
                          <CommonSelect
                            className="select"
                            options={routinename}
                            defaultValue={routinename[0]}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Numéro de Salle</label>
                          <CommonSelect
                            className="select"
                            options={count}
                            defaultValue={count[0]}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Jour</label>
                          <CommonSelect
                            className="select"
                            options={weak}
                            defaultValue={weak[0]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 d-flex align-items-center justify-content-end">
                    <Link to="#" className="btn btn-light me-3">
                      Réinitialiser
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-primary"
                      onClick={handleApplyClick}
                    >
                      Appliquer
                    </Link>
                  </div>
                </form>
              </div>
            </div>
            <div className="dropdown mb-3">
              <Link
                to="#"
                className="btn btn-outline-light bg-white dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i className="ti ti-sort-ascending-2 me-2" />
                Trier de A à Z
              </Link>
              <ul className="dropdown-menu p-3">
                <li>
                  <Link to="#" className="dropdown-item rounded-1 active">
                    Ascendant
                  </Link>
                </li>
                <li>
                  <Link to="#" className="dropdown-item rounded-1">
                    Descendant
                  </Link>
                </li>
                <li>
                  <Link to="#" className="dropdown-item rounded-1">
                    Vu Récemment
                  </Link>
                </li>
                <li>
                  <Link to="#" className="dropdown-item rounded-1">
                    Ajouté Récemment
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card-body p-0 py-3">
          {/* Liste des tuteurs */}
          <Table columns={columns} dataSource={data} Selection={true} />
          {/* /Liste des tuteurs */}
        </div>
      </div>
      {/* /Liste des tuteurs */}
    </div>
  </div>
  {/* /Page Wrapper */}
</>

      <>
        {/* Add Class Routine */}
        <div className="modal fade" id="add_class_routine">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Ajouter un Emploi du Temps</h4>
        <button
          type="button"
          className="btn-close custom-btn-close"
          data-bs-dismiss="modal"
          aria-label="Fermer"
        >
          <i className="ti ti-x" />
        </button>
      </div>
      <form >
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Enseignant</label>
                <CommonSelect
                  className="select"
                  options={teacher}
                  defaultValue={teacher[0]}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Classe</label>
                <CommonSelect
                  className="select"
                  options={allClass}
                  defaultValue={allClass[0]}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Section</label>
                <CommonSelect
                  className="select"
                  options={classSection}
                  defaultValue={classSection[0]}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Jour</label>
                <CommonSelect
                  className="select"
                  options={weak}
                  defaultValue={weak[0]}
                />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Heure de Début</label>
                    <div className="date-pic">
                      <TimePicker
                        getPopupContainer={getModalContainer2}
                        use12Hours
                        placeholder="Choisir"
                        format="h:mm A"
                        className="form-control timepicker"
                      />
                      <span className="cal-icon">
                        <i className="ti ti-clock" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Heure de Fin</label>
                    <div className="date-pic">
                      <TimePicker
                        getPopupContainer={getModalContainer}
                        use12Hours
                        placeholder="Choisir"
                        format="h:mm A"
                        className="form-control timepicker"
                      />
                      <span className="cal-icon">
                        <i className="ti ti-clock" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Salle de Classe</label>
                <CommonSelect className="select" options={count} />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>Statut</h5>
                  <p>Changez le statut en basculant</p>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="switch-sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Link
            to="#"
            className="btn btn-light me-2"
            data-bs-dismiss="modal"
          >
            Annuler
          </Link>
          <Link to="#" className="btn btn-primary" data-bs-dismiss="modal">
            Ajouter l'Emploi du Temps
          </Link>
        </div>
      </form>
    </div>
  </div>
</div>
{/* /Ajouter Emploi du Temps */}

{/* Modifier l'Emploi du Temps */}
<div className="modal fade" id="edit_class_routine">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Modifier l'Emploi du Temps</h4>
        <button
          type="button"
          className="btn-close custom-btn-close"
          data-bs-dismiss="modal"
          aria-label="Fermer"
        >
          <i className="ti ti-x" />
        </button>
      </div>
      <form >
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Enseignant</label>
                <CommonSelect className="select" options={teacher} defaultValue={teacher[2]}/>
              </div>
              <div className="mb-3">
                <label className="form-label">Classe</label>
                <CommonSelect className="select" options={allClass} defaultValue={allClass[2]}/>
              </div>
              <div className="mb-3">
                <label className="form-label">Section</label>
                <CommonSelect
                  className="select"
                  options={classSection}
                  defaultValue={classSection[2]}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Jour</label>
                <CommonSelect className="select" options={weak} />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Heure de Début</label>
                    <div className="date-pic">
                      <TimePicker
                        getPopupContainer={getModalContainer3}
                        use12Hours
                        placeholder="Choisir"
                        format="h:mm A"
                        className="form-control timepicker"
                      />
                      <span className="cal-icon">
                        <i className="ti ti-clock" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Heure de Fin</label>
                    <div className="date-pic">
                      <TimePicker
                        getPopupContainer={getModalContainer4}
                        use12Hours
                        placeholder="Choisir"
                        format="h:mm A"
                        className="form-control timepicker"
                      />
                      <span className="cal-icon">
                        <i className="ti ti-clock" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Salle de Classe</label>
                <CommonSelect className="select" options={count} />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>Statut</h5>
                  <p>Changez le statut en basculant</p>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="switch-sm2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Link
            to="#"
            className="btn btn-light me-2"
            data-bs-dismiss="modal"
          >
            Annuler
          </Link>
          <Link to="#" className="btn btn-primary" data-bs-dismiss="modal">
            Enregistrer les Modifications
          </Link>
        </div>
      </form>
    </div>
  </div>
</div>
{/* /Modifier l'Emploi du Temps */}

        {/* /Edit Class Routine */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <form >
        <div className="modal-body text-center">
          <span className="delete-icon">
            <i className="ti ti-trash-x" />
          </span>
          <h4>Confirmer la Suppression</h4>
          <p>
            Vous voulez supprimer tous les éléments sélectionnés. Cette action est irréversible.
          </p>
          <div className="d-flex justify-content-center">
            <Link
              to="#"
              className="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link to="#" className="btn btn-danger" data-bs-dismiss="modal">
              Oui, Supprimer
            </Link>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

        {/* /Delete Modal */}
      </>
    </div>
  );
};

export default ClassRoutine;
