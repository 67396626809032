export const Studentlist = [
  {
    key: "1",
    result: "Réussi",
    promotion: 1,
    AdmissionNo: "AD9892434",
    RollNo: "35013",
    name: "Janet",
    class: "III",
    section: "A",
    gender: "Femme",
    status: "Actif",
    DateofJoin: "25 mars 2024",
    DOB: "10 janvier 2015",
    imgSrc: "assets/img/students/student-01.jpg"
  }  
]
  