export const classRoutine= [
	{
		id: "RT167648",
		class: "I",
		section: "A",
		teacher: "Erickson",
		subject: "Anglais",
		day: "Lundi",
		startTime: "09:30",
		endTime: "10:45",
		classRoom: "101",
		key:1
	},
];
