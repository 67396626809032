import React from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../../../core/common/imageWithBasePath'
import { all_routes } from '../../../router/all_routes'
import StudentModals from '../studentModals'
import StudentSidebar from './studentSidebar'
import StudentBreadcrumb from './studentBreadcrumb'

const StudentDetails = () => {
    const routes = all_routes
  return (
    <>
  {/* Page Wrapper */}
  <div className="page-wrapper">
    <div className="content">
      <div className="row">
        {/* Page Header */}
        <StudentBreadcrumb />
        {/* /Page Header */}
      </div>
      <div className="row">
        {/* Student Information */}
        <StudentSidebar />
        {/* /Student Information */}
        <div className="col-xxl-9 col-xl-8">
          <div className="row">
            <div className="col-md-12">
              {/* Liste */}
              <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                <li>
                  <Link to={routes.studentDetail} className="nav-link active">
                    <i className="ti ti-school me-2" />
                    Détails de l'élève
                  </Link>
                </li>
                <li>
                  <Link to={routes.studentTimeTable} className="nav-link">
                    <i className="ti ti-table-options me-2" />
                    Emploi du temps
                  </Link>
                </li>
                <li>
                  <Link to={routes.studentLeaves} className="nav-link">
                    <i className="ti ti-calendar-due me-2" />
                    Absences &amp; Présences
                  </Link>
                </li>
                <li>
                  <Link to={routes.studentFees} className="nav-link">
                    <i className="ti ti-report-money me-2" />
                    Frais
                  </Link>
                </li>
                <li>
                  <Link to={routes.studentResult} className="nav-link">
                    <i className="ti ti-bookmark-edit me-2" />
                    Examens &amp; Résultats
                  </Link>
                </li>
                <li>
                  <Link to={routes.studentLibrary} className="nav-link">
                    <i className="ti ti-books me-2" />
                    Bibliothèque
                  </Link>
                </li>
              </ul>
              {/* /Liste */}
              {/* Informations sur les parents */}
              <div className="card">
                <div className="card-header">
                  <h5>Informations sur les parents</h5>
                </div>
                <div className="card-body">
                  <div className="border rounded p-3 pb-0 mb-3">
                    <div className="row">
                      <div className="col-sm-6 col-lg-4">
                        <div className="d-flex align-items-center mb-3">
                          <span className="avatar avatar-lg flex-shrink-0">
                            <ImageWithBasePath
                              src="assets/img/parents/parent-13.jpg"
                              className="img-fluid rounded"
                              alt="img"
                            />
                          </span>
                          <div className="ms-2 overflow-hidden">
                            <h6 className="text-truncate">Jerald Vicinius</h6>
                            <p className="text-primary">Père</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="mb-3">
                          <p className="text-dark fw-medium mb-1">Téléphone</p>
                          <p>+1 45545 46464</p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="mb-3 overflow-hidden me-3">
                            <p className="text-dark fw-medium mb-1">Email</p>
                            <p className="text-truncate">jera@example.com</p>
                          </div>
                          <Link
                            to="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            aria-label="Print"
                            data-bs-original-title="Réinitialiser le mot de passe"
                            className="btn btn-dark btn-icon btn-sm mb-3"
                          >
                            <i className="ti ti-lock-x" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border rounded p-3 pb-0 mb-3">
                    <div className="row">
                      <div className="col-lg-4 col-sm-6 ">
                        <div className="d-flex align-items-center mb-3">
                          <span className="avatar avatar-lg flex-shrink-0">
                            <ImageWithBasePath
                              src="assets/img/parents/parent-14.jpg"
                              className="img-fluid rounded"
                              alt="img"
                            />
                          </span>
                          <div className="ms-2 overflow-hidden">
                            <h6 className="text-truncate">Roberta Webber</h6>
                            <p className="text-primary">Mère</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 ">
                        <div className="mb-3">
                          <p className="text-dark fw-medium mb-1">Téléphone</p>
                          <p>+1 46499 24357</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="mb-3 overflow-hidden me-3">
                            <p className="text-dark fw-medium mb-1">Email</p>
                            <p className="text-truncate">robe@example.com</p>
                          </div>
                          <Link
                            to="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            aria-label="Print"
                            data-bs-original-title="Réinitialiser le mot de passe"
                            className="btn btn-dark btn-icon btn-sm mb-3"
                          >
                            <i className="ti ti-lock-x" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border rounded p-3 pb-0">
                    <div className="row">
                      <div className="col-lg-4 col-sm-6">
                        <div className="d-flex align-items-center mb-3">
                          <span className="avatar avatar-lg flex-shrink-0">
                            <ImageWithBasePath
                              src="assets/img/parents/parent-13.jpg"
                              className="img-fluid rounded"
                              alt="img"
                            />
                          </span>
                          <div className="ms-2 overflow-hidden">
                            <h6 className="text-truncate">Jerald Vicinius</h6>
                            <p className="text-primary">Tuteur (Père)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6">
                        <div className="mb-3">
                          <p className="text-dark fw-medium mb-1">Téléphone</p>
                          <p>+1 45545 46464</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="mb-3 overflow-hidden me-3">
                            <p className="text-dark fw-medium mb-1">Email</p>
                            <p className="text-truncate">jera@example.com</p>
                          </div>
                          <Link
                            to="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            aria-label="Print"
                            data-bs-original-title="Réinitialiser le mot de passe"
                            className="btn btn-dark btn-icon btn-sm mb-3"
                          >
                            <i className="ti ti-lock-x" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Informations sur les parents */}
            </div>
            {/* Documents */}
            <div className="col-xxl-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <h5>Documents</h5>
                </div>
                <div className="card-body">
                  <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between mb-3 p-2">
                    <div className="d-flex align-items-center overflow-hidden">
                      <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                        <i className="ti ti-pdf fs-15" />
                      </span>
                      <div className="ms-2">
                        <p className="text-truncate fw-medium text-dark">
                          Certificat de naissance.pdf
                        </p>
                      </div>
                    </div>
                    <Link to="#" className="btn btn-dark btn-icon btn-sm">
                      <i className="ti ti-download" />
                    </Link>
                  </div>
                  <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between p-2">
                    <div className="d-flex align-items-center overflow-hidden">
                      <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                        <i className="ti ti-pdf fs-15" />
                      </span>
                      <div className="ms-2">
                        <p className="text-truncate fw-medium text-dark">
                          Certificat de transfert.pdf
                        </p>
                      </div>
                    </div>
                    <Link to="#" className="btn btn-dark btn-icon btn-sm">
                      <i className="ti ti-download" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* /Documents */}
          </div>
        </div>

      </div>
    </div>
  </div>
  {/* /Page Wrapper */}
  <StudentModals />
</>

  )
}

export default StudentDetails