import React, { useState } from 'react'
import { all_routes } from '../../router/all_routes'
import { Link } from 'react-router-dom'
import Select from "react-select";
import { DatePicker, DatePickerProps, Space, TimePicker } from 'antd';

const AdminDashboardModal = () => {
    const [activeContent, setActiveContent] = useState('');
    const routes = all_routes
    const handleContentChange = (event:any) => {
        setActiveContent(event.target.value);
    };
    const eventoption = [
        { value: "Select", label: "Sélectionner" },
        { value: "Celebration", label: "Célébration" },
        { value: "Training", label: "Formation" },
        { value: "Meeting", label: "Réunion" },
        { value: "Holidays", label: "Vacances" },
    ];
    const allTeacher = [
        { value: "All Teacher", label: "Tous les Enseignants" },
        { value: "James", label: "James" },
        { value: "Joseph", label: "Joseph" },
        { value: "Mori", label: "Mori" },
        { value: "Erickson", label: "Erickson" },
    ];
    const allSection = [
        { value: "All Sections", label: "Toutes les Sections" },
        { value: "A", label: "A" },
        { value: "B", label: "B" },
    ];
    const allClass = [
        { value: "All Classes", label: "Toutes les Classes" },
        { value: "I", label: "I" },
        { value: "II", label: "II" },
        { value: "III", label: "III" },
        { value: "IV", label: "IV" },
    ];
    const allClassRoom = [
        { value: "101", label: "101" },
        { value: "102", label: "102" },
        { value: "103", label: "103" },
        { value: "104", label: "104" },
        { value: "105", label: "105" },
    ];
    const allDay = [
        { value: "Monday", label: "Lundi" },
        { value: "Tuesday", label: "Mardi" },
        { value: "Wednesday", label: "Mercredi" },
        { value: "Thursday", label: "Jeudi" },
        { value: "Friday", label: "Vendredi" },
        { value: "Saturday", label: "Samedi" },
        { value: "Sunday", label: "Dimanche" },
    ];
    const getModalContainer = () => {
        const modalElement = document.getElementById('modal-datepicker');
        return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
    };
    const getModalContainer2 = () => {
        const modalElement = document.getElementById('modal_datepicker');
        return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
    };

    return (
        <>
        {/* Ajouter un Emploi du Temps de Classe */}
        <div className="modal fade" id="add_class_routine">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-wrapper">
                        <div className="modal-header">
                            <h4 className="modal-title">Ajouter un Emploi du Temps de Classe</h4>
                            <button
                                type="button"
                                className="btn-close custom-btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="ti ti-x" />
                            </button>
                        </div>
                        <form >
                            <div id='modal_datepicker' className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label">Enseignant</label>
                                            <Select classNamePrefix="react-select"
                                                className="select"
                                                options={allTeacher}
                                                defaultValue={allTeacher[0]}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Classe</label>
                                            <Select classNamePrefix="react-select"
                                                className="select"
                                                options={allClass}
                                                defaultValue={allClass[0]}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Section</label>
                                            <Select classNamePrefix="react-select"
                                                className="select"
                                                options={allSection}
                                                defaultValue={allSection[0]}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Jour</label>
                                            <Select classNamePrefix="react-select"
                                                className="select"
                                                options={allDay}
                                                defaultValue={allDay[0]}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Heure de Début</label>
                                                    <div className="date-pic">
                                                        <TimePicker getPopupContainer={getModalContainer2} use12Hours placeholder="Choisir" format="h:mm A" className="form-control timepicker" />
                                                        <span className="cal-icon">
                                                            <i className="ti ti-clock" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Heure de Fin</label>
                                                    <div className="date-pic">
                                                        <TimePicker getPopupContainer={getModalContainer2} use12Hours placeholder="Choisir" format="h:mm A" className="form-control timepicker" />
                                                        <span className="cal-icon">
                                                            <i className="ti ti-clock" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Salle de Classe</label>
                                            <Select classNamePrefix="react-select"
                                                className="select"
                                                options={allClassRoom}
                                                defaultValue={allClassRoom[0]}
                                            />
                                        </div>
                                        <div className="modal-satus-toggle d-flex align-items-center justify-content-between">
                                            <div className="status-title">
                                                <h5>Statut</h5>
                                                <p>Changer le statut en activant le bouton</p>
                                            </div>
                                            <div className="status-toggle modal-status">
                                                <input type="checkbox" id="user1" className="check" />
                                                <label htmlFor="user1" className="checktoggle">
                                                    {" "}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <Link
                                    to="#"
                                    className="btn btn-light me-2"
                                    data-bs-dismiss="modal"
                                >
                                    Annuler
                                </Link>
                                <Link data-bs-dismiss="modal" to={routes.adminDashboard} className="btn btn-primary">
                                    Ajouter l'Emploi du Temps
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        {/* /Ajouter un Emploi du Temps de Classe */}

        {/* Ajouter un Événement */}
        <div className="modal fade" id="add_event">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Nouvel Événement</h4>
                        <button
                            type="button"
                            className="btn-close custom-btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <i className="ti ti-x" />
                        </button>
                    </div>
                    <form>
                        <div id='modal-datepicker' className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div>
                                        <label className="form-label">Événement Pour</label>
                                        <div className="d-flex align-items-center flex-wrap">
                                            <div className="form-check me-3 mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="event"
                                                    id="all"
                                                    value=""
                                                    checked={activeContent === ''}
                                                    onChange={handleContentChange}
                                                />
                                                <label className="form-check-label" htmlFor="all">
                                                    Tout
                                                </label>
                                            </div>
                                            <div className="form-check me-3 mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="event"
                                                    id="all-student"
                                                    value="all-student"
                                                    onChange={handleContentChange}
                                                />
                                                <label className="form-check-label" htmlFor="all-student">
                                                    Étudiants
                                                </label>
                                            </div>
                                            <div className="form-check me-3 mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="event"
                                                    id="all-teacher"
                                                    value="all-teacher"
                                                    onChange={handleContentChange}
                                                />
                                                <label className="form-check-label" htmlFor="all-teacher">
                                                    Enseignants
                                                </label>
                                            </div>
                                            <div className="form-check me-3 mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="event"
                                                    id="all-class"
                                                    value="all-class"
                                                    onChange={handleContentChange}
                                                />
                                                <label className="form-check-label" htmlFor="all-class">
                                                    Classes
                                                </label>
                                            </div>
                                            <div className="form-check me-3 mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="event"
                                                    id="all-section"
                                                    value="all-section"
                                                    onChange={handleContentChange}
                                                />
                                                <label className="form-check-label" htmlFor="all-section">
                                                    Sections
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Type d'Événement</label>
                                        <Select classNamePrefix="react-select"
                                            className="select"
                                            options={eventoption}
                                            defaultValue={eventoption[0]}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Titre de l'Événement</label>
                                        <input type="text" className="form-control" placeholder="Saisir le titre de l'événement" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Description</label>
                                        <textarea className="form-control" rows={4} placeholder="Saisir la description de l'événement" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Date</label>
                                        <DatePicker getPopupContainer={getModalContainer} format="YYYY-MM-DD" className="form-control" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Heure de Début</label>
                                        <TimePicker getPopupContainer={getModalContainer} use12Hours placeholder="Choisir" format="h:mm A" className="form-control timepicker" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Heure de Fin</label>
                                        <TimePicker getPopupContainer={getModalContainer} use12Hours placeholder="Choisir" format="h:mm A" className="form-control timepicker" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Link
                                to="#"
                                className="btn btn-light me-2"
                                data-bs-dismiss="modal"
                            >
                                Annuler
                            </Link>
                            <Link data-bs-dismiss="modal" to={routes.adminDashboard} className="btn btn-primary">
                                Ajouter l'Événement
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        {/* /Ajouter un Événement */}

        {/* Ajouter un Congé */}
        <div className="modal fade" id="add_leave">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Ajouter un Congé</h4>
                        <button
                            type="button"
                            className="btn-close custom-btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <i className="ti ti-x" />
                        </button>
                    </div>
                    <form>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Nom de l'Enseignant</label>
                                        <input type="text" className="form-control" placeholder="Saisir le nom de l'enseignant" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Motif du Congé</label>
                                        <input type="text" className="form-control" placeholder="Saisir le motif du congé" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Date de Début</label>
                                        <DatePicker getPopupContainer={getModalContainer} format="YYYY-MM-DD" className="form-control" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Date de Fin</label>
                                        <DatePicker getPopupContainer={getModalContainer} format="YYYY-MM-DD" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Link
                                to="#"
                                className="btn btn-light me-2"
                                data-bs-dismiss="modal"
                            >
                                Annuler
                            </Link>
                            <Link data-bs-dismiss="modal" to={routes.adminDashboard} className="btn btn-primary">
                                Ajouter le Congé
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        {/* /Ajouter un Congé */}
        </>
    );
}

export default AdminDashboardModal;