import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  activeList,
  gradeOne,
  gradePercentage,
  gradePoints,
  marksFrom,
  marksUpto,
} from "../../../../core/common/selectoption/selectoption";
import Table from "../../../../core/common/dataTable/index";
import { gradetable } from "../../../../core/data/json/grade";
import { TableData } from "../../../../core/data/interface";
import CommonSelect from "../../../../core/common/commonSelect";
import PredefinedDateRanges from "../../../../core/common/datePicker";
import { all_routes } from "../../../router/all_routes";
import TooltipOption from "../../../../core/common/tooltipOption";
const Grade = () => {
  const routes = all_routes;
  const data = gradetable;
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },
    {
      title: "Classe",
      dataIndex: "grade",
      sorter: (a: TableData, b: TableData) => a.grade.length - b.grade.length,
    },
    {
      title: "Pourcentage",
      dataIndex: "percentage",
      sorter: (a: TableData, b: TableData) =>
        a.percentage.length - b.percentage.length,
    },
    {
      title: "Points de Note",
      dataIndex: "gradePoints",
      sorter: (a: TableData, b: TableData) =>
        a.gradePoints.length - b.gradePoints.length,
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: () => (
        <>
          <span className="badge badge-soft-success d-inline-flex align-items-center">
            <i className="ti ti-circle-filled fs-5 me-1"></i>Actif
          </span>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_grade"
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Modifier
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                  >
                    <i className="ti ti-trash-x me-2" />
                    Supprimer
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Notes</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Académique</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Notes
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_grade"
                >
                  <i className="ti ti-square-rounded-plus-filled me-2" />
                  Ajouter une note
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Liste des notes */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Liste des notes</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filtrer
                  </Link>
                  <div className="dropdown-menu drop-width" ref={dropdownMenuRef}>
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filtrer</h4>
                      </div>
                      <div className="p-3 border-bottom pb-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Note</label>
                              <CommonSelect
                                className="select"
                                options={gradeOne}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Pourcentage</label>
                              <CommonSelect
                                className="select"
                                options={gradePercentage}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Réinitialiser
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Appliquer
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Trier de A à Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Croissant
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Décroissant
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Récemment consulté
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Récemment ajouté
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Liste des notes */}
              <Table columns={columns} dataSource={data} Selection={true} />
              {/* /Liste des notes */}
            </div>
          </div>
          {/* /Liste des notes */}
        </div>
      </div>
      <>
        <div className="d-flex align-items-center">
          <div className="dropdown">
            <Link
              to="#"
              className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="ti ti-dots-vertical fs-14" />
            </Link>
            <ul className="dropdown-menu dropdown-menu-right p-3">
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#edit_grade"
                >
                  <i className="ti ti-edit-circle me-2" />
                  Modifier
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#delete-modal"
                >
                  <i className="ti ti-trash-x me-2" />
                  Supprimer
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Ajouter une note */}
        <div className="modal fade" id="add_grade">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Ajouter une note</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Note</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Marks From(%)</label>
                        <CommonSelect className="select" options={marksFrom} />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Marks Upto(%)</label>
                        <CommonSelect className="select" options={marksUpto} />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Points de note</label>
                        <CommonSelect
                          className="select"
                          options={gradePoints}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Statut</label>
                        <CommonSelect className="select" options={activeList} />
                      </div>
                      <div className="mb-0">
                        <label className="form-label">Description</label>
                        <textarea
                          className="form-control"
                          rows={4}
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Ajouter la note
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Ajouter une note */}
        {/* Modifier une note */}
        <div className="modal fade" id="edit_grade">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Modifier une note</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Note</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={"A+"}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Marks From(%)</label>
                        <CommonSelect
                          className="select"
                          options={marksFrom}
                          defaultValue={marksFrom[1]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Marks Upto(%)</label>
                        <CommonSelect
                          className="select"
                          options={marksUpto}
                          defaultValue={marksUpto[1]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Points de note</label>
                        <CommonSelect
                          className="select"
                          options={gradePoints}
                          defaultValue={gradePoints[1]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Statut</label>
                        <CommonSelect
                          className="select"
                          options={activeList}
                          defaultValue={activeList[1]}
                        />
                      </div>
                      <div className="mb-0">
                        <label className="form-label">Description</label>
                        <textarea
                          className="form-control"
                          rows={4}
                          defaultValue={"Excellent"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Enregistrer les modifications
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Modifier une note */}
        {/* Supprimer une note */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Supprimer une note</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <div className="modal-body">
                <div className="text-center">
                  <i className="ti ti-trash-x text-danger fs-48" />
                  <h4 className="mt-3">Êtes-vous sûr de vouloir supprimer cette note ?</h4>
                  <p className="mt-2">
                    Cette action est irréversible et supprimera définitivement la note.
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </Link>
                <Link
                  to="#"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Supprimer
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Supprimer une note */}
      </>
    </div>
  );
};

export default Grade;
