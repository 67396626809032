export const classhomework = [
	{
        id: "HW1783929",
        class: "I",
        section: "A",
        subject: "Anglais",
        homeworkDate: "10 Mai 2024",
        submissionDate: "12 Mai 2024",
        createdBy: "Janet",
        img : "assets/img/students/student-01.jpg",
        key:1
    },
    {
        id: "HW1783928",
        class: "I",
        section: "B",
        subject: "Mathématiques",
        homeworkDate: "11 Mai 2024",
        submissionDate: "13 Mai 2024",
        createdBy: "Joann",
        img : "assets/img/students/student-02.jpg",
        key:2
    },
    {
        id: "HW1783927",
        class: "II",
        section: "A",
        subject: "Physique",
        homeworkDate: "12 Mai 2024",
        submissionDate: "14 Mai 2024",
        createdBy: "Kathleen",
        img : "assets/img/students/student-03.jpg",
        key:3
    },
    {
        id: "HW1783926",
        class: "II",
        section: "B",
        subject: "Chimie",
        homeworkDate: "13 Mai 2024",
        submissionDate: "15 Mai 2024",
        createdBy: "Gifford",
        img : "assets/img/students/student-04.jpg",
        key:4
    },
    {
        id: "HW1783925",
        class: "II",
        section: "C",
        subject: "Biologie",
        homeworkDate: "14 Mai 2024",
        submissionDate: "16 Mai 2024",
        createdBy: "Lisa",
        img : "assets/img/students/student-05.jpg",
        key:5
    },
    {
        id: "HW1783924",
        class: "III",
        section: "A",
        subject: "Mathématiques avancées",
        homeworkDate: "15 Mai 2024",
        submissionDate: "17 Mai 2024",
        createdBy: "Ralph",
        img : "assets/img/students/student-06.jpg",
        key:6
    },
    {
        id: "HW1783923",
        class: "III",
        section: "B",
        subject: "Technologie de l'information",
        homeworkDate: "16 Mai 2024",
        submissionDate: "18 Mai 2024",
        createdBy: "Julie",
        img : "assets/img/students/student-07.jpg",
        key:7
    },
    {
        id: "HW1783922",
        class: "IV",
        section: "A",
        subject: "Éducation morale",
        homeworkDate: "17 Mai 2024",
        submissionDate: "19 Mai 2024",
        createdBy: "Ryan",
        img : "assets/img/students/student-08.jpg",
        key:8
    },
    {
        id: "HW1783921",
        class: "IV",
        section: "B",
        subject: "Finance",
        homeworkDate: "18 Mai 2024",
        submissionDate: "20 Mai 2024",
        createdBy: "Susan",
        img : "assets/img/students/student-09.jpg",
        key:9
    },
    {
        id: "HW1783920",
        class: "V",
        section: "A",
        subject: "Économie",
        homeworkDate: "19 Mai 2024",
        submissionDate: "21 Mai 2024",
        createdBy: "Richard",
        img : "assets/img/students/student-07.jpg",
        key:10
    }
];