import React from "react";
import TeacherModal from "../teacherModal";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import TeacherSidebar from "./teacherSidebar";
import TeacherBreadcrumb from "./teacherBreadcrumb";

const TeachersRoutine = () => {
  const routes = all_routes;
  return (
    <>
      {/* Conteneur de la page */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            {/* En-tête de la page */}
            <TeacherBreadcrumb />
            {/* /En-tête de la page */}
            {/* Informations sur l'enseignant */}
            <TeacherSidebar />
            {/* /Informations sur l'enseignant */}
            <div className="col-xxl-9 col-xl-8">
              <div className="row">
                <div className="col-md-12">
                  {/* Liste */}
                  <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                    <li>
                      <Link to={routes.teacherDetails} className="nav-link">
                        <i className="ti ti-school me-2" />
                        Détails de l'enseignant
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={routes.teachersRoutine}
                        className="nav-link active"
                      >
                        <i className="ti ti-table-options me-2" />
                        Emploi du temps
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.teacherLeaves} className="nav-link">
                        <i className="ti ti-calendar-due me-2" />
                        Congés &amp; Présence
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.teacherSalary} className="nav-link">
                        <i className="ti ti-report-money me-2" />
                        Salaire
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.teacherLibrary} className="nav-link">
                        <i className="ti ti-bookmark-edit me-2" />
                        Bibliothèque
                      </Link>
                    </li>
                  </ul>
                  {/* /Liste */}
                  <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                      <h4 className="mb-3">Emploi du temps</h4>
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="dropdown mb-3">
                          <Link
                            to="#"
                            className="btn btn-outline-light border-white bg-white dropdown-toggle shadow-md"
                            data-bs-toggle="dropdown"
                          >
                            <i className="ti ti-calendar-due me-2" />
                            Cette année
                          </Link>
                          <ul className="dropdown-menu p-3">
                            <li>
                              <Link to="#" className="dropdown-item rounded-1">
                                Cette année
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="dropdown-item rounded-1">
                                Ce mois-ci
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="dropdown-item rounded-1">
                                Cette semaine
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="d-flex flex-nowrap overflow-auto">
                        <div className="d-flex flex-column me-4 flex-fill">
                          <div className="mb-3">
                            <h6>Lundi</h6>
                          </div>
                          <div className="rounded p-3 mb-4 border">
                            <div className="pb-3 border-bottom">
                              <span className="text-danger badge bg-transparent-danger text-nowrap">
                                Salle No:104
                              </span>
                            </div>
                            <span className="text-dark d-block py-2">
                              Classe : III, A
                            </span>
                            <span className="text-dark d-block pb-2">
                              Matière : Espagnol
                            </span>
                            <p className="text-dark">
                              <i className="ti ti-clock me-1" />
                              09:45 - 10:30 AM
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-column me-4 flex-fill">
                          <div className="mb-3">
                            <h6>Mardi</h6>
                          </div>
                          <div className="rounded p-3 mb-4 border">
                            <div className="pb-3 border-bottom">
                              <span className="text-danger badge bg-transparent-danger text-nowrap">
                                Salle No:104
                              </span>
                            </div>
                            <p className="text-dark d-block py-2 m-0">
                              Classe : III, A
                            </p>
                            <p className="text-dark d-block pb-2 m-0">
                              Matière : Espagnol
                            </p>
                            <p className="text-dark text-nowrap m-0">
                              <i className="ti ti-clock me-1" />
                              09:45 - 10:30 AM
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-column me-4 flex-fill">
                          <div className="mb-3">
                            <h6>Mercredi</h6>
                          </div>
                          <div className="rounded p-3 mb-4 border">
                            <div className="pb-3 border-bottom">
                              <span className="text-danger badge bg-transparent-danger text-nowrap">
                                Salle No:104
                              </span>
                            </div>
                            <p className="text-dark d-block py-2 m-0">
                              Classe : III, A
                            </p>
                            <p className="text-dark d-block pb-2 m-0">
                              Matière : Informatique
                            </p>
                            <p className="text-dark text-nowrap m-0">
                              <i className="ti ti-clock me-1" />
                              09:00 - 09:45 AM
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-column me-4 flex-fill">
                          <div className="mb-3">
                            <h6>Jeudi</h6>
                          </div>
                          <div className="rounded p-3 mb-4 border">
                            <div className="pb-3 border-bottom">
                              <span className="text-danger badge bg-transparent-danger text-nowrap">
                                Salle No:108
                              </span>
                            </div>
                            <p className="text-dark d-block py-2 m-0">
                              Classe : III, A
                            </p>
                            <p className="text-dark d-block pb-2 m-0">
                              Matière : Mathématiques
                            </p>
                            <p className="text-dark text-nowrap m-0">
                              <i className="ti ti-clock me-1" />
                              03:15 - 04:00 PM
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-column me-4 flex-fill">
                          <div className="mb-3">
                            <h6>Vendredi</h6>
                          </div>
                          <div className="rounded p-3 mb-4 border">
                            <div className="pb-3 border-bottom">
                              <span className="text-danger badge bg-transparent-danger text-nowrap">
                                Salle No:104
                              </span>
                            </div>
                            <p className="text-dark d-block py-2 m-0">
                              Classe : III, A
                            </p>
                            <p className="text-dark d-block pb-2 m-0">
                              Matière : Espagnol
                            </p>
                            <p className="text-dark text-nowrap m-0">
                              <i className="ti ti-clock me-1" />
                              09:45 - 10:30 AM
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-column me-4 flex-fill">
                          <div className="mb-3">
                            <h6>Samedi</h6>
                          </div>
                          <div className="rounded p-3 mb-4 border">
                            <div className="pb-3 border-bottom">
                              <span className="text-danger badge bg-transparent-danger text-nowrap">
                                Salle No:104
                              </span>
                            </div>
                            <p className="text-dark d-block py-2 m-0">
                              Classe : III, A
                            </p>
                            <p className="text-dark d-block pb-2 m-0">
                              Matière : Espagnol
                            </p>
                            <p className="text-dark text-nowrap m-0">
                              <i className="ti ti-clock me-1" />
                              09:45 - 10:30 AM
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer border-0 pb-0">
                      <div className="row">
                        <div className="col-lg-4 col-xxl-4 col-xl-4 d-flex">
                          <div className="card flex-fill">
                            <div className="card-body bg-transparent-primary">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <h5>5</h5>
                                  <span>Classes</span>
                                </div>
                                <div>
                                  <i className="fa fa-chalkboard-teacher text-white" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-xxl-4 col-xl-4 d-flex">
                          <div className="card flex-fill">
                            <div className="card-body bg-transparent-success">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <h5>4</h5>
                                  <span>Présentations</span>
                                </div>
                                <div>
                                  <i className="fa fa-file-powerpoint text-white" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-xxl-4 col-xl-4 d-flex">
                          <div className="card flex-fill">
                            <div className="card-body bg-transparent-warning">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <h5>20</h5>
                                  <span>Examens</span>
                                </div>
                                <div>
                                  <i className="fa fa-file-alt text-white" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center pt-0">
                      <div className="d-flex align-items-center flex-wrap justify-content-center">
                        <button
                          type="button"
                          className="btn btn-outline-danger me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <i className="ti ti-edit me-2" />
                          Modifier
                        </button>
                        <button type="button" className="btn btn-outline-success">
                          <i className="ti ti-download me-2" />
                          Télécharger
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Informations sur l'enseignant */}
          </div>
        </div>
      </div>
      {/* /Conteneur de la page */}
      <TeacherModal />
    </>
  );
};

export default TeachersRoutine;