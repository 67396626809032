import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import Logo from "../../../core/common/Logo";

const EmailVerification2 = () => {
  const routes = all_routes;
  const navigation = useNavigate();

  const navigationPath = () => {
    navigation(routes.login);
  };
  return (
    <>
      <div className="container-fuild">
        <div className="login-wrapper w-100 overflow-hidden position-relative flex-wrap d-block vh-100">
          <div className="row">
            <div className="col-lg-6">
              <div className="d-lg-flex align-items-center justify-content-center bg-light-300 d-lg-block d-none flex-wrap vh-100 overflowy-auto bg-01">
                <div>
                  <Logo />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="row justify-content-center align-items-center vh-100 overflow-auto flex-wrap ">
                <div className="col-md-9 mx-auto p-4">
                  <form>
                    <div>
                      <div className=" mx-auto mb-5 text-center">
                      <Logo />
                      </div>
                      <div className="card">
                        <div className="card-body p-4">
                          <div className=" mb-3 text-center">
                            <h2 className="mb-2 text-center">
                              Vérifiez votre Email
                            </h2>
                            <p className="mb-0 text-center">
                              Nous avons envoyé un lien à votre email ter4@example.com.
                              Veuillez suivre le lien pour continuer
                            </p>
                          </div>
                          <div className="text-center mb-3">
                            <h6 className="fw-normal text-dark mb-0">
                              Vous n’avez pas reçu l’email ?
                              <Link to="#" className="hover-a ">
                                {" "}
                                Renvoyer le Lien
                              </Link>
                            </h6>
                          </div>
                          <button
                            onClick={navigationPath}
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            Passer Maintenant
                          </button>
                        </div>
                      </div>
                      <div className="mt-5 text-center">
                        <p className="mb-0 ">Copyright © 2024 - Preskool</p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default EmailVerification2;
