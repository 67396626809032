import React from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ConnectedApps = () => {
  const routes = all_routes;
  return (
    <div>
      <div className="page-wrapper">
        <div className="content bg-white">
        <div className="d-md-flex d-block align-items-center justify-content-between border-bottom pb-3">
  <div className="my-auto mb-2">
    <h3 className="page-title mb-1">Paramètres Généraux</h3>
    <nav>
      <ol className="breadcrumb mb-0">
        <li className="breadcrumb-item">
          <Link to="index">Tableau de Bord</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="#">Paramètres</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Paramètres Généraux
        </li>
      </ol>
    </nav>
  </div>
  <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
    <div className="pe-1 mb-2">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-top">Actualiser</Tooltip>}
      >
        <Link
          to="#"
          className="btn btn-outline-light bg-white btn-icon me-1"
        >
          <i className="ti ti-refresh" />
        </Link>
      </OverlayTrigger>
    </div>
  </div>
</div>

<div className="row">
  <div className="col-xxl-2 col-xl-3">
    <div className="pt-3 d-flex flex-column list-group mb-4">
      <Link
        to={routes.profilesettings}
        className="d-block rounded p-2"
      >
        Paramètres du Profil
      </Link>
      <Link
        to={routes.securitysettings}
        className="d-block rounded p-2"
      >
        Paramètres de Sécurité
      </Link>
      <Link
        to={routes.notificationssettings}
        className="d-block rounded p-2"
      >
        Notifications
      </Link>
      <Link
        to={routes.connectedApps}
        className="d-block rounded active p-2"
      >
        Applications Connectées
      </Link>
    </div>
  </div>
  <div className="col-xxl-10 col-xl-9">
    <div className="flex-fill border-start ps-3">
      <div className="d-flex align-items-center justify-content-between flex-wrap border-bottom mb-3 pt-3">
        <div className="mb-3">
          <h5>Applications Connectées</h5>
          <p>Téléchargez votre photo et vos informations personnelles ici</p>
        </div>
      </div>
      <div className="d-block">
        <div className="d-flex align-items-center justify-content-between flex-wrap border mb-3 p-3 pb-0 rounded bg-white">
          <div className="">
            <div className="d-flex align-items-center">
              <span className="flex-shrink-0 mb-3 me-2 border rounded p-2 d-flex align-items-center justify-content-center">
                <ImageWithBasePath
                  src="assets/img/icons/connected-app-01.svg"
                  alt="Img"
                />
              </span>
              <div className="mb-3">
                <h6>Slack</h6>
                <p>
                  Plateforme de communication d'équipe avec des canaux pour les
                  discussions de groupe et la messagerie directe.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#connect_app"
            >
              Connecter
            </Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap border mb-3 p-3 pb-0 rounded bg-white">
          <div className="">
            <div className="d-flex align-items-center">
              <span className="flex-shrink-0 mb-3 me-2 border rounded p-2 d-flex align-items-center justify-content-center">
                <ImageWithBasePath
                  src="assets/img/icons/connected-app-02.svg"
                  alt="Img"
                />
              </span>
              <div className="mb-3">
                <h6>Google Agenda</h6>
                <p>
                  Google Agenda est un outil de planification en ligne qui
                  permet aux utilisateurs de créer, gérer et partager des
                  événements.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#connect_app_two"
            >
              Connecter
            </Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap border mb-3 p-3 pb-0 rounded bg-white">
          <div className="">
            <div className="d-flex align-items-center">
              <span className="flex-shrink-0 mb-3 me-2 border rounded p-2 d-flex align-items-center justify-content-center">
                <ImageWithBasePath
                  src="assets/img/icons/connected-app-03.svg"
                  alt="Img"
                />
              </span>
              <div className="mb-3">
                <h6>Gmail</h6>
                <p>
                  Gmail est un service de messagerie gratuit de Google qui offre
                  une protection contre le spam et 15 Go de stockage.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#connect_app_three"
            >
              Connecter
            </Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap border mb-0 p-3 pb-0 rounded bg-white">
          <div className="">
            <div className="d-flex align-items-center">
              <span className="flex-shrink-0 mb-3 me-2 border rounded p-2 d-flex align-items-center justify-content-center">
                <ImageWithBasePath
                  src="assets/img/icons/connected-app-04.svg"
                  alt="Img"
                />
              </span>
              <div className="mb-3">
                <h6>Github</h6>
                <p>
                  GitHub est une plateforme en ligne pour le contrôle de version
                  et la collaboration, permettant aux développeurs d'héberger et
                  de réviser du code.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#connect_app_four"
            >
              Connecter
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </div>
      </div>
      <>
        {/* Connect App */}
        <div className="modal fade" id="connect_app">
  <div className="modal-dialog modal-dialog-centered connected-app-modal">
    <div className="modal-content">
      <div className="modal-body">
        <div className="d-flex align-items-center justify-content-center">
          <span className="modal-logo-small me-3">
            <ImageWithBasePath
              src="assets/img/icons/modal-small-logo.svg"
              alt="Img"
            />
          </span>
          <div className="connected-design">
            <span />
            <span />
          </div>
          <span>
            <ImageWithBasePath
              src="assets/img/icons/connected-app-01.svg"
              alt="Img"
            />
          </span>
        </div>
        <div className="border-bottom pb-3 mb-3">
          <h5 className="mb-2">Connexion avec Slack</h5>
          <p>Confirmez les exigences pour vous connecter à Slack</p>
        </div>
        <div>
          <h5 className="mb-2">Slack nécessite :</h5>
          <ul>
            <li className="mb-2 d-flex align-items-center">
              <span className="avatar avatar-xs rounded-circle bg-success border-0 me-2">
                <i className="ti ti-check fs-14" />
              </span>
              Un nouvel espace de travail Slack pour la communication
            </li>
            <li className="mb-2 d-flex align-items-center">
              <span className="avatar avatar-xs rounded-circle bg-success border-0 me-2">
                <i className="ti ti-check fs-14" />
              </span>
              L'API Slack pour interagir avec Slack
            </li>
            <li className="d-flex align-items-center">
              <span className="avatar avatar-xs rounded-circle bg-success border-0 me-2">
                <i className="ti ti-check fs-14" />
              </span>
              Implémentation de OAuth 2.0 pour l'autorisation
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-footer">
        <form className="w-100">
          <div className="d-flex align-items-center mb-3">
            <input type="text" className="form-control flex-fill" />
            <Link
              to="#"
              className="border rounded d-flex align-items-center justify-content-center p-2 ms-3"
            >
              <i className="ti ti-copy" />
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn btn-light me-3 flex-fill"
            >
              Annuler
            </button>
            <button type="submit" className="btn btn-primary flex-fill">
              Connecter
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
{/* /Connexion App */}
{/* Connexion App */}
<div className="modal fade" id="connect_app_two">
  <div className="modal-dialog modal-dialog-centered connected-app-modal">
    <div className="modal-content">
      <div className="modal-body">
        <div className="d-flex align-items-center justify-content-center">
          <span className="modal-logo-small me-3">
            <ImageWithBasePath
              src="assets/img/icons/modal-small-logo.svg"
              alt="Img"
            />
          </span>
          <div className="connected-design">
            <span />
            <span />
          </div>
          <span>
            <ImageWithBasePath
              src="assets/img/icons/connected-app-02.svg"
              alt="Img"
            />
          </span>
        </div>
        <div className="border-bottom pb-3 mb-3">
          <h5 className="mb-2">Connexion avec Google Agenda</h5>
          <p>Confirmez les exigences pour vous connecter à Google Agenda</p>
        </div>
        <div>
          <h5 className="mb-2">Slack nécessite :</h5>
          <ul>
            <li className="mb-2 d-flex align-items-center">
              <span className="avatar avatar-xs rounded-circle bg-success border-0 me-2">
                <i className="ti ti-check fs-16" />
              </span>
              Un nouvel espace de travail Slack pour la communication
            </li>
            <li className="mb-2 d-flex align-items-center">
              <span className="avatar avatar-xs rounded-circle bg-success border-0 me-2">
                <i className="ti ti-check fs-14" />
              </span>
              L'API Slack pour interagir avec Slack
            </li>
            <li className="d-flex align-items-center">
              <span className="avatar avatar-xs rounded-circle bg-success border-0 me-2">
                <i className="ti ti-check fs-14" />
              </span>
              Implémentation de OAuth 2.0 pour l'autorisation
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-footer">
        <form className="w-100">
          <div className="d-flex align-items-center mb-3">
            <input type="text" className="form-control flex-fill" />
            <Link
              to="#"
              className="border rounded d-flex align-items-center justify-content-center p-2 ms-3"
            >
              <i className="ti ti-copy" />
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn btn-light me-3 flex-fill"
            >
              Annuler
            </button>
            <button type="submit" className="btn btn-primary flex-fill">
              Connecter
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
{/* /Connexion App */}
{/* Connexion App */}
<div className="modal fade" id="connect_app_three">
  <div className="modal-dialog modal-dialog-centered connected-app-modal">
    <div className="modal-content">
      <div className="modal-body">
        <div className="d-flex align-items-center justify-content-center">
          <span className="modal-logo-small me-3">
            <ImageWithBasePath
              src="assets/img/icons/modal-small-logo.svg"
              alt="Img"
            />
          </span>
          <div className="connected-design">
            <span />
            <span />
          </div>
          <span>
            <ImageWithBasePath
              src="assets/img/icons/connected-app-03.svg"
              alt="Img"
            />
          </span>
        </div>
        <div className="border-bottom pb-3 mb-3">
          <h5 className="mb-2">Connexion avec Email</h5>
          <p>Confirmez les exigences pour vous connecter avec Email</p>
        </div>
        <div>
          <h5 className="mb-2">Slack nécessite :</h5>
          <ul>
            <li className="mb-2 d-flex align-items-center">
              <span className="avatar avatar-xs rounded-circle bg-success border-0 me-2">
                <i className="ti ti-check fs-16" />
              </span>
              Un nouvel espace de travail Slack pour la communication
            </li>
            <li className="mb-2 d-flex align-items-center">
              <span className="avatar avatar-xs rounded-circle bg-success border-0 me-2">
                <i className="ti ti-check fs-14" />
              </span>
              L'API Slack pour interagir avec Slack
            </li>
            <li className="d-flex align-items-center">
              <span className="avatar avatar-xs rounded-circle bg-success border-0 me-2">
                <i className="ti ti-check fs-14" />
              </span>
              Implémentation de OAuth 2.0 pour l'autorisation
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-footer">
        <form className="w-100">
          <div className="d-flex align-items-center mb-3">
            <input type="text" className="form-control flex-fill" />
            <Link
              to="#"
              className="border rounded d-flex align-items-center justify-content-center p-2 ms-3"
            >
              <i className="ti ti-copy" />
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn btn-light me-3 flex-fill"
            >
              Annuler
            </button>
            <button type="submit" className="btn btn-primary flex-fill">
              Connecter
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
{/* /Connexion App */}
{/* Connexion App */}
<div className="modal fade" id="connect_app_four">
  <div className="modal-dialog modal-dialog-centered connected-app-modal">
    <div className="modal-content">
      <div className="modal-body">
        <div className="d-flex align-items-center justify-content-center">
          <span className="modal-logo-small me-3">
            <ImageWithBasePath
              src="assets/img/icons/modal-small-logo.svg"
              alt="Img"
            />
          </span>
          <div className="connected-design">
            <span />
            <span />
          </div>
          <span>
            <ImageWithBasePath
              src="assets/img/icons/connected-app-04.svg"
              alt="Img"
            />
          </span>
        </div>
        <div className="border-bottom pb-3 mb-3">
          <h5 className="mb-2">Connexion avec Outlook</h5>
          <p>Confirmez les exigences pour vous connecter à Outlook</p>
        </div>
        <div>
          <h5 className="mb-2">Slack nécessite :</h5>
          <ul>
            <li className="mb-2 d-flex align-items-center">
              <span className="avatar avatar-xs rounded-circle bg-success border-0 me-2">
                <i className="ti ti-check fs-16" />
              </span>
              Un nouvel espace de travail Slack pour la communication
            </li>
            <li className="mb-2 d-flex align-items-center">
              <span className="avatar avatar-xs rounded-circle bg-success border-0 me-2">
                <i className="ti ti-check fs-14" />
              </span>
              L'API Slack pour interagir avec Slack
            </li>
            <li className="d-flex align-items-center">
              <span className="avatar avatar-xs rounded-circle bg-success border-0 me-2">
                <i className="ti ti-check fs-14" />
              </span>
              Implémentation de OAuth 2.0 pour l'autorisation
            </li>
          </ul>
        </div>
      </div>
      <div className="modal-footer">
        <form className="w-100">
          <div className="d-flex align-items-center mb-3">
            <input type="text" className="form-control flex-fill" />
            <Link
              to="#"
              className="border rounded d-flex align-items-center justify-content-center p-2 ms-3"
            >
              <i className="ti ti-copy" />
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn btn-light me-3 flex-fill"
            >
              Annuler
            </button>
            <button type="submit" className="btn btn-primary flex-fill">
              Connecter
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

        {/* /Connect App */}
      </>
    </div>
  );
};

export default ConnectedApps;
