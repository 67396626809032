import { useState } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import AdminDashboardEtablissementModal from "./adminDashboardEtablissementModal";
import { useNavigate } from 'react-router-dom';
import { all_routes } from "../../router/all_routes";

// Définition du type pour les établissements
type EtablissementType = "public" | "private" | "exotic";

interface EtablissementDetails {
  ecoles?: number;
  universites?: number;
  centresFormation?: number;
}

interface EtablissementsData {
  total: number;
  details: EtablissementDetails;
}

// Les icônes pour chaque type d'établissement
const icons: Record<EtablissementType, string> = {
  public: "assets/img/icons/etablissement-public.svg",
  private: "assets/img/icons/etablissement-private.svg",
  exotic: "assets/img/icons/etablissement-exotic.svg",
};

const capitalizeFirstLetter = (string : string) => {
  if (!string) return ''; // Gère les chaînes vides ou nulles
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// Les données simulées des établissements
const etablissementsData: Record<EtablissementType, EtablissementsData> = {
  public: {
    total: 150,
    details: {
      ecoles: 100,
      universites: 30,
      centresFormation: 20,
    },
  },
  private: {
    total: 200,
    details: {
      ecoles: 150,
      universites: 30,
      centresFormation: 20,
    },
  },
  exotic: {
    total: 50,
    details: {
      ecoles: 30,
      centresFormation: 20,
    },
  },
};

const AdminDashboardEtablissements = () => {
  // Typage explicite de selectedType
  const [selectedType, setSelectedType] = useState<EtablissementType | null>(null);
  const routes = all_routes;

  const navigate = useNavigate()
  // Fonction pour afficher les détails après clic
  const handleSelectType = (type: EtablissementType) => {
    setSelectedType(type);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          {/* En-tête */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Tableau de bord des établissements</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Tableau de bord</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          {/* Affichage des types d'établissements */}
          {!selectedType ? (
            <div className="row">
              {/* Public */}
              <div className="col-xxl-4 col-sm-6 d-flex">
                <div
                  className="card flex-fill animate-card border-0"
                  onClick={() => handleSelectType("public")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="avatar avatar-xl me-2 bg-primary-transparent p-1">
                        <ImageWithBasePath src={icons.public} alt="Public" />
                      </div>
                      <div className="overflow-hidden flex-fill">
                        <h2 className="counter">
                          <CountUp end={etablissementsData.public.total} />
                        </h2>
                        <p>Établissements Publics</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Privé */}
              <div className="col-xxl-4 col-sm-6 d-flex">
                <div
                  className="card flex-fill animate-card border-0"
                  onClick={() => handleSelectType("private")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="avatar avatar-xl me-2 bg-success-transparent p-1">
                        <ImageWithBasePath src={icons.private} alt="Privé" />
                      </div>
                      <div className="overflow-hidden flex-fill">
                        <h2 className="counter">
                          <CountUp end={etablissementsData.private.total} />
                        </h2>
                        <p>Établissements Privés</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Exotique */}
              <div className="col-xxl-4 col-sm-6 d-flex">
                <div
                  className="card flex-fill animate-card border-0"
                  onClick={() => handleSelectType("exotic")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="avatar avatar-xl me-2 bg-warning-transparent p-1">
                        <ImageWithBasePath src={icons.exotic} alt="Exotique" />
                      </div>
                      <div className="overflow-hidden flex-fill">
                        <h2 className="counter">
                          <CountUp end={etablissementsData.exotic.total} />
                        </h2>
                        <p>Établissements Exotics</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // Affichage des détails après clic
            <div className="row">
              <div className="col-12">
                <h4>Détails des établissements {selectedType === "private" ? "privé" : selectedType}s</h4>
                <div className="row">
                  {/* Ecoles */}
                  {etablissementsData[selectedType].details.ecoles !== undefined && (
                    <div className="col-xxl-4 col-sm-6 d-flex" onClick={() => navigate(routes.etablissementList, { state: { type: 'Ecole', cadre: capitalizeFirstLetter(selectedType) } })} style={{ cursor: "pointer" }}>
                      <div className="card flex-fill animate-card border-0">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar avatar-xl me-2 bg-primary-transparent p-1">
                              <ImageWithBasePath src="assets/img/icons/school.svg" alt="Écoles" />
                            </div>
                            <div className="overflow-hidden flex-fill">
                              <h2 className="counter">
                                <CountUp end={etablissementsData[selectedType].details.ecoles ?? 0} />
                              </h2>
                              <p>Écoles</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Universités */}
                  {etablissementsData[selectedType].details.universites !== undefined && (
                    <div className="col-xxl-4 col-sm-6 d-flex" onClick={() => navigate(routes.etablissementList, { state: { type: 'Université', cadre: capitalizeFirstLetter(selectedType) } })} style={{ cursor: "pointer" }}>
                      <div className="card flex-fill animate-card border-0">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar avatar-xl me-2 bg-success-transparent p-1">
                              <ImageWithBasePath src="assets/img/icons/university.svg" alt="Universités" />
                            </div>
                            <div className="overflow-hidden flex-fill">
                              <h2 className="counter">
                                <CountUp end={etablissementsData[selectedType].details.universites ?? 0} />
                              </h2>
                              <p>Universités</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Centres de Formation */}
                  {etablissementsData[selectedType].details.centresFormation !== undefined && (
                    <div className="col-xxl-4 col-sm-6 d-flex" onClick={() => navigate(routes.etablissementList, { state: { type: 'Centre', cadre: capitalizeFirstLetter(selectedType) } })} style={{ cursor: "pointer" }}>
                      <div className="card flex-fill animate-card border-0">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="avatar avatar-xl me-2 bg-warning-transparent p-1">
                              <ImageWithBasePath src="assets/img/icons/center.svg" alt="Centres de Formation" />
                            </div>
                            <div className="overflow-hidden flex-fill">
                              <h2 className="counter">
                                <CountUp end={etablissementsData[selectedType].details.centresFormation ?? 0} />
                              </h2>
                              <p>Centres de Formation</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>


                {/* Bouton retour */}
                <div className="mt-3">
                  <button className="btn btn-secondary text-center" onClick={() => setSelectedType(null)}>
                    Retour
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <AdminDashboardEtablissementModal />
    </>
  );
};

export default AdminDashboardEtablissements;