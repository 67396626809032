import { Link } from "react-router-dom";
import {
  internetCategory,
  markAs,
  names,
  priority,
  priorityList,
  staffName,
  statusOption,
  ticketStatus,
} from "../../core/common/selectoption/selectoption";
import { all_routes } from "../router/all_routes";
import CommonSelect from "../../core/common/commonSelect";
import ImageWithBasePath from "../../core/common/imageWithBasePath";
import TooltipOption from "../../core/common/tooltipOption";

const TicketDetails = () => {
  const route = all_routes;

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
  <div className="content pb-lg-4 pb-2">
    <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
      <div className="my-auto mb-2">
        <h3 className="page-title mb-1">Tickets</h3>
        <nav>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to={route.adminDashboard}>Tableau de bord</Link>
            </li>
            <li className="breadcrumb-item">Support</li>
            <li className="breadcrumb-item active" aria-current="page">
              Tickets
            </li>
          </ol>
        </nav>
      </div>
      <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
        <TooltipOption />
        <div className="mb-2">
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#add_ticket"
            className="btn btn-primary"
          >
            <i className="ti ti-square-rounded-plus me-2" />
            Ajouter un nouveau ticket
          </Link>
        </div>
      </div>
    </div>
    <div className="row">
      {/* Tickets */}
      <div className="col-xl-8 col-xxl-9">
        {/* Liste des Tickets */}
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between p-3 pb-0">
            <h5 className="text-primary mb-3">Support IT</h5>
            <div className="d-flex align-items-center">
              <span className="badge bg-danger mb-3 me-3">
                <i className="ti ti-circle-filled fs-5 me-1" />
                Élevé
              </span>
              <div className="mb-3">
                <CommonSelect
                  className="select"
                  options={markAs}
                  defaultValue={markAs[1]}
                />
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="ticket-information ticket-details">
              <div className="d-flex align-items-center justify-content-between flex-wrap p-3 pb-0 border-bottom">
                <div className="d-flex align-items-center flex-wrap">
                  <div className="mb-3">
                    <span className="badge bg-pending rounded-pill mb-1">
                      #TK0003
                    </span>
                    <div className="d-flex align-items-center mb-2">
                      <h5 className="fw-semibold me-2">Problème de Laptop</h5>
                      <span className="badge bg-outline-danger d-flex align-items-center ms-1">
                        Ouvert
                      </span>
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                      <p className="d-flex align-items-center mb-1 me-2">
                        <i className="ti ti-calendar-bolt me-1" />
                        Mis à jour il y a 22 heures
                      </p>
                      <p className="d-flex align-items-center mb-1">
                        <i className="ti ti-message-share me-1" />9
                        Commentaires
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <Link to="#" className="btn btn-primary">
                    <i className="ti ti-reload me-1" />
                    Répondre
                  </Link>
                </div>
              </div>
              <div className="border-bottom p-3">
                <div>
                  <p className="mb-3">
                    Depuis une semaine, mon ordinateur portable rencontre des
                    problèmes de gel intermittents. Les gels se produisent
                    aléatoirement, environ 3 à 4 fois par jour, et durent
                    environ 30 à 60 secondes à chaque fois. Pendant ces gels,
                    le curseur devient non réactif et je ne peux rien cliquer ni
                    utiliser les raccourcis clavier. Le problème se résout
                    généralement de lui-même, mais il perturbe considérablement
                    mon travail.
                  </p>
                  <ul className="list-styled-dotted">
                    <li className="ms-4 mb-3">
                      J'ai d'abord remarqué le problème le 1er février 2024,
                      pendant une vidéoconférence sur Google Meet. Depuis, le
                      problème s'est produit lors de diverses tâches, y compris
                      la navigation avec Chrome, l'utilisation des applications
                      Microsoft Office, et même lorsque l'ordinateur est au
                      repos.
                    </li>
                    <li className="ms-4">
                      Messages d'erreur : Aucun message d'erreur spécifique n'a
                      été affiché, mais le Gestionnaire des tâches (lorsqu'il est
                      accessible) montre un pic d'utilisation du CPU à 100 % pendant
                      ces gels.
                    </li>
                  </ul>
                </div>
                <div className="mt-4">
                  <div className="d-flex align-items-center mb-3">
                    <span className="avatar avatar-xl me-2 flex-shrink-0">
                      <ImageWithBasePath
                        src="assets/img/students/student-11.jpg"
                        alt="Img"
                      />
                    </span>
                    <div>
                      <h6 className="mb-1">James Hendriques</h6>
                      <p>
                        <i className="ti ti-calendar-bolt me-1" />
                        Mis à jour il y a 5 heures
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="mb-3">
                      <p>
                        Ce problème perturbe les réunions, retarde l'achèvement
                        des tâches et affecte ma productivité globale.
                      </p>
                    </div>
                    <span className="badge bg-light">
                      Screenshot.png
                      <i className="ti ti-download ms-1" />
                    </span>
                    <div className="d-flex align-items-center my-3">
                      <Link
                        to="#"
                        className="d-inline-flex align-items-center text-primary fw-medium me-3"
                      >
                        <i className="ti ti-reload me-1" />
                        Répondre
                      </Link>
                      <p className="d-flex align-items-center">
                        <i className="ti ti-message-share me-1" />1
                        Commentaire
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-light-300 rounded p-3">
                  <div className="d-flex align-items-center mb-3">
                    <span className="avatar avatar-xl me-2 flex-shrink-0">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-19.jpg"
                        alt="Img"
                      />
                    </span>
                    <div>
                      <h6 className="mb-1">Agent Support Angio</h6>
                      <p>
                        <i className="ti ti-calendar-bolt me-1" />
                        Mis à jour il y a 5 heures
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="mb-3">
                      <p>
                        Activez le panneau latéral &amp; mettez à jour le système
                        d'exploitation, Connectez-vous au gestionnaire de périphériques
                        et mettez à jour le mot de passe
                      </p>
                    </div>
                    <span className="badge bg-light">
                      Screenshot.png
                      <i className="ti ti-download ms-1" />
                    </span>
                    <div className="d-flex align-items-center mt-3">
                      <Link
                        to="#"
                        className="d-inline-flex align-items-center text-primary fw-medium"
                      >
                        <i className="ti ti-reload me-1" />
                        Répondre
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-bottom p-3">
                <div>
                  <div className="d-flex align-items-center mb-3">
                    <span className="avatar avatar-xl me-2 flex-shrink-0">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-01.jpg"
                        alt="Img"
                      />
                    </span>
                    <div>
                      <h6 className="mb-1">Marilyn Siegle</h6>
                      <p>
                        <i className="ti ti-calendar-bolt me-1" />
                        Mis à jour il y a 6 heures
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="mb-3">
                      <p>
                        Vérifiez les journaux système et d'application dans le
                        Visualiseur d'événements pour des avertissements ou des
                        erreurs qui coïncident avec les moments où les gels se
                        produisent.
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="badge bg-light me-2">
                        Screenshot.png
                        <i className="ti ti-download ms-1" />
                      </span>
                      <span className="badge bg-light me-2">
                        Screenshot.png
                        <i className="ti ti-download ms-1" />
                      </span>
                      <span className="badge bg-light me-2">
                        Screenshot.png
                        <i className="ti ti-download ms-1" />
                      </span>
                      <span className="badge bg-light">
                        Screenshot.png
                        <i className="ti ti-download ms-1" />
                      </span>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <Link
                        to="#"
                        className="d-inline-flex align-items-center text-primary fw-medium"
                      >
                        <i className="ti ti-reload me-1" />
                        Répondre
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-bottom p-3">
                <div>
                  <div className="d-flex align-items-center mb-3">
                    <span className="avatar avatar-xl me-2 flex-shrink-0">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-22.jpg"
                        alt="Img"
                      />
                    </span>
                    <div>
                      <h6 className="mb-1">Marilyn Siegle</h6>
                      <p>
                        <i className="ti ti-calendar-bolt me-1" />
                        Mis à jour il y a 6 heures
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="mb-3">
                      <p>
                        Vérifiez les mises à jour en attente et installez-les
                        pour voir si cela résout le problème
                      </p>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <Link
                        to="#"
                        className="d-inline-flex align-items-center text-primary fw-medium"
                      >
                        <i className="ti ti-reload me-1" />
                        Répondre
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Liste des Tickets */}
        <div className="load-more text-center">
          <Link to="#" className="btn btn-primary">
            <i className="ti ti-loader-3" />
            Charger plus
          </Link>
        </div>
      </div>
      {/* /Tickets */}
      {/* Détails du Ticket */}
      <div className="col-xl-4 col-xxl-3 theiaStickySidebar">
        <div className="stickybar">
          <div className="stickybar">
            <div className="card">
              <div className="card-header p-3">
                <h4>Détails du Ticket</h4>
              </div>
              <div className="card-body p-0">
                <div className="border-bottom p-3">
                  <div className="mb-3">
                    <label className="form-label">Changer la Priorité</label>
                    <CommonSelect
                      className="select"
                      options={priority}
                      defaultValue={undefined}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Assigner À</label>
                    <CommonSelect
                      className="select"
                      options={names}
                      defaultValue={undefined}
                    />
                  </div>
                  <div className="mb-0">
                    <label className="form-label">Statut du Ticket</label>
                    <CommonSelect
                      className="select"
                      options={ticketStatus}
                      defaultValue={undefined}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center border-bottom p-3">
                  <span className="avatar avatar-md me-2 flex-shrink-0">
                    <ImageWithBasePath
                      src="assets/img/teachers/teacher-03.jpg"
                      className="rounded-circle"
                      alt="Img"
                    />
                  </span>
                  <div>
                    <span className="fs-12">Utilisateur</span>
                    <p className="text-dark">Hellana</p>
                  </div>
                </div>
                <div className="d-flex align-items-center border-bottom p-3">
                  <span className="avatar avatar-md me-2 flex-shrink-0">
                    <ImageWithBasePath
                      src="assets/img/teachers/teacher-04.jpg"
                      className="rounded-circle"
                      alt="Img"
                    />
                  </span>
                  <div>
                    <span className="fs-12">Agent de Support</span>
                    <p className="text-dark">Angio</p>
                  </div>
                </div>
                <div className="border-bottom p-3">
                  <span className="fs-12">Catégorie</span>
                  <p className="text-dark">Réparation &amp; Service</p>
                </div>
                <div className="border-bottom p-3">
                  <span className="fs-12">Email</span>
                  <p className="text-dark">Hellana@gmail.com</p>
                </div>
                <div className="p-3">
                  <span className="fs-12">Dernière Mise à Jour / Fermé Le</span>
                  <p className="text-dark">25 Mai 2024</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Détails du Ticket */}
    </div>
  </div>
</div>

      {/* /Page Wrapper */}

      {/* Add Ticket */}
      <div className="modal fade" id="add_ticket">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Ajouter un Ticket</h4>
        <button
          type="button"
          className="btn-close custom-btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i className="ti ti-x" />
        </button>
      </div>
      <form>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="col-form-label">Titre</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez le titre"
                />
              </div>
              <div className="mb-3">
                <label className="col-form-label">Catégorie d'Événement</label>
                <CommonSelect
                  className="select"
                  options={internetCategory}
                  defaultValue={undefined}
                />
              </div>
              <div className="mb-3">
                <label className="col-form-label">Sujet</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez le sujet"
                />
              </div>
              <div className="mb-3">
                <label className="col-form-label">Assigner À</label>
                <CommonSelect
                  className="select"
                  options={staffName}
                  defaultValue={undefined}
                />
              </div>
              <div className="mb-3">
                <label className="col-form-label">Description du Ticket</label>
                <textarea
                  className="form-control"
                  placeholder="Ajouter une question"
                  defaultValue={""}
                />
              </div>
              <div className="mb-3">
                <label className="col-form-label">Priorité</label>
                <CommonSelect
                  className="select"
                  options={priorityList}
                  defaultValue={undefined}
                />
              </div>
              <div className="mb-0">
                <label className="col-form-label">Statut</label>
                <CommonSelect
                  className="select"
                  options={statusOption}
                  defaultValue={undefined}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Link
            to="#"
            className="btn btn-light me-2"
            data-bs-dismiss="modal"
          >
            Annuler
          </Link>
          <Link
            to="#"
            data-bs-dismiss="modal"
            className="btn btn-primary"
          >
            Ajouter le Ticket
          </Link>
        </div>
      </form>
    </div>
  </div>
</div>

      {/* /Add Ticket */}
    </>
  );
};

export default TicketDetails;
