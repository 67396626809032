import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { all_routes } from "../../../router/all_routes";
import StudentModals from "../studentModals";
import StudentSidebar from "./studentSidebar";
import StudentBreadcrumb from "./studentBreadcrumb";
import Table from "../../../../core/common/dataTable/index";
import { TableData } from "../../../../core/data/interface";
import { leaveData } from "../../../../core/data/json/leaveData";
import { Attendance } from "../../../../core/data/json/attendance";

const StudentFees = () => {
  const routes = all_routes;
  const data = leaveData;
  const data2 = Attendance;
  const columns = [
    {
      title: "Type de Congé",
      dataIndex: "leaveType",
      sorter: (a: TableData, b: TableData) =>
        a.leaveType.length - b.leaveType.length,
    },
    {
      title: "Date de Congé",
      dataIndex: "leaveDate",
      sorter: (a: TableData, b: TableData) =>
        a.leaveDate.length - b.leaveDate.length,
    },
    {
      title: "Nombre de Jours",
      dataIndex: "noOfDays",
      sorter: (a: TableData, b: TableData) =>
        parseFloat(a.noOfDays) - parseFloat(b.noOfDays),
    },
    {
      title: "Demandé le",
      dataIndex: "appliedOn",
      sorter: (a: TableData, b: TableData) =>
        a.appliedOn.length - b.appliedOn.length,
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: (text: string) => (
        <>
          {text === "Approved" ? (
            <span className="badge badge-soft-success d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : (
            <span className="badge badge-soft-danger d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.status.length - b.status.length,
    },
  ];
  
  const columns2 = [
    {
      title: "Date | Mois",
      dataIndex: "date",
      sorter: (a: TableData, b: TableData) => a.date.length - b.date.length,
    },
    {
      title: "Jan",
      dataIndex: "jan",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.jan.length - b.jan.length,
    },
    {
      title: "Fév",
      dataIndex: "feb",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.feb.length - b.feb.length,
    },
    {
      title: "Mar",
      dataIndex: "mar",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.mar.length - b.mar.length,
    },
    {
      title: "Avr",
      dataIndex: "apr",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.apr.length - b.apr.length,
    },
    {
      title: "Mai",
      dataIndex: "may",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.may.length - b.may.length,
    },
    {
      title: "Juin",
      dataIndex: "jun",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.jun.length - b.jun.length,
    },
    {
      title: "Juil",
      dataIndex: "jul",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.jul.length - b.jul.length,
    },
    {
      title: "Août",
      dataIndex: "aug",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.aug.length - b.aug.length,
    },
    {
      title: "Sep",
      dataIndex: "sep",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.sep.length - b.sep.length,
    },
    {
      title: "Oct",
      dataIndex: "oct",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.oct.length - b.oct.length,
    },
    {
      title: "Nov",
      dataIndex: "nov",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.nov.length - b.nov.length,
    },
    {
      title: "Déc",
      dataIndex: "dec",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.dec.length - b.dec.length,
    },
  ];
  



  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            {/* En-tête de la page */}
            <StudentBreadcrumb />
            {/* /En-tête de la page */}
          </div>
          <div className="row">
            {/* Informations sur l'élève */}
            <StudentSidebar />
            {/* /Informations sur l'élève */}
            <div className="col-xxl-9 col-xl-8">
              <div className="row">
                <div className="col-md-12">
                  {/* Liste */}
                  <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                    <li>
                      <Link to={routes.studentDetail} className="nav-link">
                        <i className="ti ti-school me-2" />
                        Détails de l'élève
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentTimeTable} className="nav-link">
                        <i className="ti ti-table-options me-2" />
                        Emploi du temps
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentLeaves} className="nav-link ">
                        <i className="ti ti-calendar-due me-2" />
                        Congés &amp; Présences
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentFees} className="nav-link active">
                        <i className="ti ti-report-money me-2" />
                        Frais
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentResult} className="nav-link">
                        <i className="ti ti-bookmark-edit me-2" />
                        Examens &amp; Résultats
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentLibrary} className="nav-link">
                        <i className="ti ti-books me-2" />
                        Bibliothèque
                      </Link>
                    </li>
                  </ul>
                  {/* /Liste */}
                  <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                      <h4 className="mb-3">Frais</h4>
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="dropdown mb-3 me-2">
                          <Link
                            to=""
                            className="btn btn-outline-light bg-white dropdown-toggle"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                          >
                            <i className="ti ti-calendar-due me-2" />
                            Année : 2024 / 2025
                          </Link>
                          <ul className="dropdown-menu p-3">
                            <li>
                              <Link to="" className="dropdown-item rounded-1">
                                Année : 2024 / 2025
                              </Link>
                            </li>
                            <li>
                              <Link to="" className="dropdown-item rounded-1">
                                Année : 2023 / 2024
                              </Link>
                            </li>
                            <li>
                              <Link to="" className="dropdown-item rounded-1">
                                Année : 2022 / 2023
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0 py-3">
                      {/* Liste des frais */}
                      <div className="custom-datatable-filter table-responsive">
                        <table className="table datatable">
                          <thead className="thead-light">
                            <tr>
                              <th>Groupe de frais</th>
                              <th>Code de frais</th>
                              <th>Date d'échéance</th>
                              <th>Montant $</th>
                              <th>État</th>
                              <th>ID de référence</th>
                              <th>Mode</th>
                              <th>Date de paiement</th>
                              <th>Réduction ($)</th>
                              <th>Amende ($)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <p className="text-primary fees-group">
                                  Général Classe 1
                                  <span className="d-block">
                                    (Frais d'admission)
                                  </span>
                                </p>
                              </td>
                              <td>frais-admission</td>
                              <td>25 Mar 2024</td>
                              <td>2000</td>
                              <td>
                                <span className="badge badge-soft-success d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  Payé
                                </span>
                              </td>
                              <td>#435454</td>
                              <td>Espèces</td>
                              <td>25 Jan 2024</td>
                              <td>10%</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-primary">
                                  Général Classe 1{" "}
                                  <span className="d-block">
                                    {" "}
                                    (Frais du mois de mars)
                                  </span>
                                </p>
                              </td>
                              <td>frais-mars</td>
                              <td>10 Apr 2024</td>
                              <td>2500</td>
                              <td>
                                <span className="badge badge-soft-success d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  Payé
                                </span>
                              </td>
                              <td>#435453</td>
                              <td>Espèces</td>
                              <td>03 Apr 2024</td>
                              <td>10%</td>
                              <td>0</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-primary">
                                  Général Classe 1{" "}
                                  <span className="d-block">
                                    (Frais du mois d'avril)
                                  </span>
                                </p>
                              </td>
                              <td>frais-avril</td>
                              <td>10 May 2024</td>
                              <td>2500</td>
                              <td>
                                <span className="badge badge-soft-success d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  Payé
                                </span>
                              </td>
                              <td>#435453</td>
                              <td>Espèces</td>
                              <td>03 Apr 2024</td>
                              <td>10%</td>
                              <td>0</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-primary">
                                  Général Classe 1{" "}
                                  <span className="d-block">
                                    (Frais du mois de mai)
                                  </span>
                                </p>
                              </td>
                              <td>frais-mai</td>
                              <td>10 Jun 2024</td>
                              <td>2500</td>
                              <td>
                                <span className="badge badge-soft-success d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  Payé
                                </span>
                              </td>
                              <td>#435451</td>
                              <td>Espèces</td>
                              <td>02 Jun 2024</td>
                              <td>10%</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-primary">
                                  Général Classe 1{" "}
                                  <span className="d-block">
                                    (Frais du mois de juin)
                                  </span>
                                </p>
                              </td>
                              <td>frais-juin</td>
                              <td>10 Jul 2024</td>
                              <td>2500</td>
                              <td>
                                <span className="badge badge-soft-success d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  Payé
                                </span>
                              </td>
                              <td>#435450</td>
                              <td>Espèces</td>
                              <td>05 Jul 2024</td>
                              <td>10%</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-primary">
                                  Général Classe 1{" "}
                                  <span className="d-block">
                                    (Frais du mois de juillet)
                                  </span>
                                </p>
                              </td>
                              <td>frais-juillet</td>
                              <td>10 Aug 2024</td>
                              <td>2500</td>
                              <td>
                                <span className="badge badge-soft-success d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  Payé
                                </span>
                              </td>
                              <td>#435449</td>
                              <td>Espèces</td>
                              <td>01 Aug 2024</td>
                              <td>10%</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-primary">
                                  Général Classe 1{" "}
                                  <span className="d-block">
                                    (Frais du mois d'août)
                                  </span>
                                </p>
                              </td>
                              <td>frais-aout</td>
                              <td>10 Sep 2024</td>
                              <td>2500</td>
                              <td>
                                <span className="badge badge-soft-success d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  Payé
                                </span>
                              </td>
                              <td>#435448</td>
                              <td>Espèces</td>
                              <td>01 Sep 2024</td>
                              <td>10%</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-primary">
                                  Général Classe 1{" "}
                                  <span className="d-block">
                                    (Frais du mois de septembre)
                                  </span>
                                </p>
                              </td>
                              <td>frais-septembre</td>
                              <td>10 Oct 2024</td>
                              <td>2500</td>
                              <td>
                                <span className="badge badge-soft-success d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  Payé
                                </span>
                              </td>
                              <td>#435447</td>
                              <td>Espèces</td>
                              <td>01 Oct 2024</td>
                              <td>10%</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-primary">
                                  Général Classe 1{" "}
                                  <span className="d-block">
                                    (Frais du mois d'octobre)
                                  </span>
                                </p>
                              </td>
                              <td>frais-octobre</td>
                              <td>10 Nov 2024</td>
                              <td>2500</td>
                              <td>
                                <span className="badge badge-soft-success d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  Payé
                                </span>
                              </td>
                              <td>#435446</td>
                              <td>Espèces</td>
                              <td>01 Nov 2024</td>
                              <td>10%</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-primary">
                                  Général Classe 1{" "}
                                  <span className="d-block">
                                    (Frais du mois de novembre)
                                  </span>
                                </p>
                              </td>
                              <td>frais-novembre</td>
                              <td>10 Dec 2024</td>
                              <td>2500</td>
                              <td>
                                <span className="badge badge-soft-success d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  Payé
                                </span>
                              </td>
                              <td>#435445</td>
                              <td>Espèces</td>
                              <td>02 Dec 2024</td>
                              <td>10%</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-primary">
                                  Général Classe 1{" "}
                                  <span className="d-block">
                                    (Frais du mois de décembre)
                                  </span>
                                </p>
                              </td>
                              <td>frais-decembre</td>
                              <td>10 Jan 2025</td>
                              <td>2500</td>
                              <td>
                                <span className="badge badge-soft-success d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  Payé
                                </span>
                              </td>
                              <td>#435444</td>
                              <td>Espèces</td>
                              <td>02 Jan 2025</td>
                              <td>10%</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-primary">
                                  Général Classe 1{" "}
                                  <span className="d-block">
                                    (Frais du mois de janvier)
                                  </span>
                                </p>
                              </td>
                              <td>frais-janvier</td>
                              <td>10 Feb 2025</td>
                              <td>2500</td>
                              <td>
                                <span className="badge badge-soft-danger d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  Non payé
                                </span>
                              </td>
                              <td>#435443</td>
                              <td>Espèces</td>
                              <td>-</td>
                              <td>10%</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="text-primary">
                                  Général Classe 1{" "}
                                  <span className="d-block">
                                    (Frais du mois de février)
                                  </span>
                                </p>
                              </td>
                              <td>frais-fevrier</td>
                              <td>10 Mar 2025</td>
                              <td>2500</td>
                              <td>
                                <span className="badge badge-soft-danger d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  Non payé
                                </span>
                              </td>
                              <td>#435442</td>
                              <td>Espèces</td>
                              <td>-</td>
                              <td>10%</td>
                              <td>200</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/* /Liste des frais */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* /Page Wrapper */}
      <StudentModals />
    </>
  );
};

export default StudentFees;
