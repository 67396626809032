import React from "react";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const routes = all_routes;
const EmailSettings = () => {
  return (
    <div>
      <div className="page-wrapper">
        <div className="content bg-white">
          <div className="d-md-flex d-block align-items-center justify-content-between border-bottom pb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Paramètres du Système</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="index">Tableau de Bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Paramètres</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Paramètres du Système
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <div className="pe-1 mb-2">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Rafraîchir</Tooltip>}
                >
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white btn-icon me-1"
                  >
                    <i className="ti ti-refresh" />
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-2 col-xl-3">
              <div className="pt-3 d-flex flex-column list-group mb-4">
                <Link
                  to={routes.emailSettings}
                  className="d-block rounded active p-2"
                >
                  Paramètres Email
                </Link>
                <Link
                  to={routes.emailTemplates}
                  className="d-block rounded p-2"
                >
                  Modèles Email
                </Link>
                <Link to={routes.smsSettings} className="d-block rounded p-2">
                  Paramètres SMS
                </Link>
                <Link to={routes.optSettings} className="d-block rounded p-2">
                  OTP
                </Link>
                <Link to={routes.gdprCookies} className="d-block rounded p-2">
                  Cookies RGPD
                </Link>
              </div>
            </div>
            <div className="col-xxl-10 col-xl-9">
              <div className="flex-fill border-start ps-3">
                <form>
                  <div className="d-flex align-items-center justify-content-between flex-wrap border-bottom pt-3 mb-3">
                    <div className="mb-3">
                      <h5 className="mb-1">Paramètres Email</h5>
                      <p>Configuration des paramètres email</p>
                    </div>
                    <div className="mb-3">
                      <button className="btn btn-light me-2" type="button">
                        Annuler
                      </button>
                      <button className="btn btn-primary" type="submit">
                        Sauvegarder
                      </button>
                    </div>
                  </div>
                  <div className="d-md-flex">
                    <div className="flex-fill">
                      <div className="row">
                        <div className="col-xxl-4 col-xl-6">
                          <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between border-0 mb-3 pb-0">
                              <div className="d-flex align-items-center">
                                <span className="avatar avatar-lg p-2 rounded bg-gray flex-shrink-0 me-2">
                                  <ImageWithBasePath
                                    src="assets/img/icons/php-icon.svg"
                                    alt="Img"
                                  />
                                </span>
                                <h6>PHP Mailer</h6>
                              </div>
                              <span className="badge bg-transparent-success text-success">
                                Connecté
                              </span>
                            </div>
                            <div className="card-body pt-0">
                              <p>
                                Utilisé pour envoyer des emails de manière
                                sécurisée et simple via du code PHP depuis un
                                serveur web.
                              </p>
                            </div>
                            <div className="card-footer d-flex justify-content-between align-items-center">
                              <div>
                                <Link
                                  to="#"
                                  className="btn btn-outline-light"
                                  data-bs-toggle="modal"
                                  data-bs-target="#connect_php"
                                >
                                  <i className="ti ti-tool me-2" />
                                  Voir l'Intégration
                                </Link>
                              </div>
                              <div className="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="user1"
                                  className="check"
                                />
                                <label htmlFor="user1" className="checktoggle">
                                  {" "}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-6">
                          <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between border-0 mb-3 pb-0">
                              <div className="d-flex align-items-center">
                                <span className="avatar avatar-lg p-2 rounded bg-gray flex-shrink-0 me-2">
                                  <ImageWithBasePath
                                    src="assets/img/icons/smtp-icon.svg"
                                    alt="Img"
                                  />
                                </span>
                                <h6>SMTP</h6>
                              </div>
                              <span className="badge bg-transparent-dark text-dark">
                                Non Connecté
                              </span>
                            </div>
                            <div className="card-body pt-0">
                              <p>
                                SMTP est utilisé pour envoyer, relayer ou
                                transférer des messages depuis un client de
                                messagerie.
                              </p>
                            </div>
                            <div className="card-footer d-flex justify-content-between align-items-center">
                              <div>
                                <Link
                                  to="#"
                                  className="btn btn-outline-light"
                                  data-bs-toggle="modal"
                                  data-bs-target="#connect_smtp"
                                >
                                  <i className="ti ti-tool me-2" />
                                  Voir l'Intégration
                                </Link>
                              </div>
                              <div className="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="user2"
                                  className="check"
                                />
                                <label htmlFor="user2" className="checktoggle">
                                  {" "}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-6">
                          <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between border-0 mb-3 pb-0">
                              <div className="d-flex align-items-center">
                                <span className="avatar avatar-lg p-2 rounded bg-gray flex-shrink-0 me-2">
                                  <ImageWithBasePath
                                    src="assets/img/icons/google-icon-02.svg"
                                    alt="Img"
                                  />
                                </span>
                                <h6>Google</h6>
                              </div>
                              <span className="badge bg-transparent-dark text-dark">
                                Non Connecté
                              </span>
                            </div>
                            <div className="card-body pt-0">
                              <p>
                                Outil de marketing par email basé sur le cloud
                                qui assiste les marketeurs et les développeurs.
                              </p>
                            </div>
                            <div className="card-footer d-flex justify-content-between align-items-center">
                              <div>
                                <Link
                                  to="#"
                                  className="btn btn-outline-light"
                                  data-bs-toggle="modal"
                                  data-bs-target="#connect_test_mail"
                                >
                                  <i className="ti ti-tool me-2" />
                                  Voir l'Intégration
                                </Link>
                              </div>
                              <div className="status-toggle modal-status">
                                <input
                                  type="checkbox"
                                  id="user3"
                                  className="check"
                                />
                                <label htmlFor="user3" className="checktoggle">
                                  {" "}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        {/* Connecter Php */}
        <div className="modal fade" id="connect_php">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">PHP Mailer</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Fermer"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Adresse Email de l'Expéditeur
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Entrez l'adresse email de l'expéditeur"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Nom d'Utilisateur de l'Expéditeur
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez le nom d'utilisateur de l'expéditeur"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Mot de Passe</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Entrez le mot de passe"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer text-end">
                  <button
                    type="button"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Sauvegarder
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Connecter Smtp */}
        <div className="modal fade" id="connect_smtp">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">SMTP</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Fermer"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Adresse Email de l'Expéditeur
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Entrez l'adresse email de l'expéditeur"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Nom d'Utilisateur de l'Expéditeur
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez le nom d'utilisateur de l'expéditeur"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Hôte SMTP
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez l'hôte SMTP"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Mot de Passe</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Entrez le mot de passe"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer text-end">
                  <button
                    type="button"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Sauvegarder
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Connecter Test Mail */}
        <div className="modal fade" id="connect_test_mail">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Google</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Fermer"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Adresse Email de l'Expéditeur
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Entrez l'adresse email de l'expéditeur"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Nom d'Utilisateur de l'Expéditeur
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez le nom d'utilisateur de l'expéditeur"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Mot de Passe</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Entrez le mot de passe"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer text-end">
                  <button
                    type="button"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Sauvegarder
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};


export default EmailSettings;
