export const parentData = [
  {
    key: "1",
    ParentImage: "assets/img/parents/parent-01.jpg",
    ChildImage: "assets/img/students/student-01.jpg",
    id: "P124556",
    name: "Thomas",
    Addedon: "Ajouté le 25 mars 2024",
    Child: "Janet",
    class: "III, A",
    phone: "+1 65738 58937",
    email: "thomas@example.com",
  }  
];
