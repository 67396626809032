import React, { useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import ReactApexChart from "react-apexcharts";

const ParentDashboard = () => {
  const routes = all_routes;
  const [activeStudent, setActiveStudent] = useState<string>("student-1");
  const [statistic_chart] = useState<any>({
    chart: {
      type: "line",
      height: 345,
    },
    series: [
      {
        name: "Avg. Exam Score",
        data: [0, 32, 40, 50, 60, 52, 50, 44, 40, 60, 75, 70], // Sample data
      },
      {
        name: "Avg. Attendance",
        data: [0, 35, 43, 34, 30, 28, 25, 50, 60, 75, 77, 80], // Sample data
      },
    ],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val + "%";
        },
      },
      shared: true,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        return `<div class="apexcharts-tooltip">${w.globals.labels[dataPointIndex]}<br>Exam Score: <span style="color: #1E90FF;">${series[0][dataPointIndex]}%</span><br>Attendance: <span style="color: #00BFFF;">${series[1][dataPointIndex]}%</span></div>`;
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: -8,
      },
    },
    yaxis: {
      labels: {
        offsetX: -15,
      },
    },
    markers: {
      size: 0,
      colors: ["#1E90FF", "#00BFFF"],
      strokeColors: "#fff",
      strokeWidth: 1,
      hover: {
        size: 7,
      },
    },
    colors: ["#3D5EE1", "#6FCCD8"], // Color for the lines
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  });
 
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Tableau de Bord des Parents</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Tableau de Bord</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tableau de Bord des Parents
                  </li>
                </ol>
              </nav>
            </div>
            <div className="dash-select-student d-flex align-items-center mb-2">
              <h6 className="mb-0">Sélectionner un Élève</h6>
              <div className="student-active d-flex align-items-center ms-2">
                <Link
                  to="#"
                  onClick={() => setActiveStudent("student-1")}
                  className={`avatar avatar-lg p-1 me-2 ${
                    activeStudent === "student-1" && "active"
                  }`}
                >
                  <ImageWithBasePath
                    src="assets/img/students/student-01.jpg"
                    alt="Profil"
                  />
                </Link>
                <Link
                  to="#"
                  onClick={() => setActiveStudent("student-2")}
                  className={`avatar avatar-lg p-1  ${
                    activeStudent === "student-2" && "active"
                  }`}
                >
                  <ImageWithBasePath
                    src="assets/img/students/student-02.jpg"
                    alt="Profil"
                  />
                </Link>
              </div>
            </div>
          </div>

          {/* /Page Header */}
          <div className="row">
            {/* Profil */}
            <div className="col-xxl-5 col-xl-12 d-flex">
              <div className="card bg-dark position-relative flex-fill">
                <div className="card-body">
                  <div className="d-flex align-items-center row-gap-3">
                    <div className="avatar avatar-xxl rounded flex-shrink-0 me-3">
                      <ImageWithBasePath
                        src="assets/img/parents/parent-01.jpg"
                        alt="Img"
                      />
                    </div>
                    <div className="d-block">
                      <span className="badge bg-transparent-primary text-primary mb-1">
                        #P124556
                      </span>
                      <h4 className="text-truncate text-white mb-1">
                        Thomas Bown
                      </h4>
                      <div className="d-flex align-items-center flex-wrap row-gap-2 class-info">
                        <span>Ajouté le : 25 mars 2024</span>
                        <span>Enfant : Janet</span>
                      </div>
                    </div>
                  </div>
                  <div className="student-card-bg">
                    <ImageWithBasePath
                      src="assets/img/bg/circle-shape.png"
                      alt="Bg"
                    />
                    <ImageWithBasePath
                      src="assets/img/bg/shape-02.png"
                      alt="Bg"
                    />
                    <ImageWithBasePath
                      src="assets/img/bg/shape-04.png"
                      alt="Bg"
                    />
                    <ImageWithBasePath
                      src="assets/img/bg/blue-polygon.png"
                      alt="Bg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* /Profil */}
            {/* Congés */}
            <div className="col-xxl-7 d-flex">
              <div className="row flex-fill">
                <div className="col-xl-4 d-flex flex-column">
                  <div className="d-flex bg-white border rounded flex-wrap justify-content-between align-items-center p-3 row-gap-2 mb-2 animate-card">
                    <div className="d-flex align-items-center">
                      <span className="avatar avatar-sm bg-light-500 me-2 rounded">
                        <i className="ti ti-calendar-event text-dark fs-16" />
                      </span>
                      <h6>Demander un Congé</h6>
                    </div>
                    <Link
                      to={routes.studentLeaves}
                      className="badge rounded-circle arrow d-flex align-items-center justify-content-center"
                    >
                      <i className="ti ti-chevron-right fs-14" />
                    </Link>
                  </div>
                  <div className="d-flex bg-white border rounded flex-wrap justify-content-between align-items-center p-3 row-gap-2 mb-4 animate-card">
                    <div className="d-flex align-items-center">
                      <span className="avatar avatar-sm bg-light-500 me-2 rounded">
                        <i className="ti ti-message-up text-dark fs-16" />
                      </span>
                      <h6>Faire une Demande</h6>
                    </div>
                    <Link
                      to={routes.approveRequest}
                      className="badge rounded-circle arrow d-flex align-items-center justify-content-center"
                    >
                      <i className="ti ti-chevron-right fs-14" />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="card bg-success-transparent border-3 border-white text-center p-3">
                    <span className="avatar avatar-sm rounded bg-success mx-auto mb-3">
                      <i className="ti ti-calendar-share fs-15" />
                    </span>
                    <h6 className="mb-2">Congés Médicaux (10)</h6>
                    <div className="d-flex align-items-center justify-content-between text-default">
                      <p className="border-end mb-0">Utilisé : 05</p>
                      <p>Disponible : 10</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="card bg-primary-transparent border-3 border-white text-center p-3">
                    <span className="avatar avatar-sm rounded bg-primary mx-auto mb-3">
                      <i className="ti ti-hexagonal-prism-plus fs-15" />
                    </span>
                    <h6 className="mb-2">Congés Annuels (12)</h6>
                    <div className="d-flex align-items-center justify-content-between text-default">
                      <p className="border-end mb-0">Utilisé : 05</p>
                      <p>Disponible : 10</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Congés */}
          </div>

          <div className="row">
            {/* Liste des Événements */}
            <div className="col-xxl-4 col-xl-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h4 className="card-title">Liste des Événements</h4>
                  <Link to={routes.events} className="fw-medium">
                    Voir Tout
                  </Link>
                </div>
                <div className="card-body p-0">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item p-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <Link to="#" className="avatar avatar-lg flex-shrink-0 me-2">
                            <ImageWithBasePath
                              src="assets/img/events/event-01.jpg"
                              className="img-fluid"
                              alt="img"
                            />
                          </Link>
                          <div className="overflow-hidden">
                            <h6 className="mb-1">
                              <Link to={routes.events}>
                                Réunion Parents - Enseignants
                              </Link>
                            </h6>
                            <p>
                              <i className="ti ti-calendar me-1" />
                              15 Juillet 2024
                            </p>
                          </div>
                        </div>
                        <span className="badge badge-soft-danger d-inline-flex align-items-center">
                          <i className="ti ti-circle-filled fs-5 me-1" />
                          Journée Complète
                        </span>
                      </div>
                    </li>
                    <li className="list-group-item p-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <Link to="#" className="avatar avatar-lg flex-shrink-0 me-2">
                            <ImageWithBasePath
                              src="assets/img/events/event-02.jpg"
                              className="img-fluid"
                              alt="img"
                            />
                          </Link>
                          <div className="overflow-hidden">
                            <h6 className="mb-1">
                              <Link to={routes.events}>Adieu</Link>
                            </h6>
                            <p>
                              <i className="ti ti-calendar me-1" />
                              11 Mars 2024
                            </p>
                          </div>
                        </div>
                        <span className="badge badge-soft-skyblue d-inline-flex align-items-center">
                          <i className="ti ti-circle-filled fs-5 me-1" />
                          Demi-Journée
                        </span>
                      </div>
                    </li>
                    <li className="list-group-item p-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <Link to="#" className="avatar avatar-lg flex-shrink-0 me-2">
                            <ImageWithBasePath
                              src="assets/img/events/event-03.jpg"
                              className="img-fluid"
                              alt="img"
                            />
                          </Link>
                          <div className="overflow-hidden">
                            <h6 className="mb-1">
                              <Link to={routes.events}>Journée Annuelle</Link>
                            </h6>
                            <p>
                              <i className="ti ti-calendar me-1" />
                              11 Mars 2024
                            </p>
                          </div>
                        </div>
                        <span className="badge badge-soft-skyblue d-inline-flex align-items-center">
                          <i className="ti ti-circle-filled fs-5 me-1" />
                          Demi-Journée
                        </span>
                      </div>
                    </li>
                    <li className="list-group-item p-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <Link to="#" className="avatar avatar-lg flex-shrink-0 me-2">
                            <ImageWithBasePath
                              src="assets/img/events/event-04.jpg"
                              className="img-fluid"
                              alt="img"
                            />
                          </Link>
                          <div className="overflow-hidden">
                            <h6 className="mb-1">
                              <Link to={routes.events}>Célébration de Holi</Link>
                            </h6>
                            <p>
                              <i className="ti ti-calendar me-1" />
                              15 Juillet 2024
                            </p>
                          </div>
                        </div>
                        <span className="badge badge-soft-danger d-inline-flex align-items-center">
                          <i className="ti ti-circle-filled fs-5 me-1" />
                          Journée Complète
                        </span>
                      </div>
                    </li>
                    <li className="list-group-item p-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <Link to="#" className="avatar avatar-lg flex-shrink-0 me-2">
                            <ImageWithBasePath
                              src="assets/img/events/event-05.jpg"
                              className="img-fluid"
                              alt="img"
                            />
                          </Link>
                          <div className="overflow-hidden">
                            <h6 className="mb-1">
                              <Link to={routes.events}>Résultats des Examens</Link>
                            </h6>
                            <p>
                              <i className="ti ti-calendar me-1" />
                              16 Juillet 2024
                            </p>
                          </div>
                        </div>
                        <span className="badge badge-soft-skyblue d-inline-flex align-items-center">
                          <i className="ti ti-circle-filled fs-5 me-1" />
                          Demi-Journée
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Liste des Événements */}
            {/* Statistiques */}
            <div className="col-xxl-8 col-xl-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h4 className="card-title">Statistiques</h4>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-calendar me-2" />
                      Ce Mois
                    </Link>
                    <ul className="dropdown-menu mt-2 p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Ce Mois
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Cette Année
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          La Semaine Dernière
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-body pb-0">
                  <div id="statistic_chart" />
                  <ReactApexChart
                    id="statistic_chart"
                    options={statistic_chart}
                    series={statistic_chart.series}
                    type="line"
                    height={345}
                  />
                </div>
              </div>
            </div>
            {/* /Statistiques */}
          </div>

          <div className="row">
            {/* Statut des Congés */}
            <div className="col-xxl-4 col-xl-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h4 className="card-title">Statut des Congés</h4>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-calendar me-2" />
                      Ce Mois-ci
                    </Link>
                    <ul className="dropdown-menu mt-2 p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Ce Mois-ci
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Cette Année
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          La Semaine Dernière
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <div className="bg-light-300 d-sm-flex align-items-center justify-content-between p-3 mb-3">
                    <div className="d-flex align-items-center mb-2 mb-sm-0">
                      <div className="avatar avatar-lg bg-danger-transparent flex-shrink-0 me-2">
                        <i className="ti ti-brand-socket-io" />
                      </div>
                      <div>
                        <h6 className="mb-1">Congés d'Urgence</h6>
                        <p>Date : 15 Juin 2024</p>
                      </div>
                    </div>
                    <span className="badge bg-skyblue d-inline-flex align-items-center">
                      <i className="ti ti-circle-filled fs-5 me-1" />
                      En Attente
                    </span>
                  </div>
                  <div className="bg-light-300 d-sm-flex align-items-center justify-content-between p-3 mb-3">
                    <div className="d-flex align-items-center mb-2 mb-sm-0">
                      <div className="avatar avatar-lg bg-info-transparent flex-shrink-0 me-2">
                        <i className="ti ti-medical-cross" />
                      </div>
                      <div>
                        <h6 className="mb-1">Congés Médicaux</h6>
                        <p>Date : 15 Juin 2024</p>
                      </div>
                    </div>
                    <span className="badge bg-success d-inline-flex align-items-center">
                      <i className="ti ti-circle-filled fs-5 me-1" />
                      Approuvé
                    </span>
                  </div>
                  <div className="bg-light-300 d-sm-flex align-items-center justify-content-between p-3 mb-3">
                    <div className="d-flex align-items-center mb-2 mb-sm-0">
                      <div className="avatar avatar-lg bg-info-transparent flex-shrink-0 me-2">
                        <i className="ti ti-medical-cross" />
                      </div>
                      <div>
                        <h6 className="mb-1">Congés Médicaux</h6>
                        <p>Date : 16 Juin 2024</p>
                      </div>
                    </div>
                    <span className="badge bg-danger d-inline-flex align-items-center">
                      <i className="ti ti-circle-filled fs-5 me-1" />
                      Refusé
                    </span>
                  </div>
                  <div className="bg-light-300 d-sm-flex align-items-center justify-content-between p-3 mb-0">
                    <div className="d-flex align-items-center mb-2 mb-sm-0">
                      <div className="avatar avatar-lg bg-danger-transparent flex-shrink-0 me-2">
                        <i className="ti ti-brand-socket-io" />
                      </div>
                      <div>
                        <h6 className="mb-1">Fièvre</h6>
                        <p>Date : 16 Juin 2024</p>
                      </div>
                    </div>
                    <span className="badge bg-success d-inline-flex align-items-center">
                      <i className="ti ti-circle-filled fs-5 me-1" />
                      Approuvé
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* /Statut des Congés */}
            {/* Devoirs à Domicile */}
            <div className="col-xxl-4  col-xl-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h4 className="card-title">Devoirs à Domicile</h4>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-book-2 me-2" />
                      Tous les Sujets
                    </Link>
                    <ul className="dropdown-menu mt-2 p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Physique
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Chimie
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Maths
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-body py-1">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item py-3 px-0">
                      <div className="d-flex align-items-center">
                        <Link
                          to="#"
                          className="avatar avatar-xl flex-shrink-0 me-2"
                        >
                          <ImageWithBasePath
                            src="assets/img/home-work/home-work-01.jpg"
                            alt="img"
                          />
                        </Link>
                        <div className="overflow-hidden">
                          <p className="d-flex align-items-center text-info mb-1">
                            <i className="ti ti-tag me-2" />
                            Physique
                          </p>
                          <h6 className="text-truncate mb-1">
                            <Link to={routes.classHomeWork}>
                              Rédiger sur la Théorie du Pendule
                            </Link>
                          </h6>
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="d-flex align-items-center border-end me-1 pe-1">
                              <Link
                                to={routes.teacherDetails}
                                className="avatar avatar-xs flex-shrink-0 me-2"
                              >
                                <ImageWithBasePath
                                  src="assets/img/teachers/teacher-01.jpg"
                                  className="rounded-circle"
                                  alt="teacher"
                                />
                              </Link>
                              <p className="text-dark">Aaron</p>
                            </div>
                            <p>À rendre pour : 16 Juin 2024</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item py-3 px-0">
                      <div className="d-flex align-items-center">
                        <Link
                          to="#"
                          className="avatar avatar-xl flex-shrink-0 me-2"
                        >
                          <ImageWithBasePath
                            src="assets/img/home-work/home-work-02.jpg"
                            alt="img"
                          />
                        </Link>
                        <div className="overflow-hidden">
                          <p className="d-flex align-items-center text-success mb-1">
                            <i className="ti ti-tag me-2" />
                            Chimie
                          </p>
                          <h6 className="text-truncate mb-1">
                            <Link to={routes.classHomeWork}>
                              Chimie - Changement des Éléments
                            </Link>
                          </h6>
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="d-flex align-items-center border-end me-1 pe-1">
                              <Link
                                to={routes.teacherDetails}
                                className="avatar avatar-xs flex-shrink-0 me-2"
                              >
                                <ImageWithBasePath
                                  src="assets/img/teachers/teacher-01.jpg"
                                  className="rounded-circle"
                                  alt="teacher"
                                />
                              </Link>
                              <p className="text-dark">Hellana</p>
                            </div>
                            <p>À rendre pour : 16 Juin 2024</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item py-3 px-0">
                      <div className="d-flex align-items-center">
                        <Link
                          to="#"
                          className="avatar avatar-xl flex-shrink-0 me-2"
                        >
                          <ImageWithBasePath
                            src="assets/img/home-work/home-work-03.jpg"
                            alt="img"
                          />
                        </Link>
                        <div className="overflow-hidden">
                          <p className="d-flex align-items-center text-warning mb-1">
                            <i className="ti ti-tag me-2" />
                            Maths
                          </p>
                          <h6 className="text-truncate mb-1">
                            <Link to={routes.classHomeWork}>
                              Problèmes de Géométrie
                            </Link>
                          </h6>
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="d-flex align-items-center border-end me-1 pe-1">
                              <Link
                                to={routes.teacherDetails}
                                className="avatar avatar-xs flex-shrink-0 me-2"
                              >
                                <ImageWithBasePath
                                  src="assets/img/teachers/teacher-01.jpg"
                                  className="rounded-circle"
                                  alt="teacher"
                                />
                              </Link>
                              <p className="text-dark">Dylan</p>
                            </div>
                            <p>À rendre pour : 16 Juin 2024</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Devoirs à Domicile */}
          </div>

          <div className="row">
            {/* Résultats des Examens */}
            <div className="col-xxl-8 col-xl-7 d-flex">
              <div className="card flex-fill">
                <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                  <h4 className="card-title mb-3">Résultats des Examens</h4>
                  <div className="d-flex align-items-center">
                    <div className="dropdown me-3 mb-3">
                      <Link
                        to="#"
                        className="bg-white dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <i className="ti ti-calendar me-2" />
                        Toutes les Classes
                      </Link>
                      <ul className="dropdown-menu mt-2 p-3">
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            I
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            II
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            III
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="dropdown mb-3">
                      <Link
                        to="#"
                        className="bg-white dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <i className="ti ti-calendar me-2" />
                        Tous les Examens
                      </Link>
                      <ul className="dropdown-menu mt-2 p-3">
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            Trimestriels
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            Pratiques
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            1er Trimestre
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-body px-0">
                  <div className="custom-datatable-filter table-responsive">
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th>ID</th>
                          <th>Nom</th>
                          <th>Classe</th>
                          <th>Section</th>
                          <th>Pourcentage de Note</th>
                          <th>Examens</th>
                          <th>Statut</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>35013</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Link
                                to={routes.studentDetail}
                                className="avatar avatar-md"
                              >
                                <ImageWithBasePath
                                  src="assets/img/students/student-01.jpg"
                                  className="img-fluid rounded-circle"
                                  alt="img"
                                />
                              </Link>
                              <div className="ms-2">
                                <p className="text-dark mb-0">
                                  <Link to={routes.studentDetail}>Janet</Link>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>III</td>
                          <td>A</td>
                          <td>89%</td>
                          <td>Trimestriels</td>
                          <td>
                            <span className="badge bg-success">Réussi</span>
                          </td>
                        </tr>
                        <tr>
                          <td>35013</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Link
                                to={routes.studentDetail}
                                className="avatar avatar-md"
                              >
                                <ImageWithBasePath
                                  src="assets/img/students/student-02.jpg"
                                  className="img-fluid rounded-circle"
                                  alt="img"
                                />
                              </Link>
                              <div className="ms-2">
                                <p className="text-dark mb-0">
                                  <Link to={routes.studentDetail}>Joann</Link>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>IV</td>
                          <td>B</td>
                          <td>88%</td>
                          <td>Pratiques</td>
                          <td>
                            <span className="badge bg-success">Réussi</span>
                          </td>
                        </tr>
                        <tr>
                          <td>35010</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Link
                                to={routes.studentDetail}
                                className="avatar avatar-md"
                              >
                                <ImageWithBasePath
                                  src="assets/img/students/student-04.jpg"
                                  className="img-fluid rounded-circle"
                                  alt="img"
                                />
                              </Link>
                              <div className="ms-2">
                                <p className="text-dark mb-0">
                                  <Link to={routes.studentDetail}>Gifford</Link>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>I</td>
                          <td>B</td>
                          <td>21%</td>
                          <td>Mi-Trimestre</td>
                          <td>
                            <span className="badge bg-success">Réussi</span>
                          </td>
                        </tr>
                        <tr>
                          <td>35009</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Link
                                to={routes.studentDetail}
                                className="avatar avatar-md"
                              >
                                <ImageWithBasePath
                                  src="assets/img/students/student-05.jpg"
                                  className="img-fluid rounded-circle"
                                  alt="img"
                                />
                              </Link>
                              <div className="ms-2">
                                <p className="text-dark mb-0">
                                  <Link to={routes.studentDetail}>Lisa</Link>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>II</td>
                          <td>B</td>
                          <td>31%</td>
                          <td>Annuel</td>
                          <td>
                            <span className="badge bg-danger">Échoué</span>
                          </td>
                        </tr>
                        <tr>
                          <td>35015</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Link
                                to={routes.studentDetail}
                                className="avatar avatar-md"
                              >
                                <ImageWithBasePath
                                  src="assets/img/students/student-08.jpg"
                                  className="img-fluid rounded-circle"
                                  alt="img"
                                />
                              </Link>
                              <div className="ms-2">
                                <p className="text-dark mb-0">
                                  <Link to={routes.studentDetail}>Riana</Link>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>III</td>
                          <td>A</td>
                          <td>89%</td>
                          <td>Trimestriels</td>
                          <td>
                            <span className="badge bg-success">Réussi</span>
                          </td>
                        </tr>
                        <tr>
                          <td>35013</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Link
                                to={routes.studentDetail}
                                className="avatar avatar-md"
                              >
                                <ImageWithBasePath
                                  src="assets/img/students/student-06.jpg"
                                  className="img-fluid rounded-circle"
                                  alt="img"
                                />
                              </Link>
                              <div className="ms-2">
                                <p className="text-dark mb-0">
                                  <Link to={routes.studentDetail}>Angelo</Link>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>IV</td>
                          <td>B</td>
                          <td>88%</td>
                          <td>Pratiques</td>
                          <td>
                            <span className="badge bg-danger">Échoué</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* /Résultats des Examens */}
            {/* Tableau d'Affichage */}
            <div className="col-xxl-4 col-xl-5 d-flex">
              <div className="card flex-fill">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h4 className="card-title">Tableau d'Affichage</h4>
                  <Link to={routes.noticeBoard} className="fw-medium">
                    Voir Tout
                  </Link>
                </div>
                <div className="card-body">
                  <div className="notice-widget">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <div className="d-flex align-items-center overflow-hidden me-2">
                        <span className="bg-primary-transparent avatar avatar-md me-2 rounded-circle flex-shrink-0">
                          <i className="ti ti-books fs-16" />
                        </span>
                        <div className="overflow-hidden">
                          <h6 className="text-dark mb-1">
                            <Link to={routes.noticeBoard} className="text-dark">
                              Matériaux Scolaires Requis
                            </Link>
                          </h6>
                          <p className="text-muted mb-0">
                            Les parents doivent acheter des livres pour la classe II.
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="text-muted mb-0">28 Juillet 2024</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <div className="d-flex align-items-center overflow-hidden me-2">
                        <span className="bg-primary-transparent avatar avatar-md me-2 rounded-circle flex-shrink-0">
                          <i className="ti ti-calendar fs-16" />
                        </span>
                        <div className="overflow-hidden">
                          <h6 className="text-dark mb-1">
                            <Link to={routes.noticeBoard} className="text-dark">
                              Prochain Examen
                            </Link>
                          </h6>
                          <p className="text-muted mb-0">
                            L'examen de Mathématiques pour la classe III aura lieu le 30 juillet.
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="text-muted mb-0">25 Juillet 2024</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <div className="d-flex align-items-center overflow-hidden me-2">
                        <span className="bg-primary-transparent avatar avatar-md me-2 rounded-circle flex-shrink-0">
                          <i className="ti ti-school fs-16" />
                        </span>
                        <div className="overflow-hidden">
                          <h6 className="text-dark mb-1">
                            <Link to={routes.noticeBoard} className="text-dark">
                              Réunion Parents-Professeurs
                            </Link>
                          </h6>
                          <p className="text-muted mb-0">
                            La réunion aura lieu le 5 août pour discuter des progrès des élèves.
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="text-muted mb-0">20 Juillet 2024</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Tableau d'Affichage */}
          </div>

        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default ParentDashboard;
