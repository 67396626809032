import React from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";

import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import CommonSelect from "../../../core/common/commonSelect";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const routes = all_routes;
const InvoiceSettings = () => {
  const options1 = [
    { value: "5", label: "5" },
    { value: "4", label: "4" },
    { value: "3", label: "3" },
  ];

  const options2 = [
    { value: "roundoff-up", label: "Roundoff Up" },
    { value: "roundoff-down", label: "Roundoff Down" },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content bg-white">
          <div className="d-md-flex d-block align-items-center justify-content-between border-bottom pb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Paramètres de l'application</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="index">Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Paramètres</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Paramètres de l'application
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <div className="pe-1 mb-2">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Rafraîchir</Tooltip>}
                >
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white btn-icon me-1"
                  >
                    <i className="ti ti-refresh" />
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-2 col-xl-3">
              <div className="pt-3 d-flex flex-column list-group mb-4">
                <Link
                  to={routes.invoiceSettings}
                  className="d-block rounded p-2 active"
                >
                  Paramètres de facturation
                </Link>
                <Link to={routes.customFields} className="d-block rounded p-2">
                  Champs personnalisés
                </Link>
              </div>
            </div>
            <div className="col-xxl-10 col-xl-9">
              <div className="flex-fill border-start ps-3">
                <form>
                  <div className="d-flex align-items-center justify-content-between flex-wrap border-bottom pt-3 mb-3">
                    <div className="mb-3">
                      <h5 className="mb-1">Paramètres de facturation</h5>
                      <p>Collection de paramètres pour la facturation</p>
                    </div>
                    <div className="mb-3">
                      <button className="btn btn-light me-2" type="button">
                        Annuler
                      </button>
                      <button className="btn btn-primary" type="submit">
                        Enregistrer
                      </button>
                    </div>
                  </div>
                  <div className="d-md-flex d-block">
                    <div className="row flex-fill">
                      <div className="col-xl-10">
                        <div className="settings-middle-info invoice-setting-wrap">
                          <div className="row align-items-center mb-2">
                            <div className="col-xxl-7 col-lg-6">
                              <div className="invoice-info-title">
                                <h6>Logo de la facture</h6>
                                <p>
                                  Téléchargez le logo de votre entreprise pour l'afficher sur la facture
                                </p>
                              </div>
                            </div>
                            <div className="col-xxl-5 col-lg-6">
                              <div className="card">
                                <div className="card-body">
                                  <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex align-items-center">
                                      <span className="avatar avatar-xl border rounded d-flex align-items-center justify-content-center p-2 me-2">
                                        <ImageWithBasePath
                                          src="assets/img/logo-small.svg"
                                          alt="Img"
                                        />
                                      </span>
                                      <h5>Logo</h5>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <Link
                                        to="#"
                                        className="text-primary border rounded fs-16 p-1 badge badge-primary-hover me-2"
                                      >
                                        <i className="ti ti-edit-circle" />
                                      </Link>
                                      <Link
                                        to="#"
                                        className="text-danger border rounded fs-16 p-1 badge badge-danger-hover"
                                      >
                                        <i className="ti ti-trash-x" />
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="profile-uploader profile-uploader-two mb-0">
                                    <span className="d-block text-center lh-1 fs-24 mb-1">
                                      <i className="ti ti-upload" />
                                    </span>
                                    <div className="drag-upload-btn bg-transparent me-0 border-0">
                                      <p className="fs-12 mb-2">
                                        <span className="text-primary">
                                          Cliquez pour télécharger
                                        </span>{" "}
                                        ou glissez-déposez
                                      </p>
                                      <h6>JPG ou PNG</h6>
                                      <h6>(Max 450 x 450 px)</h6>
                                    </div>
                                    <input
                                      type="file"
                                      className="form-control"
                                      multiple
                                      id="image_sign"
                                    />
                                    <div id="frames" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between flex-wrap border mb-3 p-3 pb-0 rounded">
                            <div className="row align-items-center flex-fill">
                              <div className="col-xxl-7 col-lg-6">
                                <div className="mb-3">
                                  <h6>Préfixe de facture</h6>
                                  <p>Ajoutez un préfixe à votre facture</p>
                                </div>
                              </div>
                              <div className="col-xxl-5 col-lg-6">
                                <div className="mb-3">
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between flex-wrap border mb-3 p-3 pb-0 rounded">
                            <div className="row align-items-center flex-fill">
                              <div className="col-xxl-7 col-lg-6">
                                <div className="mb-3">
                                  <h6>Échéance de la facture</h6>
                                  <p>Sélectionnez la date d'échéance à afficher sur la facture</p>
                                </div>
                              </div>
                              <div className="col-xxl-5 col-lg-6">
                                <div className="mb-3 d-flex align-items-center">
                                  <div className="w-100">
                                    <CommonSelect
                                      className="select"
                                      options={options1}
                                      defaultValue={options1[0]}
                                    />
                                  </div>
                                  <span className="ms-3 d-block">Jours</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between flex-wrap border mb-3 p-3 pb-0 rounded">
                            <div className="row align-items-center flex-fill">
                              <div className="col-xxl-7 col-lg-6">
                                <div className="mb-3">
                                  <h6>Arrondir les factures</h6>
                                  <p>Valeur arrondie dans la facture</p>
                                </div>
                              </div>
                              <div className="col-xxl-5 col-lg-6">
                                <div className="mb-3 d-flex align-items-center">
                                  <div className="w-100">
                                    <CommonSelect
                                      className="select"
                                      options={options2}
                                      defaultValue={options2[0]}
                                    />
                                  </div>
                                  <div className="status-toggle modal-status ms-3">
                                    <input
                                      type="checkbox"
                                      id="user1"
                                      className="check"
                                    />
                                    <label htmlFor="user1" className="checktoggle">
                                      {" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between flex-wrap border mb-3 p-3 pb-0 rounded">
                            <div className="row align-items-center flex-fill">
                              <div className="col-xxl-7 col-lg-6">
                                <div className="mb-3">
                                  <h6>Afficher les détails de l'entreprise</h6>
                                  <p>Afficher/masquer les détails de l'entreprise dans la facture</p>
                                </div>
                              </div>
                              <div className="col-xxl-5 col-lg-6">
                                <div className="mb-3">
                                  <div className="status-toggle modal-status">
                                    <input
                                      type="checkbox"
                                      id="user2"
                                      className="check"
                                    />
                                    <label htmlFor="user2" className="checktoggle">
                                      {" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between flex-wrap border mb-3 p-3 pb-0 rounded">
                            <div className="row align-items-center flex-fill">
                              <div className="col-xxl-7 col-lg-6">
                                <div className="mb-3">
                                  <h6>Afficher les coordonnées bancaires</h6>
                                  <p>Afficher/masquer les coordonnées bancaires dans la facture</p>
                                </div>
                              </div>
                              <div className="col-xxl-5 col-lg-6">
                                <div className="mb-3">
                                  <div className="status-toggle modal-status">
                                    <input
                                      type="checkbox"
                                      id="user3"
                                      className="check"
                                    />
                                    <label htmlFor="user3" className="checktoggle">
                                      {" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between flex-wrap border mb-3 p-3 pb-0 rounded">
                            <div className="row align-items-center flex-fill">
                              <div className="col-xxl-7 col-lg-6">
                                <div className="mb-3">
                                  <h6>Numéro de facture</h6>
                                  <p>Numérotation automatique des factures</p>
                                </div>
                              </div>
                              <div className="col-xxl-5 col-lg-6">
                                <div className="mb-3">
                                  <div className="status-toggle modal-status">
                                    <input
                                      type="checkbox"
                                      id="user4"
                                      className="check"
                                    />
                                    <label htmlFor="user4" className="checktoggle">
                                      {" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between flex-wrap border mb-3 p-3 pb-0 rounded">
                            <div className="row align-items-center flex-fill">
                              <div className="col-xxl-7 col-lg-6">
                                <div className="mb-3">
                                  <h6>Affichage des conditions générales</h6>
                                  <p>Afficher/masquer les conditions générales sur la facture</p>
                                </div>
                              </div>
                              <div className="col-xxl-5 col-lg-6">
                                <div className="mb-3">
                                  <div className="status-toggle modal-status">
                                    <input
                                      type="checkbox"
                                      id="user5"
                                      className="check"
                                    />
                                    <label htmlFor="user5" className="checktoggle">
                                      {" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between flex-wrap border p-3 pb-0 rounded">
                            <div className="row align-items-center flex-fill">
                              <div className="col-xxl-7 col-lg-6">
                                <div className="mb-3">
                                  <h6>Afficher les informations fiscales</h6>
                                  <p>Afficher/masquer les informations fiscales sur la facture</p>
                                </div>
                              </div>
                              <div className="col-xxl-5 col-lg-6">
                                <div className="mb-3">
                                  <div className="status-toggle modal-status">
                                    <input
                                      type="checkbox"
                                      id="user6"
                                      className="check"
                                    />
                                    <label htmlFor="user6" className="checktoggle">
                                      {" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2">
                        <div className="settings-right-sidebar h-100 mt-4 mt-md-0">
                          <h5 className="mb-4">Plus d'options</h5>
                          <div className="mb-3">
                            <button className="btn btn-light w-100" type="button">
                              Aperçu de la facture
                            </button>
                          </div>
                          <div className="mb-3">
                            <button className="btn btn-light w-100" type="button">
                              Téléchargez une facture
                            </button>
                          </div>
                          <div className="mb-3">
                            <button className="btn btn-light w-100" type="button">
                              Nouveau modèle
                            </button>
                          </div>
                          <div className="mb-3">
                            <button className="btn btn-light w-100" type="button">
                              Ajouter un élément
                            </button>
                          </div>
                          <div className="mb-3">
                            <button className="btn btn-light w-100" type="button">
                              Ouvrir les paramètres
                            </button>
                          </div>
                          <div className="mb-3">
                            <button className="btn btn-light w-100" type="button">
                              Fichiers de rapport
                            </button>
                          </div>
                          <div className="mb-3">
                            <button className="btn btn-light w-100" type="button">
                              Gérer les éléments
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default InvoiceSettings;
