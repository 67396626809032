import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { Etablissementlist } from "../../../core/data/json/etablissementList";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import Table from "../../../core/common/dataTable/index";
import { useLocation, useNavigate } from 'react-router-dom';
import { Column, Etablissement, EtablissementsListProps } from "../../../core/data/interface";



const EtablissementsList: React.FC<EtablissementsListProps> = () => {
  const routes = all_routes;
  const [data, setData] = useState<Etablissement[]>(Etablissementlist);
  const [selectedCadres, setSelectedCadres] = useState<string[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedVilles, setSelectedVilles] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const location = useLocation()
  const navigate = useNavigate()

  const { state } = location;

  const cadreOptions: string[] = ["Privé", "Public", "Exotic"];
  const typeOptions: string[] = ["Ecole", "Université", "Centre"];
  const villeOptions: string[] = Array.from(new Set(Etablissementlist.map((item) => item.city)));


  const handleCheckboxChange = (type: string, value: string) => {
    if (type === "cadre") {
      setSelectedCadres((prev) =>
        prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
      );
    } else if (type === "type") {
      setSelectedTypes((prev) =>
        prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
      );
    } else if (type === "ville") {
      setSelectedVilles((prev) =>
        prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
      );
    }
  };

  useEffect(() => {
    if (state) {
      setSelectedTypes([state.type || '']);
      setSelectedCadres([
        state.cadre === 'Private' ? 'Privé' : state.cadre || ''
      ]);
    }    
  }, [state])

  useEffect(() => {
    const filteredData = Etablissementlist.filter((item) => {
      const matchesCadres =
        selectedCadres.length === 0 || selectedCadres.includes(item.cadre);
      const matchesTypes =
        selectedTypes.length === 0 || selectedTypes.includes(item.type);
      const matchesVilles =
        selectedVilles.length === 0 || selectedVilles.includes(item.city);

      // Filtre par recherche (nom, ville, type)
      const matchesSearchQuery =
        searchQuery === "" ||
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.type.toLowerCase().includes(searchQuery.toLowerCase());

      return matchesCadres && matchesTypes && matchesVilles && matchesSearchQuery;
    });

    setData(filteredData);
  }, [selectedCadres, selectedTypes, selectedVilles, searchQuery]);

  const columns: Column[] = [
    {
      title: "Nom de l'établissement",
      dataIndex: "name",
      render: (text: string, record?: Etablissement) => {
        if (!record) return null;
        return (
          <div className="d-flex align-items-center">
            <Link to="#" className="avatar avatar-md">
              <ImageWithBasePath
                src={record.imgSrc}
                className="img-fluid rounded-circle"
                alt="img"
              />
            </Link>
            <div className="ms-2">
              <p className="text-dark mb-0">
                <Link to="#">{text}</Link>
              </p>
            </div>
          </div>
        );
      },
      sorter: (a: Etablissement, b: Etablissement) => a.name.length - b.name.length,
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text: string) => <span>{text}</span>,
      sorter: (a: Etablissement, b: Etablissement) => a.type.length - b.type.length,
    },
    {
      title: "Cadre",
      dataIndex: "cadre",
      render: (text: string) => <span>{text}</span>,
      sorter: (a: Etablissement, b: Etablissement) => a.cadre.length - b.cadre.length,
    },
    {
      title: "Ville",
      dataIndex: "city",
      sorter: (a: Etablissement, b: Etablissement) => a.city.length - b.city.length,
    },
    {
      title: "Pays",
      dataIndex: "country",
      sorter: (a: Etablissement, b: Etablissement) => a.country.length - b.country.length,
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: (text: string) => (
        <>
          {text === "Actif" ? (
            <span className="badge badge-soft-success d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : (
            <span className="badge badge-soft-danger d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          )}
        </>
      ),
      sorter: (a: Etablissement, b: Etablissement) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record : any) => (
        <div className="d-flex align-items-center">
          <button className="btn btn-primary" onClick={() => {
            localStorage.setItem("etablissement", JSON.stringify(record))
            localStorage.setItem("ministry-etablissement", JSON.stringify(true))
            localStorage.setItem("isMinistry", JSON.stringify(true))

            navigate(routes.mainDashboard);
          }}>Voir l'établissement ✨</button>
        </div>
      ),
    },    
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        {/* En-tête de Page */}
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <div className="my-auto mb-2">
            <h3 className="page-title mb-1">Liste des Etablissements</h3>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={routes.gestionEtablissementsScolaires}>Etablissements scolaires</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Tous les Etablissements
                </li>
              </ol>
            </nav>
          </div>
          <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
            <div className="mb-2">
              <Link
                to={routes.addEtablissement}
                className="btn btn-primary d-flex align-items-center"
              >
                <i className="ti ti-square-rounded-plus me-2" />
                Ajouter un Etablissement
              </Link>
            </div>
          </div>
        </div>
        {/* /En-tête de Page */}
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
            <h4 className="mb-3">Liste des étudiants</h4>
            <div className="d-flex align-items-center flex-wrap">
              <div className="dropdown mb-3 me-2">
                <Link
                  to="#"
                  className="btn btn-outline-light bg-white dropdown-toggle"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                >
                  <i className="ti ti-filter me-2" />
                  Filtrer
                </Link>
                <div
                  className="dropdown-menu drop-width"
                >
                  <div className="card mb-4">
                    <div className="card-header">
                      <h4>Filtres</h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        {/* Filtres Cadres */}
                        <div className="col-lg-4 col-md-6 mb-3">
                          <h5>Cadre</h5>
                          {cadreOptions.map((cadre) => (
                            <div key={cadre}>
                              <input
                                type="checkbox"
                                id={`cadre-${cadre}`}
                                value={cadre}
                                checked={selectedCadres.includes(cadre)}
                                onChange={() => handleCheckboxChange("cadre", cadre)}
                              />
                              <label htmlFor={`cadre-${cadre}`}>{cadre}</label>
                            </div>
                          ))}
                        </div>
                        {/* Filtres Types */}
                        <div className="col-lg-4 col-md-6 mb-3">
                          <h5>Type</h5>
                          {typeOptions.map((type) => (
                            <div key={type}>
                              <input
                                type="checkbox"
                                id={`type-${type}`}
                                value={type}
                                checked={selectedTypes.includes(type)}
                                onChange={() => handleCheckboxChange("type", type)}
                              />
                              <label htmlFor={`type-${type}`}>{type}</label>
                            </div>
                          ))}
                        </div>
                        {/* Filtres Villes */}
                        <div className="col-lg-4 col-md-6 mb-3">
                          <h5>Ville</h5>
                          {villeOptions.map((ville) => (
                            <div key={ville}>
                              <input
                                type="checkbox"
                                id={`ville-${ville}`}
                                value={ville}
                                checked={selectedVilles.includes(ville)}
                                onChange={() => handleCheckboxChange("ville", ville)}
                              />
                              <label htmlFor={`ville-${ville}`}>{ville}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center bg-white border rounded-2 p-1 mb-3 me-2">
                <Link
                  to={routes.etablissementList}
                  className="active btn btn-icon btn-sm me-1 primary-hover"
                >
                  <i className="ti ti-list-tree" />
                </Link>
                <Link
                  to={routes.etablissementGrid}
                  className="btn btn-icon btn-sm bg-light primary-hover"
                >
                  <i className="ti ti-grid-dots" />
                </Link>
              </div>
            </div>
          </div>
          <div className="card-body p-0 py-3">
            {/* Recherche */}
            <div className="serch-global">
              <input
                type="search"
                className="form-control form-control-sm mb-3 w-auto float-end"
                placeholder="Recherche"
                aria-controls="DataTables_Table_0"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {/* Liste des étudiants */}
            <Table dataSource={data} columns={columns} Selection={true} />
            {/* /Liste des étudiants */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EtablissementsList;