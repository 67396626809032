export const classSyllabus = [
	{
	  id: "1",
	  classe: "I",
	  section: "A",
	  groupeDeMatières: "I, C Anglais",
	  dateDeCréation: "10 Mai 2024",
	  statut: "Actif",
	  key: 1,
	},
	{
	  id: "2",
	  classe: "I",
	  section: "B",
	  groupeDeMatières: "III, A Mathématiques",
	  dateDeCréation: "11 Mai 2024",
	  statut: "Actif",
	  key: 2,
	},
	{
	  id: "3",
	  classe: "II",
	  section: "A",
	  groupeDeMatières: "II, A Anglais",
	  dateDeCréation: "12 Mai 2024",
	  statut: "Actif",
	  key: 3,
	},
	{
	  id: "4",
	  classe: "II",
	  section: "B",
	  groupeDeMatières: "IV, A Physique",
	  dateDeCréation: "13 Mai 2024",
	  statut: "Actif",
	  key: 4,
	},
	{
	  id: "5",
	  classe: "II",
	  section: "C",
	  groupeDeMatières: "V, A Chimie",
	  dateDeCréation: "14 Mai 2024",
	  statut: "Actif",
	  key: 5,
	},
	{
	  id: "6",
	  classe: "III",
	  section: "A",
	  groupeDeMatières: "III, B Mathématiques",
	  dateDeCréation: "15 Mai 2024",
	  statut: "Actif",
	  key: 6,
	},
	{
	  id: "7",
	  classe: "III",
	  section: "B",
	  groupeDeMatières: "IV, B Chimie",
	  dateDeCréation: "16 Mai 2024",
	  statut: "Actif",
	  key: 7,
	},
	{
	  id: "8",
	  classe: "IV",
	  section: "A",
	  groupeDeMatières: "I, B Mathématiques",
	  dateDeCréation: "17 Mai 2024",
	  statut: "Actif",
	  key: 11,
	},
	{
	  id: "9",
	  classe: "IV",
	  section: "B",
	  groupeDeMatières: "VI, B Chimie",
	  dateDeCréation: "18 Mai 2024",
	  statut: "Actif",
	  key: 8,
	},
	{
	  id: "10",
	  classe: "V",
	  section: "A",
	  groupeDeMatières: "IV, D Mathématiques",
	  dateDeCréation: "19 Mai 2024",
	  statut: "Actif",
	  key: 9,
	},
  ];  