import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import Logo from "../../../core/common/Logo";
type PasswordField = "password";

const Login3 = () => {
  const routes = all_routes;
  const navigation = useNavigate();

  const navigationPath = () => {
    navigation(routes.mainDashboard);
  };
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
  });

  const togglePasswordVisibility = (field: PasswordField) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5 mx-auto">
            <form>
              <div className="d-flex flex-column justify-content-between vh-100">
                <div className=" mx-auto p-4 text-center">
                <Logo />
                </div>
                <div className="card">
                  <div className="card-body p-4">
                    <div className=" mb-4">
                      <h2 className="mb-2 text-center">Connexion</h2>
                      <p className="mb-0 text-center">
                        Veuillez entrer vos informations pour vous connecter
                      </p>
                    </div>
                    <div className="mb-3 ">
                      <label className="form-label">L'email de l'utilisateur</label>
                      <div className="input-icon mb-3 position-relative">
                        <span className="input-icon-addon">
                          <i className="ti ti-mail" />
                        </span>
                        <input type="text" className="form-control" />
                      </div>
                      <label className="form-label">Mot de passe</label>
                      <div className="pass-group">
                        <input
                          type={
                            passwordVisibility.password ? "text" : "password"
                          }
                          className="pass-input form-control"
                        />
                        <span
                          className={`ti toggle-passwords ${
                            passwordVisibility.password
                              ? "ti-eye"
                              : "ti-eye-off"
                          }`}
                          onClick={() => togglePasswordVisibility("password")}
                        ></span>
                      </div>
                    </div>
                    <div className="form-wrap form-wrap-checkbox mb-3">
                      <div className="d-flex align-items-center">
                        <div className="form-check form-check-md mb-0">
                          <input
                            className="form-check-input mt-0"
                            type="checkbox"
                          />
                        </div>
                        <p className="ms-1 mb-0 ">Se souvenir de moi</p>
                      </div>
                      <div className="text-end ">
                        <Link to={routes.forgotPassword} className="link-danger">
                          Mot de passe oublié ?
                        </Link>
                      </div>
                    </div>
                    <div className="mb-3">
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        onClick={navigationPath}
                      >
                        Se connecter
                      </button>
                    </div>
                    <div className="text-center">
                      <h6 className="fw-normal text-dark mb-0">
                        Vous n'avez pas de compte ?{" "}
                        <Link to={routes.register} className="hover-a ">
                          {" "}
                          Créer un compte
                        </Link>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="p-4 text-center">
                  <p className="mb-0 ">Copyright © 2024 - Preskool</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};


export default Login3;
