import { all_routes } from "../../../feature-module/router/all_routes";

const routes = all_routes;

export const SidebarDataGestionEtablissements = [
  {
      "label": "",
      "submenuOpen": true,
      "showSubRoute": false,
      "submenuHdr": "Principal",
      "submenuItems": [
        {
          "label": "Tableau de Bord",
          "icon": "ti ti-layout-dashboard",
          "submenu": true,
          "showSubRoute": false,
          "submenuItems": [
            { "label": "Tableau de Bord Etablissements", "link": routes.gestionEtablissementsScolaires },
          ]
        },
      ]
  },
  {
    "label": "",
    "submenuOpen": true,
    "showSubRoute": false,
    "submenuHdr": "Etablissements scolaires",
    "submenuItems": [
      {
        "label": "Etablissements scolaires",
        "icon": "ti ti-school",
        "submenu": true,
        "showSubRoute": false,
        "submenuItems": [
          { "label": "Liste des Etablissements", "link": routes.etablissementList },
        ]
      },
    ]
  },
];


export const SidebarDataCoursClasses = [
  {
    "label": "Académique",
    "submenuOpen": true,
    "showSubRoute": false,
    "submenuHdr": "Académique",
    "submenuItems": [
      {
        "label": "Classes",
        "icon": "ti ti-school-bell",
        "submenu": true,
        "showSubRoute": false,
        "submenuItems": [
          { "label": "Classes", "link": routes.classes },
          { "label": "Emploi du Temps", "link": routes.sheduleClasses }
        ]
      },
      {
        "label": "Salle de Classe",
        "link": routes.classRoom,
        "icon": "ti ti-building",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Routine de Classe",
        "link": routes.classRoutine,
        "icon": "ti ti-bell-school",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Section",
        "link": routes.classSection,
        "icon": "ti ti-square-rotated-forbid-2",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Matière",
        "link": routes.classSubject,
        "icon": "ti ti-book",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Programme",
        "link": routes.classSyllabus,
        "icon": "ti ti-book-upload",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Emploi du Temps",
        "link": routes.classTimetable,
        "icon": "ti ti-table",
        "showSubRoute": false,
        "submenu": false
      }
    ]
  },
]

export const SidebarGestionUtilisateurs = [
  {
    "label": "Gestion des Utilisateurs",
    "submenuOpen": true,
    "showSubRoute": false,
    "submenuHdr": "Académique",
    "submenuItems": [
      {
        "label": "Gestion des utilisateurs",
        "icon": "ti ti-user",
        "submenu": true,
        "showSubRoute": false,
        "submenuItems": [
          { "label": "Ajouter un utilisateur", "link": routes.addStudent },
          { "label": "Afficher Utilisateur", "link": routes.studentList },
          { "label": "Supprimer un Utilisateur", "link": routes.sheduleClasses },
          { "label": "Mis a jour d'un Utilisateur", "link": routes.sheduleClasses }
        ]
      },
      {
        "label": "Gestion des Roles",
        "icon": "ti ti-key",
        "submenu": true,
        "showSubRoute": false,
        "submenuItems": [
          { "label": "Creation Role", "icon": "ti ti-home", "link": routes.addStudent },
          { "label": "Supprimer un Role","icon": "ti ti-user-delete", "link": routes.studentList },
          { "label": "Afficher des Roles", "link": routes.sheduleClasses },
          { "label": "Modifier un Role", "link": routes.sheduleClasses }
        ]
      },
      {
        "label": "Gestion des Permissions",
        "icon": "ti ti-lock",
        "submenu": true,
        "showSubRoute": false,
        "submenuItems": [
          { "label": "Creer une Permission", "icon": "ti ti-home", "link": routes.addStudent },
          { "label": "Supprimer une Permission","icon": "ti ti-user-delete", "link": routes.studentList },
          { "label": "Afficher des Permissions", "link": routes.sheduleClasses },
          { "label": "Modifier une Permission", "link": routes.sheduleClasses }
        ]
      },
      {
        "label": "Rapport et Statistique",
        "link": routes.classTimetable,
        "icon": "ti ti-table",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Securité et Connexion",
        "link": routes.classTimetable,
        "icon": "ti ti-shield",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Vue General",
        "link": routes.classTimetable,
        "icon": "ti ti-dashboard",
        "showSubRoute": false,
        "submenu": false
      },
      
    ]
  },
  
  {
    label: "Paramètres",
    submenu: true,
    showSubRoute: false,
    submenuHdr: "Paramètres",
    submenuItems: [
      {
        label: "Paramètres Généraux",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-shield-cog",
        submenuItems: [
          { label: "Paramètres du Profil", link: routes.profilesettings },
          { label: "Paramètres de Sécurité", link: routes.securitysettings },
          {
            label: "Paramètres des Notifications",
            link: routes.notificationssettings,
          },
          { label: "Applications Connectées", link: routes.connectedApps },
        ],
      },
      {
        label: "Paramètres du Site Web",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-device-laptop",
        submenuItems: [
          {
            label: "Paramètres de l'Entreprise",
            link: routes.companySettings,
            showSubRoute: false,
          },
          {
            label: "Localisation",
            link: routes.localization,
            showSubRoute: false,
          },
          { label: "Préfixes", link: routes.prefixes, showSubRoute: false },
          { label: "Préférences", link: routes.preference, showSubRoute: false },
          {
            label: "Authentification Sociale",
            link: routes.socialAuthentication,
            showSubRoute: false,
          },
          {
            label: "Langue",
            link: routes.language,
            showSubRoute: false,
          },
        ],
      },
      {
        label: "Paramètres de l'Application",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-apps",
        submenuItems: [
          {
            label: "Paramètres des Factures",
            link: routes.invoiceSettings,
            showSubRoute: false,
          },
          {
            label: "Champs Personnalisés",
            link: routes.customFields,
            showSubRoute: false,
          },
        ],
      },
      {
        label: "Paramètres du Système",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-file-symlink",
        submenuItems: [
          {
            label: "Paramètres des Emails",
            link: routes.emailSettings,
            showSubRoute: false,
          },
          {
            label: "Modèles d'Emails",
            link: routes.emailTemplates,
            showSubRoute: false,
          },
          {
            label: "Paramètres des SMS",
            link: routes.smsSettings,
            showSubRoute: false,
          },
          {
            label: "OTP",
            link: routes.optSettings,
            showSubRoute: false,
          },
          {
            label: "Cookies GDPR",
            link: routes.gdprCookies,
            showSubRoute: false,
          },
        ],
      },
      {
        label: "Paramètres Financiers",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-zoom-money",
        submenuItems: [
          {
            label: "Passerelle de Paiement",
            link: routes.paymentGateways,
            showSubRoute: false,
          },
          { label: "Taux de Taxe", link: routes.taxRates, showSubRoute: false },
        ],
      },
      {
        label: "Paramètres Académiques",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-calendar-repeat",
        submenuItems: [
          {
            label: "Paramètres de l'École",
            link: routes.schoolSettings,
            showSubRoute: false,
          },
          { label: "Religion", link: routes.religion, showSubRoute: false },
        ],
      },
      {
        label: "Autres Paramètres",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-flag-cog",
        submenuItems: [
          { label: "Stockage", link: routes.storage, showSubRoute: false },
          {
            label: "Bannir Adresse IP",
            link: routes.banIpAddress,
            showSubRoute: false,
          },
        ],
      },
    ],
  },
]

export const SidebarDataInscriptions = [
  {
    "label": "Académique",
    "submenuOpen": true,
    "showSubRoute": false,
    "submenuHdr": "Académique",
    "submenuItems": [
      {
        "label": "Gestion des inscriptions",
        "icon": "ti ti-user",
        "submenu": true,
        "showSubRoute": false,
        "submenuItems": [
          { "label": "Ajouter une inscription", "link": routes.addStudent },
          { "label": "Afficher les inscriptions", "link": routes.studentList },
          { "label": "Supprimer une inscription", "link": routes.sheduleClasses },
          { "label": "Mis a jour d'une inscription", "link": routes.sheduleClasses }
        ]
      },
      {
        "label": "Gestion des paiements",
        "link": routes.classRoom,
        "icon": "ti ti-credit-card",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Dossiers Scolaire",
        "link": routes.classSection,
        "icon": "ti ti-folder",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Rapport et Statistique",
        "link": routes.classRoutine,
        "icon": "ti ti-bar-chart",
        "showSubRoute": false,
        "submenu": false
      },

    ]
  },
  {
    label: "Paramètres",
    submenu: true,
    showSubRoute: false,
    submenuHdr: "Paramètres",
    submenuItems: [
      {
        label: "Paramètres Généraux",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-shield-cog",
        submenuItems: [
          { label: "Paramètres du Profil", link: routes.profilesettings },
          { label: "Paramètres de Sécurité", link: routes.securitysettings },
          {
            label: "Paramètres des Notifications",
            link: routes.notificationssettings,
          },
          { label: "Applications Connectées", link: routes.connectedApps },
        ],
      },
      {
        label: "Paramètres du Site Web",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-device-laptop",
        submenuItems: [
          {
            label: "Paramètres de l'Entreprise",
            link: routes.companySettings,
            showSubRoute: false,
          },
          {
            label: "Localisation",
            link: routes.localization,
            showSubRoute: false,
          },
          { label: "Préfixes", link: routes.prefixes, showSubRoute: false },
          { label: "Préférences", link: routes.preference, showSubRoute: false },
          {
            label: "Authentification Sociale",
            link: routes.socialAuthentication,
            showSubRoute: false,
          },
          {
            label: "Langue",
            link: routes.language,
            showSubRoute: false,
          },
        ],
      },
      {
        label: "Paramètres de l'Application",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-apps",
        submenuItems: [
          {
            label: "Paramètres des Factures",
            link: routes.invoiceSettings,
            showSubRoute: false,
          },
          {
            label: "Champs Personnalisés",
            link: routes.customFields,
            showSubRoute: false,
          },
        ],
      },
      {
        label: "Paramètres du Système",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-file-symlink",
        submenuItems: [
          {
            label: "Paramètres des Emails",
            link: routes.emailSettings,
            showSubRoute: false,
          },
          {
            label: "Modèles d'Emails",
            link: routes.emailTemplates,
            showSubRoute: false,
          },
          {
            label: "Paramètres des SMS",
            link: routes.smsSettings,
            showSubRoute: false,
          },
          {
            label: "OTP",
            link: routes.optSettings,
            showSubRoute: false,
          },
          {
            label: "Cookies GDPR",
            link: routes.gdprCookies,
            showSubRoute: false,
          },
        ],
      },
      {
        label: "Paramètres Financiers",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-zoom-money",
        submenuItems: [
          {
            label: "Passerelle de Paiement",
            link: routes.paymentGateways,
            showSubRoute: false,
          },
          { label: "Taux de Taxe", link: routes.taxRates, showSubRoute: false },
        ],
      },
      {
        label: "Paramètres Académiques",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-calendar-repeat",
        submenuItems: [
          {
            label: "Paramètres de l'École",
            link: routes.schoolSettings,
            showSubRoute: false,
          },
          { label: "Religion", link: routes.religion, showSubRoute: false },
        ],
      },
      {
        label: "Autres Paramètres",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-flag-cog",
        submenuItems: [
          { label: "Stockage", link: routes.storage, showSubRoute: false },
          {
            label: "Bannir Adresse IP",
            link: routes.banIpAddress,
            showSubRoute: false,
          },
        ],
      },
    ],
  },
]




export const SidebarData = [
  {
      "label": "PRINCIPAL",
      "submenuOpen": true,
      "showSubRoute": false,
      "submenuHdr": "Principal",
      "submenuItems": [
        {
          "label": "Tableau de Bord",
          "icon": "ti ti-layout-dashboard",
          "submenu": true,
          "showSubRoute": false,
          "submenuItems": [
            { "label": "Tableau de Bord Administrateur", "link": routes.adminDashboard },
            { "label": "Tableau de Bord Enseignant", "link": routes.teacherDashboard },
            { "label": "Tableau de Bord Élève", "link": routes.studentDashboard },
            { "label": "Tableau de Bord Parent", "link": routes.parentDashboard }
          ]
        },
        {
          "label": "Application",
          "icon": "ti ti-layout-list",
          "submenu": true,
          "showSubRoute": false,
          "submenuItems": [
            { "label": "Chat", "link": routes.chat, "showSubRoute": false },
            { "label": "Appel", "link": routes.audioCall, "showSubRoute": false },
            { "label": "Calendrier", "link": routes.calendar, "showSubRoute": false },
            { "label": "Email", "link": routes.email, "showSubRoute": false },
            { "label": "À Faire", "link": routes.todo, "showSubRoute": false },
            { "label": "Notes", "link": routes.notes, "showSubRoute": false },
            { "label": "Gestionnaire de Fichiers", "link": routes.fileManager, "showSubRoute": false }
          ]
        }
      ]
  },
  {
    "label": "DISPOSITION",
    "submenuOpen": false,
    "showSubRoute": false,
    "submenuHdr": "DISPOSITION",
    "submenuItems": [
      {
        "label": "Par Défaut",
        "icon": "ti ti-layout-sidebar",
        "submenu": false,
        "showSubRoute": false,
        "link": routes.layoutDefault,
        "themeSetting": true
      },
      {
        "label": "Mini",
        "icon": "ti ti-layout-align-left",
        "submenu": false,
        "showSubRoute": false,
        "link": routes.layoutMini,
        "themeSetting": true
      },
      {
        "label": "RTL",
        "icon": "ti ti-text-direction-rtl",
        "submenu": false,
        "showSubRoute": false,
        "link": routes.layoutRtl,
        "themeSetting": true
      },
      {
        "label": "Boîte",
        "icon": "ti ti-layout-distribute-vertical",
        "submenu": false,
        "showSubRoute": false,
        "link": routes.layoutBox,
        "themeSetting": true
      },
      {
        "label": "Dark",
        "icon": "ti ti-moon",
        "submenu": false,
        "showSubRoute": false,
        "link": routes.layoutDark,
        "themeSetting": true
      }
    ]
  },
  {
    "label": "Personnes",
    "submenuOpen": true,
    "showSubRoute": false,
    "submenuHdr": "Personnes",
    "submenuItems": [
      {
        "label": "Élèves",
        "icon": "ti ti-school",
        "submenu": true,
        "showSubRoute": false,
        "submenuItems": [
          {
            "label": "Tous les Élèves",
            "link": routes.studentGrid,
            "subLink1": routes.addStudent,
            "subLink2": routes.editStudent
          },
          { "label": "Liste des Élèves", "link": routes.studentList },
          {
            "label": "Détails des Élèves",
            "link": routes.studentDetail,
            "subLink1": routes.studentLibrary,
            "subLink2": routes.studentResult,
            "subLink3": routes.studentFees,
            "subLink4": routes.studentLeaves,
            "subLink5": routes.studentTimeTable
          },
          { "label": "Promotion des Élèves", "link": routes.studentPromotion }
        ]
      },
      {
        "label": "Parents",
        "icon": "ti ti-user-bolt",
        "showSubRoute": false,
        "submenu": true,
        "submenuItems": [
          { "label": "Tous les Parents", "link": routes.parentGrid },
          { "label": "Liste des Parents", "link": routes.parentList }
        ]
      },
      {
        "label": "Tuteurs",
        "icon": "ti ti-user-shield",
        "showSubRoute": false,
        "submenu": true,
        "submenuItems": [
          { "label": "Tous les Tuteurs", "link": routes.guardiansGrid },
          { "label": "Liste des Tuteurs", "link": routes.guardiansList }
        ]
      },
      {
        "label": "Enseignants",
        "icon": "ti ti-users",
        "submenu": true,
        "showSubRoute": false,
        "submenuItems": [
          {
            "label": "Tous les Enseignants",
            "link": routes.teacherGrid,
            "subLink1": routes.addTeacher,
            "subLink2": routes.editTeacher
          },
          { "label": "Liste des Enseignants", "link": routes.teacherList },
          {
            "label": "Détails des Enseignants",
            "link": routes.teacherDetails,
            "subLink1": routes.teacherLibrary,
            "subLink2": routes.teacherSalary,
            "subLink3": routes.teacherLeaves
          },
          { "label": "Routine", "link": routes.teachersRoutine }
        ]
      }
    ]
  },
  {
    "label": "Académique",
    "submenuOpen": true,
    "showSubRoute": false,
    "submenuHdr": "Académique",
    "submenuItems": [
      {
        "label": "Classes",
        "icon": "ti ti-school-bell",
        "submenu": true,
        "showSubRoute": false,
        "submenuItems": [
          { "label": "Classes", "link": routes.classes },
          { "label": "Emploi du Temps", "link": routes.sheduleClasses }
        ]
      },
      {
        "label": "Salle de Classe",
        "link": routes.classRoom,
        "icon": "ti ti-building",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Routine de Classe",
        "link": routes.classRoutine,
        "icon": "ti ti-bell-school",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Section",
        "link": routes.classSection,
        "icon": "ti ti-square-rotated-forbid-2",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Matière",
        "link": routes.classSubject,
        "icon": "ti ti-book",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Programme",
        "link": routes.classSyllabus,
        "icon": "ti ti-book-upload",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Emploi du Temps",
        "link": routes.classTimetable,
        "icon": "ti ti-table",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Devoirs",
        "link": routes.classHomeWork,
        "icon": "ti ti-license",
        "showSubRoute": false,
        "submenu": false
      },
      {
        "label": "Examens",
        "icon": "ti ti-hexagonal-prism-plus",
        "submenu": true,
        "showSubRoute": false,
        "submenuItems": [
          { "label": "Examen", "link": routes.exam },
          { "label": "Emploi du Temps des Examens", "link": routes.examSchedule },
          { "label": "Grade", "link": routes.grade },
          { "label": "Présence aux Examens", "link": routes.examAttendance },
          { "label": "Résultats des Examens", "link": routes.examResult }
        ]
      },
      {
        "label": "Raisons",
        "link": routes.AcademicReason,
        "icon": "ti ti-lifebuoy",
        "showSubRoute": false,
        "submenu": false
      }
    ]
  },
  
  {
    label: "GESTION",
    submenuOpen: true,
    submenuHdr: "Gestion",
    submenu: false,
    showSubRoute: false,
    submenuItems: [
      {
        label: "Collecte des Frais",
        icon: "ti ti-report-money",
        submenu: true,
        showSubRoute: false,

        submenuItems: [
          { label: "Groupe de Frais", link: routes.feesGroup },
          { label: "Type de Frais", link: routes.feesType },
          { label: "Maître de Frais", link: routes.feesMaster },
          { label: "Attribution des Frais", link: routes.feesAssign },
          { label: "Collecter les Frais", link: routes.collectFees },
        ],
      },
      {
        label: "Bibliothèque",
        icon: "ti ti-notebook",
        submenu: true,
        showSubRoute: false,

        submenuItems: [
          { label: "Membres de la Bibliothèque", link: routes.libraryMembers },
          { label: "Livres", link: routes.libraryBooks },
          { label: "Prêter un Livre", link: routes.libraryIssueBook },
          { label: "Retour", link: routes.libraryReturn },
        ],
      },
      {
        label: "Sports",
        link: routes.sportsList,
        icon: "ti ti-run",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Joueurs",
        link: routes.playerList,
        icon: "ti ti-play-football",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Auberge",
        icon: "ti ti-building-fortress",
        submenu: true,
        showSubRoute: false,

        submenuItems: [
          { label: "Liste des Auberges", link: routes.hostelList },
          { label: "Chambres d'Auberge", link: routes.hostelRoom },
          { label: "Type de Chambre", link: routes.hostelType },
        ],
      },
      {
        label: "Transport",
        icon: "ti ti-bus",
        submenu: true,
        showSubRoute: false,

        submenuItems: [
          { label: "Itinéraires", link: routes.transportRoutes },
          { label: "Points de Ramassage", link: routes.transportPickupPoints },
          { label: "Conducteurs de Véhicules", link: routes.transportVehicleDrivers },
          { label: "Véhicule", link: routes.transportVehicle },
          { label: "Assigner un Véhicule", link: routes.transportAssignVehicle },
        ],
      },
    ],
  },
  {
    label: "RHM",
    submenuOpen: true,
    submenuHdr: "Ressources Humaines",
    submenu: false,
    showSubRoute: false,
    submenuItems: [
      {
        label: "Personnel",
        link: routes.staff,
        subLink1: routes.addStaff,
        subLink2: routes.editStaff,
        icon: "ti ti-users-group",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Départements",
        link: routes.departments,
        icon: "ti ti-layout-distribute-horizontal",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Poste",
        link: routes.designation,
        icon: "ti ti-user-exclamation",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Présences",
        icon: "ti ti-calendar-share",
        submenu: true,
        showSubRoute: false,

        submenuItems: [
          { label: "Présences des Étudiants", link: routes.studentAttendance },
          { label: "Présences des Enseignants", link: routes.teacherAttendance },
          { label: "Présences du Personnel", link: routes.staffAttendance },
        ],
      },
      {
        label: "Congés",
        icon: "ti ti-calendar-stats",
        submenu: true,
        showSubRoute: false,

        submenuItems: [
          { label: "Liste des Congés", link: routes.listLeaves },
          { label: "Approuver les Demandes", link: routes.approveRequest },
        ],
      },
      {
        label: "Vacances",
        link: routes.holidays,
        icon: "ti ti-briefcase",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Paie",
        link: routes.payroll,
        icon: "ti ti-moneybag",
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Finance & Comptabilité",
    submenuOpen: true,
    submenuHdr: "Finance & Comptabilité",
    submenu: false,
    showSubRoute: false,
    submenuItems: [
      {
        label: "Comptes",
        icon: "ti ti-swipe",
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          { label: "Dépenses", link: routes.expense },
          { label: "Catégorie de Dépense", link: routes.expenseCategory },
          { label: "Revenus", link: routes.accountsIncome },
          {
            label: "Factures",
            link: routes.accountsInvoices,
            subLink1: routes.addInvoice,
            subLink2: routes.editInvoice,
          },
          { label: "Vue de Facture", link: routes.invoice },
          { label: "Transactions", link: routes.accountsTransactions },
        ],
      },
    ],
  },
  {
    label: "Annonces",
    submenuOpen: true,
    submenuHdr: "Annonces",
    submenu: false,
    showSubRoute: false,
    submenuItems: [
      {
        label: "Tableau d'Affichage",
        link: routes.noticeBoard,
        icon: "ti ti-clipboard-data",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Événements",
        link: routes.events,
        icon: "ti ti-calendar-question",
        showSubRoute: false,
        submenu: false,
      },
    ],
  },

  {
    label: "Rapports",
    submenuOpen: true,
    submenuHdr: "Rapports",
    submenu: false,
    showSubRoute: false,
    submenuItems: [
      {
        label: "Rapport de Présences",
        link: routes.attendanceReport,
        subLink1: routes.studentAttendanceType,
        subLink2: routes.staffReport,
        subLink3: routes.teacherReport,
        subLink4: routes.staffDayWise,
        subLink5: routes.teacherDayWise,
        subLink6: routes.studentDayWise,
        subLink7: routes.dailyAttendance,
        icon: "ti ti-calendar-due",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Rapport de Classe",
        link: routes.classReport,
        icon: "ti ti-graph",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Rapport des Étudiants",
        link: routes.studentReport,
        icon: "ti ti-chart-infographic",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Rapport de Notes",
        link: routes.gradeReport,
        icon: "ti ti-calendar-x",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Rapport de Congés",
        link: routes.leaveReport,
        icon: "ti ti-line",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Rapport des Frais",
        link: routes.feesReport,
        icon: "ti ti-mask",
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "GESTION DES UTILISATEURS",
    submenuOpen: true,
    submenuHdr: "Ventes",
    submenu: false,
    showSubRoute: false,
    submenuItems: [
      {
        label: "Utilisateurs",
        link: routes.manageusers,
        icon: "ti ti-users-minus",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Rôles & Permissions",
        link: routes.rolesPermissions,
        icon: "ti ti-shield-plus",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Demande de Suppression de Compte",
        link: routes.deleteRequest,
        icon: "ti ti-user-question",
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "ADHÉSION",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Finance & Comptabilité",
    submenuItems: [
      {
        label: "Plans d'Adhésion",
        link: routes.membershipplan,
        icon: "ti ti-user-plus",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Addons d'Adhésion",
        link: routes.membershipAddon,
        icon: "ti ti-cone-plus",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Transactions",
        link: routes.membershipTransaction,
        icon: "ti ti-file-power",
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  
  {
    label: "CONTENU",
    icon: "ti ti-page-break",
    submenu: true,
    showSubRoute: false,
    submenuItems: [
      {
        label: "Pages",
        link: routes.pages,
        showSubRoute: false,
        icon: "ti ti-page-break",
      },
      {
        label: "Blog",
        icon: "ti ti-brand-blogger",
        submenu: true,
        submenuItems: [
          { label: "Tous les Blogs", link: routes.allBlogs },
          {
            label: "Catégories",
            link: routes.blogCategories,
            icon: "ti ti-quote",
          },
          {
            label: "Commentaires",
            link: routes.blogComments,
            icon: "ti ti-question-mark",
          },
          {
            label: "Tags",
            link: routes.blogTags,
            icon: "ti ti-question-mark",
          },
        ],
      },
      {
        label: "Emplacement",
        icon: "ti ti-map-pin-search",
        submenu: true,
        submenuItems: [
          { label: "Pays", link: routes.countries },
          { label: "États", link: routes.states, icon: "ti ti-quote" },
          {
            label: "Villes",
            link: routes.cities,
            icon: "ti ti-question-mark",
          },
        ],
      },
      {
        label: "Témoignages",
        link: routes.testimonials,
        showSubRoute: false,
        icon: "ti ti-quote",
      },
      {
        label: "FAQ",
        link: routes.faq,
        showSubRoute: false,
        icon: "ti ti-question-mark",
      },
    ],
  },
  {
    label: "Support",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Finance & Comptabilité",
    submenuItems: [
      {
        label: "Messages de Contact",
        link: routes.contactMessages,
        icon: "ti ti-message",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Tickets",
        link: routes.tickets,
        icon: "ti ti-ticket",
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Pages",
    submenu: true,
    showSubRoute: false,
    submenuHdr: "Authentification",
    submenuItems: [
      {
        label: "Profil",
        link: routes.profile,
        icon: "ti ti-user",
        showSubRoute: false,
        submenu: false,
      },

      {
        label: "Authentification",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-lock-square-rounded",
        submenuItems: [
          {
            label: "Connexion",
            submenu: true,
            showSubRoute: false,
            submenuItems: [
              { label: "Couverture", link: routes.login },
              { label: "Illustration", link: routes.login },
              { label: "Basique", link: routes.login },
            ],
          },
          {
            label: "Inscription",
            submenu: true,
            showSubRoute: false,
            submenuItems: [
              { label: "Couverture", link: routes.register },
              { label: "Illustration", link: routes.register },
              { label: "Basique", link: routes.register },
            ],
          },
          {
            label: "Mot de Passe Oublié",
            submenu: true,
            showSubRoute: false,
            submenuItems: [
              { label: "Couverture", link: routes.forgotPassword },
              { label: "Illustration", link: routes.forgotPassword },
              { label: "Basique", link: routes.forgotPassword },
            ],
          },
          {
            label: "Réinitialiser le Mot de Passe",
            submenu: true,
            showSubRoute: false,
            submenuItems: [
              { label: "Couverture", link: routes.resetPassword },
              { label: "Illustration", link: routes.resetPassword },
              { label: "Basique", link: routes.resetPassword },
            ],
          },
          {
            label: "Vérification par Email",
            submenu: true,
            showSubRoute: false,
            submenuItems: [
              { label: "Couverture", link: routes.emailVerification },
              { label: "Illustration", link: routes.emailVerification },
              { label: "Basique", link: routes.emailVerification },
            ],
          },
          {
            label: "Vérification en 2 Étapes",
            submenu: true,
            showSubRoute: false,
            submenuItems: [
              { label: "Couverture", link: routes.emailVerification },
              { label: "Illustration", link: routes.emailVerification },
              { label: "Basique", link: routes.emailVerification },
            ],
          },
          { label: "Écran de Verrouillage", link: routes.lockScreen },
        ],
      },
      {
        label: "Pages d'Erreur",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-error-404",
        submenuItems: [
          {
            label: "Erreur 404",
            link: routes.error404,
            showSubRoute: false,
          },
          { label: "Erreur 500", link: routes.error500, showSubRoute: false },
        ],
      },
      {
        label: "Page Vide",
        link: routes.blankPage,
        icon: "ti ti-brand-nuxt",
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Bientôt Disponible",
        link: routes.comingSoon,
        icon: "ti ti-file",
        showSubRoute: false,
      },
      {
        label: "En Maintenance",
        link: routes.underMaintenance,
        icon: "ti ti-moon-2",
        showSubRoute: false,
      },
    ],
  },
  
  {
    label: "Paramètres",
    submenu: true,
    showSubRoute: false,
    submenuHdr: "Paramètres",
    submenuItems: [
      {
        label: "Paramètres Généraux",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-shield-cog",
        submenuItems: [
          { label: "Paramètres du Profil", link: routes.profilesettings },
          { label: "Paramètres de Sécurité", link: routes.securitysettings },
          {
            label: "Paramètres des Notifications",
            link: routes.notificationssettings,
          },
          { label: "Applications Connectées", link: routes.connectedApps },
        ],
      },
      {
        label: "Paramètres du Site Web",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-device-laptop",
        submenuItems: [
          {
            label: "Paramètres de l'Entreprise",
            link: routes.companySettings,
            showSubRoute: false,
          },
          {
            label: "Localisation",
            link: routes.localization,
            showSubRoute: false,
          },
          { label: "Préfixes", link: routes.prefixes, showSubRoute: false },
          { label: "Préférences", link: routes.preference, showSubRoute: false },
          {
            label: "Authentification Sociale",
            link: routes.socialAuthentication,
            showSubRoute: false,
          },
          {
            label: "Langue",
            link: routes.language,
            showSubRoute: false,
          },
        ],
      },
      {
        label: "Paramètres de l'Application",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-apps",
        submenuItems: [
          {
            label: "Paramètres des Factures",
            link: routes.invoiceSettings,
            showSubRoute: false,
          },
          {
            label: "Champs Personnalisés",
            link: routes.customFields,
            showSubRoute: false,
          },
        ],
      },
      {
        label: "Paramètres du Système",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-file-symlink",
        submenuItems: [
          {
            label: "Paramètres des Emails",
            link: routes.emailSettings,
            showSubRoute: false,
          },
          {
            label: "Modèles d'Emails",
            link: routes.emailTemplates,
            showSubRoute: false,
          },
          {
            label: "Paramètres des SMS",
            link: routes.smsSettings,
            showSubRoute: false,
          },
          {
            label: "OTP",
            link: routes.optSettings,
            showSubRoute: false,
          },
          {
            label: "Cookies GDPR",
            link: routes.gdprCookies,
            showSubRoute: false,
          },
        ],
      },
      {
        label: "Paramètres Financiers",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-zoom-money",
        submenuItems: [
          {
            label: "Passerelle de Paiement",
            link: routes.paymentGateways,
            showSubRoute: false,
          },
          { label: "Taux de Taxe", link: routes.taxRates, showSubRoute: false },
        ],
      },
      {
        label: "Paramètres Académiques",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-calendar-repeat",
        submenuItems: [
          {
            label: "Paramètres de l'École",
            link: routes.schoolSettings,
            showSubRoute: false,
          },
          { label: "Religion", link: routes.religion, showSubRoute: false },
        ],
      },
      {
        label: "Autres Paramètres",
        submenu: true,
        showSubRoute: false,
        icon: "ti ti-flag-cog",
        submenuItems: [
          { label: "Stockage", link: routes.storage, showSubRoute: false },
          {
            label: "Bannir Adresse IP",
            link: routes.banIpAddress,
            showSubRoute: false,
          },
        ],
      },
    ],
  },

  // {
  //   label: "UI Interface",
  //   submenuOpen: true,
  //   showSubRoute: false,
  //   submenuHdr: "UI Interface",
  //   submenuItems: [
  //     {
  //       label: "Base UI",
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: "ti ti-hierarchy-2",
  //       submenuItems: [
  //         { label: "Alerts", link: routes.alert, showSubRoute: false },
  //         { label: "Accordion", link: routes.accordion, showSubRoute: false },
  //         { label: "Avatar", link: routes.avatar, showSubRoute: false },
  //         { label: "Badges", link: routes.uiBadges, showSubRoute: false },
  //         { label: "Border", link: routes.border, showSubRoute: false },
  //         { label: "Buttons", link: routes.button, showSubRoute: false },
  //         {
  //           label: "Button Group",
  //           link: routes.buttonGroup,
  //           showSubRoute: false,
  //         },
  //         { label: "Breadcrumb", link: routes.breadcrums, showSubRoute: false },
  //         { label: "Card", link: routes.cards, showSubRoute: false },
  //         { label: "Carousel", link: routes.carousel, showSubRoute: false },
  //         { label: "Colors", link: routes.colors, showSubRoute: false },
  //         { label: "Dropdowns", link: routes.dropdowns, showSubRoute: false },
  //         { label: "Grid", link: routes.grid, showSubRoute: false },
  //         { label: "Images", link: routes.images, showSubRoute: false },
  //         { label: "Lightbox", link: routes.lightbox, showSubRoute: false },
  //         { label: "Media", link: routes.media, showSubRoute: false },
  //         { label: "Modals", link: routes.modals, showSubRoute: false },
  //         { label: "Offcanvas", link: routes.offcanvas, showSubRoute: false },
  //         { label: "Pagination", link: routes.pagination, showSubRoute: false },
  //         { label: "Popovers", link: routes.popover, showSubRoute: false },
  //         { label: "Progress", link: routes.progress, showSubRoute: false },
  //         {
  //           label: "Placeholders",
  //           link: routes.placeholder,
  //           showSubRoute: false,
  //         },
  //         {
  //           label: "Range Slider",
  //           link: routes.rangeSlider,
  //           showSubRoute: false,
  //         },
  //         { label: "Spinner", link: routes.spinner, showSubRoute: false },
  //         {
  //           label: "Sweet Alerts",
  //           link: routes.sweetalert,
  //           showSubRoute: false,
  //         },
  //         { label: "Tabs", link: routes.navTabs, showSubRoute: false },
  //         { label: "Toasts", link: routes.toasts, showSubRoute: false },
  //         { label: "Tooltips", link: routes.tooltip, showSubRoute: false },
  //         { label: "Typography", link: routes.typography, showSubRoute: false },
  //         { label: "Video", link: routes.video, showSubRoute: false },
  //       ],
  //     },
  //     {
  //       label: "Advanced UI",
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: "ti ti-hierarchy-3",
  //       submenuItems: [
  //         { label: "Ribbon", link: routes.ribbon, showSubRoute: false },
  //         { label: "Clipboard", link: routes.clipboard, showSubRoute: false },
  //         {
  //           label: "Drag & Drop",
  //           link: routes.dragandDrop,
  //           showSubRoute: false,
  //         },
  //         {
  //           label: "Range Slider",
  //           link: routes.rangeSlider,
  //           showSubRoute: false,
  //         },
  //         { label: "Rating", link: routes.rating, showSubRoute: false },
  //         {
  //           label: "Text Editor",
  //           link: routes.textEditor,
  //           showSubRoute: false,
  //         },
  //         { label: "Counter", link: routes.counter, showSubRoute: false },
  //         { label: "Scrollbar", link: routes.scrollBar, showSubRoute: false },
  //         {
  //           label: "Sticky Note",
  //           link: routes.stickyNotes,
  //           showSubRoute: false,
  //         },
  //         { label: "Timeline", link: routes.timeLine, showSubRoute: false },
  //       ],
  //     },
  //     {
  //       label: "Charts",
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: "ti ti-chart-line",
  //       submenuItems: [
  //         { label: "Apex Charts", link: routes.apexChat, showSubRoute: false },
  //         // { label: "Chart Js", link: routes.chart, showSubRoute: false },
  //       ],
  //     },
  //     {
  //       label: "Icons",
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: "ti ti-icons",
  //       submenuItems: [
  //         {
  //           label: "Fontawesome Icons",
  //           link: routes.fantawesome,
  //           showSubRoute: false,
  //         },
  //         {
  //           label: "Feather Icons",
  //           link: routes.featherIcons,
  //           showSubRoute: false,
  //         },
  //         {
  //           label: "Ionic Icons",
  //           link: routes.iconicIcon,
  //           showSubRoute: false,
  //         },
  //         {
  //           label: "Material Icons",
  //           link: routes.materialIcon,
  //           showSubRoute: false,
  //         },
  //         { label: "Pe7 Icons", link: routes.pe7icon, showSubRoute: false },
  //         {
  //           label: "Simpleline Icons",
  //           link: routes.simpleLineIcon,
  //           showSubRoute: false,
  //         },
  //         {
  //           label: "Themify Icons",
  //           link: routes.themifyIcon,
  //           showSubRoute: false,
  //         },
  //         {
  //           label: "Weather Icons",
  //           link: routes.weatherIcon,
  //           showSubRoute: false,
  //         },
  //         {
  //           label: "Typicon Icons",
  //           link: routes.typicon,
  //           showSubRoute: false,
  //         },
  //         { label: "Flag Icons", link: routes.falgIcons, showSubRoute: false },
  //       ],
  //     },
  //     {
  //       label: "Forms",
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: "ti ti-input-search",
  //       submenuItems: [
  //         {
  //           label: "Form Elements",
  //           submenu: true,
  //           showSubRoute: false,
  //           submenuItems: [
  //             {
  //               label: "Basic Inputs",
  //               link: routes.basicInput,
  //               showSubRoute: false,
  //             },
  //             {
  //               label: "Checkbox & Radios",
  //               link: routes.checkboxandRadion,
  //               showSubRoute: false,
  //             },
  //             {
  //               label: "Input Groups",
  //               link: routes.inputGroup,
  //               showSubRoute: false,
  //             },
  //             {
  //               label: "Grid & Gutters",
  //               link: routes.gridandGutters,
  //               showSubRoute: false,
  //             },
  //             {
  //               label: "Form Select",
  //               link: routes.formSelect,
  //               showSubRoute: false,
  //             },
  //             {
  //               label: "Input Masks",
  //               link: routes.formMask,
  //               showSubRoute: false,
  //             },
  //             {
  //               label: "File Uploads",
  //               link: routes.fileUpload,
  //               showSubRoute: false,
  //             },
  //           ],
  //         },
  //         {
  //           label: "Layouts",
  //           submenu: true,
  //           showSubRoute: false,
  //           submenuItems: [
  //             { label: "Horizontal Form", link: routes.horizontalForm },
  //             { label: "Vertical Form", link: routes.verticalForm },
  //             { label: "Floating Labels", link: routes.floatingLable },
  //           ],
  //         },
  //         { label: "Form Validation", link: routes.formValidation },
  //         { label: "Select", link: routes.reactSelect },
  //         // { label: "Form Wizard", link: routes.formWizard },
  //       ],
  //     },
  //     {
  //       label: "Tables",
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: "ti ti-table-plus",
  //       submenuItems: [
  //         { label: "Basic Tables", link: "/tables-basic" },
  //         { label: "Data Table", link: "/data-tables" },
  //       ],
  //     },
  //   ],
  // },
  
  // {
  //   label: "Aide",
  //   submenuOpen: true,
  //   showSubRoute: false,
  //   submenuHdr: "Aide",
  //   submenuItems: [
  //     {
  //       label: "Documentation",
  //       link: "https://preschool.dreamstechnologies.com/documentation/index.html",
  //       icon: "ti ti-file-text",
  //       showSubRoute: false,
  //     },
  //     {
  //       label: "Historique des Modifications",
  //       version: "v1.8.3",
  //       link: "https://preschool.dreamstechnologies.com/documentation/changelog.html",
  //       icon: "ti ti-exchange",
  //       showSubRoute: false,
  //     },
  //     {
  //       label: "Multi-Niveaux",
  //       showSubRoute: false,
  //       submenu: true,
  //       icon: "ti ti-menu-2",
  //       submenuItems: [
  //         { label: "Niveau 1.1", link: "#", showSubRoute: false },
  //         {
  //           label: "Niveau 1.2",
  //           submenu: true,
  //           showSubRoute: false,
  //           submenuItems: [
  //             { label: "Niveau 2.1", link: "#", showSubRoute: false },
  //             {
  //               label: "Niveau 2.2",
  //               submenu: true,
  //               showSubRoute: false,
  //               submenuItems: [
  //                 { label: "Niveau 3.1", link: "#", showSubRoute: false },
  //                 { label: "Niveau 3.2", link: "#", showSubRoute: false },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
];
