import React, { useState } from "react";
import { Link } from "react-router-dom";
import CommonSelect from "../../../core/common/commonSelect";
import { feeGroup, feesTypes } from "../../../core/common/selectoption/selectoption";
import { DatePicker } from 'antd'
import dayjs from "dayjs";

const FeesModal = () => {
    const [activeContent, setActiveContent] = useState('');
    const handleContentChange = (event:any) => {
        setActiveContent(event.target.value);
      };
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0') // Month is zero-based, so we add 1
    const day = String(today.getDate()).padStart(2, '0')
    const formattedDate = `${month}-${day}-${year}`
    const defaultValue = dayjs(formattedDate);
    const getModalContainer = () => {
     const modalElement = document.getElementById('modal-datepicker');
     return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
   };
    const getModalContainer2 = () => {
     const modalElement = document.getElementById('modal-datepicker2');
     return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
   };
  return (
    <>
<>
  {/* Ajouter Master des Frais */}
  <div className="modal fade" id="add_fees_master">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <div className="d-flex align-items-center">
            <h4 className="modal-title">Ajouter Master des Frais</h4>
            <span className="badge bg-soft-info ms-2">2024 - 2025</span>
          </div>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body" id="modal-datepicker2">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Groupe de Frais</label>
                  <CommonSelect
                    className="select"
                    options={feeGroup}
                    defaultValue={undefined}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Type de Frais</label>
                  <CommonSelect
                    className="select"
                    options={feesTypes}
                    defaultValue={undefined}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Date d'Échéance</label>
                      <div className="date-pic">
                        <DatePicker
                          className="form-control datetimepicker"
                          format={{
                            format: "DD-MM-YYYY",
                            type: "mask",
                          }}
                          getPopupContainer={getModalContainer2}
                          defaultValue=""
                          placeholder="Sélectionner une date"
                        />
                        <span className="cal-icon">
                          <i className="ti ti-calendar" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Montant</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Type de Pénalité</label>
                  <div className="d-flex align-items-center check-radio-group">
                    <label className="custom-radio">
                      <input type="radio" name="radio" value="" checked={activeContent === ''}
                            onChange={handleContentChange} />
                      <span className="checkmark" />
                      Aucun
                    </label>
                    <label className="custom-radio percentage-radio">
                      <input type="radio" name="radio" value="percentage" onChange={handleContentChange} />
                      <span className="checkmark" />
                      Pourcentage
                    </label>
                    <label className="custom-radio fixed-radio">
                      <input type="radio" name="radio" value="fixed" onChange={handleContentChange} />
                      <span className="checkmark" />
                      Fixe
                    </label>
                  </div>
                </div>
                <div className={`percentage-field ${activeContent === 'percentage' ? 'percentage-field-show' : ''} `}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Pourcentage</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="%"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Montant ($)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="$"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`fixed-field ${activeContent === 'fixed' ? 'fixed-field-show' : ''} `}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">Montant ($)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="$"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>État</h5>
                  <p>Changer l'état en utilisant le bouton de bascule</p>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="switch-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
              Annuler
            </Link>
            <Link to="#" data-bs-dismiss="modal" className="btn btn-primary">
              Ajouter Master des Frais
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Ajouter Master des Frais*/}
  {/* Modifier Master des Frais */}
  <div className="modal fade" id="edit_fees_master">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <div className="d-flex align-items-center">
            <h4 className="modal-title">Modifier Master des Frais</h4>
            <span className="badge bg-soft-info ms-2">2024 - 2025</span>
          </div>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body" id="modal-datepicker">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Groupe de Frais</label>
                  <CommonSelect
                    className="select"
                    options={feeGroup}
                    defaultValue={feeGroup[1]}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Type de Frais</label>
                  <CommonSelect
                    className="select"
                    options={feesTypes}
                    defaultValue={feesTypes[1]}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Date d'Échéance</label>
                      <div className="date-pic">
                        <DatePicker
                          className="form-control datetimepicker"
                          format={{
                            format: "DD-MM-YYYY",
                            type: "mask",
                          }}
                          getPopupContainer={getModalContainer}
                          defaultValue={defaultValue}
                          placeholder="16 Mai 2024"
                        />
                        <span className="cal-icon">
                          <i className="ti ti-calendar" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Montant</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrer le Montant"
                        defaultValue="$500"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Type de Pénalité</label>
                  <div className="d-flex align-items-center check-radio-group">
                    <label className="custom-radio">
                      <input type="radio" name="radio" value="" checked={activeContent === ''}
                            onChange={handleContentChange} />
                      <span className="checkmark" />
                      Aucun
                    </label>
                    <label className="custom-radio percentage-radio">
                      <input type="radio" name="radio" value="percentage" onChange={handleContentChange} />
                      <span className="checkmark" />
                      Pourcentage
                    </label>
                    <label className="custom-radio fixed-radio">
                      <input type="radio" name="radio" value="fixed" onChange={handleContentChange} />
                      <span className="checkmark" />
                      Fixe
                    </label>
                  </div>
                </div>
                <div className={`percentage-field ${activeContent === 'percentage' ? 'percentage-field-show' : ''} `}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Pourcentage</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="%"
                          defaultValue="10%"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Montant ($)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="$"
                          defaultValue="$50"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`fixed-field ${activeContent === 'fixed' ? 'fixed-field-show' : ''} `}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">Montant ($)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="$"
                          defaultValue="$50"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>État</h5>
                  <p>Changer l'état en utilisant le bouton de bascule</p>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="switch-sm"
                    defaultChecked
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
              Annuler
            </Link>
            <Link to="#" data-bs-dismiss="modal" className="btn btn-primary">
              Enregistrer les Modifications
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Modifier Master des Frais*/}
  {/* Supprimer Master des Frais */}
  <div className="modal fade" id="delete_fees_master">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Supprimer Master des Frais</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <div className="modal-body">
          <p>Êtes-vous sûr de vouloir supprimer le master des frais ?</p>
          <p className="text-danger">
            Cette action est irréversible et supprimera toutes les informations
            associées.
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light me-2"
            data-bs-dismiss="modal"
          >
            Annuler
          </button>
          <button
            type="button"
            className="btn btn-danger"
            data-bs-dismiss="modal"
          >
            Supprimer
          </button>
        </div>
      </div>
    </div>
  </div>
  {/* Supprimer Master des Frais */}
</>


<>
  <div className="modal fade" id="add_fees_Type">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Ajouter un Type de Frais</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Nom</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">Groupe de Frais</label>
                    <Link
                      to="#"
                      className="text-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#add_new_fees_group"
                    >
                      <span>
                        <i className="ti ti-square-rounded-plus-filled" />
                      </span>{" "}
                      Ajouter Nouveau
                    </Link>
                  </div>
                  <CommonSelect
                    className="select"
                    options={feeGroup}
                    defaultValue={undefined}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                  className="form-control"
                  rows={4}
                  defaultValue={""}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>État</h5>
                  <p>Changer l'état en utilisant le bouton de bascule</p>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="switch-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Ajouter le Type de Frais
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>

  {/* Modifier Type de Frais */}
  <div className="modal fade" id="edit_fees_Type">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Modifier le Type de Frais</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Nom</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez le Nom"
                    defaultValue=""
                  />
                </div>
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">Groupe de Frais</label>
                    <Link
                      to="#"
                      className="text-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#add_new_fees_group"
                    >
                      <span>
                        <i className="ti ti-square-rounded-plus-filled" />
                      </span>{" "}
                      Ajouter Nouveau
                    </Link>
                  </div>
                  <CommonSelect
                    className="select"
                    options={feeGroup}
                    defaultValue={feeGroup[1]}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                  className="form-control"
                  rows={4}
                  placeholder="Ajouter un Commentaire"
                  defaultValue={""}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>État</h5>
                  <p>Changer l'état en utilisant le bouton de bascule</p>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="switch-sm2"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Enregistrer les Modifications
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Modifier Type de Frais */}

  {/* Ajouter Nouveau Groupe de Frais */}
  <div className="modal fade" id="add_new_fees_group">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Ajouter un Nouveau Groupe de Frais</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Nom</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez le Nom"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                  className="form-control"
                  rows={4}
                  placeholder="Ajouter un Commentaire"
                  defaultValue={""}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>État</h5>
                  <p>Changer l'état en utilisant le bouton de bascule</p>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="switch-sm3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Ajouter le Groupe de Frais
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Ajouter Nouveau Groupe de Frais */}
</>


<>
  {/* Ajouter un Groupe de Frais */}
  <div className="modal fade" id="add_fees_group">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Ajouter un Groupe de Frais</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Groupe de Frais</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control"
                    rows={4}
                    defaultValue={""}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="status-title">
                    <h5>Statut</h5>
                    <p>Changez le statut en basculant</p>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="switch-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Ajouter le Groupe de Frais
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Ajouter un Groupe de Frais */}

  {/* Modifier un Groupe de Frais */}
  <div className="modal fade" id="edit_fees_group">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Modifier un Groupe de Frais</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Groupe de Frais</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez le Groupe de Frais"
                    defaultValue="Frais de Scolarité"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control"
                    rows={4}
                    placeholder="Ajouter un Commentaire"
                    defaultValue={"L'argent que vous payez pour être enseigné"}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="status-title">
                    <h5>Statut</h5>
                    <p>Changez le statut en basculant</p>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="switch-sm2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Enregistrer les Modifications
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Modifier un Groupe de Frais */}
</>


      {/* Delete Modal */}
      <div className="modal fade" id="delete-modal">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <form>
        <div className="modal-body text-center">
          <span className="delete-icon">
            <i className="ti ti-trash-x" />
          </span>
          <h4>Confirmer la Suppression</h4>
          <p>
            Vous souhaitez supprimer tous les éléments marqués, cette action est irréversible une fois que vous avez supprimé.
          </p>
          <div className="d-flex justify-content-center">
            <Link
              to="#"
              className="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Oui, Supprimer
            </Link>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

      {/* /Delete Modal */}
    </>
  );
};

export default FeesModal;
