import { useRef } from "react";
import Table from "../../../core/common/dataTable/index";
import { scheduleClass } from "../../../core/data/json/schedule_class";
import PredefinedDateRanges from "../../../core/common/datePicker";
import {
  activeList,
  classselect,
} from "../../../core/common/selectoption/selectoption";
import CommonSelect from "../../../core/common/commonSelect";
import { TableData } from "../../../core/data/interface";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import TooltipOption from "../../../core/common/tooltipOption";

const ScheduleClasses = () => {
  const isMinistryEtablissement = localStorage.getItem("ministry-etablissement") 
  ? JSON.parse(localStorage.getItem("ministry-etablissement") as string) 
  : null;
  const data = scheduleClass;
  const route = all_routes
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },
  
    {
      title: "Type",
      dataIndex: "type",
      sorter: (a: TableData, b: TableData) => a.type.length - b.type.length,
    },
    {
      title: "Heure de début",
      dataIndex: "startTime",
      sorter: (a: TableData, b: TableData) => a.startTime.length - b.startTime.length,
    },
    {
      title: "Heure de fin",
      dataIndex: "endTime",
      sorter: (a: TableData, b: TableData) => a.endTime.length - b.endTime.length,
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: (text: string) => (
        <>
          {text === "Active" ? (
            <span
              className="badge badge-soft-success d-inline-flex align-items-center"
            >
              <i className='ti ti-circle-filled fs-5 me-1'></i>{text}
            </span>
          ) :
          (
            <span
              className="badge badge-soft-danger d-inline-flex align-items-center"
            >
              <i className='ti ti-circle-filled fs-5 me-1'></i>{text}
            </span>
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_Schedule"
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Modifier
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                  >
                    <i className="ti ti-trash-x me-2" />
                    Supprimer
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  
  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Emploi du temps</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={route.adminDashboard}>Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Classes</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Emploi du temps
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              {
                !isMinistryEtablissement &&
                <div className="mb-2">
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_Schedule"
                  >
                    <i className="ti ti-square-rounded-plus-filled me-2" />
                    Ajouter un emploi du temps
                  </Link>
                </div>
              }
            </div>
          </div>
          {/* /Page Header */}
          {/* Liste des classes */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Emploi du temps des classes</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filtrer
                  </Link>
                  <div className="dropdown-menu drop-width" ref={dropdownMenuRef}>
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filtrer</h4>
                      </div>
                      <div className="p-3 border-bottom pb-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Type</label>
                              <CommonSelect
                                className="select"
                                options={classselect}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Statut</label>
                              <CommonSelect
                                className="select"
                                options={activeList}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Réinitialiser
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Appliquer
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Trier par A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-1 active"
                      >
                        Croissant
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-1"
                      >
                        Décroissant
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-1"
                      >
                        Vu récemment
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-1"
                      >
                        Ajouté récemment
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Liste des classes */}
              <Table columns={columns} dataSource={data} Selection={true} />
              {/* /Liste des classes */}
            </div>
          </div>
          {/* /Liste des classes */}
        </div>
      </div>
      {/* /Page Wrapper */}
      <div>
        {/* Ajouter un emploi du temps */}
        <div className="modal fade" id="add_Schedule">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Ajouter un emploi du temps</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label className="form-label">Type</label>
                    <CommonSelect className="select" options={classselect} />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Heure de début</label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="Heure de début"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Heure de fin</label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="Heure de fin"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Statut</label>
                    <CommonSelect className="select" options={activeList} />
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Annuler
                    </Link>
                    <Link to="#" className="btn btn-primary">
                      Ajouter
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Ajouter un emploi du temps */}
  
        {/* Modifier un emploi du temps */}
        <div className="modal fade" id="edit_Schedule">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Modifier un emploi du temps</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label className="form-label">Type</label>
                    <CommonSelect className="select" options={classselect} />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Heure de début</label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="Heure de début"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Heure de fin</label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="Heure de fin"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Statut</label>
                    <CommonSelect className="select" options={activeList} />
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Annuler
                    </Link>
                    <Link to="#" className="btn btn-primary">
                      Modifier
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Modifier un emploi du temps */}
  
        {/* Supprimer un emploi du temps */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Supprimer un emploi du temps</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>Êtes-vous sûr de vouloir supprimer cet emploi du temps ?</p>
              </div>
              <div className="modal-footer">
                <Link to="#" className="btn btn-light" data-bs-dismiss="modal">
                  Annuler
                </Link>
                <Link to="#" className="btn btn-danger">
                  Supprimer
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Supprimer un emploi du temps */}
      </div>
    </div>
  );  
};

export default ScheduleClasses;
