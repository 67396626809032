import { DatePicker } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import PredefinedDateRanges from "../../core/common/datePicker";
import CommonSelect from "../../core/common/commonSelect";
import {
  messageTo,
  transactionDate,
} from "../../core/common/selectoption/selectoption";
import { all_routes } from "../router/all_routes";
import TooltipOption from "../../core/common/tooltipOption";

const NoticeBoard = () => {
  const routes = all_routes;
  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
  <div className="content content-two">
    {/* Page Header */}
    <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
      <div className="my-auto mb-2">
        <h3 className="page-title mb-1">Tableau d'Annonces</h3>
        <nav>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to={routes.adminDashboard}>Tableau de Bord</Link>
            </li>
            <li className="breadcrumb-item">Annonce</li>
            <li className="breadcrumb-item active" aria-current="page">
              Tableau d'Annonces
            </li>
          </ol>
        </nav>
      </div>
      <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
        <TooltipOption />
        <div className="mb-2">
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#add_message"
            className="btn btn-primary d-flex align-items-center"
          >
            <i className="ti ti-square-rounded-plus me-2" />
            Ajouter un Message
          </Link>
        </div>
      </div>
    </div>
    {/* /Page Header */}
    <div className="d-flex align-items-center justify-content-end flex-wrap mb-2">
      <div className="form-check me-2 mb-3">
        <input className="form-check-input" type="checkbox" />
        <span className="checkmarks">Sélectionner &amp; Supprimer Tout</span>
      </div>
      <div className="d-flex align-items-center flex-wrap">
        <div className="input-icon-start mb-3 me-2 position-relative">
          <PredefinedDateRanges />
        </div>
        <div className="dropdown mb-3">
          <Link
            to="#"
            className="btn btn-outline-light bg-white dropdown-toggle"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
          >
            <i className="ti ti-filter me-2" />
            Filtrer
          </Link>
          <div className="dropdown-menu drop-width">
            <form>
              <div className="d-flex align-items-center border-bottom p-3">
                <h4>Filtrer</h4>
              </div>
              <div className="p-3 border-bottom pb-0">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Message à</label>
                      <CommonSelect
                        className="select"
                        options={messageTo}
                        defaultValue={messageTo[0]}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Date d'Ajout</label>
                      <CommonSelect
                        className="select"
                        options={transactionDate}
                        defaultValue={transactionDate[0]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 d-flex align-items-center justify-content-end">
                <Link to="#" className="btn btn-light me-3">
                  Réinitialiser
                </Link>
                <button type="submit" className="btn btn-primary">
                  Appliquer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    {/* Notice Board List */}
    <div className="card board-hover mb-3">
      <div className="card-body d-md-flex align-items-center justify-content-between pb-1">
        <div className="d-flex align-items-center mb-3">
          <div className="form-check form-check-md me-2">
            <input className="form-check-input" type="checkbox" />
          </div>
          <span className="bg-soft-primary text-primary avatar avatar-md me-2 br-5 flex-shrink-0">
            <i className="ti ti-notification fs-16" />
          </span>
          <div>
            <h6 className="mb-1 fw-semibold">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#view_details"
              >
                Préparation des Cours
              </Link>
            </h6>
            <p>
              <i className="ti ti-calendar me-1" />
              Ajouté le : 24 Mai 2024
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center board-action mb-3">
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#edit_message"
            className="text-primary border rounded p-1 badge me-1 primary-btn-hover"
          >
            <i className="ti ti-edit-circle fs-16" />
          </Link>
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#delete-modal"
            className="text-danger border rounded p-1 badge danger-btn-hover"
          >
            <i className="ti ti-trash-x fs-16" />
          </Link>
        </div>
      </div>
    </div>
    {/* Notice Board List */}
    {/* Notice Board List */}
    <div className="card board-hover mb-3">
      <div className="card-body d-md-flex align-items-center justify-content-between pb-1">
        <div className="d-flex align-items-center mb-3">
          <div className="form-check form-check-md me-2">
            <input className="form-check-input" type="checkbox" />
          </div>
          <span className="bg-soft-primary text-primary avatar avatar-md me-2 br-5 flex-shrink-0">
            <i className="ti ti-notification fs-16" />
          </span>
          <div>
            <h6 className="mb-1 fw-semibold">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#view_details"
              >
                Célébration de la Journée de la République
              </Link>
            </h6>
            <p>
              <i className="ti ti-calendar me-1" />
              Ajouté le : 24 Jan 2024
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center board-action mb-3">
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#edit_message"
            className="text-primary border rounded p-1 badge me-1 primary-btn-hover"
          >
            <i className="ti ti-edit-circle fs-16" />
          </Link>
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#delete-modal"
            className="text-danger border rounded p-1 badge danger-btn-hover"
          >
            <i className="ti ti-trash-x fs-16" />
          </Link>
        </div>
      </div>
    </div>
    {/* Notice Board List */}
    <div className="text-center">
      <Link to="#" className="btn btn-primary">
        <i className="ti ti-loader-3 me-2" />
        Charger Plus
      </Link>
    </div>
  </div>
</div>

{/* /Page Wrapper */}
{/* Ajouter un Message */}
<div className="modal fade" id="add_message">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Nouveau Message</h4>
        <button
          type="button"
          className="btn-close custom-btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i className="ti ti-x" />
        </button>
      </div>
      <form>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Titre</label>
                <input type="text" className="form-control" />
              </div>
              <div className="mb-3">
                <label className="form-label">Date de l'avis</label>
                <div className="date-pic">
                  <DatePicker
                    className="form-control datetimepicker"
                    placeholder="Sélectionner la date"
                  />
                  <span className="cal-icon">
                    <i className="ti ti-calendar" />
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Publication le</label>
                <div className="date-pic">
                  <DatePicker
                    className="form-control datetimepicker"
                    placeholder="Sélectionner la date"
                  />
                  <span className="cal-icon">
                    <i className="ti ti-calendar" />
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <div className="bg-light p-3 pb-2 rounded">
                  <div className="mb-3">
                    <label className="form-label">Pièce jointe</label>
                    <p>Taille de téléchargement de 4 Mo, Format accepté PDF</p>
                  </div>
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                      <i className="ti ti-file-upload me-1" />
                      Télécharger
                      <input
                        type="file"
                        className="form-control image_sign"
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Message</label>
                <textarea
                  className="form-control"
                  rows={4}
                  defaultValue={""}
                />
              </div>
              <div className="mb-0">
                <label className="form-label">Message à</label>
                <div className="row">
                  <div className="col-md-6">
                    <label className="checkboxs mb-1">
                      <input type="checkbox" />
                      <span className="checkmarks" />
                      Étudiant
                    </label>
                    <label className="checkboxs mb-1">
                      <input type="checkbox" />
                      <span className="checkmarks" />
                      Parent
                    </label>
                    <label className="checkboxs mb-1">
                      <input type="checkbox" />
                      <span className="checkmarks" />
                      Administrateur
                    </label>
                    <label className="checkboxs mb-1">
                      <input type="checkbox" />
                      <span className="checkmarks" />
                      Enseignant
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label className="checkboxs mb-1">
                      <input type="checkbox" />
                      <span className="checkmarks" />
                      Comptable
                    </label>
                    <label className="checkboxs mb-1">
                      <input type="checkbox" />
                      <span className="checkmarks" />
                      Bibliothécaire
                    </label>
                    <label className="checkboxs mb-1">
                      <input type="checkbox" />
                      <span className="checkmarks" />
                      Réceptionniste
                    </label>
                    <label className="checkboxs mb-1">
                      <input type="checkbox" />
                      <span className="checkmarks" />
                      Super Administrateur
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Link
            to="#"
            className="btn btn-light me-2"
            data-bs-dismiss="modal"
          >
            Annuler
          </Link>
          <button type="submit" className="btn btn-primary">
            Ajouter Nouveau Message
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
{/* /Ajouter un Message */}
{/* Modifier Message */}
<div className="modal fade" id="edit_message">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Modifier le Message</h4>
        <button
          type="button"
          className="btn-close custom-btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i className="ti ti-x" />
        </button>
      </div>
      <form>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Titre</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez le titre"
                  defaultValue="Rappel de Frais"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Date de l'avis</label>
                <div className="date-pic">
                  <DatePicker
                    className="form-control datetimepicker"
                    placeholder="Sélectionner la date"
                  />
                  <span className="cal-icon">
                    <i className="ti ti-calendar" />
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Publication le</label>
                <div className="date-pic">
                  <DatePicker
                    className="form-control datetimepicker"
                    placeholder="Sélectionner la date"
                  />
                  <span className="cal-icon">
                    <i className="ti ti-calendar" />
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <div className="bg-light p-3 pb-2 rounded">
                  <div className="mb-3">
                    <label className="form-label">Pièce jointe</label>
                    <p>Taille de téléchargement de 4 Mo, Format accepté PDF</p>
                  </div>
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                      <i className="ti ti-file-upload me-1" />
                      Télécharger
                      <input
                        type="file"
                        className="form-control image_sign"
                        multiple
                      />
                    </div>
                    <p className="mb-2">Frais_Structure.pdf</p>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Message</label>
                <textarea
                  className="form-control"
                  rows={4}
                  placeholder="Ajouter un commentaire"
                  defaultValue={
                    "Veuillez régler les frais scolaires en attente de manière urgente."
                  }
                />
              </div>
              <div className="mb-0">
                <label className="form-label">Message à</label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-check form-check-md mb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                      />
                      <span>Étudiant</span>
                    </div>
                    <div className="form-check form-check-md mb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                      />
                      <span>Parent</span>
                    </div>
                    <div className="form-check form-check-md mb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                      />
                      <span>Administrateur</span>
                    </div>
                    <div className="form-check form-check-md">
                      <input
                        className="form-check-input"
                        type="checkbox"
                      />
                      <span>Enseignant</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check form-check-md mb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                      />
                      <span>Comptable</span>
                    </div>
                    <div className="form-check form-check-md mb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                      />
                      <span>Bibliothécaire</span>
                    </div>
                    <div className="form-check form-check-md mb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                      />
                      <span>Réceptionniste</span>
                    </div>
                    <div className="form-check form-check-md">
                      <input
                        className="form-check-input"
                        type="checkbox"
                      />
                      <span>Super Administrateur</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Link
            to="#"
            className="btn btn-light me-2"
            data-bs-dismiss="modal"
          >
            Annuler
          </Link>
          <button type="submit" className="btn btn-primary">
            Modifier le Message
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
{/* /Modifier Message */}
{/* Supprimer Message */}
<div
  className="modal fade"
  id="delete_message"
  tabIndex={-1}
  aria-labelledby="delete_message"
  aria-hidden="true"
>
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="delete_message">
          Supprimer Message
        </h5>
        <button
          type="button"
          className="btn-close custom-btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i className="ti ti-x" />
        </button>
      </div>
      <div className="modal-body">
        <p>
          Vous êtes sur le point de supprimer ce message. Voulez-vous continuer ?
        </p>
      </div>
      <div className="modal-footer">
        <Link
          to="#"
          className="btn btn-light me-2"
          data-bs-dismiss="modal"
        >
          Annuler
        </Link>
        <button type="button" className="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  </div>
</div>
{/* /Supprimer Message */}

      {/* /Main Wrapper */}
      {/* Delete Modal */}
      <div className="modal fade" id="delete-modal">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <form>
        <div className="modal-body text-center">
          <span className="delete-icon">
            <i className="ti ti-trash-x" />
          </span>
          <h4>Confirmer la Suppression</h4>
          <p>
            Vous voulez supprimer tous les éléments marqués, cette action est
            irréversible une fois que vous avez supprimé.
          </p>
          <div className="d-flex justify-content-center">
            <Link
              to="#"
              className="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <button type="submit" className="btn btn-danger">
              Oui, Supprimer
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

      {/* /Delete Modal */}
    </>
  );
};

export default NoticeBoard;
