

export const all_routes = {
  mainDashboard: `/main-index`,
  mainDashboardMinistries: `/main-ministries-index`,
  adminDashboard: `/index`,

  gestionEtablissementsScolaires: `/gestion-etablissements-scolaires`,
  gestionCoursClasses: `/gestion-cours-classes`,
  gestionInscriptions: `/gestion-inscriptions`,
  parentDashboard: `/parent-dashboard`,
  studentDashboard: `/student-dashboard`,
  teacherDashboard: `/teacher-dashboard`,

  blankPage: `/blank-page`,
  calendar: `/calendar`,
  dataTables: `/data-tables`,
  tablesBasic: `/tables-basic`,
  notes: `/notes`,
  comingSoon: `/coming-soon`,

  // auth routes routes
  login: `/login`,
  login2: `/login-2`,
  login3: `/login-3`,
  register: `/register`,
  register2: `/register-2`,
  register3: `/register-3`,
  forgotPassword: `/forgot-password`,
  forgotPassword2: `/forgot-password-2`,
  forgotPassword3: `/forgot-password-3`,
  twoStepVerification: `/two-step-verification`,
  twoStepVerification2: `/two-step-verification-2`,
  twoStepVerification3: `/two-step-verification-3`,
  success: `/success`,
  emailVerification: `/email-verification`,
  emailVerification2: `/email-verification-2`,
  emailVerification3: `/email-verification-3`,
  lockScreen: `/lock-screen`,
  resetPassword: `/reset-password`,
  resetPassword2: `/reset-password-2`,
  resetPassword3: `/reset-password-3`,
  resetPasswordSuccess: `/reset-password-success`,
  resetPasswordSuccess2: `/reset-password-success-2`,
  resetPasswordSuccess3: `/reset-password-success-3`,



  //ui routes
  alert: `/ui-alert`,
  accordion: `/ui-accordion`,
  avatar: `/ui-avatar`,
  badges: `/ui-badges`,
  border: `/ui-border`,
  breadcrums: `/ui-breadcrums`,
  button: `/ui-buttons`,
  buttonGroup: `/ui-button-group`,
  cards: `/ui-cards`,
  carousel: `/ui-carousel`,
  colors: `/ui-colors`,
  dropdowns: `/ui-dropdowns`,
  grid: `/ui-grid`,
  images: `/ui-images`,
  lightbox: `/ui-lightbox`,
  media: `/ui-media`,
  modals: `/ui-modals`,
  navTabs: `/ui-navtabs`,
  offcanvas: `/ui-offcanvas`,
  pagination: `/ui-pagination`,
  placeholder: `/ui-placeholder`,
  popover: `/ui-popover`,
  progress: `/ui-progress`,
  rangeSlider: `/ui-rangeslider`,
  spinner: `/ui-spinner`,
  sweetalert: `/sweetalert`,
  toasts: `/ui-toasts`,
  tooltip: `/ui-tooltip`,
  typography: `/ui-typography`,
  video: `/ui-video`,
  clipboard: `/ui-clipboard`,
  counter: `/ui-counter`,
  dragandDrop: `/ui-drag-drop`,
  rating: `/ui-rating`,
  ribbon: `/ui-ribbon`,
  stickyNotes: `/ui-sticky-notes`,
  textEditor: `/ui-text-editor`,
  timeLine: `/ui-timeline`,
  scrollBar: `/ui-scrollbar`,
  apexChat: `/ui-apexchat`,
  chart: `/ui-chartjs`,
  featherIcons: `/ui-feather-icon`,
  falgIcons: `/ui-flag-icon`,
  fantawesome: `/ui-fantawesome`,
  materialIcon: `/ui-material-icon`,
  pe7icon: `/ui-icon-pe7`,
  simpleLineIcon: `/ui-simpleline`,
  themifyIcon: `/ui-themify`,
  typicon: `/ui-typicon`,
  weatherIcon: `/ui-weather-icon`,
  basicInput: `/forms-basic-input`,
  checkboxandRadion: `/form-checkbox-radios`,
  inputGroup: `/form-input-groups`,
  gridandGutters: `/form-grid-gutters`,
  formSelect: `/form-select`,
  formMask: `/form-mask`,
  fileUpload: `/form-fileupload`,
  horizontalForm: `/form-horizontal`,
  verticalForm: `/form-vertical`,
  floatingLable: `/form-floating-labels`,
  formValidation: `/form-validation`,
  reactSelect: `/select`,
  formWizard: `/form-wizard`,
  dataTable: `/tables-basic`,
  tableBasic: `/data-tables`,
  iconicIcon: `/icon-ionic`,

  //base-ui
  uiAlerts: `/ui-alerts`,
  uiAccordion: `/ui-accordion`,
  uiAvatar: `/ui-avatar`,
  uiBadges: `/ui-badges`,
  uiBorders: `/ui-borders`,
  uiButtons: `/ui-buttons`,
  uiButtonsGroup: `/ui-buttons-group`,
  uiBreadcrumb: `/ui-breadcrumb`,
  uiCards: `/ui-cards`,
  uiCarousel: `/ui-carousel`,
  uiColor: `/ui-color`,
  uiDropdowns: "ui-dropdowns",

  // pages routes
  error404: `/error-404`,
  error500: `/error-500`,
  underMaintenance: `/under-maintenance`,

  // settings routes
  customFields: `/app-settings/custom-fields`,
  invoiceSettings: `/app-settings/invoice-settings`,

  bankAccount: `/financial-settings/bank-ccount`,
  paymentGateways: `/financial-settings/payment-gateways`,
  taxRates: `/financial-settings/tax-rates`,

  schoolSettings : `/academic-settings/school-settings`,
  religion : `/academic-settings/religion`,

  connectedApps: `/general-settings/connected-apps`,
  notificationssettings: `/general-settings/notifications-settings`,
  profilesettings: `/general-settings/profile-settings`,
  securitysettings: `/general-settings/security-settings`,

  banIpAddress: `/other-settings/ban-ip-address`,
  storage: `/other-settings/storage`,

  emailSettings: `/system-settings/email-settings`,
  emailTemplates: `/system-settings/email-templates`,
  gdprCookies: `/system-settings/gdpr-cookies`,
  smsSettings: `/system-settings/sms-settings`,
  optSettings: `/system-settings/otp-settings`,

  socialAuthentication : `/website-settings/social-authentication`,
  companySettings: `/website-settings/company-settings`,
  language: `/website-settings/language`,
  localization: `/website-settings/localization`,
  preference: `/website-settings/preference`,
  prefixes: `/website-settings/prefixes`,

  // application routes
  todo: `/application/todo`,
  email: `/application/email`,
  videoCall: `/application/video-call`,
  chat: `/application/chat`,
  audioCall: `/application/audio-call`,
  callHistory: `/application/call-history`,
  fileManager: `/application/file-manager`,

  //content routes
  pages: `/content/pages`,
  cities: `/content/cities`,
  states: `/content/states`,
  testimonials: `/content/testimonials`,
  countries: `/content/countries`,
  faq: `/content/faq`,
  // blog
  allBlogs: `/content/all-blogs`,
  blogCategories: `/content/blog-categories`,
  blogComments: `/content/blog-comments`,
  blogTags: `/content/blog-tags`,

  //userManagement routes
  deleteRequest: `/user-management/delete-request`,
  rolesPermissions: `/user-management/roles-permissions`,
  manageusers: `/user-management/manage-users`,
  permissions: `/user-management/permissions`,

  //support routes
  contactMessages: `/support/contact-messages`,
  tickets: `/support/tickets`,
  ticketGrid: `/support/ticket-grid`,
  ticketDetails: `/support/ticket-details`,

  // membership routes
  membershipplan: `/membership-plans`,
  membershipAddon: `/membership-addons`,
  membershipTransaction: `/membership-transactions`,

  // Etablissements Module
  etablissementList: `/etablissement/etablissement-list`,
  etablissementGrid: `/etablissement/etablissement-grid`,
  addEtablissement: `/etablissement/add-etablissement`,
  // Peoples Module
  studentGrid: `/student/student-grid`,
  studentPromotion: `/student/student-promotion`,
  studentDetail: `/student/student-details`,
  studentTimeTable: `/student/student-time-table`,
  studentLeaves: `/student/student-leaves`,
  studentFees: `/student/student-fees`,
  studentResult: `/student/student-result`,
  studentLibrary: `/student/student-library`,
  studentList: `/student/student-list`,
  addStudent: `/student/add-student`,
  editStudent: `/student/edit-student`,
  teacherDetails: `/teacher/teacher-details`,
  teacherGrid: `/teacher/teacher-grid`,
  teacherList: `/teacher/teacher-list`,
  addTeacher: `/teacher/add-teacher`,
  editTeacher: `/teacher/edit-teacher`,
  teacherLibrary: `/teacher/teacher-library`,
  teacherSalary: `/teacher/teacher-salary`,
  teacherLeaves: `/teacher/teacher-leaves`,
  teachersRoutine: `/teacher/teacher-routine`,
  parentGrid: `/parent/parent-grid`,
  parentList: `/parent/parent-list`,
  guardiansGrid: `/parent/guardians-grid`,
  guardiansList: `/parent/guardians-list`,

  // Management
  feesGroup:`/management/fees-group`,
  feesType:`/management/fees-type`,
  feesMaster:`/management/fees-master`,
  feesAssign:`/management/fees-assign`,
  collectFees:`/management/collect-fees`,
  libraryMembers:`/management/library-members`,
  libraryIssueBook:`/management/library-issue-book`,
  libraryBooks:`/management/library-books`,
  libraryReturn:`/management/library-return`,
  playerList:`/management/players`,
  sportsList:`/management/sports`,
  hostelList:`/management/hostel-list`,
  hostelType:`/management/hostel-type`,
  hostelRoom:`/management/hostel-rooms`,
  transportAssignVehicle:`/management/transport-assign-vehicle`,
  transportVehicle:`/management/transport-vehicle`,
  transportVehicleDrivers:`/management/transport-vehicle-drivers`,
  transportPickupPoints:`/management/transport-pickup-points`,
  transportRoutes:`/management/transport-routes`,




  //Academic module
  AcademicReason: `/academic/academic-reason`,
  classSyllabus: `/academic/class-syllabus`,
  classSubject: `/academic/class-subject`,
  classSection: `/academic/class-section`,
  classRoom: `/academic/class-room`,
  classRoutine: `/academic/class-routine`,
  sheduleClasses: `/academic/schedule-classes`,
  classes: `/academic/classes`,
  classHomeWork: `/academic/class-home-work`,
  exam: `/academic/exam`,
  examSchedule: `/academic/exam-schedule`,
  grade: `/academic/grade`,
  examResult: `/academic/exam-result`,
  examAttendance: `/academic/exam-attendance`,
  classTimetable: `/academic/class-time-table`,

  //Hrm module
  staff: `/hrm/staff`,
  departments: `/hrm/departments`,
  payroll: `/hrm/payroll`,
  holidays: `/hrm/holidays`,
  designation: `/hrm/designation`,
  listLeaves: `/hrm/list-leaves`,
  staffDetails: `/hrm/staff-details`,
  staffPayroll: `/hrm/staff-payroll`,
  staffLeave: `/hrm/staff-leave`,

  approveRequest: `/hrm/approve-request`,
  studentAttendance: `/hrm/student-attendance`,
  teacherAttendance: `/hrm/teacher-attendance`,
  staffAttendance: `/hrm/staff-attendance`,
  addStaff: `/hrm/add-staff`,
  editStaff: `/hrm/edit-staff`,
  staffsAttendance: `/hrm/staffs-attendance`,

  layoutDefault: `/layout-default`,
  layoutMini: `/layout-mini`,
  layoutRtl: `/layout-rtl`,
  layoutBox: `/layout-box`,
  layoutDark: `/layout-dark`,

  // finance & accounts routes
  accountsIncome: `/accounts/accounts-income`,
  accountsInvoices: `/accounts/accounts-invoices`,
  accountsTransactions: `/accounts/accounts-transactions`,
  expense: `/accounts/expense`,
  expenseCategory: `/accounts/expense-category`,
  invoice: `/accounts/invoice`,
  addInvoice: `/accounts/add-invoice`,
  editInvoice: `/accounts/edit-invoice`,

  // announcements routes
  events: `/announcements/events`,
  noticeBoard: `/announcements/notice-board`,

  //Report
  attendanceReport: `/report/attendance-report`,
  classReport: `/report/class-report`,
  studentReport: `/report/student-report`,
  gradeReport: `/report/grade-report`,
  leaveReport: `/report/leave-report`,
  feesReport: `/report/fees-report`,
  teacherReport: `/report/teacher-report`,
  staffReport: `/report/staff-report`,
  studentAttendanceType: `/report/student-attendance-type`,
  dailyAttendance: `/report/daily-attendance`,
  studentDayWise: `/report/student-day-wise`,
  teacherDayWise: `/report/teacher-day-wise`,
  staffDayWise: `/report/staff-day-wise`,
  //page module
  profile:`/pages/profile`,
  activity:`/pages/activities`
};
