import React from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Emailtemplates = () => {
  const routes = all_routes;
  return (
    <div>
      <div className="page-wrapper">
        <div className="content bg-white">
          <div className="d-md-flex d-block align-items-center justify-content-between border-bottom pb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Paramètres du Système</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="index">Tableau de Bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Paramètres</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Paramètres du Système
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <div className="pe-1 mb-2">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Rafraîchir</Tooltip>}
                >
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white btn-icon me-1"
                  >
                    <i className="ti ti-refresh" />
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-2 col-xl-3">
              <div className="pt-3 d-flex flex-column list-group mb-4">
                <Link to={routes.emailSettings} className="d-block rounded p-2">
                  Paramètres Email
                </Link>
                <Link
                  to={routes.emailTemplates}
                  className="d-block rounded p-2 active"
                >
                  Modèles d'Email
                </Link>
                <Link to={routes.smsSettings} className="d-block rounded p-2">
                  Paramètres SMS
                </Link>
                <Link to={routes.optSettings} className="d-block rounded p-2">
                  OTP
                </Link>
                <Link to={routes.gdprCookies} className="d-block rounded p-2">
                  Cookies RGPD
                </Link>
              </div>
            </div>
            <div className="col-xxl-10 col-xl-9">
              <div className="flex-fill border-start ps-3">
                <form>
                  <div className="d-flex align-items-center justify-content-between flex-wrap border-bottom pt-3 mb-3">
                    <div className="mb-3">
                      <h5 className="mb-1">Modèles d'Email</h5>
                      <p>Configuration des Modèles d'Email</p>
                    </div>
                    <div className="mb-3">
                      <Link
                        to="#"
                        className="btn btn-outline-light bg-white btn-icon me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#add_email_template"
                      >
                        <i className="ti ti-plus" />
                      </Link>
                      <button className="btn btn-light me-2" type="button">
                        Annuler
                      </button>
                      <button className="btn btn-primary" type="submit">
                        Sauvegarder
                      </button>
                    </div>
                  </div>
                  <div className="d-md-flex">
                    <div className="flex-fill">
                      <div className="card">
                        <div className="card-body p-3 pb-0">
                          <div className="row">
                            <div className="col-xxl-4 col-md-6">
                              <div className="d-flex align-items-center justify-content-between bg-white p-3 border rounded mb-3">
                                <h5 className="fs-15 fw-normal">
                                  Vérification Email
                                </h5>
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit_email_template"
                                  >
                                    <i className="ti ti-edit" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-modal"
                                  >
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-md-6">
                              <div className="d-flex align-items-center justify-content-between bg-white p-3 border rounded mb-3">
                                <h5 className="fs-15 fw-normal">
                                  Email de Bienvenue
                                </h5>
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit_email_template"
                                  >
                                    <i className="ti ti-edit" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-modal"
                                  >
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-md-6">
                              <div className="d-flex align-items-center justify-content-between bg-white p-3 border rounded mb-3">
                                <h5 className="fs-15 fw-normal">
                                  Annonce de Fonctionnalité
                                </h5>
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit_email_template"
                                  >
                                    <i className="ti ti-edit" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-modal"
                                  >
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-md-6">
                              <div className="d-flex align-items-center justify-content-between bg-white p-3 border rounded mb-3">
                                <h5 className="fs-15 fw-normal">
                                  Annonce de Plan
                                </h5>
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit_email_template"
                                  >
                                    <i className="ti ti-edit" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-modal"
                                  >
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-md-6">
                              <div className="d-flex align-items-center justify-content-between bg-white p-3 border rounded mb-3">
                                <h5 className="fs-15 fw-normal">
                                  Rappel Utilisateur
                                </h5>
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit_email_template"
                                  >
                                    <i className="ti ti-edit" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-modal"
                                  >
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-md-6">
                              <div className="d-flex align-items-center justify-content-between bg-white p-3 border rounded mb-3">
                                <h5 className="fs-15 fw-normal">
                                  Demande de Retour
                                </h5>
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit_email_template"
                                  >
                                    <i className="ti ti-edit" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-modal"
                                  >
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-md-6">
                              <div className="d-flex align-items-center justify-content-between bg-white p-3 border rounded mb-3">
                                <h5 className="fs-15 fw-normal">
                                  Rappel de Suppression de Plan
                                </h5>
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit_email_template"
                                  >
                                    <i className="ti ti-edit" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-modal"
                                  >
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-md-6">
                              <div className="d-flex align-items-center justify-content-between bg-white p-3 border rounded mb-3">
                                <h5 className="fs-15 fw-normal">
                                  Plan Expiré
                                </h5>
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit_email_template"
                                  >
                                    <i className="ti ti-edit" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-modal"
                                  >
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-md-6">
                              <div className="d-flex align-items-center justify-content-between bg-white p-3 border rounded mb-3">
                                <h5 className="fs-15 fw-normal">
                                  Avis de Mise à Jour de Politique
                                </h5>
                                <div className="d-flex align-items-center">
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit_email_template"
                                  >
                                    <i className="ti ti-edit" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-outline-light bg-white btn-icon"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-modal"
                                  >
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        {/* Add Email Template */}
        <div className="modal fade" id="add_email_template">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Ajouter un Modèle d'Email</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Fermer"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Titre</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez le Titre"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Sujet</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez le Sujet"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Contenu du Modèle</label>
                        <div className="input-blocks summer-description-box notes-summernote">
                          <label className="form-label">Descriptions</label>
                          <div id="summernote"></div>
                        </div>
                      </div>
                      <div className="modal-satus-toggle d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Statut</h5>
                          <p>Changez le statut avec le toggle</p>
                        </div>
                        <div className="status-toggle modal-status">
                          <input
                            type="checkbox"
                            id="user4"
                            className="check"
                            defaultChecked
                          />
                          <label htmlFor="user4" className="checktoggle">
                            {" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    Soumettre
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Ajouter un Modèle d'Email */}
        {/* Modifier un Modèle d'Email */}
        <div className="modal fade" id="edit_email_template">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Modifier un Modèle d'Email</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Fermer"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Titre</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez le Titre"
                          defaultValue="Vérification Email"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Sujet</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez le Sujet"
                          defaultValue="Anglais"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Contenu du Modèle</label>
                        <div className="input-blocks summer-description-box notes-summernote">
                          <label className="form-label">Descriptions</label>
                          <div id="summernote2">
                            <p>
                              Bonjour [Nom de l'Utilisateur], Bienvenue chez Preskool ! Nous sommes ravis de vous accueillir. Préparez-vous à vivre une expérience fluide et efficace. Pour commencer, cliquez ici. Si vous avez des questions ou besoin d'assistance, n'hésitez pas à contacter notre équipe de support à [support@example.com].
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="modal-satus-toggle d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Statut</h5>
                          <p>Changez le statut avec le toggle</p>
                        </div>
                        <div className="status-toggle modal-status">
                          <input
                            type="checkbox"
                            id="user5"
                            className="check"
                            defaultChecked
                          />
                          <label htmlFor="user5" className="checktoggle">
                            {" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Modifier un Modèle d'Email */}
        {/* Modal de Suppression */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirmer la Suppression</h4>
                  <p>
                    Vous souhaitez supprimer tous les éléments marqués, cela ne peut pas être annulé une fois supprimé.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Annuler
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Oui, Supprimer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* /Delete Modal */}
      </>
    </div>
  );
};

export default Emailtemplates;
