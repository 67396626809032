import React from 'react'
import TeacherModal from '../teacherModal'
import { Link } from 'react-router-dom'
import { all_routes } from '../../../router/all_routes'
import TeacherSidebar from './teacherSidebar'
import TeacherBreadcrumb from './teacherBreadcrumb'

const TeacherDetails = () => {
    const routes = all_routes
  return (
    <>
  {/* Page Wrapper */}
  <div className="page-wrapper">
    <div className="content">
      <div className="row">
        {/* Page Header */}
        <TeacherBreadcrumb />
        {/* /Page Header */}
        {/* Student Information */}
        <TeacherSidebar />
        {/* /Student Information */}
        <div className="col-xxl-9 col-xl-8">
          <div className="row">
            <div className="col-md-12">
              {/* List */}
              <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                <li>
                  <Link to={routes.teacherDetails} className="nav-link active">
                    <i className="ti ti-school me-2" />
                    Détails du Professeur
                  </Link>
                </li>
                <li>
                  <Link to={routes.teachersRoutine} className="nav-link">
                    <i className="ti ti-table-options me-2" />
                    Routine
                  </Link>
                </li>
                <li>
                  <Link to={routes.teacherLeaves} className="nav-link">
                    <i className="ti ti-calendar-due me-2" />
                    Congés &amp; Présence
                  </Link>
                </li>
                <li>
                  <Link to={routes.teacherSalary} className="nav-link">
                    <i className="ti ti-report-money me-2" />
                    Salaire
                  </Link>
                </li>
                <li>
                  <Link to={routes.teacherLibrary} className="nav-link">
                    <i className="ti ti-bookmark-edit me-2" />
                    Bibliothèque
                  </Link>
                </li>
              </ul>
              {/* /List */}
              {/* Parents Information */}
              <div className="card">
                <div className="card-header">
                  <h5>Détails du Profil</h5>
                </div>
                <div className="card-body">
                  <div className="border rounded p-3 pb-0">
                    <div className="row">
                      <div className="col-sm-6 col-lg-4">
                        <div className="mb-3">
                          <p className="text-dark fw-medium mb-1">
                            Nom du Père
                          </p>
                          <p>Francis Saviour</p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="mb-3">
                          <p className="text-dark fw-medium mb-1">
                            Nom de la Mère
                          </p>
                          <p>Stella Bruce</p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="mb-3">
                          <p className="text-dark fw-medium mb-1">Date de Naissance</p>
                          <p>25 Jan 1992</p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="mb-3">
                          <p className="text-dark fw-medium mb-1">
                            État Civil
                          </p>
                          <p>Célibataire</p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="mb-3">
                          <p className="text-dark fw-medium mb-1">
                            Qualification
                          </p>
                          <p>MBA</p>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="mb-3">
                          <p className="text-dark fw-medium mb-1">Expérience</p>
                          <p>2 Ans</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Parents Information */}
            </div>
            {/* Documents */}
            <div className="col-xxl-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <h5>Documents</h5>
                </div>
                <div className="card-body">
                  <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between mb-3 p-2">
                    <div className="d-flex align-items-center overflow-hidden">
                      <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                        <i className="ti ti-pdf fs-15" />
                      </span>
                      <div className="ms-2">
                        <p className="text-truncate fw-medium text-dark">
                          Resume.pdf
                        </p>
                      </div>
                    </div>
                    <Link to="#" className="btn btn-dark btn-icon btn-sm">
                      <i className="ti ti-download" />
                    </Link>
                  </div>
                  <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between p-2">
                    <div className="d-flex align-items-center overflow-hidden">
                      <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                        <i className="ti ti-pdf fs-15" />
                      </span>
                      <div className="ms-2">
                        <p className="text-truncate fw-medium text-dark">
                          Lettre d'Embauche.pdf
                        </p>
                      </div>
                    </div>
                    <Link to="#" className="btn btn-dark btn-icon btn-sm">
                      <i className="ti ti-download" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* /Documents */}
            {/* Address */}
            <div className="col-xxl-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <h5>Adresse</h5>
                </div>
                <div className="card-body">
                  <div className="d-flex align-items-center mb-3">
                    <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                      <i className="ti ti-map-pin-up" />
                    </span>
                    <div>
                      <p className="text-dark fw-medium mb-1">
                        Adresse Actuelle
                      </p>
                      <p>3495 Red Hawk Road, Buffalo Lake, MN 55314</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                      <i className="ti ti-map-pins" />
                    </span>
                    <div>
                      <p className="text-dark fw-medium mb-1">
                        Adresse Permanente
                      </p>
                      <p>3495 Red Hawk Road, Buffalo Lake, MN 55314</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Address */}
            {/* Previous School Details */}
            <div className="col-xxl-12">
              <div className="card">
                <div className="card-header">
                  <h5>Détails de l'École Précédente</h5>
                </div>
                <div className="card-body pb-1">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <p className="mb-1 text-dark fw-medium">
                          Nom de l'École Précédente
                        </p>
                        <p>Oxford Matriculation, USA</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <p className="mb-1 text-dark fw-medium">
                          Adresse de l'École
                        </p>
                        <p>1852 Barnes Avenue, Cincinnati, OH 45202</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <p className="mb-1 text-dark fw-medium">Numéro de Téléphone</p>
                        <p>+1 35676 45556</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Previous School Details */}
            {/* Bank Details */}
            <div className="col-xxl-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <h5>Détails Bancaires</h5>
                </div>
                <div className="card-body pb-1">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <p className="mb-1 text-dark fw-medium">Nom de la Banque</p>
                        <p>American Bank</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <p className="mb-1 text-dark fw-medium">Numéro de Compte</p>
                        <p>ABX-678-93817</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <p className="mb-1 text-dark fw-medium">Code SWIFT</p>
                        <p>ABA12345</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Bank Details */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* /Page Wrapper */}

  {/* Teacher Modal */}
  <TeacherModal />
  {/* /Teacher Modal */}
  </>
  )
}

export default TeacherDetails