export const classSubject = [
	{
	  id: "SU128394",
	  nom: "Anglais",
	  code: "101",
	  type: "Théorie",
	  statut: "Actif",
	  key: 1
	},
	{
	  id: "SU128393",
	  nom: "Mathématiques",
	  code: "102",
	  type: "Théorie",
	  statut: "Actif",
	  key: 2
	},
	{
	  id: "SU128392",
	  nom: "Physique",
	  code: "103",
	  type: "Pratique",
	  statut: "Actif",
	  key: 3
	},
	{
	  id: "SU128391",
	  nom: "Chimie",
	  code: "104",
	  type: "Pratique",
	  statut: "Actif",
	  key: 4
	},
	{
	  id: "SU128390",
	  nom: "Biologie",
	  code: "105",
	  type: "Pratique",
	  statut: "Inactif",
	  key: 5
	},
	{
	  id: "SU128389",
	  nom: "Mathématiques Supérieures",
	  code: "106",
	  type: "Pratique",
	  statut: "Actif",
	  key: 6
	},
	{
	  id: "SU128388",
	  nom: "Technologies de l'Information",
	  code: "107",
	  type: "Pratique",
	  statut: "Actif",
	  key: 7
	},
	{
	  id: "SU128387",
	  nom: "Éducation Morale",
	  code: "108",
	  type: "Pratique",
	  statut: "Inactif",
	  key: 8
	},
	{
	  id: "SU128388",
	  nom: "Finance",
	  code: "109",
	  type: "Théorie",
	  statut: "Actif",
	  key: 9
	},
	{
	  id: "SU128386",
	  nom: "Économie",
	  code: "110",
	  type: "Théorie",
	  statut: "Actif",
	  key: 10
	}
  ];  