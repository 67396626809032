import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CompanySettings = () => {
  const routes = all_routes;
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="d-md-flex d-block align-items-center justify-content-between border-bottom pb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Paramètres du site Web</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="index">Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Paramètres</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Paramètres du site Web
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <div className="pe-1 mb-2">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Rafraîchir</Tooltip>}
                >
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white btn-icon me-1"
                  >
                    <i className="ti ti-refresh" />
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-2 col-xl-3">
              <div className="pt-3 d-flex flex-column list-group mb-4">
                <Link
                  to={routes.companySettings}
                  className="d-block rounded p-2 active"
                >
                  Paramètres de l'entreprise
                </Link>
                <Link to={routes.localization} className="d-block rounded p-2">
                  Localisation
                </Link>
                <Link to={routes.prefixes} className="d-block rounded p-2">
                  Préfixes
                </Link>
                <Link to={routes.preference} className="d-block rounded p-2">
                  Préférences
                </Link>
                <Link
                  to={routes.socialAuthentication}
                  className="d-block rounded p-2"
                >
                  Authentification sociale
                </Link>
                <Link to={routes.language} className="d-block rounded p-2">
                  Langue
                </Link>
              </div>
            </div>
            <div className="col-xxl-10 col-xl-9">
              <div className="flex-fill border-start ps-3">
                <form>
                  <div className="d-flex align-items-center justify-content-between flex-wrap border-bottom pt-3 mb-3">
                    <div className="mb-3">
                      <h5>Paramètres de l'entreprise</h5>
                      <p>Fournissez les informations de votre entreprise</p>
                    </div>
                    <div className="mb-3">
                      <button className="btn btn-light me-2" type="button">
                        Annuler
                      </button>
                      <button className="btn btn-primary" type="submit">
                        Sauvegarder
                      </button>
                    </div>
                  </div>
                  <div className="d-md-flex d-block">
                    <div className="flex-fill">
                      <div className="card">
                        <div className="card-header">
                          <h5>Informations sur l'entreprise</h5>
                        </div>
                        <div className="card-body pb-0">
                          <div className="d-block d-xl-flex">
                            <div className="mb-3 flex-fill me-xl-3 me-0">
                              <label className="form-label">Nom de l'entreprise</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Entrez le nom de l'entreprise"
                              />
                            </div>
                            <div className="mb-3 flex-fill">
                              <label className="form-label">Numéro de téléphone</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Entrez le numéro de téléphone"
                              />
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Adresse e-mail de l'entreprise
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Entrez l'adresse e-mail"
                            />
                          </div>
                          <div className="d-block d-xl-flex">
                            <div className="mb-3 flex-fill me-xl-3 me-0">
                              <label className="form-label">Numéro de fax</label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Entrez le numéro de fax"
                              />
                            </div>
                            <div className="mb-3 flex-fill">
                              <label className="form-label">Site web</label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Entrez le site web"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <h5>Informations d'adresse</h5>
                        </div>
                        <div className="card-body pb-0">
                          <div className="mb-3">
                            <label className="form-label">Adresse</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Entrez l'adresse"
                            />
                          </div>
                          <div className="d-block d-xl-flex">
                            <div className="mb-3 flex-fill me-xl-3 me-0">
                              <label className="form-label">Pays</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Entrez le pays"
                              />
                            </div>
                            <div className="mb-3 flex-fill">
                              <label className="form-label">
                                État / Province
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Entrez l'état"
                              />
                            </div>
                          </div>
                          <div className="d-block d-xl-flex">
                            <div className="mb-3 flex-fill me-xl-3 me-0">
                              <label className="form-label">Ville</label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Ville"
                              />
                            </div>
                            <div className="mb-3 flex-fill">
                              <label className="form-label">Code postal</label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Entrez le code postal"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="settings-right-sidebar ms-md-3">
                      <div className="card">
                        <div className="card-header">
                          <h5>Images de l'entreprise</h5>
                        </div>
                        <div className="card-body">
                          <div className="border-bottom mb-3 pb-3">
                            <div className="d-flex justify-content-between mb-3">
                              <div className="d-flex align-items-center">
                                <span className="avatar avatar-xl border rounded d-flex align-items-center justify-content-center p-2 me-2">
                                  <ImageWithBasePath
                                    src="assets/img/logo-small.svg"
                                    alt="Img"
                                  />
                                </span>
                                <h5>Logo</h5>
                              </div>
                              <div className="d-flex align-items-center">
                                <Link
                                  to="#"
                                  className="text-primary border rounded fs-16 p-1 badge badge-primary-hover me-2"
                                >
                                  <i className="ti ti-edit-circle" />
                                </Link>
                                <Link
                                  to="#"
                                  className="text-danger border rounded fs-16 p-1 badge badge-danger-hover"
                                >
                                  <i className="ti ti-trash-x" />
                                </Link>
                              </div>
                            </div>
                            <div className="profile-uploader profile-uploader-two mb-0">
                              <span className="d-block text-center lh-1 fs-24 mb-1">
                                <i className="ti ti-upload" />
                              </span>
                              <div className="drag-upload-btn bg-transparent me-0 border-0">
                                <p className="fs-12 mb-2">
                                  <span className="text-primary">
                                    Cliquez pour télécharger
                                  </span>{" "}
                                  ou faites glisser et déposez
                                </p>
                                <h6>JPG ou PNG</h6>
                                <h6>(Max 450 x 450 px)</h6>
                              </div>
                              <input
                                type="file"
                                className="form-control image-sign"
                                multiple
                              />
                              <div className="frames" />
                            </div>
                          </div>
                          <div className="border-bottom mb-3 pb-3">
                            <div className="d-flex justify-content-between mb-3">
                              <div className="d-flex align-items-center">
                                <span className="avatar avatar-xl border rounded d-flex align-items-center justify-content-center p-2 me-2">
                                  <ImageWithBasePath
                                    src="assets/img/logo-small.svg"
                                    alt="Img"
                                  />
                                </span>
                                <h5>Icône du favicon</h5>
                              </div>
                              <div className="d-flex align-items-center">
                                <Link
                                  to="#"
                                  className="text-primary border rounded fs-16 p-1 badge badge-primary-hover me-2"
                                >
                                  <i className="ti ti-edit-circle" />
                                </Link>
                                <Link
                                  to="#"
                                  className="text-danger border rounded fs-16 p-1 badge badge-danger-hover"
                                >
                                  <i className="ti ti-trash-x" />
                                </Link>
                              </div>
                            </div>
                            <div className="profile-uploader profile-uploader-two mb-0">
                              <span className="d-block text-center lh-1 fs-24 mb-1">
                                <i className="ti ti-upload" />
                              </span>
                              <div className="drag-upload-btn bg-transparent me-0 border-0">
                                <p className="fs-12 mb-2">
                                  <span className="text-primary">
                                    Cliquez pour télécharger
                                  </span>{" "}
                                  ou faites glisser et déposez
                                </p>
                                <h6>JPG ou PNG</h6>
                                <h6>(Max 450 x 450 px)</h6>
                              </div>
                              <input
                                type="file"
                                className="form-control image-sign"
                                multiple
                              />
                              <div className="frames" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanySettings;