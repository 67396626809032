import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router";
import { authRoutes, publicRoutes } from "./router.link";
import Feature from "../feature";
import AuthFeature from "../authFeature";
import Login2 from "../auth/login/login-2";
import { modules } from "../../utils/modules";

const ALLRoutes: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // Mapping des chemins avec les titres à récupérer
    const currentModule = modules.find((module) => module.link === location.pathname);

    if (currentModule) {
      document.title = "NYOTA | "+currentModule.title; // Met à jour le titre en fonction de la propriété souhaitée
    } else {
      document.title = "NYOTA"; // Titre par défaut si aucune correspondance n'est trouvée
    }
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Login2 />} />
        <Route element={<Feature />}>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>

        <Route element={<AuthFeature />}>
          {authRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default ALLRoutes;
