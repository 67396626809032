import React, { useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import CommonSelect from "../../../core/common/commonSelect";
import { TagsInput } from "react-tag-input-component";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


const Localization = () => {
  const routes = all_routes;
  const [owner, setOwner] = useState<string[]>(["JPG", "GIF", "PNG"]);
  const languageOptions = [
    { value: "english", label: "Anglais" },
    { value: "spanish", label: "Espagnol" },
  ];
  const timezoneOptions = [
    { value: "utc5:30", label: "UTC 5:30" },
    { value: "utc+11:00", label: "(UTC+11:00) INR" },
  ];
  const dateOptions = [
    { value: "01 Jan 2024", label: "01 Jan 2024" },
    { value: "Jan 01 2024", label: "Jan 01 2024" },
    { value: "2024 Jan 01", label: "2024 Jan 01" },
  ];
  const timeFormatOptions = [
    { value: "12-hours", label: "12 Heures" },
    { value: "24-hours", label: "24 Heures" },
  ];
  const yearOptions = [
    { value: "2024", label: "2024" },
    { value: "2023", label: "2023" },
  ];
  const monthOptions = [
    { value: "January", label: "Janvier" },
    { value: "February", label: "Février" },
    { value: "March", label: "Mars" },
  ];
  const country = [
    { value: "India", label: "Inde" },
    { value: "United States Of America", label: "États-Unis d'Amérique" },
  ];
  const symbols = [
    { value: "$", label: "$" },
    { value: "€", label: "€" },
  ];
  const symbolsandvalue = [
    { value: "$100", label: "$100" },
    { value: "$400", label: "$400" },
  ];
  const dot = [
    { value: ".", label: "." },
    { value: ".", label: "." },
  ];
  const comma = [
    { value: ",", label: "," },
    { value: ",", label: "," },
  ];
  const permissionforcountry = [
    { value: "Allow All Countries", label: "Autoriser Tous les Pays" },
    { value: "Deny All Countries", label: "Refuser Tous les Pays" },
  ];
  const mbSize = [
    { value: "5000 MB", label: "5000 Mo" },
    { value: "2000 MB", label: "2000 Mo" },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="d-md-flex d-block align-items-center justify-content-between border-bottom pb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Paramètres du site Web</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="index">Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Paramètres</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Paramètres du site Web
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <div className="pe-1 mb-2">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Rafraîchir</Tooltip>}
                >
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white btn-icon me-1"
                  >
                    <i className="ti ti-refresh" />
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-2 col-xl-3">
              <div className="pt-3 d-flex flex-column list-group mb-4">
                <Link
                  to={routes.companySettings}
                  className="d-block rounded p-2"
                >
                  Paramètres de l'entreprise
                </Link>
                <Link to={routes.localization} className="d-block rounded p-2 active">
                  Localisation
                </Link>
                <Link to={routes.prefixes} className="d-block rounded p-2">
                  Préfixes
                </Link>
                <Link to={routes.preference} className="d-block rounded p-2">
                  Préférences
                </Link>
                <Link to={routes.socialAuthentication} className="d-block rounded p-2">
                  Authentification Sociale
                </Link>
                <Link to={routes.language} className="d-block rounded p-2">
                  Langue
                </Link>
              </div>
            </div>
            <div className="col-xxl-10 col-xl-9">
              <div className="border-start ps-3">
                <form>
                  <div className="d-flex align-items-center justify-content-between flex-wrap border-bottom pt-3 mb-3">
                    <div className="mb-3">
                      <h5 className="mb-1">Localisation</h5>
                      <p>Collection des paramètres pour l'environnement utilisateur</p>
                    </div>
                    <div className="mb-3">
                      <button className="btn btn-light me-2" type="button">
                        Annuler
                      </button>
                      <button className="btn btn-primary" type="submit">
                        Enregistrer
                      </button>
                    </div>
                  </div>
                  <div className="d-md-flex d-block">
                    <div className="flex-fill">
                      <div className="card">
                        <div className="card-header p-3">
                          <h5>Informations de base</h5>
                        </div>
                        <div className="card-body p-3 pb-0">
                          <div className="d-block d-xl-flex align-items-end">
                            <div className="mb-3 flex-fill me-xl-3 me-0">
                              <label className="form-label">Langue</label>
                              <CommonSelect
                                className="select"
                                options={languageOptions}
                              />
                            </div>
                            <div className="mb-3 flex-fill">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="status-title">
                                  <h5>Commutateur de langue</h5>
                                  <p>À afficher sur toutes les pages</p>
                                </div>
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="switch-sm"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-4 pe-xl-0">
                              <div className="mb-3 flex-fill me-xl-3 me-0">
                                <label className="form-label">Fuseau horaire</label>
                                <CommonSelect
                                  className="select"
                                  options={timezoneOptions}
                                />
                              </div>
                            </div>
                            <div className="col-xl-4 ps-xl-0 pe-xl-0">
                              <div className="mb-3 flex-fill  me-xl-3 me-0">
                                <label className="form-label">Format de date</label>
                                <CommonSelect
                                  className="select"
                                  options={dateOptions}
                                />
                              </div>
                            </div>
                            <div className="col-xl-4 ps-xl-0">
                              <div className="mb-3 flex-fill">
                                <label className="form-label">Format de l'heure</label>
                                <CommonSelect
                                  className="select"
                                  options={timeFormatOptions}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-4 pe-xl-0">
                              <div className="mb-3 flex-fill me-xl-3 me-0">
                                <label className="form-label">Année fiscale</label>
                                <CommonSelect
                                  className="select"
                                  options={yearOptions}
                                />
                              </div>
                            </div>
                            <div className="col-xl-4 ps-xl-0">
                              <div className="mb-3 flex-fill">
                                <label className="form-label">Mois de début</label>
                                <CommonSelect
                                  className="select"
                                  options={monthOptions}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header p-3">
                          <h5>Paramètres de la monnaie</h5>
                        </div>
                        <div className="card-body p-3 pb-0">
                          <div className="row">
                            <div className="col-xl-4 pe-xl-0">
                              <div className="mb-3 flex-fill me-xl-3 me-0">
                                <label className="form-label">Monnaie</label>
                                <CommonSelect
                                  className="select"
                                  options={country}
                                />
                              </div>
                            </div>
                            <div className="col-xl-4 ps-xl-0 pe-xl-0">
                              <div className="mb-3 flex-fill  me-xl-3 me-0">
                                <label className="form-label">
                                  Position de la monnaie
                                </label>
                                <CommonSelect
                                  className="select"
                                  options={symbols}
                                />
                              </div>
                            </div>
                            <div className="col-xl-4 ps-xl-0">
                              <div className="mb-3 flex-fill">
                                <label className="form-label">Valeur de la monnaie</label>
                                <CommonSelect
                                  className="select"
                                  options={symbolsandvalue}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-4 pe-xl-0">
                              <div className="mb-3 flex-fill me-xl-3 me-0">
                                <label className="form-label">
                                  Séparateur décimal
                                </label>
                                <CommonSelect
                                  className="select"
                                  options={dot}
                                />
                              </div>
                            </div>
                            <div className="col-xl-4 ps-xl-0">
                              <div className="mb-3 flex-fill">
                                <label className="form-label">
                                  Séparateur des milliers
                                </label>
                                <CommonSelect
                                  className="select"
                                  options={comma}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header p-3">
                          <h5>Paramètres du pays</h5>
                        </div>
                        <div className="card-body p-3 pb-0">
                          <div className="row align-items-center">
                            <div className="col-xl-8">
                              <div className="mb-3 flex-fill">
                                <h6>Restriction des pays</h6>
                                <p>Sélectionner les pays restreints</p>
                              </div>
                            </div>
                            <div className="col-xl-4">
                              <div className="mb-3 flex-fill">
                                <CommonSelect
                                  className="select"
                                  options={permissionforcountry}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header p-3">
                          <h5>Paramètres des fichiers</h5>
                        </div>
                        <div className="card-body p-3 pb-0">
                          <div className="row align-items-center mb-2">
                            <div className="col-xl-8">
                              <div className="mb-3 flex-fill">
                                <h6>Fichiers autorisés</h6>
                                <p>Sélectionner les fichiers autorisés</p>
                              </div>
                            </div>
                            <div className="col-xl-4">
                              <div className="mb-3 flex-fill">
                                <TagsInput
                                  // className="input-tags form-control"
                                  value={owner}
                                  onChange={setOwner}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-8">
                              <div className="mb-3 flex-fill">
                                <h6>Taille maximale des fichiers</h6>
                                <p>Sélectionner la taille maximale des fichiers</p>
                              </div>
                            </div>
                            <div className="col-xl-4">
                              <div className="mb-3 flex-fill">
                                <CommonSelect
                                  className="select"
                                  options={mbSize}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Localization;
