import React from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const routes = all_routes;
const BanIpAddress = () => {
  return (
    <div>
      <div className="page-wrapper">
        <div className="content bg-white">
          <div className="d-md-flex d-block align-items-center justify-content-between border-bottom pb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Autres Paramètres</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="index">Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Paramètres</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Autres Paramètres
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <div className="pe-1 mb-2">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Rafraîchir</Tooltip>}
                >
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white btn-icon me-1"
                  >
                    <i className="ti ti-refresh" />
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-2 col-xl-3">
              <div className="pt-3 d-flex flex-column list-group mb-4">
                <Link
                  to={routes.schoolSettings}
                  className="d-block rounded p-2"
                >
                  Stockage
                </Link>
                <Link
                  to={routes.banIpAddress}
                  className="d-block active rounded p-2"
                >
                  Bloquer Adresse IP
                </Link>
              </div>
            </div>
            <div className="col-xxl-10 col-xl-9">
              <div className="border-start ps-3">
                <form>
                  <div className="d-flex align-items-center justify-content-between flex-wrap border-bottom pt-3 mb-3">
                    <div className="mb-3">
                      <h5 className="mb-1">Bloquer Adresse IP</h5>
                      <p>Configuration du blocage d'adresse IP</p>
                    </div>
                    <div className="mb-3">
                      <Link
                        to="#"
                        className="btn btn-outline-light bg-white btn-icon me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#add_ban_ip"
                      >
                        <i className="ti ti-plus" />
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-4 col-md-6 d-flex">
                      <div className="card flex-fill">
                        <div className="card-header p-3 d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <span className="avatar avatar-sm rounded p-1 text-danger bg-soft-danger me-2">
                              <i className="ti ti-ban" />
                            </span>
                            <h6>198.120.16.01</h6>
                          </div>
                          <div className="d-flex align-items-center">
                            <Link
                              to="#"
                              className="me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#edit_ban_ip"
                            >
                              <i className="ti ti-edit" />
                            </Link>
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete-modal"
                            >
                              <i className="ti ti-trash" />
                            </Link>
                          </div>
                        </div>
                        <div className="card-body p-3">
                          <div className="d-flex align-items-center">
                            <span className="flex-shrink-0 d-block me-2">
                              <i className="ti ti-info-square-rounded" />
                            </span>
                            <p>
                              Blocage temporaire pour protéger les comptes utilisateurs contre les fraudeurs en ligne.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-md-6 d-flex">
                      <div className="card flex-fill">
                        <div className="card-header p-3 d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <span className="avatar avatar-sm rounded p-1 text-danger bg-soft-danger me-2">
                              <i className="ti ti-ban" />
                            </span>
                            <h6>198.160.11.20</h6>
                          </div>
                          <div className="d-flex align-items-center">
                            <Link
                              to="#"
                              className="me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#edit_ban_ip"
                            >
                              <i className="ti ti-edit" />
                            </Link>
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete-modal"
                            >
                              <i className="ti ti-trash" />
                            </Link>
                          </div>
                        </div>
                        <div className="card-body p-3">
                          <div className="d-flex align-items-center">
                            <span className="flex-shrink-0 d-block me-2">
                              <i className="ti ti-info-square-rounded" />
                            </span>
                            <p>
                              Tentatives d'accès non autorisées, ou autres signes d'une potentielle menace de sécurité
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-md-6 d-flex">
                      <div className="card flex-fill">
                        <div className="card-header p-3 d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <span className="avatar avatar-sm rounded p-1 text-danger bg-soft-danger me-2">
                              <i className="ti ti-ban" />
                            </span>
                            <h6>198.123.10.2</h6>
                          </div>
                          <div className="d-flex align-items-center">
                            <Link
                              to="#"
                              className="me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#edit_ban_ip"
                            >
                              <i className="ti ti-edit" />
                            </Link>
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete-modal"
                            >
                              <i className="ti ti-trash" />
                            </Link>
                          </div>
                        </div>
                        <div className="card-body p-3">
                          <div className="d-flex align-items-center">
                            <span className="flex-shrink-0 d-block me-2">
                              <i className="ti ti-info-square-rounded" />
                            </span>
                            <p>
                              Tentative d'accès à des zones restreintes de l'administration
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-md-6 d-flex">
                      <div className="card flex-fill">
                        <div className="card-header p-3 d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <span className="avatar avatar-sm rounded p-1 text-danger bg-soft-danger me-2">
                              <i className="ti ti-ban" />
                            </span>
                            <h6>198.110.01.05</h6>
                          </div>
                          <div className="d-flex align-items-center">
                            <Link
                              to="#"
                              className="me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#edit_ban_ip"
                            >
                              <i className="ti ti-edit" />
                            </Link>
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete-modal"
                            >
                              <i className="ti ti-trash" />
                            </Link>
                          </div>
                        </div>
                        <div className="card-body p-3">
                          <div className="d-flex align-items-center">
                            <span className="flex-shrink-0 d-block me-2">
                              <i className="ti ti-info-square-rounded" />
                            </span>
                            <p>
                              Détection de téléchargement ou d'envoi de contenu inapproprié
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        {/* Ajouter une adresse IP bannie */}
        <div className="modal fade" id="add_ban_ip">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Ajouter une adresse IP bannie</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                />
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="ip-address" className="form-label">
                      Adresse IP
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ip-address"
                      placeholder="Entrez l'adresse IP"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="reason" className="form-label">
                      Raison
                    </label>
                    <textarea
                      className="form-control"
                      id="reason"
                      rows={3}
                      placeholder="Entrez la raison du bannissement"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary">
                  Ajouter
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modifier une adresse IP bannie */}
        <div className="modal fade" id="edit_ban_ip">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Modifier une adresse IP bannie</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                />
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="ip-address-edit" className="form-label">
                      Adresse IP
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ip-address-edit"
                      placeholder="Entrez l'adresse IP"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="reason-edit" className="form-label">
                      Raison
                    </label>
                    <textarea
                      className="form-control"
                      id="reason-edit"
                      rows={3}
                      placeholder="Entrez la raison du bannissement"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary">
                  Mettre à jour
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Supprimer une adresse IP bannie */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirmer la suppression</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                />
              </div>
              <div className="modal-body">
                <p>
                  Êtes-vous sûr de vouloir supprimer cette adresse IP de la liste
                  des adresses bannies ?
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Supprimer
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};


export default BanIpAddress;
