export const modulesMinistries = [
  {
    title: "Gestion des Établissements Scolaires",
    description:
      "Supervision et administration des établissements scolaires, gestion des informations, performances et conformité.",
    icon: "fa-school",
    color: "#28a745",
    link: "/gestion-etablissements-scolaires",
  },
  {
    title: "Gestion des Statistiques, Reporting et Outils d'Aide à la Décision",
    description:
      "Collecte, analyse des données et génération de rapports pour aider à la prise de décision stratégique.",
    icon: "fa-chart-line",
    color: "#007bff",
    link: "/statistiques-reporting-decision",
  },
  {
    title: "Gestion des Cours et des Programmes",
    description:
      "Planification, organisation des cours, et gestion des programmes scolaires au niveau national.",
    icon: "fa-book-reader",
    color: "#ffc107",
    link: "/gestion-cours-programmes",
  },
  {
    title: "Gestion Administration Centrale",
    description:
      "Gestion des activités administratives et des processus au niveau du ministère central de l'Éducation.",
    icon: "fa-building",
    color: "#dc3545",
    link: "/gestion-administration-centrale",
  },
  {
    title: "Gestion des Transferts Scolaires",
    description:
      "Gestion des demandes de transfert d'élèves entre différents établissements scolaires.",
    icon: "fa-exchange-alt",
    color: "#17a2b8",
    link: "/gestion-transferts-scolaires",
  },
  {
    title: "Communication et Collaboration",
    description:
      "Facilitation de la communication et de la collaboration entre les établissements scolaires et le ministère.",
    icon: "fa-comments",
    color: "#6f42c1",
    link: "/communication-collaboration",
  },
];