import React, { useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
type PasswordField =
  | "oldPassword"
  | "newPassword"
  | "confirmPassword"
  | "currentPassword";

const Profile = () => {
  const route = all_routes;
  const [passwordVisibility, setPasswordVisibility] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
    currentPassword: false,
  });

  const togglePasswordVisibility = (field: PasswordField) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
  <div className="content">
    <div className="d-md-flex d-block align-items-center justify-content-between border-bottom pb-3">
      <div className="my-auto mb-2">
        <h3 className="page-title mb-1">Profil</h3>
        <nav>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to={route.adminDashboard}>Tableau de bord</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="#">Paramètres</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Profil
            </li>
          </ol>
        </nav>
      </div>
      <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
        <div className="pe-1 mb-2">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">Rafraîchir</Tooltip>}
          >
            <Link
              to="#"
              className="btn btn-outline-light bg-white btn-icon me-1"
            >
              <i className="ti ti-refresh" />
            </Link>
          </OverlayTrigger>
        </div>
      </div>
    </div>
    <div className="d-md-flex d-block mt-3">
      <div className="settings-right-sidebar me-md-3 border-0">
        <div className="card">
          <div className="card-header">
            <h5>Informations personnelles</h5>
          </div>
          <div className="card-body ">
            <div className="settings-profile-upload">
              <span className="profile-pic">
                <ImageWithBasePath
                  src="assets/img/profiles/avatar-27.jpg"
                  alt="Profil"
                />
              </span>
              <div className="title-upload">
                <h5>Modifier votre photo</h5>
                <Link to="#" className="me-2">
                  Supprimer{" "}
                </Link>
                <Link to="#" className="text-primary">
                  Mettre à jour
                </Link>
              </div>
            </div>
            <div className="profile-uploader profile-uploader-two mb-0">
              <span className="upload-icon">
                <i className="ti ti-upload" />
              </span>
              <div className="drag-upload-btn bg-transparent me-0 border-0">
                <p className="upload-btn">
                  <span>Cliquez pour télécharger</span> ou faites glisser et déposez
                </p>
                <h6>JPG ou PNG</h6>
                <h6>(Max 450 x 450 px)</h6>
              </div>
              <input
                type="file"
                className="form-control"
                multiple
                id="image_sign"
              />
              <div id="frames" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-fill ps-0 border-0">
        <form>
          <div className="d-md-flex">
            <div className="flex-fill">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5>Informations personnelles</h5>
                  <Link
                    to="#"
                    className="btn btn-primary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_personal_information"
                  >
                    <i className="ti ti-edit me-2" />
                    Modifier
                  </Link>
                </div>
                <div className="card-body pb-0">
                  <div className="d-block d-xl-flex">
                    <div className="mb-3 flex-fill me-xl-3 me-0">
                      <label className="form-label">Prénom</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le prénom"
                      />
                    </div>
                    <div className="mb-3 flex-fill">
                      <label className="form-label">Nom de famille</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le nom de famille"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Adresse e-mail</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Entrez l'email"
                    />
                  </div>
                  <div className="d-block d-xl-flex">
                    <div className="mb-3 flex-fill me-xl-3 me-0">
                      <label className="form-label">Nom d'utilisateur</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le nom d'utilisateur"
                      />
                    </div>
                    <div className="mb-3 flex-fill">
                      <label className="form-label">Numéro de téléphone</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le numéro de téléphone"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5>Informations sur l'adresse</h5>
                  <Link
                    to="#"
                    className="btn btn-primary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_address_information"
                  >
                    <i className="ti ti-edit me-2" />
                    Modifier
                  </Link>
                </div>
                <div className="card-body pb-0">
                  <div className="mb-3">
                    <label className="form-label">Adresse</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Entrez l'adresse"
                    />
                  </div>
                  <div className="d-block d-xl-flex">
                    <div className="mb-3 flex-fill me-xl-3 me-0">
                      <label className="form-label">Pays</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le pays"
                      />
                    </div>
                    <div className="mb-3 flex-fill">
                      <label className="form-label">État / Province</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez l'état"
                      />
                    </div>
                  </div>
                  <div className="d-block d-xl-flex">
                    <div className="mb-3 flex-fill me-xl-3 me-0">
                      <label className="form-label">Ville</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ville"
                      />
                    </div>
                    <div className="mb-3 flex-fill">
                      <label className="form-label">Code postal</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le code postal"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5>Mot de passe</h5>
                  <Link
                    to="#"
                    className="btn btn-primary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#change_password"
                  >
                    Changer
                  </Link>
                </div>
                <div className="card-body pb-0">
                  <div className="mb-3 mb-3">
                    <label className="form-label">
                      Mot de passe actuel
                    </label>
                    <div className="pass-group d-flex">
                      <input
                        type={
                          passwordVisibility.currentPassword
                            ? "text"
                            : "password"
                        }
                        className="pass-input form-control"
                      />
                      <span
                        className={`ti toggle-passwords ${
                          passwordVisibility.currentPassword
                            ? "ti-eye"
                            : "ti-eye-off"
                        }`}
                        onClick={() =>
                          togglePasswordVisibility("currentPassword")
                        }
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
{/* /Page Wrapper */}
{/* Edit Profile */}
<div className="modal fade" id="edit_personal_information">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Modifier les informations personnelles</h4>
        <button
          type="button"
          className="btn-close custom-btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i className="ti ti-x" />
        </button>
      </div>
      <form>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Prénom</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez le prénom"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Nom de famille</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez le nom de famille"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Nom d'utilisateur</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez le nom d'utilisateur"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez l'email"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Numéro de téléphone</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez le numéro de téléphone"
                />
              </div>
              <div className="mb-0">
                <label className="form-label">Bio</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez la bio"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Link
            to="#"
            className="btn btn-light me-2"
            data-bs-dismiss="modal"
          >
            Annuler
          </Link>
          <Link
            to="#"
            className="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Sauvegarder les modifications
          </Link>
        </div>
      </form>
    </div>
  </div>
</div>

        
        
        
        
        {/* /Edit Profile */}
        {/* Edit Profile */}
{/* Modifier les Informations d'Adresse */}
<div className="modal fade" id="edit_address_information">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Modifier les Informations d'Adresse</h4>
        <button
          type="button"
          className="btn-close custom-btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i className="ti ti-x" />
        </button>
      </div>
      <form>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Adresse</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez l'adresse"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Pays</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez le pays"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">État/Province</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez l'état/la province"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Ville</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez la ville"
                />
              </div>
              <div className="mb-0">
                <label className="form-label">Code Postal</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez le code postal"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Link
            to="#"
            className="btn btn-light me-2"
            data-bs-dismiss="modal"
          >
            Annuler
          </Link>
          <Link
            to="#"
            className="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Sauvegarder les Modifications
          </Link>
        </div>
      </form>
    </div>
  </div>
</div>
{/* /Modifier les Informations d'Adresse */}

{/* Changer le Mot de Passe */}
<div className="modal fade" id="change_password">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Changer le Mot de Passe</h4>
        <button
          type="button"
          className="btn-close custom-btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i className="ti ti-x" />
        </button>
      </div>
      <form>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Mot de Passe Actuel</label>
                <div className="pass-group d-flex">
                  <input
                    type={
                      passwordVisibility.oldPassword
                        ? "text"
                        : "password"
                    }
                    className="pass-input form-control"
                  />
                  <span
                    className={`ti toggle-passwords ${
                      passwordVisibility.oldPassword
                        ? "ti-eye"
                        : "ti-eye-off"
                    }`}
                    onClick={() =>
                      togglePasswordVisibility("oldPassword")
                    }
                  ></span>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Nouveau Mot de Passe</label>
                <div className="pass-group d-flex">
                  <input
                    type={
                      passwordVisibility.newPassword
                        ? "text"
                        : "password"
                    }
                    className="pass-input form-control"
                  />
                  <span
                    className={`ti toggle-passwords ${
                      passwordVisibility.newPassword
                        ? "ti-eye"
                        : "ti-eye-off"
                    }`}
                    onClick={() =>
                      togglePasswordVisibility("newPassword")
                    }
                  ></span>
                </div>
              </div>
              <div className="mb-0">
                <label className="form-label">Confirmer le Mot de Passe</label>
                <div className="pass-group d-flex">
                  <input
                    type={
                      passwordVisibility.confirmPassword
                        ? "text"
                        : "password"
                    }
                    className="pass-input form-control"
                  />
                  <span
                    className={`ti toggle-passwords ${
                      passwordVisibility.confirmPassword
                        ? "ti-eye"
                        : "ti-eye-off"
                    }`}
                    onClick={() =>
                      togglePasswordVisibility("confirmPassword")
                    }
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Link
            to="#"
            className="btn btn-light me-2"
            data-bs-dismiss="modal"
          >
            Annuler
          </Link>
          <Link
            to="#"
            className="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Sauvegarder les Modifications
          </Link>
        </div>
      </form>
    </div>
  </div>
</div>
{/* /Changer le Mot de Passe */}


        {/* /Change Password */}
      </>
    </div>
  );
};

export default Profile;
