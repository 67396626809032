import ImageWithBasePath from '../../core/common/imageWithBasePath'
import { Link } from 'react-router-dom'
import { all_routes } from '../router/all_routes'
import { feeGroup, feesTypes, leaveType, paymentType } from '../../core/common/selectoption/selectoption'
import { DatePicker } from 'antd'
import dayjs from "dayjs";
import CommonSelect from '../../core/common/commonSelect'

const EtablissementModals = () => {
   const routes = all_routes
   const today = new Date()
   const year = today.getFullYear()
   const month = String(today.getMonth() + 1).padStart(2, '0') // Month is zero-based, so we add 1
   const day = String(today.getDate()).padStart(2, '0')
   const formattedDate = `${month}-${day}-${year}`
   const defaultValue = dayjs(formattedDate);
   const getModalContainer = () => {
    const modalElement = document.getElementById('modal-datepicker');
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  return (
    <>
  {/* Add Fees Collect */}
  <div className="modal fade" id="add_fees_collect">
    <div className="modal-dialog modal-dialog-centered  modal-lg">
    <div className="modal-content">
  <div className="modal-header">
    <div className="d-flex align-items-center">
      <h4 className="modal-title">Collecter les frais</h4>
      <span className="badge badge-sm bg-primary ms-2">AD124556</span>
    </div>
    <button
      type="button"
      className="btn-close custom-btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
    >
      <i className="ti ti-x" />
    </button>
  </div>
  <form>
    <div id='modal-datepicker' className="modal-body">
      <div className="bg-light-300 p-3 pb-0 rounded mb-4">
        <div className="row align-items-center">
          <div className="col-lg-3 col-md-6">
            <div className="d-flex align-items-center mb-3">
              <Link
                to={routes.studentGrid}
                className="avatar avatar-md me-2"
              >
                <ImageWithBasePath src="assets/img/students/student-01.jpg" alt="img" />
              </Link>
              <Link
                to={routes.studentGrid}
                className="d-flex flex-column"
              >
                <span className="text-dark">Janet</span>III, A
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="mb-3">
              <span className="fs-12 mb-1">Total des arriérés</span>
              <p className="text-dark">2000</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="mb-3">
              <span className="fs-12 mb-1">Dernière date</span>
              <p className="text-dark">25 Mai 2024</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="mb-3">
              <span className="badge badge-soft-danger">
                <i className="ti ti-circle-filled me-2" />
                Non payé
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-3">
            <label className="form-label">Groupe de frais</label>
            <CommonSelect
              className="select"
              options={feeGroup}
              defaultValue={feeGroup[0]}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label className="form-label">Type de frais</label>
            <CommonSelect
              className="select"
              options={feesTypes}
              defaultValue={feesTypes[0]}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label className="form-label">Montant</label>
            <input
              type="text"
              className="form-control"
              placeholder="Entrez le montant"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label className="form-label">Date de collecte</label>
            <div className="date-pic">
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                getPopupContainer={getModalContainer}
                defaultValue={defaultValue}
                placeholder="16 Mai 2024"
              />
              <span className="cal-icon">
                <i className="ti ti-calendar" />
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label className="form-label">Type de paiement</label>
            <CommonSelect
              className="select"
              options={paymentType}
              defaultValue={paymentType[0]}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label className="form-label">Numéro de référence du paiement</label>
            <input
              type="text"
              className="form-control"
              placeholder="Entrez le numéro de référence du paiement"
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="modal-satus-toggle d-flex align-items-center justify-content-between mb-3">
            <div className="status-title">
              <h5>Statut</h5>
              <p>Changer le statut en utilisant le bouton bascule</p>
            </div>
            <div className="status-toggle modal-status">
              <input type="checkbox" id="user1" className="check" />
              <label htmlFor="user1" className="checktoggle"> </label>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="mb-0">
            <label className="form-label">Notes</label>
            <textarea
              rows={4}
              className="form-control"
              placeholder="Ajoutez des notes"
              defaultValue={""}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="modal-footer">
      <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
        Annuler
      </Link>
      <Link to="#" className="btn btn-primary" data-bs-dismiss="modal">
        Payer les frais
      </Link>
    </div>
  </form>
</div>

    </div>
  </div>
  {/* Add Fees Collect */}
  {/* Delete Modal */}
  <div className="modal fade" id="delete-modal">
    <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
  <form>
    <div className="modal-body text-center">
      <span className="delete-icon">
        <i className="ti ti-trash-x" />
      </span>
      <h4>Confirmer la suppression</h4>
      <p>
        Vous souhaitez supprimer tous les éléments sélectionnés, cette action est irréversible.
      </p>
      <div className="d-flex justify-content-center">
        <Link
          to="#"
          className="btn btn-light me-3"
          data-bs-dismiss="modal"
        >
          Annuler
        </Link>
        <Link
          to="#"
          className="btn btn-danger"
          data-bs-dismiss="modal"
        >
          Oui, supprimer
        </Link>
      </div>
    </div>
  </form>
</div>

    </div>
  </div>
  {/* /Delete Modal */}

  <>
  {/* Login Details */}
  <div className="modal fade" id="login_detail">
    <div className="modal-dialog modal-dialog-centered  modal-lg">
    <div className="modal-content">
  <div className="modal-header">
    <h4 className="modal-title">Détails de Connexion</h4>
    <button
      type="button"
      className="btn-close custom-btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
    >
      <i className="ti ti-x" />
    </button>
  </div>
  <div className="modal-body">
    <div className="student-detail-info">
      <span className="student-img">
        <ImageWithBasePath src="assets/img/students/student-01.jpg" alt="Img" />
      </span>
      <div className="name-info">
        <h6>
          Janet <span>III, A</span>
        </h6>
      </div>
    </div>
    <div className="table-responsive custom-table no-datatable_length">
      <table className="table datanew">
        <thead className="thead-light">
          <tr>
            <th>Type d'utilisateur</th>
            <th>Nom d'utilisateur</th>
            <th>Mot de passe</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Parent</td>
            <td>parent53</td>
            <td>parent@53</td>
          </tr>
          <tr>
            <td>Étudiant</td>
            <td>student20</td>
            <td>stdt@53</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div className="modal-footer">
    <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
      Annuler
    </Link>
  </div>
</div>

    </div>
  </div>
  {/* /Login Details */}
</>
<>
  {/* Apply Leave */}
  <div className="modal fade" id="apply_leave">
    <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
  <div className="modal-header">
    <h4 className="modal-title">Demander un Congé</h4>
    <button
      type="button"
      className="btn-close custom-btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
    >
      <i className="ti ti-x" />
    </button>
  </div>
  <form>
    <div className="modal-body">
      <div className="row">
        <div className="col-md-12">
          <div className="mb-3">
            <label className="form-label">Date du Congé</label>
            <div className="date-pic">
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                getPopupContainer={getModalContainer}
                defaultValue={defaultValue}
                placeholder="16 Mai 2024"
              />
              <span className="cal-icon">
                <i className="ti ti-calendar" />
              </span>
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">Type de Congé</label>
            <CommonSelect
              className="select"
              options={leaveType}
              defaultValue={leaveType[0]}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Date de Début du Congé</label>
            <div className="date-pic">
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                getPopupContainer={getModalContainer}
                defaultValue={defaultValue}
                placeholder="16 Mai 2024"
              />
              <span className="cal-icon">
                <i className="ti ti-calendar" />
              </span>
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">Date de Fin du Congé</label>
            <div className="date-pic">
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                getPopupContainer={getModalContainer}
                defaultValue={defaultValue}
                placeholder="16 Mai 2024"
              />
              <span className="cal-icon">
                <i className="ti ti-calendar" />
              </span>
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">Type de Congé</label>
            <div className="d-flex align-items-center check-radio-group">
              <label className="custom-radio">
                <input type="radio" name="radio" defaultChecked />
                <span className="checkmark" />
                Journée Complète
              </label>
              <label className="custom-radio">
                <input type="radio" name="radio" />
                <span className="checkmark" />
                Première Partie
              </label>
              <label className="custom-radio">
                <input type="radio" name="radio" />
                <span className="checkmark" />
                Deuxième Partie
              </label>
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">Nombre de Jours</label>
            <input type="text" className="form-control" />
          </div>
          <div className="mb-0">
            <label className="form-label">Raison</label>
            <input type="text" className="form-control" />
          </div>
        </div>
      </div>
    </div>
    <div className="modal-footer">
      <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
        Annuler
      </Link>
      <Link to="#" data-bs-dismiss="modal" className="btn btn-primary">
        Demander un Congé
      </Link>
    </div>
  </form>
</div>

    </div>
  </div>
  {/* /Apply Leave */}
</>

</>

  )
}

export default EtablissementModals