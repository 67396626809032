import React, { useRef } from "react";
import { classes } from "../../../core/data/json/classes";
import Table from "../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../core/common/datePicker";
import {
  activeList,
  classSection,
  classSylabus,
} from "../../../core/common/selectoption/selectoption";
import CommonSelect from "../../../core/common/commonSelect";
import { TableData } from "../../../core/data/interface";
import { Link } from "react-router-dom";
import TooltipOption from "../../../core/common/tooltipOption";
import { all_routes } from "../../router/all_routes";

const Classes = () => {
  const routes = all_routes;
  const isMinistryEtablissement = localStorage.getItem("ministry-etablissement") 
? JSON.parse(localStorage.getItem("ministry-etablissement") as string) 
: null;
  const data = classes;
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };



  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
    },

    {
      title: "Classe",
      dataIndex: "class",
      sorter: (a: TableData, b: TableData) => a.class.length - b.class.length,
    },
    {
      title: "Section",
      dataIndex: "section",
      sorter: (a: TableData, b: TableData) =>
        a.section.length - b.section.length,
    },
    {
      title: "Nombre d'élèves",
      dataIndex: "noOfStudents",
      sorter: (a: TableData, b: TableData) =>
        a.noOfStudents.length - b.noOfStudents.length,
    },
    {
      title: "Nombre de sujets",
      dataIndex: "noOfSubjects",
      sorter: (a: TableData, b: TableData) =>
        a.noOfSubjects.length - b.noOfSubjects.length,
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: (text: string) => (
        <>
          {text === "Active" ? (
            <span className="badge badge-soft-success d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : (
            <span className="badge badge-soft-danger d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <>
        {
          !isMinistryEtablissement &&
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_class"
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Modifier
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                  >
                    <i className="ti ti-trash-x me-2" />
                    Supprimer
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        }
        </>
      ),
    },
  ];

  return (
    <div>
      {/* Wrapper de la page */}
      <div className="page-wrapper">
        <div className="content">
          {/* En-tête de la page */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Liste des Classes</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Classes</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Toutes les Classes
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              {
                !isMinistryEtablissement &&
                <div className="mb-2">
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_class"
                  >
                    <i className="ti ti-square-rounded-plus-filled me-2" />
                    Ajouter une classe
                  </Link>
                </div>
              }
            </div>
          </div>
          {/* /En-tête de la page */}
          {/* Liste des Classes */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Liste des Classes</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filtrer
                  </Link>
                  <div className="dropdown-menu drop-width" ref={dropdownMenuRef}>
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filtrer</h4>
                      </div>
                      <div className="p-3 border-bottom pb-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Classe</label>
                              <CommonSelect
                                className="select"
                                options={classSylabus}
                                defaultValue={classSylabus[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Section</label>
                              <CommonSelect
                                className="select"
                                options={classSection}
                                defaultValue={classSection[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Statut</label>
                              <CommonSelect
                                className="select"
                                options={activeList}
                                defaultValue={activeList[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Réinitialiser
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Appliquer
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Trier de A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascendant
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descendant
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Récemment vu
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Récemment ajouté
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Liste des Classes */}
              <Table columns={columns} dataSource={data} Selection={true} />
              {/* /Liste des Classes */}
            </div>
          </div>
          {/* /Liste des Classes */}
        </div>
      </div>
      {/* /Wrapper de la page */}
      <>
        {/* Ajouter une Classe */}
        <div
          className="modal fade"
          id="add_class"
          aria-labelledby="add_class"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="add_class">
                  Ajouter une Classe
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label className="form-label">Nom de la Classe</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Entrez le nom de la classe"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Section</label>
                    <CommonSelect
                      className="select"
                      options={classSection}
                      defaultValue={classSection[0]}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Nombre d'élèves</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Entrez le nombre d'élèves"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Nombre de sujets</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Entrez le nombre de sujets"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Statut</label>
                    <CommonSelect
                      className="select"
                      options={activeList}
                      defaultValue={activeList[0]}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </button>
                <button type="button" className="btn btn-primary">
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* /Ajouter une Classe */}
        {/* Modifier une Classe */}
        <div
          className="modal fade"
          id="edit_class"
          aria-labelledby="edit_class"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="edit_class">
                  Modifier la Classe
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label className="form-label">Nom de la Classe</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Entrez le nom de la classe"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Section</label>
                    <CommonSelect
                      className="select"
                      options={classSection}
                      defaultValue={classSection[0]}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Nombre d'élèves</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Entrez le nombre d'élèves"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Nombre de sujets</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Entrez le nombre de sujets"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Statut</label>
                    <CommonSelect
                      className="select"
                      options={activeList}
                      defaultValue={activeList[0]}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </button>
                <button type="button" className="btn btn-primary">
                  Sauvegarder
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* /Modifier une Classe */}
        {/* Supprimer une Classe */}
        <div
          className="modal fade"
          id="delete-modal"
          aria-labelledby="delete-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="delete-modal">
                  Supprimer une Classe
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p>
                  Êtes-vous sûr de vouloir supprimer cette classe ? Cette action
                  est irréversible.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </button>
                <button type="button" className="btn btn-danger">
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* /Supprimer une Classe */}
      </>
    </div>
  );
};

export default Classes;
