import React from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import CommonSelect from "../../../core/common/commonSelect";
import {
  customeFields,
  editcustomeFields,
} from "../../../core/common/selectoption/selectoption";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const routes = all_routes;
const CustomFields = () => {
  return (
    <div>
      <div className="page-wrapper">
        <div className="content bg-white">
          <div className="d-md-flex d-block align-items-center justify-content-between border-bottom pb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Paramètres de l'application</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="index">Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Paramètres</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Paramètres de l'application
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <div className="pe-1 mb-2">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Rafraîchir</Tooltip>}
                >
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white btn-icon me-1"
                  >
                    <i className="ti ti-refresh" />
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-2 col-xl-3">
              <div className="pt-3 d-flex flex-column list-group mb-4">
                <Link
                  to={routes.invoiceSettings}
                  className="d-block rounded p-2"
                >
                  Paramètres de facturation
                </Link>
                <Link
                  to={routes.customFields}
                  className="d-block rounded p-2 active"
                >
                  Champs personnalisés
                </Link>
              </div>
            </div>
            <div className="col-xxl-10 col-xl-9">
              <div className="flex-fill border-start ps-3">
                <form>
                  <div className="d-flex align-items-center justify-content-between flex-wrap border-bottom pt-3 mb-3">
                    <div className="mb-3">
                      <h5 className="mb-1">Champs personnalisés</h5>
                      <p>Configuration des champs personnalisés</p>
                    </div>
                    <div className="mb-3">
                      <Link
                        to="#"
                        className="btn btn-outline-light bg-white btn-icon me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#add_custom_field"
                      >
                        <i className="ti ti-plus" />
                      </Link>
                      <button className="btn btn-light me-2" type="button">
                        Annuler
                      </button>
                      <button className="btn btn-primary" type="submit">
                        Enregistrer
                      </button>
                    </div>
                  </div>
                  <div className="d-md-flex d-block">
                    <div className="flex-fill">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between p-3">
                          <h5>Dépense</h5>
                          <div className="d-flex align-items-center">
                            <Link
                              to="#"
                              className="btn btn-outline-light bg-white btn-icon me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#edit_custom_field"
                            >
                              <i className="ti ti-edit" />
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-outline-light bg-white btn-icon"
                              data-bs-toggle="modal"
                              data-bs-target="#delete-modal"
                            >
                              <i className="ti ti-trash" />
                            </Link>
                          </div>
                        </div>
                        <div className="card-body p-3 pb-0">
                          <div className="row">
                            <div className="col-xl-4 pe-xl-0">
                              <div className="mb-3 flex-fill me-xl-3 me-0">
                                <label className="form-label">Étiquette</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="col-xl-4 ps-xl-0 pe-xl-0">
                              <div className="mb-3 flex-fill  me-xl-3 me-0">
                                <label className="form-label">Type</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="col-xl-4 ps-xl-0">
                              <div className="mb-3 flex-fill">
                                <label className="form-label">
                                  Valeur par défaut
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-4 pe-xl-0">
                              <div className="mb-3 flex-fill me-xl-3 me-0">
                                <label className="form-label">
                                  Valeur requise
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="col-xl-4 ps-xl-0">
                              <div className="mb-3 flex-fill">
                                <label className="form-label">Statut</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between p-3">
                          <h5>Transaction</h5>
                          <div className="d-flex align-items-center">
                            <Link
                              to="#"
                              className="btn btn-outline-light bg-white btn-icon me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#edit_custom_field"
                            >
                              <i className="ti ti-edit" />
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-outline-light bg-white btn-icon"
                              data-bs-toggle="modal"
                              data-bs-target="#delete-modal"
                            >
                              <i className="ti ti-trash" />
                            </Link>
                          </div>
                        </div>
                        <div className="card-body p-3 pb-0">
                          <div className="row">
                            <div className="col-xl-4 pe-xl-0">
                              <div className="mb-3 flex-fill me-xl-3 me-0">
                                <label className="form-label">Étiquette</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="col-xl-4 ps-xl-0 pe-xl-0">
                              <div className="mb-3 flex-fill  me-xl-3 me-0">
                                <label className="form-label">Type</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="col-xl-4 ps-xl-0">
                              <div className="mb-3 flex-fill">
                                <label className="form-label">
                                  Valeur par défaut
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-4 pe-xl-0">
                              <div className="mb-3 flex-fill me-xl-3 me-0">
                                <label className="form-label">
                                  Valeur requise
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="col-xl-4 ps-xl-0">
                              <div className="mb-3 flex-fill">
                                <label className="form-label">Statut</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        {/* Ajouter un champ personnalisé */}
        <div className="modal fade" id="add_custom_field">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Ajouter un champ personnalisé</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Fermer"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Choisir un champ pour</label>
                        <CommonSelect
                          className="select"
                          options={customeFields}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Étiquette</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez l'étiquette"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Type</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez le type"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Valeur par défaut</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez la valeur"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Requis</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Statut</h5>
                          <p>Changez le statut avec le bouton</p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    Ajouter le champ
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Ajouter un champ personnalisé */}
        {/* Modifier un champ personnalisé */}
        <div className="modal fade" id="edit_custom_field">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Modifier un champ personnalisé</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Fermer"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Choisir un champ pour</label>
                        <CommonSelect
                          className="select"
                          options={editcustomeFields}
                          defaultValue={editcustomeFields[1]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Étiquette</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez l'étiquette"
                          defaultValue="Texte"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Type</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez le type"
                          defaultValue="Texte"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Valeur par défaut</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez la valeur"
                          defaultValue="Texte"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Requis</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue="Texte"
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Statut</h5>
                          <p>Changez le statut avec le bouton</p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    Enregistrer les modifications
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Modifier un champ personnalisé */}
        {/* Modal de suppression */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirmer la suppression</h4>
                  <p>
                    Vous souhaitez supprimer tous les éléments marqués, cela ne peut pas être annulé une fois supprimé.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Annuler
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Oui, supprimer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Modal de suppression */}
      </>
    </div>
  );
};


export default CustomFields;
