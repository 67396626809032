import React from "react";
import { names } from "../../../core/common/selectoption/selectoption";
import { Select } from "antd";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../router/all_routes";

const GuardianModal = () => {
  const routes = all_routes;

  const getModalContainer = () => {
    const modalElement = document.getElementById("modal-tag");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };

  const getModalContainer2 = () => {
    const modalElement = document.getElementById("modal-tag2");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };

  return (
    <>
      {/* Ajouter un Parent */}
      <div className="modal fade" id="add_guardian">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Ajouter un Gardien</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div id="modal-tag2" className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex align-items-center upload-pic flex-wrap row-gap-3 mb-3">
                      <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                        <i className="ti ti-photo-plus fs-16" />
                      </div>
                      <div className="profile-upload">
                        <div className="profile-uploader d-flex align-items-center">
                          <div className="drag-upload-btn mb-3">
                            Télécharger
                            <input
                              type="file"
                              className="form-control image-sign"
                              multiple
                            />
                          </div>
                          <Link to="#" className="btn btn-primary mb-3">
                            Supprimer
                          </Link>
                        </div>
                        <p>Téléchargez une image de taille 4 Mo, Format JPG, PNG, SVG</p>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Nom</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Numéro de Téléphone</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Adresse Email</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-0">
                      <label className="form-label">Enfant</label>
                      <Select
                        mode="multiple"
                        allowClear
                        className="select"
                        getPopupContainer={getModalContainer2}
                        style={{ width: "100%" }}
                        placeholder="Veuillez sélectionner"
                        defaultValue={[]}
                        options={names}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </Link>
                <Link to="#" data-bs-dismiss="modal" className="btn btn-primary">
                  Ajouter un Gardien
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Ajouter un Parent */}

      {/* Modifier un Parent */}
      <div className="modal fade" id="edit_guardian">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Modifier un Gardien</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div id="modal-tag" className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex align-items-center upload-pic flex-wrap row-gap-3 mb-3">
                      <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                        <i className="ti ti-photo-plus fs-16" />
                      </div>
                      <div className="profile-upload">
                        <div className="profile-uploader d-flex align-items-center">
                          <div className="drag-upload-btn mb-3">
                            Télécharger
                            <input
                              type="file"
                              className="form-control image-sign"
                              multiple
                            />
                          </div>
                          <Link to="#" className="btn btn-primary mb-3">
                            Supprimer
                          </Link>
                        </div>
                        <p>Téléchargez une image de taille 4 Mo, Format JPG, PNG, SVG</p>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Nom"
                        defaultValue="Thomas"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Numéro de Téléphone</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Numéro de Téléphone"
                        defaultValue="+1 65738 58937"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Adresse Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez l'Adresse Email"
                        defaultValue="thomas@example.com"
                      />
                    </div>
                    <div className="mb-0">
                      <label className="form-label">Enfant</label>
                      <Select
                        mode="multiple"
                        allowClear
                        className="select"
                        getPopupContainer={getModalContainer}
                        style={{ width: "100%" }}
                        placeholder="Veuillez sélectionner"
                        defaultValue={["Tim", "Jammy"]}
                        options={names}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </Link>
                <Link to="#" className="btn btn-primary">
                  Sauvegarder les Modifications
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Modifier un Parent */}
      
      {/* Modal de Suppression */}
      <div className="modal fade" id="delete-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-body text-center">
                <span className="delete-icon">
                  <i className="ti ti-trash-x" />
                </span>
                <h4>Confirmer la Suppression</h4>
                <p>
                  Vous souhaitez supprimer tous les éléments marqués, cela ne peut pas être annulé une fois supprimé.
                </p>
                <div className="d-flex justify-content-center">
                  <Link
                    to="#"
                    className="btn btn-light me-3"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </Link>
                  <Link to="#" className="btn btn-danger" data-bs-dismiss="modal">
                    Oui, Supprimer
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Modal de Suppression */}
    </>
  );
};

export default GuardianModal;
