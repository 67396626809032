import React, { useState } from "react";
import CommonSelect from "../../../../core/common/commonSelect";
import {
  bloodGroup,
  Contract,
  gender,
  Hostel,
  Marital,
  PickupPoint,
  roomno,
  route,
  Shift,
  staffDepartment,
  staffrole,
  VehicleNumber,
} from "../../../../core/common/selectoption/selectoption";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { TagsInput } from "react-tag-input-component";

const AddStaff = () => {
  const [owner, setOwner] = useState<string[]>([]);
  const routes = all_routes;
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content content-two">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
  <div className="my-auto mb-2">
    <h3 className="mb-1">Ajouter du Personnel</h3>
    <nav>
      <ol className="breadcrumb mb-0">
        <li className="breadcrumb-item">
          <Link to={routes.adminDashboard}>Tableau de Bord</Link>
        </li>
        <li className="breadcrumb-item">RH</li>
        <li className="breadcrumb-item active" aria-current="page">
          Ajouter du Personnel
        </li>
      </ol>
    </nav>
  </div>
</div>

            {/* /Page Header */}
            <div className="row">
              <div className="col-md-12">
                <form >
                  {/* Informations Personnelles */}
<div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-info-square-rounded fs-16" />
      </span>
      <h4 className="text-dark">Informations Personnelles</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="add-section">
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
              <i className="ti ti-photo-plus fs-16" />
            </div>
            <div className="profile-upload">
              <div className="profile-uploader d-flex align-items-center">
                <div className="drag-upload-btn mb-3">
                  Télécharger
                  <input
                    type="file"
                    className="form-control image-sign"
                    multiple
                  />
                </div>
                <Link to="#" className="btn btn-primary mb-3">
                  Supprimer
                </Link>
              </div>
              <p className="fs-12">
                Taille de l'image 4MB, Format JPG, PNG, SVG
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-cols-xxl-5 row-cols-md-6">
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Prénom</label>
            <input type="text" className="form-control" />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Nom de Famille</label>
            <input type="text" className="form-control" />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Rôle</label>
            <CommonSelect
              className="select"
              options={staffrole}
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Département</label>
            <CommonSelect
              className="select"
              options={staffDepartment}
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Désignation</label>
            <CommonSelect
              className="select"
              options={staffrole}
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Genre</label>
            <CommonSelect
              className="select"
              options={gender}
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Numéro de Contact Principal
            </label>
            <input type="text" className="form-control" />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Adresse Email
            </label>
            <input type="email" className="form-control" />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Groupe Sanguin</label>
            <CommonSelect
              className="select"
              options={bloodGroup}
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              État Civil
            </label>
            <CommonSelect
              className="select"
              options={Marital}
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Nom du Père
            </label>
            <input type="text" className="form-control" />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Nom de la Mère
            </label>
            <input type="text" className="form-control" />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Date de Naissance
            </label>
            <div className="input-icon position-relative">
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                placeholder="Sélectionnez une Date"
              />
              <span className="input-icon-addon">
                <i className="ti ti-calendar" />
              </span>
            </div>
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Date d'Embauche
            </label>
            <div className="input-icon position-relative">
              <span className="input-icon-addon">
                <i className="ti ti-calendar" />
              </span>
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                placeholder="Sélectionnez une Date"
              />
            </div>
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Langues Connues
            </label>
            <TagsInput
              value={owner}
              onChange={setOwner}
            />
          </div>
        </div>
        <div className="col-xxl-4 col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Qualification
            </label>
            <input type="text" className="form-control" />
          </div>
        </div>
        <div className="col-xxl-4 col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Expérience Professionnelle
            </label>
            <input type="text" className="form-control" />
          </div>
        </div>
        <div className="col-xxl-4 col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Remarque</label>
            <input type="text" className="form-control" />
          </div>
        </div>
        <div className="col-xxl-6 col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Adresse</label>
            <input type="text" className="form-control" />
          </div>
        </div>
        <div className="col-xxl-6 col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Adresse Permanente
            </label>
            <input type="text" className="form-control" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Informations Personnelles */}
{/* Paie */}
<div className="card ">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-user-shield fs-16" />
      </span>
      <h4 className="text-dark">Paie</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="add-section">
      <div className="row row-cols-xxl-5 row-cols-md-6">
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Salaire de Base</label>
            <input
              type="text"
              className="form-control"
              placeholder="Ex: 4000"
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Montant des Allocations
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Ex: 1000"
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Indemnité de Déplacement
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Ex: 200"
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Indemnité de Téléphone
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Ex: 100"
            />
          </div>
        </div>
        <div className="col-xxl col-xl-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Autre</label>
            <input
              type="text"
              className="form-control"
              placeholder="Ex: 50"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Paie */}

                  {/* /Transport Information */}
                  {/* Hostel Information */}
                  <div className="card">
  <div className="card-header bg-light d-flex align-items-center justify-content-between">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-building-fortress fs-16" />
      </span>
      <h4 className="text-dark">Informations sur l'auberge</h4>
    </div>
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
      />
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Auberge</label>
          <CommonSelect className="select" options={Hostel} />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Numéro de chambre</label>
          <CommonSelect className="select" options={roomno} />
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Informations sur l'auberge */}
{/* Liens des réseaux sociaux */}
<div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-building fs-16" />
      </span>
      <h4 className="text-dark">Liens des réseaux sociaux</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">URL Facebook</label>
          <input type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">URL Twitter</label>
          <input type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">URL LinkedIn</label>
          <input type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">URL Instagram</label>
          <input type="text" className="form-control" />
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Liens des réseaux sociaux */}
{/* Documents */}
<div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-file fs-16" />
      </span>
      <h4 className="text-dark">Documents</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-lg-6">
        <div className="mb-2">
          <div className="mb-3">
            <label className="form-label">
              Télécharger le CV
            </label>
            <p>
              Taille maximale de l'image : 4MB, Format accepté : PDF
            </p>
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <div className="btn btn-primary drag-upload-btn mb-2 me-2">
              <i className="ti ti-file-upload me-1" />
              Modifier
              <input
                type="file"
                className="form-control image_sign"
                multiple
              />
            </div>
            <p className="mb-2">Resume.pdf</p>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="mb-2">
          <div className="mb-3">
            <label className="form-label">
              Télécharger la lettre d'embauche
            </label>
            <p>
              Taille maximale de l'image : 4MB, Format accepté : PDF
            </p>
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <div className="btn btn-primary drag-upload-btn mb-2">
              <i className="ti ti-file-upload me-1" />
              Télécharger le document
              <input
                type="file"
                className="form-control image_sign"
                multiple
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Documents */}
{/* Mot de passe */}
<div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-file fs-16" />
      </span>
      <h4 className="text-dark">Mot de passe</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Nouveau mot de passe</label>
          <input type="password" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">
            Confirmer le mot de passe
          </label>
          <input type="password" className="form-control" />
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Mot de passe */}
<div className="text-end">
  <button type="button" className="btn btn-light me-3">
    Annuler
  </button>
  <Link to={routes.staff} className="btn btn-primary">
    Ajouter un membre du personnel
  </Link>
</div>

                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </div>
  );
};

export default AddStaff;
