import React, { useRef } from "react";
import { Link } from "react-router-dom";

import Table from "../../../../core/common/dataTable/index";
import { exam } from "../../../../core/data/json/exam";
import {
  examOne,
  examtwo,
  startTime,
  startTimeOne,
} from "../../../../core/common/selectoption/selectoption";
import PredefinedDateRanges from "../../../../core/common/datePicker";
import { TableData } from "../../../../core/data/interface";
import CommonSelect from "../../../../core/common/commonSelect";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
import TooltipOption from "../../../../core/common/tooltipOption";

const Exam = () => {
  const routes = all_routes;

  const data = exam;
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${month}-${day}-${year}`;
  const defaultValue = dayjs(formattedDate);

  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const getModalContainer = () => {
    const modalElement = document.getElementById("modal-datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
      sorter: (a: any, b: any) => a.id.length - b.id.length,
    },
    {
      title: "Nom de l'examen",
      dataIndex: "examName",
      sorter: (a: TableData, b: TableData) =>
        a.examName.length - b.examName.length,
    },
    {
      title: "Date de l'examen",
      dataIndex: "examDate",
      sorter: (a: TableData, b: TableData) =>
        a.examDate.length - b.examDate.length,
    },
    {
      title: "Heure de début",
      dataIndex: "startTime",
      sorter: (a: TableData, b: TableData) =>
        a.startTime.length - b.startTime.length,
    },
    {
      title: "Heure de fin",
      dataIndex: "endTime",
      sorter: (a: TableData, b: TableData) =>
        a.endTime.length - b.endTime.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_exam"
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Modifier
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                  >
                    <i className="ti ti-trash-x me-2" />
                    Supprimer
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  
  return (
    <div>
      <div className="page-wrapper">
      <div className="content">
  {/* En-tête de page */}
  <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
    <div className="my-auto mb-2">
      <h3 className="page-title mb-1">Examen</h3>
      <nav>
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to={routes.adminDashboard}>Tableau de bord</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Académique</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Examen
          </li>
        </ol>
      </nav>
    </div>
    <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
      <TooltipOption />
      <div className="mb-2">
        <Link
          to="#"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#add_exam"
        >
          <i className="ti ti-square-rounded-plus-filled me-2" />
          Ajouter un examen
        </Link>
      </div>
    </div>
  </div>
  {/* /En-tête de page */}
  {/* Liste des examens */}
  <div className="card">
    <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
      <h4 className="mb-3">Liste des examens</h4>
      <div className="d-flex align-items-center flex-wrap">
        <div className="input-icon-start mb-3 me-2 position-relative">
          <PredefinedDateRanges />
        </div>
        <div className="dropdown mb-3 me-2">
          <Link
            to="#"
            className="btn btn-outline-light bg-white dropdown-toggle"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
          >
            <i className="ti ti-filter me-2" />
            Filtrer
          </Link>
          <div
            className="dropdown-menu drop-width"
            ref={dropdownMenuRef}
          >
            <form>
              <div className="d-flex align-items-center border-bottom p-3">
                <h4>Filtrer</h4>
              </div>
              <div className="p-3 border-bottom pb-0">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Nom de l'examen</label>
                      <CommonSelect
                        className="select"
                        options={examtwo}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Date de l'examen</label>
                      <CommonSelect
                        className="select"
                        options={examOne}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 d-flex align-items-center justify-content-end">
                <Link to="#" className="btn btn-light me-3">
                  Réinitialiser
                </Link>
                <Link
                  to="#"
                  className="btn btn-primary"
                  onClick={handleApplyClick}
                >
                  Appliquer
                </Link>
              </div>
            </form>
          </div>
        </div>
        <div className="dropdown mb-3">
          <Link
            to="#"
            className="btn btn-outline-light bg-white dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            <i className="ti ti-sort-ascending-2 me-2" />
            Trier par A-Z
          </Link>
          <ul className="dropdown-menu p-3">
            <li>
              <Link to="#" className="dropdown-item rounded-1 active">
                Ascendant
              </Link>
            </li>
            <li>
              <Link to="#" className="dropdown-item rounded-1">
                Descendant
              </Link>
            </li>
            <li>
              <Link to="#" className="dropdown-item rounded-1">
                Récemment consulté
              </Link>
            </li>
            <li>
              <Link to="#" className="dropdown-item rounded-1">
                Récemment ajouté
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="card-body p-0 py-3">
      {/* Liste des examens */}
      <Table columns={columns} dataSource={data} Selection={true} />
      {/* /Liste des examens */}
    </div>
  </div>
  {/* /Liste des examens */}
</div>

      </div>
      <>
        {/* Add Exam */}
        <div className="modal fade" id="add_exam">
        <div className="modal-dialog modal-dialog-centered">
  <div className="modal-content">
    <div className="modal-header">
      <h4 className="modal-title">Ajouter un examen</h4>
      <button
        type="button"
        className="btn-close custom-btn-close"
        data-bs-dismiss="modal"
        aria-label="Fermer"
      >
        <i className="ti ti-x" />
      </button>
    </div>
    <form>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label">Nom de l'examen</label>
              <input type="text" className="form-control" />
            </div>
            <div className="mb-3">
              <label className="form-label">Date de l'examen</label>
              <div className="date-pic">
                <DatePicker
                  className="form-control datetimepicker"
                  format={{
                    format: "DD-MM-YYYY",
                    type: "mask",
                  }}
                  getPopupContainer={getModalContainer}
                  defaultValue={defaultValue}
                  placeholder="16 mai 2024"
                />
                <span className="cal-icon">
                  <i className="ti ti-calendar" />
                </span>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Heure de début</label>
              <CommonSelect className="select" options={startTime} />
            </div>
            <div className="mb-0">
              <label className="form-label">Heure de fin</label>
              <CommonSelect
                className="select"
                options={startTimeOne}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <Link
          to="#"
          className="btn btn-light me-2"
          data-bs-dismiss="modal"
        >
          Annuler
        </Link>
        <Link className="btn btn-primary" to="#" data-bs-dismiss="modal">
          Ajouter l'examen
        </Link>
      </div>
    </form>
  </div>
</div>

        </div>
        {/* Add Exam */}
        {/* Edit Exam */}
        <div className="modal fade" id="edit_exam">
          <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
  <div className="modal-header">
    <h4 className="modal-title">Modifier l'examen</h4>
    <button
      type="button"
      className="btn-close custom-btn-close"
      data-bs-dismiss="modal"
      aria-label="Fermer"
    >
      <i className="ti ti-x" />
    </button>
  </div>
  <form>
    <div className="modal-body">
      <div className="row">
        <div className="col-md-12">
          <div className="mb-3">
            <label className="form-label">Nom de l'examen</label>
            <input
              type="text"
              className="form-control"
              placeholder="Entrez le nom de l'examen"
              defaultValue="Test de la semaine"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Date de l'examen</label>
            <div className="date-pic">
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                getPopupContainer={getModalContainer}
                defaultValue={defaultValue}
                placeholder="16 mai 2024"
              />
              <span className="cal-icon">
                <i className="ti ti-calendar" />
              </span>
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">Heure de début</label>
            <CommonSelect
              className="select"
              options={startTime}
              defaultValue={startTime[1]}
            />
          </div>
          <div className="mb-0">
            <label className="form-label">Heure de fin</label>
            <CommonSelect
              className="select"
              options={startTimeOne}
              defaultValue={startTimeOne[1]}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="modal-footer">
      <Link
        to="#"
        className="btn btn-light me-2"
        data-bs-dismiss="modal"
      >
        Annuler
      </Link>
      <Link className="btn btn-primary" to="#" data-bs-dismiss="modal">
        Enregistrer les modifications
      </Link>
    </div>
  </form>
</div>

          </div>
          
        </div>
        {/* Edit Exam */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
  <form>
    <div className="modal-body text-center">
      <span className="delete-icon">
        <i className="ti ti-trash-x" />
      </span>
      <h4>Confirmer la suppression</h4>
      <p>
        Vous souhaitez supprimer tous les éléments sélectionnés. Cette action est irréversible une fois effectuée.
      </p>
      <div className="d-flex justify-content-center">
        <Link
          to="#"
          className="btn btn-light me-3"
          data-bs-dismiss="modal"
        >
          Annuler
        </Link>
        <Link to="#" data-bs-dismiss="modal" className="btn btn-danger">
          Oui, supprimer
        </Link>
      </div>
    </div>
  </form>
</div>

          </div>
        </div>
        {/* /Delete Modal */}
      </>
    </div>
  );
};

export default Exam;
