export const scheduleClass = [
	{	
		id: "S148239",
		type: "Class",
		startTime: "09:30 AM",
		endTime: "10:30 AM",
		status: "Active",
		key:1
	},
];
