import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { all_routes } from '../../router/all_routes';
import ImageWithBasePath from '../../../core/common/imageWithBasePath';
import EtablissementModals from '../etablissementModals';
import { Etablissementlist } from '../../../core/data/json/etablissementList';

const EtablissementGrid = ({ type = "", cadre = "" }) => {
  const routes = all_routes;
  // State for filters and search
  const [data, setData] = useState(Etablissementlist);
  const [selectedCadres, setSelectedCadres] = useState<string[]>(cadre ? [cadre] : []);
  const [selectedTypes, setSelectedTypes] = useState<string[]>(type ? [type] : []);
  const [selectedVilles, setSelectedVilles] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const cadreOptions: string[] = ["Privé", "Public", "Exotique"];
  const typeOptions: string[] = ["Ecole", "Université", "Centre"];
  const villeOptions: string[] = Array.from(new Set(Etablissementlist.map((item) => item.city)));

  const handleCheckboxChange = (type: string, value: string) => {
    if (type === "cadre") {
      setSelectedCadres((prev) =>
        prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
      );
    } else if (type === "type") {
      setSelectedTypes((prev) =>
        prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
      );
    } else if (type === "ville") {
      setSelectedVilles((prev) =>
        prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
      );
    }
  };

  useEffect(() => {
    const filteredData = Etablissementlist.filter((item) => {
      const matchesCadres =
        selectedCadres.length === 0 || selectedCadres.includes(item.cadre);
      const matchesTypes =
        selectedTypes.length === 0 || selectedTypes.includes(item.type);
      const matchesVilles =
        selectedVilles.length === 0 || selectedVilles.includes(item.city);

      // Filtrer par recherche
      const matchesSearchQuery =
        searchQuery === "" ||
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.type.toLowerCase().includes(searchQuery.toLowerCase());

      return matchesCadres && matchesTypes && matchesVilles && matchesSearchQuery;
    });

    setData(filteredData);
  }, [selectedCadres, selectedTypes, selectedVilles, searchQuery]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content content-two">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Liste des Etablissements</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.gestionEtablissementsScolaires}>Etablissements scolaires</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Grille des établissements
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <div className="mb-2">
                <Link
                  to={routes.addEtablissement}
                  className="btn btn-primary d-flex align-items-center"
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Ajouter un établissement
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Filter */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Liste des établissements</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filtrer
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                  >
                    <div className="card mb-4">
                      <div className="card-header">
                        <h4>Filtres</h4>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          {/* Filtres Cadres */}
                          <div className="col-lg-4 col-md-6 mb-3">
                            <h5>Cadre</h5>
                            {cadreOptions.map((cadre) => (
                              <div key={cadre}>
                                <input
                                  type="checkbox"
                                  id={`cadre-${cadre}`}
                                  value={cadre}
                                  checked={selectedCadres.includes(cadre)}
                                  onChange={() => handleCheckboxChange("cadre", cadre)}
                                />
                                <label htmlFor={`cadre-${cadre}`}>{cadre}</label>
                              </div>
                            ))}
                          </div>
                          {/* Filtres Types */}
                          <div className="col-lg-4 col-md-6 mb-3">
                            <h5>Type</h5>
                            {typeOptions.map((type) => (
                              <div key={type}>
                                <input
                                  type="checkbox"
                                  id={`type-${type}`}
                                  value={type}
                                  checked={selectedTypes.includes(type)}
                                  onChange={() => handleCheckboxChange("type", type)}
                                />
                                <label htmlFor={`type-${type}`}>{type}</label>
                              </div>
                            ))}
                          </div>
                          {/* Filtres Villes */}
                          <div className="col-lg-4 col-md-6 mb-3">
                            <h5>Ville</h5>
                            {villeOptions.map((ville) => (
                              <div key={ville}>
                                <input
                                  type="checkbox"
                                  id={`ville-${ville}`}
                                  value={ville}
                                  checked={selectedVilles.includes(ville)}
                                  onChange={() => handleCheckboxChange("ville", ville)}
                                />
                                <label htmlFor={`ville-${ville}`}>{ville}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center bg-white border rounded-2 p-1 mb-3 me-2">
                  <Link
                    to={routes.etablissementList}
                    className="btn btn-icon btn-sm me-1 primary-hover"
                  >
                    <i className="ti ti-list-tree" />
                  </Link>
                  <Link
                    to={routes.etablissementGrid}
                    className="active btn btn-icon btn-sm me-1 primary-hover"
                  >
                    <i className="ti ti-grid-dots" />
                  </Link>
                </div>
              </div>
            </div>
            {/* Recherche */}
            <div className="serch-global text-right">
              <input
                type="search"
                className="form-control form-control-sm mb-3 w-auto float-end"
                placeholder="Recherche"
                aria-controls="DataTables_Table_0"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          {/* /Filter */}
          <div className="row">
            {/* Etablissement Grid */}
            {data.map((etablissement) => (
              <div className="col-xxl-3 col-xl-4 col-md-6 d-flex" key={etablissement.key}>
                <div className="card flex-fill">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <Link to={routes.studentDetail} className="link-primary">
                      {etablissement.name}
                    </Link>
                    <div className="d-flex align-items-center">
                      <span className={`badge badge-soft-${etablissement.status === 'Actif' ? 'success' : 'danger'} d-inline-flex align-items-center me-1`}>
                        <i className="ti ti-circle-filled fs-5 me-1" />
                        {etablissement.status}
                      </span>
                      <div className="dropdown">
                        <Link
                          to="#"
                          className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="ti ti-dots-vertical fs-14" />
                        </Link>
                        <ul className="dropdown-menu dropdown-menu-right p-3">
                          <li>
                            <Link
                              className="dropdown-item rounded-1"
                              to={routes.studentDetail}
                            >
                              <i className="ti ti-menu me-2" />
                              Voir l'établissement
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item rounded-1"
                              to={routes.editStudent}
                            >
                              <i className="ti ti-edit me-2" />
                              Éditer
                            </Link>
                          </li>
                          <li>
                          Supprimer
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card-body text-center">
                    <ImageWithBasePath
                      className="rounded-circle"
                      src={etablissement.imgSrc}
                      alt={etablissement.name}
                      width={80}
                      height={80}
                    />
                    <h4 className="fw-bold mb-1 mt-3">{etablissement.name}</h4>
                    <div className="mb-1">{etablissement.type}</div>
                    <div>{etablissement.cadre}</div>
                    <div>{etablissement.city}</div>
                    <div>{etablissement.country}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <EtablissementModals />
    </>
  );
};

export default EtablissementGrid;