import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { all_routes } from "../../../router/all_routes";
import StudentModals from "../studentModals";
import StudentSidebar from "./studentSidebar";
import StudentBreadcrumb from "./studentBreadcrumb";
import Table from "../../../../core/common/dataTable/index";
import { TableData } from "../../../../core/data/interface";
import { leaveData } from "../../../../core/data/json/leaveData";
import { Attendance } from "../../../../core/data/json/attendance";

const StudentLeaves = () => {
  const routes = all_routes;
  const data = leaveData;
  const data2 = Attendance;
  const columns = [
    {
      title: "Type de Congé",
      dataIndex: "leaveType",
      sorter: (a: TableData, b: TableData) =>
        a.leaveType.length - b.leaveType.length,
    },
    {
      title: "Date de Congé",
      dataIndex: "leaveDate",
      sorter: (a: TableData, b: TableData) =>
        a.leaveDate.length - b.leaveDate.length,
    },
    {
      title: "Nombre de Jours",
      dataIndex: "noOfDays",
      sorter: (a: TableData, b: TableData) =>
        parseFloat(a.noOfDays) - parseFloat(b.noOfDays),
    },
    {
      title: "Demandé le",
      dataIndex: "appliedOn",
      sorter: (a: TableData, b: TableData) =>
        a.appliedOn.length - b.appliedOn.length,
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: (text: string) => (
        <>
          {text === "Approved" ? (
            <span className="badge badge-soft-success d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : (
            <span className="badge badge-soft-danger d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.status.length - b.status.length,
    },
  ];
  
  const columns2 = [
    {
      title: "Date | Mois",
      dataIndex: "date",
      sorter: (a: TableData, b: TableData) => a.date.length - b.date.length,
    },
    {
      title: "Jan",
      dataIndex: "jan",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.jan.length - b.jan.length,
    },
    {
      title: "Fév",
      dataIndex: "feb",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.feb.length - b.feb.length,
    },
    {
      title: "Mar",
      dataIndex: "mar",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.mar.length - b.mar.length,
    },
    {
      title: "Avr",
      dataIndex: "apr",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.apr.length - b.apr.length,
    },
    {
      title: "Mai",
      dataIndex: "may",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.may.length - b.may.length,
    },
    {
      title: "Juin",
      dataIndex: "jun",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.jun.length - b.jun.length,
    },
    {
      title: "Juil",
      dataIndex: "jul",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.jul.length - b.jul.length,
    },
    {
      title: "Août",
      dataIndex: "aug",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.aug.length - b.aug.length,
    },
    {
      title: "Sep",
      dataIndex: "sep",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.sep.length - b.sep.length,
    },
    {
      title: "Oct",
      dataIndex: "oct",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.oct.length - b.oct.length,
    },
    {
      title: "Nov",
      dataIndex: "nov",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.nov.length - b.nov.length,
    },
    {
      title: "Déc",
      dataIndex: "dec",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.dec.length - b.dec.length,
    },
  ];
  

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            {/* En-tête de Page */}
            <StudentBreadcrumb />
            {/* /En-tête de Page */}
          </div>
          <div className="row">
            {/* Informations sur l'Étudiant */}
            <StudentSidebar />
            {/* /Informations sur l'Étudiant */}
            <div className="col-xxl-9 col-xl-8">
              <div className="row">
                <div className="col-md-12">
                  {/* Liste */}
                  <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                    <li>
                      <Link to={routes.studentDetail} className="nav-link">
                        <i className="ti ti-school me-2" />
                        Détails de l'Étudiant
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentTimeTable} className="nav-link">
                        <i className="ti ti-table-options me-2" />
                        Emploi du Temps
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentLeaves} className="nav-link active">
                        <i className="ti ti-calendar-due me-2" />
                        Congés &amp; Absences
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentFees} className="nav-link">
                        <i className="ti ti-report-money me-2" />
                        Frais
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentResult} className="nav-link">
                        <i className="ti ti-bookmark-edit me-2" />
                        Examens &amp; Résultats
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentLibrary} className="nav-link">
                        <i className="ti ti-books me-2" />
                        Bibliothèque
                      </Link>
                    </li>
                  </ul>
                  {/* /Liste */}
                  {/* Nav Congés */}
                  <div className="card">
                    <div className="card-body pb-1">
                      <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded-fill">
                        <li className="me-3 mb-3">
                          <Link
                            to="#"
                            className="nav-link active rounded fs-12 fw-semibold"
                            data-bs-toggle="tab"
                            data-bs-target="#leave"
                          >
                            Congés
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="#"
                            className="nav-link rounded fs-12 fw-semibold"
                            data-bs-toggle="tab"
                            data-bs-target="#attendance"
                          >
                            Absences
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* /Nav Congés */}
                  <div className="tab-content">
                    {/* Congés */}
                    <div className="tab-pane fade show active" id="leave">
                      <div className="row gx-3">
                        <div className="col-lg-6 col-xxl-3 d-flex">
                          <div className="card flex-fill">
                            <div className="card-body">
                              <h5 className="mb-2">Congés Médicaux (10)</h5>
                              <div className="d-flex align-items-center flex-wrap">
                                <p className="border-end pe-2 me-2 mb-0">
                                  Utilisés : 5
                                </p>
                                <p className="mb-0">Disponibles : 5</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-xxl-3 d-flex">
                          <div className="card flex-fill">
                            <div className="card-body">
                              <h5 className="mb-2">Congés Annuels (12)</h5>
                              <div className="d-flex align-items-center flex-wrap">
                                <p className="border-end pe-2 me-2 mb-0">
                                  Utilisés : 1
                                </p>
                                <p className="mb-0">Disponibles : 11</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-xxl-3 d-flex">
                          <div className="card flex-fill">
                            <div className="card-body">
                              <h5 className="mb-2">Congés Maternité (10)</h5>
                              <div className="d-flex align-items-center flex-wrap">
                                <p className="border-end pe-2 me-2 mb-0">
                                  Utilisés : 0
                                </p>
                                <p className="mb-0">Disponibles : 10</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-xxl-3 d-flex">
                          <div className="card flex-fill">
                            <div className="card-body">
                              <h5 className="mb-2">Congés Paternité (0)</h5>
                              <div className="d-flex align-items-center flex-wrap">
                                <p className="border-end pe-2 me-2 mb-0">
                                  Utilisés : 0
                                </p>
                                <p className="mb-0">Disponibles : 0</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                          <h4 className="mb-3">Congés</h4>
                          <Link
                            to="#"
                            data-bs-target="#apply_leave"
                            data-bs-toggle="modal"
                            className="btn btn-primary d-inline-flex align-items-center mb-3"
                          >
                            <i className="ti ti-calendar-event me-2" />
                            Demander un Congé
                          </Link>
                        </div>
                        {/* Liste des Congés */}
                        <div className="card-body p-0 py-3">
                          <Table
                            dataSource={data}
                            columns={columns}
                            Selection={false}
                          />
                        </div>
                        {/* /Liste des Congés */}
                      </div>
                    </div>
                    {/* /Congés */}
                    {/* Absences */}
                    <div className="tab-pane fade" id="attendance">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-1">
                          <h4 className="mb-3">Absences</h4>
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="d-flex align-items-center flex-wrap me-3">
                              <p className="text-dark mb-3 me-2">
                                Dernière Mise à Jour : 25 Mai 2024
                              </p>
                              <Link
                                to="#"
                                className="btn btn-primary btn-icon btn-sm rounded-circle d-inline-flex align-items-center justify-content-center p-0 mb-3"
                              >
                                <i className="ti ti-refresh-dot" />
                              </Link>
                            </div>
                            <div className="dropdown mb-3">
                              <Link
                                to="#"
                                className="btn btn-outline-light bg-white dropdown-toggle"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                              >
                                <i className="ti ti-calendar-due me-2" />
                                Année : 2024 / 2025
                              </Link>
                              <ul className="dropdown-menu p-3">
                                <li>
                                  <Link
                                    to="#"
                                    className="dropdown-item rounded-1"
                                  >
                                    Année : 2024 / 2025
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    className="dropdown-item rounded-1"
                                  >
                                    Année : 2023 / 2024
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    className="dropdown-item rounded-1"
                                  >
                                    Année : 2022 / 2023
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="card-body pb-1">
                          <div className="row">
                            {/* Total Présences */}
                            <div className="col-md-6 col-xxl-3 d-flex">
                              <div className="d-flex align-items-center rounded border p-3 mb-3 flex-fill">
                                <span className="avatar avatar-lg bg-primary-transparent rounded me-2 flex-shrink-0 text-primary">
                                  <i className="ti ti-user-check fs-24" />
                                </span>
                                <div className="ms-2">
                                  <p className="mb-1">Présences</p>
                                  <h5>265</h5>
                                </div>
                              </div>
                            </div>
                            {/* /Total Présences */}
                            {/* Total Absences */}
                            <div className="col-md-6 col-xxl-3 d-flex">
                              <div className="d-flex align-items-center rounded border p-3 mb-3 flex-fill">
                                <span className="avatar avatar-lg bg-danger-transparent rounded me-2 flex-shrink-0 text-danger">
                                  <i className="ti ti-user-x fs-24" />
                                </span>
                                <div className="ms-2">
                                  <p className="mb-1">Absences</p>
                                  <h5>10</h5>
                                </div>
                              </div>
                            </div>
                            {/* /Total Absences */}
                            {/* Total Absences Justifiées */}
                            <div className="col-md-6 col-xxl-3 d-flex">
                              <div className="d-flex align-items-center rounded border p-3 mb-3 flex-fill">
                                <span className="avatar avatar-lg bg-warning-transparent rounded me-2 flex-shrink-0 text-warning">
                                  <i className="ti ti-user-question fs-24" />
                                </span>
                                <div className="ms-2">
                                  <p className="mb-1">Absences Justifiées</p>
                                  <h5>6</h5>
                                </div>
                              </div>
                            </div>
                            {/* /Total Absences Justifiées */}
                            {/* Total Absences Non Justifiées */}
                            <div className="col-md-6 col-xxl-3 d-flex">
                              <div className="d-flex align-items-center rounded border p-3 mb-3 flex-fill">
                                <span className="avatar avatar-lg bg-info-transparent rounded me-2 flex-shrink-0 text-info">
                                  <i className="ti ti-user-exclamation fs-24" />
                                </span>
                                <div className="ms-2">
                                  <p className="mb-1">Absences Non Justifiées</p>
                                  <h5>4</h5>
                                </div>
                              </div>
                            </div>
                            {/* /Total Absences Non Justifiées */}
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header pb-0">
                          <h5 className="mb-0">Liste des Absences</h5>
                        </div>
                        <div className="card-body p-0">
                          <Table dataSource={data} columns={columns} />
                        </div>
                      </div>
                    </div>
                    {/* /Absences */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Modal Demander un Congé */}
          <div
            id="apply_leave"
            className="modal fade"
            tabIndex={-1}
            aria-labelledby="apply_leave"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Demander un Congé</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Type de Congé</label>
                          <select className="form-select">
                            <option>Congés Annuels</option>
                            <option>Congés Médicaux</option>
                            <option>Congés Maternité</option>
                            <option>Congés Paternité</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Durée</label>
                          <div className="input-daterange">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Date de Début"
                            />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Date de Fin"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Motif</label>
                          <textarea className="form-control" rows={3} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Télécharger des Justificatifs</label>
                          <input type="file" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </button>
                  <button type="button" className="btn btn-primary">
                    Soumettre
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /Modal Demander un Congé */}
        </div>
      </div>

      {/* /Page Wrapper */}
      <StudentModals />
    </>
  );
};

export default StudentLeaves;
