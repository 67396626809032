import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { all_routes } from "../../../router/all_routes";
import Table from "../../../../core/common/dataTable/index";
import { TableData } from "../../../../core/data/interface";
import { leaveData } from "../../../../core/data/json/leaveData";
import { Attendance } from "../../../../core/data/json/attendance";
import TeacherSidebar from "./teacherSidebar";
import TeacherBreadcrumb from "./teacherBreadcrumb";
import TeacherModal from "../teacherModal";

const TeacherLeave = () => {
  const routes = all_routes;
  const data = leaveData;
  const data2 = Attendance;
  const columns = [
    {
      title: "Type de Congé",
      dataIndex: "leaveType",
      sorter: (a: TableData, b: TableData) =>
        a.leaveType.length - b.leaveType.length,
    },
    {
      title: "Date de Congé",
      dataIndex: "leaveDate",
      sorter: (a: TableData, b: TableData) =>
        a.leaveDate.length - b.leaveDate.length,
    },
    {
      title: "Nombre de Jours",
      dataIndex: "noOfDays",
      sorter: (a: TableData, b: TableData) =>
        parseFloat(a.noOfDays) - parseFloat(b.noOfDays),
    },
    {
      title: "Demandé Le",
      dataIndex: "appliedOn",
      sorter: (a: TableData, b: TableData) =>
        a.appliedOn.length - b.appliedOn.length,
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: (text: string) => (
        <>
          {text === "Approved" ? (
            <span className="badge badge-soft-success d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : (
            <span className="badge badge-soft-danger d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.status.length - b.status.length,
    },
  ];
  
const columns2 = [
    {
      title: "Date | Mois",
      dataIndex: "date",
      sorter: (a: TableData, b: TableData) => a.date.length - b.date.length,
    },
    {
      title: "Jan",
      dataIndex: "jan",
      render: (text: string) => (
        <>
          {text === "1" ? (
            <span className="attendance-range bg-success"></span>
          ) : text === "2" ? (
            <span className="attendance-range bg-pending"></span>
          ) : text === "3" ? (
            <span className="attendance-range bg-dark"></span>
          ) : text === "4" ? (
            <span className="attendance-range bg-danger"></span>
          ) : (
            <span className="attendance-range bg-info"></span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.jan.length - b.jan.length,
    },
    {
        title: "Fév",
        dataIndex: "feb",
        render: (text: string) => (
          <>
            {text === "1" ? (
              <span className="attendance-range bg-success"></span>
            ) : text === "2" ? (
              <span className="attendance-range bg-pending"></span>
            ) : text === "3" ? (
              <span className="attendance-range bg-dark"></span>
            ) : text === "4" ? (
              <span className="attendance-range bg-danger"></span>
            ) : (
              <span className="attendance-range bg-info"></span>
            )}
          </>
        ),
        sorter: (a: TableData, b: TableData) => a.feb.length - b.feb.length,
      },
      {
        title: "Mar",
        dataIndex: "mar",
        render: (text: string) => (
          <>
            {text === "1" ? (
              <span className="attendance-range bg-success"></span>
            ) : text === "2" ? (
              <span className="attendance-range bg-pending"></span>
            ) : text === "3" ? (
              <span className="attendance-range bg-dark"></span>
            ) : text === "4" ? (
              <span className="attendance-range bg-danger"></span>
            ) : (
              <span className="attendance-range bg-info"></span>
            )}
          </>
        ),
        sorter: (a: TableData, b: TableData) => a.mar.length - b.mar.length,
      },
      {
        title: "Avr",
        dataIndex: "apr",
        render: (text: string) => (
          <>
            {text === "1" ? (
              <span className="attendance-range bg-success"></span>
            ) : text === "2" ? (
              <span className="attendance-range bg-pending"></span>
            ) : text === "3" ? (
              <span className="attendance-range bg-dark"></span>
            ) : text === "4" ? (
              <span className="attendance-range bg-danger"></span>
            ) : (
              <span className="attendance-range bg-info"></span>
            )}
          </>
        ),
        sorter: (a: TableData, b: TableData) => a.apr.length - b.apr.length,
      },
      {
        title: "Mai",
        dataIndex: "may",
        render: (text: string) => (
          <>
            {text === "1" ? (
              <span className="attendance-range bg-success"></span>
            ) : text === "2" ? (
              <span className="attendance-range bg-pending"></span>
            ) : text === "3" ? (
              <span className="attendance-range bg-dark"></span>
            ) : text === "4" ? (
              <span className="attendance-range bg-danger"></span>
            ) : (
              <span className="attendance-range bg-info"></span>
            )}
          </>
        ),
        sorter: (a: TableData, b: TableData) => a.may.length - b.may.length,
      },
      {
        title: "Juin",
        dataIndex: "jun",
        render: (text: string) => (
          <>
            {text === "1" ? (
              <span className="attendance-range bg-success"></span>
            ) : text === "2" ? (
              <span className="attendance-range bg-pending"></span>
            ) : text === "3" ? (
              <span className="attendance-range bg-dark"></span>
            ) : text === "4" ? (
              <span className="attendance-range bg-danger"></span>
            ) : (
              <span className="attendance-range bg-info"></span>
            )}
          </>
        ),
        sorter: (a: TableData, b: TableData) => a.jun.length - b.jun.length,
      },
      {
        title: "Juil",
        dataIndex: "jul",
        render: (text: string) => (
          <>
            {text === "1" ? (
              <span className="attendance-range bg-success"></span>
            ) : text === "2" ? (
              <span className="attendance-range bg-pending"></span>
            ) : text === "3" ? (
              <span className="attendance-range bg-dark"></span>
            ) : text === "4" ? (
              <span className="attendance-range bg-danger"></span>
            ) : (
              <span className="attendance-range bg-info"></span>
            )}
          </>
        ),
        sorter: (a: TableData, b: TableData) => a.jul.length - b.jul.length,
      },
      {
        title: "Août",
        dataIndex: "aug",
        render: (text: string) => (
          <>
            {text === "1" ? (
              <span className="attendance-range bg-success"></span>
            ) : text === "2" ? (
              <span className="attendance-range bg-pending"></span>
            ) : text === "3" ? (
              <span className="attendance-range bg-dark"></span>
            ) : text === "4" ? (
              <span className="attendance-range bg-danger"></span>
            ) : (
              <span className="attendance-range bg-info"></span>
            )}
          </>
        ),
        sorter: (a: TableData, b: TableData) => a.aug.length - b.aug.length,
      },
      {
        title: "Sep",
        dataIndex: "sep",
        render: (text: string) => (
          <>
            {text === "1" ? (
              <span className="attendance-range bg-success"></span>
            ) : text === "2" ? (
              <span className="attendance-range bg-pending"></span>
            ) : text === "3" ? (
              <span className="attendance-range bg-dark"></span>
            ) : text === "4" ? (
              <span className="attendance-range bg-danger"></span>
            ) : (
              <span className="attendance-range bg-info"></span>
            )}
          </>
        ),
        sorter: (a: TableData, b: TableData) => a.sep.length - b.sep.length,
      },
      {
        title: "Oct",
        dataIndex: "oct",
        render: (text: string) => (
          <>
            {text === "1" ? (
              <span className="attendance-range bg-success"></span>
            ) : text === "2" ? (
              <span className="attendance-range bg-pending"></span>
            ) : text === "3" ? (
              <span className="attendance-range bg-dark"></span>
            ) : text === "4" ? (
              <span className="attendance-range bg-danger"></span>
            ) : (
              <span className="attendance-range bg-info"></span>
            )}
          </>
        ),
        sorter: (a: TableData, b: TableData) => a.oct.length - b.oct.length,
      },
      {
        title: "Nov",
        dataIndex: "nov",
        render: (text: string) => (
          <>
            {text === "1" ? (
              <span className="attendance-range bg-success"></span>
            ) : text === "2" ? (
              <span className="attendance-range bg-pending"></span>
            ) : text === "3" ? (
              <span className="attendance-range bg-dark"></span>
            ) : text === "4" ? (
              <span className="attendance-range bg-danger"></span>
            ) : (
              <span className="attendance-range bg-info"></span>
            )}
          </>
        ),
        sorter: (a: TableData, b: TableData) => a.nov.length - b.nov.length,
      },
      {
        title: "Déc",
        dataIndex: "dec",
        render: (text: string) => (
          <>
            {text === "1" ? (
              <span className="attendance-range bg-success"></span>
            ) : text === "2" ? (
              <span className="attendance-range bg-pending"></span>
            ) : text === "3" ? (
              <span className="attendance-range bg-dark"></span>
            ) : text === "4" ? (
              <span className="attendance-range bg-danger"></span>
            ) : (
              <span className="attendance-range bg-info"></span>
            )}
          </>
        ),
        sorter: (a: TableData, b: TableData) => a.dec.length - b.dec.length,
      },
    ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
      <div className="content">
  <div className="row">
    {/* En-tête de la page */}
    <TeacherBreadcrumb />
    {/* /En-tête de la page */}
  </div>
  <div className="row">
    {/* Informations sur l'enseignant */}
    <TeacherSidebar />
    {/* /Informations sur l'enseignant */}
    <div className="col-xxl-9 col-xl-8">
      <div className="row">
        <div className="col-md-12">
          {/* Liste */}
          <ul className="nav nav-tabs nav-tabs-bottom mb-4">
            <li>
              <Link to={routes.teacherDetails} className="nav-link ">
                <i className="ti ti-school me-2" />
                Détails de l'enseignant
              </Link>
            </li>
            <li>
              <Link to={routes.teachersRoutine} className="nav-link ">
                <i className="ti ti-table-options me-2" />
                Emploi du temps
              </Link>
            </li>
            <li>
              <Link to={routes.teacherLeaves} className="nav-link active">
                <i className="ti ti-calendar-due me-2" />
                Congés et Présences
              </Link>
            </li>
            <li>
              <Link to={routes.teacherSalary} className="nav-link">
                <i className="ti ti-report-money me-2" />
                Salaire
              </Link>
            </li>
            <li>
              <Link to={routes.teacherLibrary} className="nav-link">
                <i className="ti ti-bookmark-edit me-2" />
                Bibliothèque
              </Link>
            </li>
          </ul>
          {/* /Liste */}
          {/* Onglets Congés*/}
          <div className="card">
            <div className="card-body pb-1">
              <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded-fill">
                <li className="me-3 mb-3">
                  <Link
                    to="#"
                    className="nav-link active rounded fs-12 fw-semibold"
                    data-bs-toggle="tab"
                    data-bs-target="#leave"
                  >
                    Congés
                  </Link>
                </li>
                <li className="mb-3">
                  <Link
                    to="#"
                    className="nav-link rounded fs-12 fw-semibold"
                    data-bs-toggle="tab"
                    data-bs-target="#attendance"
                  >
                    Présences
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* /Onglets Congés*/}
          <div className="tab-content">
            {/* Congés */}
            <div className="tab-pane fade show active" id="leave">
              <div className="row gx-3">
                <div className="col-lg-6 col-xxl-3 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <h5 className="mb-2">Congés Médicaux (10)</h5>
                      <div className="d-flex align-items-center flex-wrap">
                        <p className="border-end pe-2 me-2 mb-0">
                          Utilisés : 5
                        </p>
                        <p className="mb-0">Disponibles : 5</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xxl-3 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <h5 className="mb-2">Congés Exceptionnels (12)</h5>
                      <div className="d-flex align-items-center flex-wrap">
                        <p className="border-end pe-2 me-2 mb-0">
                          Utilisés : 1
                        </p>
                        <p className="mb-0">Disponibles : 11</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xxl-3 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <h5 className="mb-2">Congés de Maternité (10)</h5>
                      <div className="d-flex align-items-center flex-wrap">
                        <p className="border-end pe-2 me-2 mb-0">
                          Utilisés : 0
                        </p>
                        <p className="mb-0">Disponibles : 10</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xxl-3 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <h5 className="mb-2">Congés de Paternité (0)</h5>
                      <div className="d-flex align-items-center flex-wrap">
                        <p className="border-end pe-2 me-2 mb-0">
                          Utilisés : 0
                        </p>
                        <p className="mb-0">Disponibles : 0</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                  <h4 className="mb-3">Congés</h4>
                  <Link
                    to="#"
                    data-bs-target="#apply_leave"
                    data-bs-toggle="modal"
                    className="btn btn-primary d-inline-flex align-items-center mb-3"
                  >
                    <i className="ti ti-calendar-event me-2" />
                    Demander un Congé
                  </Link>
                </div>
                {/* Liste des Congés */}
                <div className="card-body p-0 py-3">
                  <Table
                    dataSource={data}
                    columns={columns}
                    Selection={false}
                  />
                </div>
                {/* /Liste des Congés */}
              </div>
            </div>
            {/* /Congés */}
            {/* Présences */}
            <div className="tab-pane fade" id="attendance">
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-1">
                  <h4 className="mb-3">Présences</h4>
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="d-flex align-items-center flex-wrap me-3">
                      <p className="text-dark mb-3 me-2">
                        Dernière mise à jour : 25 Mai 2024
                      </p>
                      <Link
                        to="#"
                        className="btn btn-primary btn-icon btn-sm rounded-circle d-inline-flex align-items-center justify-content-center p-0 mb-3"
                      >
                        <i className="ti ti-refresh-dot" />
                      </Link>
                    </div>
                    <div className="dropdown mb-3">
                      <Link
                        to="#"
                        className="btn btn-outline-light bg-white dropdown-toggle"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                      >
                        <i className="ti ti-calendar-due me-2" />
                        Année : 2024 / 2025
                      </Link>
                      <ul className="dropdown-menu p-3">
                        <li>
                          <Link
                            to="#"
                            className="dropdown-item rounded-1"
                          >
                            Année : 2024 / 2025
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className="dropdown-item rounded-1"
                          >
                            Année : 2023 / 2024
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className="dropdown-item rounded-1"
                          >
                            Année : 2022 / 2023
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-body pb-1">
                  <div className="row">
                    {/* Total Présent */}
                    <div className="col-md-6 col-xxl-3 d-flex">
                      <div className="d-flex align-items-center rounded border p-3 mb-3 flex-fill">
                        <span className="avatar avatar-lg bg-primary-transparent rounded me-2 flex-shrink-0 text-primary">
                          <i className="ti ti-user-check fs-24" />
                        </span>
                        <div className="ms-2">
                          <p className="mb-1">Présent</p>
                          <h5>265</h5>
                        </div>
                      </div>
                    </div>
                    {/* /Total Présent */}
                    {/* Total Absent */}
                    <div className="col-md-6 col-xxl-3 d-flex">
                      <div className="d-flex align-items-center rounded border p-3 mb-3 flex-fill">
                        <span className="avatar avatar-lg bg-danger-transparent rounded me-2 flex-shrink-0 text-danger">
                          <i className="ti ti-user-check fs-24" />
                        </span>
                        <div className="ms-2">
                          <p className="mb-1">Absent</p>
                          <h5>10</h5>
                        </div>
                      </div>
                    </div>
                    {/* /Total Absent */}
                    {/* Total Retard */}
                    <div className="col-md-6 col-xxl-3 d-flex">
                      <div className="d-flex align-items-center rounded border p-3 mb-3 flex-fill">
                        <span className="avatar avatar-lg bg-warning-transparent rounded me-2 flex-shrink-0 text-warning">
                          <i className="ti ti-clock fs-24" />
                        </span>
                        <div className="ms-2">
                          <p className="mb-1">Retard</p>
                          <h5>8</h5>
                        </div>
                      </div>
                    </div>
                    {/* /Total Retard */}
                    {/* Total Jour d'Absence */}
                    <div className="col-md-6 col-xxl-3 d-flex">
                      <div className="d-flex align-items-center rounded border p-3 mb-3 flex-fill">
                        <span className="avatar avatar-lg bg-secondary-transparent rounded me-2 flex-shrink-0 text-secondary">
                          <i className="ti ti-calendar fs-24" />
                        </span>
                        <div className="ms-2">
                          <p className="mb-1">Jours d'Absence</p>
                          <h5>15</h5>
                        </div>
                      </div>
                    </div>
                    {/* /Total Jour d'Absence */}
                  </div>
                  <Table
                    dataSource={data2}
                    columns={data}
                    Selection={false}
                  />
                </div>
              </div>
            </div>
            {/* /Présences */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Modal de demande de Congé */}
  <div
    className="modal fade"
    id="apply_leave"
    tabIndex={-1}
    aria-labelledby="applyLeaveLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="applyLeaveLabel">
            Demander un Congé
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Type de Congé</label>
                  <select className="form-select">
                    <option>Congés Médicaux</option>
                    <option>Congés Exceptionnels</option>
                    <option>Congés de Maternité</option>
                    <option>Congés de Paternité</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Date de Début</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="dd/mm/yyyy"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Date de Fin</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="dd/mm/yyyy"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Motif</label>
                  <textarea
                    className="form-control"
                    rows={3}
                    placeholder="Veuillez fournir une explication détaillée."
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Annuler
          </button>
          <button type="button" className="btn btn-primary">
            Soumettre la Demande
          </button>
        </div>
      </div>
    </div>
  </div>
  {/* /Modal de demande de Congé */}
</div>

      </div>
      {/* /Page Wrapper */}
      <TeacherModal />
    </>
  );
};

export default TeacherLeave;
