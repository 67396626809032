import React from "react";
import { all_routes } from "../../router/all_routes";
import { Link, useNavigate } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import Logo from "../../../core/common/Logo";

const EmailVerification = () => {
  const routes = all_routes;
  const navigation = useNavigate();

  const navigationPath = () => {
    navigation(routes.login);
  };
  return (
    <div className="container-fuild">
      <div className="login-wrapper w-100 overflow-hidden position-relative flex-wrap d-block vh-100">
        <div className="row">
          <div className="col-lg-6">
            <div className="login-background position-relative d-lg-flex align-items-center justify-content-center d-lg-block d-none flex-wrap vh-100 overflowy-auto">
              <div>
              <Logo />
              </div>
              <div className="authen-overlay-item w-100 p-4">
                <h4 className="text-white mb-3">Quoi de neuf sur Preskool !!!</h4>
                <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>Devoirs pour les vacances d'été</h6>
                    <p className="mb-0 text-truncate">
                      L'école sera fermée du 20 avril au...
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
                <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>Démarrage des admissions pour la nouvelle session académique (2024-25)</h6>
                    <p className="mb-0 text-truncate">
                      Un trimestre académique est une partie de l'année académique...
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
                <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>Calendrier des examens finaux pour Nursery à Sr.Kg</h6>
                    <p className="mb-0 text-truncate">
                      Chers parents, Comme les examens finaux pour la session 2024-25...
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
                <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>Fonction de la journée annuelle</h6>
                    <p className="mb-0 text-truncate">
                      Les fonctions annuelles offrent une plateforme aux étudiants pour...
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
                <div className="d-flex align-items-center flex-row mb-0 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>Devoirs pour les vacances d'été</h6>
                    <p className="mb-0 text-truncate">
                      L'école sera fermée du 20 avril au 15 juin pour les vacances d'été...
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row justify-content-center align-items-center vh-100 overflow-auto flex-wrap ">
              <div className="col-md-9 mx-auto p-4">
                <form>
                  <div>
                    <div className=" mx-auto mb-5 text-center">
                      <ImageWithBasePath
                        src="assets/img/authentication/authentication-logo.svg"
                        className="img-fluid"
                        alt="Logo"
                      />
                    </div>
                    <div className="card">
                      <div className="card-body p-4">
                        <div className=" mb-3">
                          <h2 className="mb-2 text-center">
                            Vérifiez votre Email
                          </h2>
                          <p className="mb-0 text-center">
                            Nous avons envoyé un lien à votre email ter4@example.com. Veuillez suivre le lien pour continuer
                          </p>
                        </div>
                        <div className="text-center mb-3">
                          <h6 className="fw-normal text-dark mb-0">
                            Vous n’avez pas reçu l’email ?
                            <Link to="#" className="hover-a ">
                              {" "}
                              Renvoyer le Lien
                            </Link>
                          </h6>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary w-100"
                          onClick={navigationPath}
                        >
                          Passer Maintenant
                        </button>
                      </div>
                    </div>
                    <div className="mt-5 text-center">
                      <p className="mb-0 ">Copyright © 2024 - Preskool</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default EmailVerification;
