import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";

const StudentSidebar = () => {
  return (
    <div className="col-xxl-3 col-xl-4 theiaStickySidebar">
      <div className="stickybar pb-4">
        <div className="card border-white">
          <div className="card-header">
            <div className="d-flex align-items-center flex-wrap row-gap-3">
              <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                <ImageWithBasePath
                  src="assets/img/students/student-01.jpg"
                  className="img-fluid"
                  alt="img"
                />
              </div>
              <div className="overflow-hidden">
                <span className="badge badge-soft-success d-inline-flex align-items-center mb-1">
                  <i className="ti ti-circle-filled fs-5 me-1" />
                  Actif
                </span>
                <h5 className="mb-1 text-truncate">Janet Daniel</h5>
                <p className="text-primary">AD1256589</p>
              </div>
            </div>
          </div>
          {/* Informations de base */}
          <div className="card-body">
            <h5 className="mb-3">Informations de base</h5>
            <dl className="row mb-0">
              <dt className="col-6 fw-medium text-dark mb-3">Numéro de rouleau</dt>
              <dd className="col-6 mb-3">35013</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Sexe</dt>
              <dd className="col-6 mb-3">Féminin</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Date de naissance</dt>
              <dd className="col-6 mb-3">25 Jan 2008</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Groupe sanguin</dt>
              <dd className="col-6 mb-3">O +ve</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Couleur</dt>
              <dd className="col-6 mb-3">Rouge</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Religion</dt>
              <dd className="col-6 mb-3">Christianisme</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Caste</dt>
              <dd className="col-6 mb-3">Catholique</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Catégorie</dt>
              <dd className="col-6 mb-3">OBC</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Langue maternelle</dt>
              <dd className="col-6 mb-3">Anglais</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Langue</dt>
              <dd className="col-6 mb-3">
                <span className="badge badge-light text-dark me-2">
                  Anglais
                </span>
                <span className="badge badge-light text-dark">Espagnol</span>
              </dd>
            </dl>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#add_fees_collect"
              className="btn btn-primary btn-sm w-100"
            >
              Ajouter des frais
            </Link>
          </div>
          {/* /Informations de base */}
        </div>
        {/* Informations de contact principales */}
        <div className="card border-white">
          <div className="card-body">
            <h5 className="mb-3">Informations de contact principales</h5>
            <div className="d-flex align-items-center mb-3">
              <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                <i className="ti ti-phone" />
              </span>
              <div>
                <span className="text-dark fw-medium mb-1">Numéro de téléphone</span>
                <p>+1 46548 84498</p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                <i className="ti ti-mail" />
              </span>
              <div>
                <span className="text-dark fw-medium mb-1">Adresse e-mail</span>
                <p>jan@example.com</p>
              </div>
            </div>
          </div>
        </div>
        {/* /Informations de contact principales */}
        {/* Informations sur les frères et sœurs */}
        <div className="card border-white">
          <div className="card-body">
            <h5 className="mb-3">Informations sur les frères et sœurs</h5>
            <div className="d-flex align-items-center bg-light-300 rounded p-3 mb-3">
              <span className="avatar avatar-lg">
                <ImageWithBasePath
                  src="assets/img/students/student-06.jpg"
                  className="img-fluid rounded"
                  alt="img"
                />
              </span>
              <div className="ms-2">
                <h5 className="fs-14">Ralph Claudia</h5>
                <p>III, B</p>
              </div>
            </div>
            <div className="d-flex align-items-center bg-light-300 rounded p-3">
              <span className="avatar avatar-lg">
                <ImageWithBasePath
                  src="assets/img/students/student-07.jpg"
                  className="img-fluid rounded"
                  alt="img"
                />
              </span>
              <div className="ms-2">
                <h5 className="fs-14">Julie Scott</h5>
                <p>V, A</p>
              </div>
            </div>
          </div>
        </div>
        {/* /Informations sur les frères et sœurs */}
        {/* Informations sur le transport */}
        <div className="card border-white mb-0">
          <div className="card-body pb-1">
            <ul className="nav nav-tabs nav-tabs-bottom mb-3">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to="#hostel"
                  data-bs-toggle="tab"
                >
                  Résidence
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#transport" data-bs-toggle="tab">
                  Transport
                </Link>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="hostel">
                <div className="d-flex align-items-center mb-3">
                  <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                    <i className="ti ti-building-fortress fs-16" />
                  </span>
                  <div>
                    <h6 className="fs-14 mb-1">HI-Hostel, Étage</h6>
                    <p className="text-primary">Numéro de chambre : 25</p>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="transport">
                <div className="d-flex align-items-center mb-3">
                  <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                    <i className="ti ti-bus fs-16" />
                  </span>
                  <div>
                    <span className="fs-12 mb-1">Itinéraire</span>
                    <p className="text-dark">Newyork</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <span className="fs-12 mb-1">Numéro de bus</span>
                      <p className="text-dark">AM 54548</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <span className="fs-12 mb-1">Point de ramassage</span>
                      <p className="text-dark">Cincinatti</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Informations sur le transport */}
      </div>
    </div>
  );
};

export default StudentSidebar;