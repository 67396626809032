import React, { useEffect, useState } from "react";
import { modules } from "../utils/modules";
import { Link } from "react-router-dom";
import { Etablissement } from "../core/data/interface";

const MainDashboard: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [etablissement, setEtablissement] = useState<Etablissement>();

  const filteredModules = modules.filter((module) =>
    module.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    const record = localStorage.getItem("etablissement")
    if(record){
      setEtablissement(JSON.parse(record))
    }
  }, [])


  return (
    <div className="dashboard-container">
      <section className="banner text-center">
        {
          etablissement ?
          <div className="banner-content">
            <h1 className="text-success"><i><q>Ministère de l'enseignement technique et professionnel</q></i></h1>
            <h2>Bienvenue à l'École { etablissement.name }</h2>
            <p>
              Nous sommes { etablissement.name }
            </p>
            <p>Type : { etablissement.type }</p>
            <p>Cadre : { etablissement.cadre }</p>
            <p>Ville : { etablissement.city }</p>
            <p>Country : { etablissement.country }</p>
          </div>
          :
          <div className="banner-content">
          <h2>Bienvenue à l'École Les beaux Arts</h2>
          <p>
            Nous formons les leaders de demain avec une éducation de qualité, des
            infrastructures modernes et un encadrement de premier choix.
          </p>
        </div>
        }
      </section>

      <div className="search-bar mb-4 d-flex justify-content-center">
        <div className="col-md-8 position-relative">
          <input
            type="text"
            className="form-control"
            placeholder="Rechercher un module..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ paddingLeft: '40px', fontSize: '1.25rem', width: 270, height: 50 }}
          />
          <i
            className="fa fa-search position-absolute"
            style={{ top: '50%', left: '15px', transform: 'translateY(-50%)', fontSize: '1rem', color: '#6c757d' }}
          />
        </div>
      </div>

      <div className="row justify-content-center" style={{ width: "100%" }}>
        {filteredModules.map((module, index) => (
          <div
            className="col-xl-3 col-lg-4 col-md-6 mb-4 d-flex justify-content-center"
            key={index}
          >
            <Link to={module.link} onClick={() => {
                localStorage.setItem("module", module.link)
                localStorage.setItem("isMinistry", JSON.stringify(false))
              }}>
              <div
                className="card shadow-effect d-flex flex-column align-items-center text-center card-link"
                style={{ minHeight: "200px" }}
              >
                <div className="card-body d-flex flex-column align-items-center justify-content-center">
                  <div className="cals-icon">
                    <i
                      className={`fa ${module.icon}`}
                      style={{ color: module.color, fontSize: "2.5rem" }}
                    />
                  </div>
                  <p className="mb-0 mt-3 fs-20 fw-semibold">{module.title}</p>
                </div>
                <div
                  className="card-footer text-muted module-description"
                  style={{ fontSize: "0.875rem", minHeight: "50px" }}
                >
                  {module.description}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainDashboard;
