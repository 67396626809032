import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { feeGroup, feesTypes, paymentType } from '../../../core/common/selectoption/selectoption'
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
import {
  AdmissionNo,
  Hostel,
  PickupPoint,
  VehicleNumber,
  academicYear,
  allClass,
  allSection,
  bloodGroup,
  cast,
  gender,
  house,
  mothertongue,
  names,
  religion,
  rollno,
  roomNO,
  route,
  status,
} from "../../../../core/common/selectoption/selectoption";
import { TagsInput } from "react-tag-input-component";
import CommonSelect from "../../../../core/common/commonSelect";
import { useLocation } from "react-router-dom";

const AddStudent = () => {
  const routes = all_routes;

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [owner, setOwner] = useState<string[]>(['English','Spanish']);
  const [owner1, setOwner1] = useState<string[]>([]);
  const [owner2, setOwner2] = useState<string[]>([]);
  const [defaultDate, setDefaultDate] = useState<dayjs.Dayjs | null>(null);
  const [newContents, setNewContents] = useState<number[]>([0]);
  const location = useLocation();
  const addNewContent = () => {
    setNewContents([...newContents, newContents.length]);
  };
  const removeContent = (index:any) => {
    setNewContents(newContents.filter((_, i) => i !== index));
  };
  useEffect(() => {
    if (location.pathname === routes.editStudent ) {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
      const day = String(today.getDate()).padStart(2, "0");
      const formattedDate = `${month}-${day}-${year}`;
      const defaultValue = dayjs(formattedDate);
      setIsEdit(true)
      setOwner(["English"])
      setOwner1(["Medecine Name"])
      setOwner2(["Allergy","Skin Allergy"])
      setDefaultDate(defaultValue)
      console.log(formattedDate,11);
      
    }else {
      setIsEdit(false)
      setDefaultDate(null)
    }
  }, [location.pathname])
  
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content content-two">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                  <h3 className="mb-1">{isEdit ? 'Modifier' : 'Ajouter'} un élève</h3>
                  <nav>
                      <ol className="breadcrumb mb-0">
                          <li className="breadcrumb-item">
                              <Link to={routes.adminDashboard}>Tableau de bord</Link>
                          </li>
                          <li className="breadcrumb-item">
                              <Link to={routes.studentList}>Élèves</Link>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">
                              {isEdit ? 'Modifier' : 'Ajouter'} un élève
                          </li>
                      </ol>
                  </nav>
              </div>
          </div>

          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <form>
                {/* Personal Information */}
                <div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-info-square-rounded fs-16" />
      </span>
      <h4 className="text-dark">Informations Personnelles</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-12">
        <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
          <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
            <i className="ti ti-photo-plus fs-16" />
          </div>
          <div className="profile-upload">
            <div className="profile-uploader d-flex align-items-center">
              <div className="drag-upload-btn mb-3">
                Télécharger
                <input
                  type="file"
                  className="form-control image-sign"
                  multiple
                />
              </div>
              <Link to="#" className="btn btn-primary mb-3">
                Supprimer
              </Link>
            </div>
            <p className="fs-12">
              Taille maximale de l'image 4 Mo, Format JPG, PNG, SVG
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row row-cols-xxl-5 row-cols-md-6">
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Année Académique</label>
          <CommonSelect
            className="select"
            options={academicYear}
            defaultValue={isEdit ? academicYear[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Numéro d'Admission</label>
          <input type="text" className="form-control" defaultValue={isEdit ? 'AD9892434' : undefined} />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Date d'Admission</label>
          <div className="input-icon position-relative">
            {isEdit ? (
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                value={defaultDate}
                placeholder="Sélectionner la Date"
              />
            ) : (
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                defaultValue=""
                placeholder="Sélectionner la Date"
              />
            )}
            <span className="input-icon-addon">
              <i className="ti ti-calendar" />
            </span>
          </div>
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Numéro de Roulé</label>
          <input type="text" className="form-control" defaultValue={isEdit ? '35013' : undefined} />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Statut</label>
          <CommonSelect
            className="select"
            options={status}
            defaultValue={isEdit ? status[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Prénom</label>
          <input type="text" className="form-control" defaultValue={isEdit ? 'Ralph' : undefined}/>
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Nom de Famille</label>
          <input type="text" className="form-control" defaultValue={isEdit ? 'Claudia' : undefined}/>
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Classe</label>
          <CommonSelect
            className="select"
            options={allClass}
            defaultValue={isEdit ? allClass[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Section</label>
          <CommonSelect
            className="select"
            options={allSection}
            defaultValue={isEdit ? allSection[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Genre</label>
          <CommonSelect
            className="select"
            options={gender}
            defaultValue={isEdit ? gender[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Date de Naissance</label>
          <div className="input-icon position-relative">
            {isEdit ? (
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                value={defaultDate}
                placeholder="Sélectionner la Date"
              />
            ) : (
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                defaultValue=""
                placeholder="Sélectionner la Date"
              />
            )}
            <span className="input-icon-addon">
              <i className="ti ti-calendar" />
            </span>
          </div>
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Groupe Sanguin</label>
          <CommonSelect
            className="select"
            options={bloodGroup}
            defaultValue={isEdit ? bloodGroup[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Maison</label>
          <CommonSelect
            className="select"
            options={house}
            defaultValue={isEdit ? house[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Religion</label>
          <CommonSelect
            className="select"
            options={religion}
            defaultValue={isEdit ? religion[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Catégorie</label>
          <CommonSelect
            className="select"
            options={cast}
            defaultValue={isEdit ? cast[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">
            Numéro de Contact Principal
          </label>
          <input type="text" className="form-control" defaultValue={isEdit ? '+1 46548 84498' : undefined}/>
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Adresse Email</label>
          <input type="email" className="form-control" defaultValue={isEdit ? 'jan@example.com' : undefined}/>
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Adresse</label>
          <textarea className="form-control" rows={4} defaultValue={isEdit ? '85 Rue de Leningrad' : undefined}/>
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Dépenses</label>
          <input type="number" className="form-control" defaultValue={isEdit ? '12000' : undefined} />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Notes</label>
          <textarea className="form-control" rows={4} defaultValue={isEdit ? 'Rapport de la visite du médecin.' : undefined}/>
        </div>
      </div>
    </div>
    <div className="text-end mt-3">
      <button type="submit" className="btn btn-primary">Enregistrer</button>
    </div>
  </div>
</div>

                {/* /Personal Information */}
                {/* Parents & Guardian Information */}
                <div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-user-shield fs-16" />
      </span>
      <h4 className="text-dark">
        Informations sur les Parents et les Tuteurs
      </h4>
    </div>
  </div>
  <div className="card-body pb-0">
    <div className="border-bottom mb-3">
      <h5 className="mb-3">Informations sur le Père</h5>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
              <i className="ti ti-photo-plus fs-16" />
            </div>
            <div className="profile-upload">
              <div className="profile-uploader d-flex align-items-center">
                <div className="drag-upload-btn mb-3">
                  Télécharger
                  <input
                    type="file"
                    className="form-control image-sign"
                    multiple
                  />
                </div>
                <Link to="#" className="btn btn-primary mb-3">
                  Supprimer
                </Link>
              </div>
              <p className="fs-12">
                Téléchargez une image de taille 4 Mo, Formats JPG, PNG, SVG
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Nom du Père</label>
            <input type="text" className="form-control" defaultValue={isEdit ? 'Jerald Vicinius' : undefined} />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Email</label>
            <input type="text" className="form-control" defaultValue={isEdit ? 'jera@example.com' : undefined} />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Numéro de Téléphone</label>
            <input type="text" className="form-control" defaultValue={isEdit ? '+1 45545 46464' : undefined} />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Profession du Père
            </label>
            <input type="text" className="form-control" defaultValue={isEdit ? 'Mécanicien' : undefined} />
          </div>
        </div>
      </div>
    </div>
    <div className="border-bottom mb-3">
      <h5 className="mb-3">Informations sur la Mère</h5>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
              <i className="ti ti-photo-plus fs-16" />
            </div>
            <div className="profile-upload">
              <div className="profile-uploader d-flex align-items-center">
                <div className="drag-upload-btn mb-3">
                  Télécharger
                  <input
                    type="file"
                    className="form-control image-sign"
                    multiple
                  />
                </div>
                <Link to="#" className="btn btn-primary mb-3">
                  Supprimer
                </Link>
              </div>
              <p className="fs-12">
                Téléchargez une image de taille 4 Mo, Formats JPG, PNG, SVG
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Nom de la Mère</label>
            <input type="text" className="form-control" defaultValue={isEdit ? 'Roberta Webber' : undefined} />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Email</label>
            <input type="text" className="form-control" defaultValue={isEdit ? 'robe@example.com' : undefined} />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Numéro de Téléphone</label>
            <input type="text" className="form-control" defaultValue={isEdit ? '+1 46499 24357' : undefined} />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Profession de la Mère
            </label>
            <input type="text" className="form-control" defaultValue={isEdit ? 'Ménagère' : undefined} />
          </div>
        </div>
      </div>
    </div>
    <div>
      <h5 className="mb-3">Détails du Tuteur</h5>
      <div className="row">
        <div className="col-md-12">
          <div className="mb-2">
            <div className="d-flex align-items-center flex-wrap">
              <label className="form-label text-dark fw-normal me-2">
                Si le Tuteur est
              </label>
              <div className="form-check me-3 mb-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="guardian"
                  id="parents"
                  defaultChecked
                />
                <label
                  className="form-check-label"
                  htmlFor="parents"
                >
                  Parents
                </label>
              </div>
              <div className="form-check me-3 mb-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="guardian"
                  id="guardian"
                />
                <label
                  className="form-check-label"
                  htmlFor="guardian"
                >
                  Tuteur
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="guardian"
                  id="other"
                />
                <label
                  className="form-check-label"
                  htmlFor="other"
                >
                  Autre
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
              <i className="ti ti-photo-plus fs-16" />
            </div>
            <div className="profile-upload">
              <div className="profile-uploader d-flex align-items-center">
                <div className="drag-upload-btn mb-3">
                  Télécharger
                  <input
                    type="file"
                    className="form-control image-sign"
                    multiple
                  />
                </div>
                <Link to="#" className="btn btn-primary mb-3">
                  Supprimer
                </Link>
              </div>
              <p className="fs-12">
                Téléchargez une image de taille 4 Mo, Formats JPG, PNG, SVG
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Nom du Tuteur</label>
            <input type="text" className="form-control" defaultValue={isEdit ? 'Jerald Vicinius' : undefined} />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">
              Relation avec l’Élève
            </label>
            <input type="text" className="form-control" defaultValue={isEdit ? 'Oncle' : undefined} />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Numéro de Téléphone</label>
            <input type="text" className="form-control" defaultValue={isEdit ? '+1 46499 24357' : undefined} />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Adresse</label>
            <input type="text" className="form-control" defaultValue={isEdit ? '456 Avenue Exemple, NY' : undefined} />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

                {/* /Parents & Guardian Information */}
                {/* Sibilings */}
                <div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-users fs-16" />
      </span>
      <h4 className="text-dark">Frères et Sœurs</h4>
    </div>
  </div>
  <div className="card-body">
    <div className="addsibling-info">
      <div className="row">
        <div className="col-md-12">
          <div className="mb-2">
            <label className="form-label">Informations sur les frères et sœurs</label>
            <div className="d-flex align-items-center flex-wrap">
              <label className="form-label text-dark fw-normal me-2">
                Le frère ou la sœur étudie-t-il/elle dans la même école ?
              </label>
              <div className="form-check me-3 mb-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="sibling"
                  id="yes"
                  defaultChecked
                />
                <label className="form-check-label" htmlFor="yes">
                  Oui
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="sibling"
                  id="no"
                />
                <label className="form-check-label" htmlFor="no">
                  Non
                </label>
              </div>
            </div>
          </div>
        </div>
        {newContents.map((_, index) => (
          <div key={index} className="col-lg-12">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="mb-3">
                  <label className="form-label">Nom</label>
                  <CommonSelect
                    className="select"
                    options={names}
                    defaultValue={isEdit ? names[0] : undefined}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="mb-3">
                  <label className="form-label">Numéro d'inscription</label>
                  <CommonSelect
                    className="select"
                    options={rollno}
                    defaultValue={isEdit ? rollno[0] : undefined}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="mb-3">
                  <label className="form-label">Numéro d'admission</label>
                  <CommonSelect
                    className="select"
                    options={AdmissionNo}
                    defaultValue={isEdit ? AdmissionNo[0] : undefined}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="mb-3">
                  <div className="d-flex align-items-center">
                    <div className="w-100">
                      <label className="form-label">Classe</label>
                      <CommonSelect
                        className="select"
                        options={allClass}
                        defaultValue={isEdit ? allClass[0] : undefined}
                      />
                    </div>
                    {newContents.length > 1 && (
                      <div>
                        <label className="form-label">&nbsp;</label>
                        <Link
                          to="#"
                          className="trash-icon ms-3"
                          onClick={() => removeContent(index)}
                        >
                          <i className="ti ti-trash-x" />
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className="border-top pt-3">
      <Link
        to="#"
        onClick={addNewContent}
        className="add-sibling btn btn-primary d-inline-flex align-items-center"
      >
        <i className="ti ti-circle-plus me-2" />
        Ajouter Nouveau
      </Link>
    </div>
  </div>

                </div>
                {/* /Sibilings */}
                {/* Address */}
                <div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-map fs-16" />
      </span>
      <h4 className="text-dark">Adresse</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Adresse Actuelle</label>
          <input type="text" className="form-control" defaultValue={isEdit? '3495 Red Hawk Road, Buffalo Lake, MN 55314': undefined}/>
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">
            Adresse Permanente
          </label>
          <input type="text" className="form-control" defaultValue={isEdit? '3495 Red Hawk Road, Buffalo Lake, MN 55314': undefined}/>
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Adresse */}
{/* Informations de Transport */}
<div className="card">
  <div className="card-header bg-light d-flex align-items-center justify-content-between">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-bus-stop fs-16" />
      </span>
      <h4 className="text-dark">Informations de Transport</h4>
    </div>
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
      />
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Itinéraire</label>
          <CommonSelect
            className="select"
            options={route}
            defaultValue={isEdit?route[0]:undefined}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Numéro du Véhicule</label>
          <CommonSelect
            className="select"
            options={VehicleNumber}
            defaultValue={isEdit?VehicleNumber[0]:undefined}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Point de Ramassage</label>
          <CommonSelect
            className="select"
            options={PickupPoint}
            defaultValue={isEdit?PickupPoint[0]:undefined}
          />
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Informations de Transport */}
{/* Informations sur l'Auberge */}
<div className="card">
  <div className="card-header bg-light d-flex align-items-center justify-content-between">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-building-fortress fs-16" />
      </span>
      <h4 className="text-dark">Informations sur l'Auberge</h4>
    </div>
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
      />
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Auberge</label>
          <CommonSelect
            className="select"
            options={Hostel}
            defaultValue={isEdit?Hostel[0]:undefined}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Numéro de Chambre</label>
          <CommonSelect
            className="select"
            options={roomNO}
            defaultValue={isEdit?roomNO[0]:undefined}
          />
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Informations sur l'Auberge */}
{/* Documents */}
<div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-file fs-16" />
      </span>
      <h4 className="text-dark">Documents</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-lg-6">
        <div className="mb-2">
          <div className="mb-3">
            <label className="form-label mb-1">
              Condition Médicale
            </label>
            <p>Téléchargez une image de taille 4 Mo, Format accepté PDF</p>
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <div className="btn btn-primary drag-upload-btn mb-2 me-2">
              <i className="ti ti-file-upload me-1" />
              Changer
              <input
                type="file"
                className="form-control image_sign"
                multiple
              />
            </div>
            {isEdit? <p className="mb-2">BirthCertificate.pdf</p> : <></>}
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="mb-2">
          <div className="mb-3">
            <label className="form-label mb-1">
              Télécharger Certificat de Transfert
            </label>
            <p>Téléchargez une image de taille 4 Mo, Format accepté PDF</p>
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <div className="btn btn-primary drag-upload-btn mb-2">
              <i className="ti ti-file-upload me-1" />
              Télécharger Document
              <input
                type="file"
                className="form-control image_sign"
                multiple
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Documents */}
{/* Antécédents Médicaux */}
<div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-medical-cross fs-16" />
      </span>
      <h4 className="text-dark">Antécédents Médicaux</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-12">
        <div className="mb-2">
          <label className="form-label">
            Condition Médicale
          </label>
          <div className="d-flex align-items-center flex-wrap">
            <label className="form-label text-dark fw-normal me-2">
              État de Santé de l'Élève
            </label>
            <div className="form-check me-3 mb-2">
              <input
                className="form-check-input"
                type="radio"
                name="condition"
                id="good"
                defaultChecked
              />
              <label
                className="form-check-label"
                htmlFor="good"
              >
                Bon
              </label>
            </div>
            <div className="form-check me-3 mb-2">
              <input
                className="form-check-input"
                type="radio"
                name="condition"
                id="bad"
              />
              <label className="form-check-label" htmlFor="bad">
                Mauvais
              </label>
            </div>
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="radio"
                name="condition"
                id="others"
              />
              <label
                className="form-check-label"
                htmlFor="others"
              >
                Autres
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Nom du Médecin</label>
        <input type="text" className="form-control" />
      </div>
      <div className="mb-3">
        <label className="form-label">Numéro de Téléphone du Médecin</label>
        <input type="text" className="form-control" />
      </div>
    </div>
  </div>
</div>
{/* /Antécédents Médicaux */}
{/* /Profile */}

                {/* /Medical History */}
                {/* Previous School details */}
                <div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-building fs-16" />
      </span>
      <h4 className="text-dark">Détails de l'École Précédente</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Nom de l'École</label>
          <input type="text" className="form-control" defaultValue={isEdit ? 'Oxford Matriculation, USA' : undefined} />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Adresse</label>
          <input type="text" className="form-control" defaultValue={isEdit ? '1852 Barnes Avenue, Cincinnati, OH 45202' : undefined} />
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Détails de l'École Précédente */}
{/* Autres Détails */}
<div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-building-bank fs-16" />
      </span>
      <h4 className="text-dark">Autres Détails</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-5">
        <div className="mb-3">
          <label className="form-label">Nom de la Banque</label>
          <input type="text" className="form-control" defaultValue={isEdit ? 'Bank of America' : undefined} />
        </div>
      </div>
      <div className="col-md-2">
        <div className="mb-3">
          <label className="form-label">Agence</label>
          <input type="text" className="form-control" defaultValue={isEdit ? 'Cincinnati' : undefined} />
        </div>
      </div>
      <div className="col-md-5">
        <div className="mb-3">
          <label className="form-label">Numéro IFSC</label>
          <input type="text" className="form-control" defaultValue={isEdit ? 'BOA83209832' : undefined} />
        </div>
      </div>
      <div className="col-md-12">
        <div className="mb-3">
          <label className="form-label">Autres Informations</label>
          <textarea
            className="form-control"
            rows={3}
            defaultValue={""}
          />
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Autres Détails */}
<div className="text-end">
  <button type="button" className="btn btn-light me-3">
    Annuler
  </button>
  <Link to={routes.studentList} className="btn btn-primary">
    Ajouter un Élève
  </Link>
</div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default AddStudent;
