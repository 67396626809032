import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../../core/common/imageWithBasePath'
import { all_routes } from '../../router/all_routes'
import { allClass, cast, feeGroup, feesTypes, gender } from '../../../core/common/selectoption/selectoption'
import CommonSelect from '../../../core/common/commonSelect'
import { Modal } from "react-bootstrap";

const AssignModal = ({addModal,setAddModal,editModal,setEditModal}:any) => {
    const routes = all_routes
    // const [show, setShow] = useState(false);
    const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
    const dropdownMenuRef2 = useRef<HTMLDivElement | null>(null);
    const handleApplyClick = () => {
      if (dropdownMenuRef.current) {
        dropdownMenuRef.current.classList.remove("show");
      }
      if (dropdownMenuRef2.current) {
        dropdownMenuRef2.current.classList.remove("show");
      }
    };
    const handleClose = () => {
      setAddModal(false);
      setEditModal(false);
    };
  return (
    <>
    <>
  {/* Add Fees Assign */}
  <Modal show={addModal} onHide={handleClose} centered size="xl">
  <div className="modal-header">
    <h4 className="modal-title">Assigner de nouveaux frais</h4>
    <button
      type="button"
      className="btn-close custom-btn-close"
      onClick={() => setAddModal(false)}
      aria-label="Close"
    >
      <i className="ti ti-x" />
    </button>
  </div>
  <div className="modal-body">
    {/* Table Filter */}
    <div className="table-filter-head">
      <div className="filter-head-left">
        <h5>Critères de recherche</h5>
        <div className="dropdown mb-3 me-2">
          <Link
            to="#"
            className="btn btn-outline-light bg-white dropdown-toggle"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
          >
            <i className="ti ti-filter me-2" />
            Filtrer
          </Link>
          <div className="dropdown-menu drop-width" ref={dropdownMenuRef}>
            <form>
              <div className="d-flex align-items-center border-bottom p-3">
                <h4>Filtrer</h4>
              </div>
              <div className="p-3 pb-0 border-bottom">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Groupe de frais</label>
                      <CommonSelect
                        className="select"
                        options={feeGroup}
                        defaultValue={feeGroup[0]}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Type de frais</label>
                      <CommonSelect
                        className="select"
                        options={feesTypes}
                        defaultValue={feesTypes[0]}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Classe</label>
                      <CommonSelect
                        className="select"
                        options={allClass}
                        defaultValue={allClass[0]}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Genre</label>
                      <CommonSelect
                        className="select"
                        options={gender}
                        defaultValue={gender[0]}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Catégorie d'étudiants</label>
                      <CommonSelect
                        className="select"
                        options={cast}
                        defaultValue={cast[0]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 d-flex align-items-center justify-content-end">
                <Link to="#" className="btn btn-light me-3">
                  Réinitialiser
                </Link>
                <Link to="#" className="btn btn-primary" onClick={handleApplyClick}>
                  Appliquer
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Table Filter */}
    </div>
    {/* /Filter Section */}
    <div className="modal-card-table">
      <div className="modal-table-head">
        <h4>Liste des types de frais</h4>
      </div>
      <div className="table-responsive custom-table no-datatable_length">
        <table className="table datanew">
          <thead className="thead-light">
            <tr>
              <th className="no-sort"></th>
              <th className="no-sort">Type de frais</th>
              <th className="no-sort">Type de frais</th>
              <th className="no-sort">Montant</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>Frais d'inscription</td>
              <td>Frais d'inscription</td>
              <td>5000</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>Avr-Mar</td>
              <td>Avr-Mar</td>
              <td>666</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>Frais de bus</td>
              <td>Frais de bus</td>
              <td>400</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>1ère tranche de frais</td>
              <td>1ère tranche de frais</td>
              <td>2645</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>2ème tranche de frais</td>
              <td>2ème tranche de frais</td>
              <td>7898</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>3ème tranche de frais</td>
              <td>3ème tranche de frais</td>
              <td>4785</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className="modal-card-table">
      <div className="modal-table-head">
        <h4>Détails des étudiants</h4>
      </div>
      <div className="table-responsive custom-table no-datatable_length">
        <table className="table datanew">
          <thead className="thead-light">
            <tr>
              <th className="no-sort">
                <label className="checkboxs">
                  <input type="checkbox" id="select-all2" />
                  <span className="checkmarks" />
                </label>
              </th>
              <th className="no-sort">Numéro d'inscription</th>
              <th className="no-sort">Étudiant</th>
              <th className="no-sort">Classe</th>
              <th className="no-sort">Section</th>
              <th className="no-sort">Genre</th>
              <th className="no-sort">Catégorie d'étudiant</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>
                <Link to="#" className="text-primary">
                  AD9892430
                </Link>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <Link
                    to={routes.studentDetail}
                    className="avatar avatar-md"
                  >
                    <ImageWithBasePath
                      src="assets/img/students/student-01.jpg"
                      className="img-fluid rounded-circle"
                      alt="img"
                    />
                  </Link>
                  <div className="ms-2">
                    <p className="text-dark mb-0">
                      <Link to={routes.studentDetail}>Janet</Link>
                    </p>
                  </div>
                </div>
              </td>
              <td>II</td>
              <td>B</td>
              <td>Femme</td>
              <td>MBC</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>
                <Link to="#" className="text-primary">
                  AD9892429
                </Link>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <Link
                    to={routes.studentDetail}
                    className="avatar avatar-md"
                  >
                    <ImageWithBasePath
                      src="assets/img/students/student-06.jpg"
                      className="img-fluid rounded-circle"
                      alt="img"
                    />
                  </Link>
                  <div className="ms-2">
                    <p className="text-dark mb-0">
                      <Link to={routes.studentDetail}>Gottfried</Link>
                    </p>
                  </div>
                </div>
              </td>
              <td>III</td>
              <td>B</td>
              <td>Homme</td>
              <td>FMD</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>
                <Link to="#" className="text-primary">
                  AD9892428
                </Link>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <Link
                    to={routes.studentDetail}
                    className="avatar avatar-md"
                  >
                    <ImageWithBasePath
                      src="assets/img/students/student-09.jpg"
                      className="img-fluid rounded-circle"
                      alt="img"
                    />
                  </Link>
                  <div className="ms-2">
                    <p className="text-dark mb-0">
                      <Link to={routes.studentDetail}>Aldrich</Link>
                    </p>
                  </div>
                </div>
              </td>
              <td>II</td>
              <td>A</td>
              <td>Homme</td>
              <td>FEM</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>
                <Link to="#" className="text-primary">
                  AD9892427
                </Link>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <Link
                    to={routes.studentDetail}
                    className="avatar avatar-md"
                  >
                    <ImageWithBasePath
                      src="assets/img/students/student-11.jpg"
                      className="img-fluid rounded-circle"
                      alt="img"
                    />
                  </Link>
                  <div className="ms-2">
                    <p className="text-dark mb-0">
                      <Link to={routes.studentDetail}>Marcus</Link>
                    </p>
                  </div>
                </div>
              </td>
              <td>IV</td>
              <td>B</td>
              <td>Homme</td>
              <td>MBC</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-light"
        onClick={handleClose}
      >
        Annuler
      </button>
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleClose}
      >
        Assigner
      </button>
    </div>
  </div>
</Modal>

  {/* <div className="modal fade" id="add_fees_assign">
    <div className="modal-dialog modal-dialog-centered  modal-xl">
      <div className="modal-content">
        
      </div>
    </div>
  </div> */}
  {/* Add Fees Assign */}
  {/* Edit Fees Assign */}
  <Modal show={editModal} onHide={handleClose} centered size="xl">
  <div className="modal-header">
    <h4 className="modal-title">Modifier les Frais</h4>
    <button
      type="button"
      className="btn-close custom-btn-close"
      onClick={handleClose}
      aria-label="Close"
    >
      <i className="ti ti-x" />
    </button>
  </div>
  <div className="modal-body">
    {/* Table Filter */}
    <div className="table-filter-head">
      <div className="filter-head-left">
        <h5>Critères de Recherche</h5>
        <div className="dropdown mb-3 me-2">
          <Link
            to="#"
            className="btn btn-outline-light bg-white dropdown-toggle"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
          >
            <i className="ti ti-filter me-2" />
            Filtrer
          </Link>
          <div className="dropdown-menu drop-width" ref={dropdownMenuRef2}>
            <form>
              <div className="d-flex align-items-center border-bottom p-3">
                <h4>Filtrer</h4>
              </div>
              <div className="p-3 pb-0 border-bottom">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Groupe de Frais</label>
                      <CommonSelect
                        className="select"
                        options={feeGroup}
                        defaultValue={feeGroup[0]}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Type de Frais</label>
                      <CommonSelect
                        className="select"
                        options={feesTypes}
                        defaultValue={feesTypes[0]}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Classe</label>
                      <CommonSelect
                        className="select"
                        options={allClass}
                        defaultValue={allClass[0]}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Genre</label>
                      <CommonSelect
                        className="select"
                        options={gender}
                        defaultValue={gender[0]}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">
                        Catégorie d'Élève
                      </label>
                      <CommonSelect
                        className="select"
                        options={cast}
                        defaultValue={cast[0]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 d-flex align-items-center justify-content-end">
                <Link to="#" className="btn btn-light me-3">
                  Réinitialiser
                </Link>
                <Link to="#" className="btn btn-primary" onClick={handleApplyClick}>
                  Appliquer
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Table Filter */}
    </div>
    {/* /Filter Section */}
    <div className="modal-card-table">
      <div className="modal-table-head">
        <h4>Liste des Types de Frais</h4>
      </div>
      <div className="table-responsive custom-table no-datatable_length">
        <table className="table datanew">
          <thead className="thead-light">
            <tr>
              <th className="no-sort"></th>
              <th className="no-sort">Type de Frais</th>
              <th className="no-sort">Type de Frais</th>
              <th className="no-sort">Montant</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>Frais d'Admission</td>
              <td>Frais d'Admission</td>
              <td>5000</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>Avr-Mar</td>
              <td>Avr-Mar</td>
              <td>666</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>Frais de Bus</td>
              <td>Frais de Bus</td>
              <td>400</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" defaultChecked />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>1ère Échéance</td>
              <td>1ère Échéance</td>
              <td>2645</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" defaultChecked />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>2ème Échéance</td>
              <td>2ème Échéance</td>
              <td>7898</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>3ème Échéance</td>
              <td>3ème Échéance</td>
              <td>4785</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className="modal-card-table">
      <div className="modal-table-head">
        <h4>Détails des Élèves</h4>
      </div>
      <div className="table-responsive custom-table no-datatable_length">
        <table className="table datanew">
          <thead className="thead-light">
            <tr>
              <th className="no-sort">
                <label className="checkboxs">
                  <input type="checkbox" id="select-all3" />
                  <span className="checkmarks" />
                </label>
              </th>
              <th className="no-sort">Numéro d'Admission</th>
              <th className="no-sort">Élève</th>
              <th className="no-sort">Classe</th>
              <th className="no-sort">Section</th>
              <th className="no-sort">Genre</th>
              <th className="no-sort">Catégorie d'Élève</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>
                <Link to="#" className="text-primary">
                  AD9892430
                </Link>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <Link
                    to={routes.studentDetail}
                    className="avatar avatar-md"
                  >
                    <ImageWithBasePath
                      src="assets/img/students/student-01.jpg"
                      className="img-fluid rounded-circle"
                      alt="img"
                    />
                  </Link>
                  <div className="ms-2">
                    <p className="text-dark mb-0">
                      <Link to={routes.studentDetail}>Janet</Link>
                    </p>
                  </div>
                </div>
              </td>
              <td>II</td>
              <td>B</td>
              <td>Féminin</td>
              <td>MBC</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>
                <Link to="#" className="text-primary">
                  AD9892429
                </Link>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <Link
                    to={routes.studentDetail}
                    className="avatar avatar-md"
                  >
                    <ImageWithBasePath
                      src="assets/img/students/student-06.jpg"
                      className="img-fluid rounded-circle"
                      alt="img"
                    />
                  </Link>
                  <div className="ms-2">
                    <p className="text-dark mb-0">
                      <Link to={routes.studentDetail}>Paul</Link>
                    </p>
                  </div>
                </div>
              </td>
              <td>IV</td>
              <td>A</td>
              <td>Masculin</td>
              <td>SBC</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>
                <Link to="#" className="text-primary">
                  AD9892428
                </Link>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <Link
                    to={routes.studentDetail}
                    className="avatar avatar-md"
                  >
                    <ImageWithBasePath
                      src="assets/img/students/student-02.jpg"
                      className="img-fluid rounded-circle"
                      alt="img"
                    />
                  </Link>
                  <div className="ms-2">
                    <p className="text-dark mb-0">
                      <Link to={routes.studentDetail}>Jacqueline</Link>
                    </p>
                  </div>
                </div>
              </td>
              <td>IV</td>
              <td>B</td>
              <td>Féminin</td>
              <td>HBC</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" defaultChecked />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>
                <Link to="#" className="text-primary">
                  AD9892427
                </Link>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <Link
                    to={routes.studentDetail}
                    className="avatar avatar-md"
                  >
                    <ImageWithBasePath
                      src="assets/img/students/student-03.jpg"
                      className="img-fluid rounded-circle"
                      alt="img"
                    />
                  </Link>
                  <div className="ms-2">
                    <p className="text-dark mb-0">
                      <Link to={routes.studentDetail}>Michael</Link>
                    </p>
                  </div>
                </div>
              </td>
              <td>II</td>
              <td>A</td>
              <td>Masculin</td>
              <td>HBC</td>
            </tr>
            <tr>
              <td>
                <label className="checkboxs">
                  <input type="checkbox" defaultChecked />
                  <span className="checkmarks" />
                </label>
              </td>
              <td>
                <Link to="#" className="text-primary">
                  AD9892426
                </Link>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <Link
                    to={routes.studentDetail}
                    className="avatar avatar-md"
                  >
                    <ImageWithBasePath
                      src="assets/img/students/student-04.jpg"
                      className="img-fluid rounded-circle"
                      alt="img"
                    />
                  </Link>
                  <div className="ms-2">
                    <p className="text-dark mb-0">
                      <Link to={routes.studentDetail}>Emmanuel</Link>
                    </p>
                  </div>
                </div>
              </td>
              <td>V</td>
              <td>C</td>
              <td>Masculin</td>
              <td>SBC</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div className="modal-footer">
    <button
      type="button"
      className="btn btn-light"
      onClick={handleClose}
    >
      Annuler
    </button>
    <button
      type="button"
      className="btn btn-primary"
      onClick={handleClose}
    >
      Enregistrer
    </button>
  </div>
</Modal>

  {/* <div className="modal fade" id="edit_fees_assign">
    <div className="modal-dialog modal-dialog-centered  modal-xl">
      <div className="modal-content">
        
      </div>
    </div>
  </div> */}
  {/* Edit Fees Assign */}
</>

      {/* Delete Modal */}
      <div className="modal fade" id="delete-modal">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <form>
        <div className="modal-body text-center">
          <span className="delete-icon">
            <i className="ti ti-trash-x" />
          </span>
          <h4>Confirmer la Suppression</h4>
          <p>
            Vous voulez supprimer tous les éléments marqués, cette action est
            irréversible une fois que vous avez supprimé.
          </p>
          <div className="d-flex justify-content-center">
            <Link
              to="#"
              className="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Oui, Supprimer
            </Link>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

      {/* /Delete Modal */}
    </>
  )
}

export default AssignModal