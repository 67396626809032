export const modules = [
    {
      title: "Inscriptions",
      description:
        "Ce module gère les inscriptions des nouveaux élèves et les processus administratifs associés.",
      icon: "fa-user-graduate",
      color: "#28a745",
      link: "/gestion-inscriptions",
    },
    {
      title: "Vie Scolaire",
      description:
        "Suivi des présences, incidents, et performance scolaire des élèves tout au long de l'année.",
      icon: "fa-book-open",
      color: "#007bff",
      link: "/vie-scolaire",
    },
    {
      title: "Finance et Comptabilité",
      description:
        "Gestion des paiements, factures et génération de rapports financiers détaillés.",
      icon: "fa-calculator",
      color: "#ffc107",
      link: "/finance-comptabilite",
    },
    {
      title: "Ressources Humaines et Paie",
      description:
        "Gestion des informations des enseignants et des fiches de paie du personnel administratif.",
      icon: "fa-briefcase",
      color: "#dc3545",
      link: "/ressources-humaines-paie",
    },
    {
      title: "Cantine",
      description:
        "Gestion des repas scolaires, des menus quotidiens et des commandes de nourriture.",
      icon: "fa-utensils",
      color: "#17a2b8",
      link: "/cantine",
    },
    {
      title: "Outil de Collaboration",
      description:
        "Communication entre les parents, les enseignants et le personnel.",
      icon: "fa-comments",
      color: "#6f42c1",
      link: "/outil-collaboration",
    },
    {
      title: "Bibliothèque et Matériel",
      description:
        "Gestion des livres de la bibliothèque, du matériel scolaire et des emprunts d'objets.",
      icon: "fa-book",
      color: "#28a745",
      link: "/bibliotheque-materiel",
    },
    {
      title: "Cours/Classes",
      description:
        "Organisation des cours, des emplois du temps et des programmes scolaires.",
      icon: "fa-chalkboard-teacher",
      color: "#007bff",
      link: "/gestion-cours-classes",
    },
    {
      title: "Résultats Académiques",
      description:
        "Gestion des notes, des bulletins et traitement des réclamations des élèves.",
      icon: "fa-graduation-cap",
      color: "#ffc107",
      link: "/gestion-resultats-academiques",
    },
    {
      title: "Examens et Concours",
      description:
        "Organisation et gestion des examens, des sujets et des copies corrigées.",
      icon: "fa-pencil-alt",
      color: "#dc3545",
      link: "/examens-concours",
    },
    {
      title: "Associations",
      description:
        "Gestion des activités extrascolaires, des clubs et des événements scolaires.",
      icon: "fa-users",
      color: "#17a2b8",
      link: "/associations",
    },
    {
      title: "Utilisateurs",
      description:
        "Gestion des rôles, des permissions et des accès des utilisateurs au système.",
      icon: "fa-user-shield",
      color: "#6f42c1",
      link: "/gestion-utilisateurs",
    },
  ];