import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { feeGroup, feesTypes, paymentType } from '../../../core/common/selectoption/selectoption'
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
import {
 
  Contract,
  Hostel,
  Marital,
  PickupPoint,
  Shift,
  VehicleNumber,
  allClass,
  allSubject,
  bloodGroup,
  gender,
  roomNO,
  route,
  status,
} from "../../../../core/common/selectoption/selectoption";
import { TagsInput } from "react-tag-input-component";
import CommonSelect from "../../../../core/common/commonSelect";
import { useLocation } from "react-router-dom";

const TeacherForm = () => {
  const routes = all_routes;

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [owner, setOwner] = useState<string[]>([]);
  const [owner1, setOwner1] = useState<string[]>([]);
  const [owner2, setOwner2] = useState<string[]>([]);
  const [defaultDate, setDefaultDate] = useState<dayjs.Dayjs | null>(null);
  const [newContents, setNewContents] = useState<number[]>([0]);
  const location = useLocation();
  const addNewContent = () => {
    setNewContents([...newContents, newContents.length]);
  };
  const removeContent = (index: any) => {
    setNewContents(newContents.filter((_, i) => i !== index));
  };
  useEffect(() => {
    if (location.pathname === routes.editTeacher) {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
      const day = String(today.getDate()).padStart(2, "0");
      const formattedDate = `${month}-${day}-${year}`;
      const defaultValue = dayjs(formattedDate);
      setIsEdit(true);
      setOwner(["English"]);
      setOwner1(["Medecine Name"]);
      setOwner2(["Allergy", "Skin Allergy"]);
      setDefaultDate(defaultValue);
      console.log(formattedDate, 11);
    } else {
      setIsEdit(false);
      setDefaultDate(null);
    }
  }, [location.pathname]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content content-two">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
  <div className="my-auto mb-2">
    <h3 className="mb-1">{isEdit ? "Modifier" : "Ajouter"} un enseignant</h3>
    <nav>
      <ol className="breadcrumb mb-0">
        <li className="breadcrumb-item">
          <Link to={routes.adminDashboard}>Tableau de bord</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={routes.teacherList}>Enseignant</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {isEdit ? "Modifier" : "Ajouter"} un enseignant
        </li>
      </ol>
    </nav>
  </div>
</div>

          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <form>
                <>
                  {/* Personal Information */}
                  <div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-info-square-rounded fs-16" />
      </span>
      <h4 className="text-dark">Informations Personnelles</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-12">
        <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
          <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
            <i className="ti ti-photo-plus fs-16" />
          </div>
          <div className="profile-upload">
            <div className="profile-uploader d-flex align-items-center">
              <div className="drag-upload-btn mb-3">
                Télécharger
                <input
                  type="file"
                  className="form-control image-sign"
                  multiple
                />
              </div>
              <Link
                to="#"
                className="btn btn-primary mb-3"
              >
                Supprimer
              </Link>
            </div>
            <p className="fs-12">
              Taille de l'image : 4 Mo, Format JPG, PNG, SVG
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row row-cols-xxl-5 row-cols-md-6">
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">ID Enseignant</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "T849126" : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Prénom</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "Teresa" : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Nom</label>
          <input type="text" className="form-control" />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Classe</label>
          <CommonSelect
            className="select"
            options={allClass}
            defaultValue={isEdit ? allClass[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Matière</label>
          <CommonSelect
            className="select"
            options={allSubject}
            defaultValue={isEdit ? allSubject[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Genre</label>
          <CommonSelect
            className="select"
            options={gender}
            defaultValue={isEdit ? gender[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">
            Numéro de Contact Principal
          </label>
          <input
            type="text"
            className="form-control"
            defaultValue={
              isEdit ? "+1 46548 84498" : undefined
            }
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Adresse Email</label>
          <input
            type="email"
            className="form-control"
            defaultValue={
              isEdit ? "jan@example.com" : undefined
            }
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Groupe Sanguin</label>
          <CommonSelect
            className="select"
            options={bloodGroup}
            defaultValue={isEdit ? bloodGroup[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">
            Date d'Entrée
          </label>
          <div className="input-icon position-relative">
            <span className="input-icon-addon">
              <i className="ti ti-calendar" />
            </span>
            <input
              type="text"
              className="form-control datetimepicker"
            />
          </div>
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Nom du Père</label>
          <input
            type="text"
            className="form-control"
            defaultValue={
              isEdit ? "Francis Saviour" : undefined
            }
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Nom de la Mère</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "Stella Bruce" : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Date de Naissance</label>
          <div className="input-icon position-relative">
            {isEdit ? (
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                value={defaultDate}
                placeholder="Sélectionner la Date"
              />
            ) : (
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                defaultValue=""
                placeholder="Sélectionner la Date"
              />
            )}
            <span className="input-icon-addon">
              <i className="ti ti-calendar" />
            </span>
          </div>
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Statut Marital</label>
          <CommonSelect
            className="select"
            options={Marital}
            defaultValue={isEdit ? Marital[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Langues Connues</label>
          <TagsInput
            // className="input-tags form-control"
            value={owner}
            onChange={setOwner}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Qualification</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "MBA" : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">
            Expérience Professionnelle
          </label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "2 Ans" : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">
            École Précédente le Cas Échéant
          </label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "École ABC" : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Année d'Admission</label>
          <CommonSelect
            className="select"
            options={status}
            defaultValue={isEdit ? status[0] : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Statut Actuel</label>
          <CommonSelect
            className="select"
            options={status}
            defaultValue={isEdit ? status[0] : undefined}
          />
        </div>
      </div>
    </div>
  </div>
</div>

                  {/* /Personal Information */}
                </>

                <>
                  {/* Payroll */}
                  <div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-user-shield fs-16" />
      </span>
      <h4 className="text-dark">Paie</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Numéro EPF</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "34234345" : undefined}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Salaire de Base</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "150000" : undefined}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Type de Contrat</label>
          <CommonSelect
            className="select"
            options={Contract}
            defaultValue={isEdit ? Contract[0] : undefined}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Quart de Travail</label>
          <CommonSelect
            className="select"
            options={Shift}
            defaultValue={isEdit ? Shift[0] : undefined}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Lieu de Travail</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "2ème Étage" : undefined}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Date de Départ</label>
          <div className="input-icon position-relative">
            {isEdit ? (
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                value={defaultDate}
                placeholder="Sélectionner la Date"
              />
            ) : (
              <DatePicker
                className="form-control datetimepicker"
                format={{
                  format: "DD-MM-YYYY",
                  type: "mask",
                }}
                defaultValue=""
                placeholder="Sélectionner la Date"
              />
            )}
            <span className="input-icon-addon">
              <i className="ti ti-calendar" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

                  {/* /Payroll */}
                  {/* Leaves */}
                  <div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-users fs-16" />
      </span>
      <h4 className="text-dark">Congés</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-lg-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Congés Médicaux</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "01" : undefined}
          />
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Congés Exceptionnels</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "02" : undefined}
          />
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Congés Maternité</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "20" : undefined}
          />
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="mb-3">
          <label className="form-label">Congés Maladie</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "02" : undefined}
          />
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Congés */}

{/* Détails du Compte Bancaire */}
<div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-map fs-16" />
      </span>
      <h4 className="text-dark">Détails du Compte Bancaire</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Nom du Compte</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "Teresa" : undefined}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Numéro de Compte</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "0126784900" : undefined}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Nom de la Banque</label>
          <input
            type="text"
            className="form-control"
            defaultValue={
              isEdit ? "Bank of America" : undefined
            }
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Code IFSC</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "BOA83209832" : undefined}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Nom de l'Agence</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "Cincinnati" : undefined}
          />
        </div>
      </div>
    </div>
  </div>
</div>
{/* /Détails du Compte Bancaire */}

                  {/* /Bank Details */}
                </>

                {/* Transport Information */}
                <div className="card">
  <div className="card-header bg-light d-flex align-items-center justify-content-between">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-bus-stop fs-16" />
      </span>
      <h4 className="text-dark">Informations sur le Transport</h4>
    </div>
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
      />
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Itinéraire</label>
          <CommonSelect
            className="select"
            options={route}
            defaultValue={isEdit ? route[0] : undefined}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Numéro du Véhicule</label>
          <CommonSelect
            className="select"
            options={VehicleNumber}
            defaultValue={isEdit ? VehicleNumber[0] : undefined}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Point de Ramassage</label>
          <CommonSelect
            className="select"
            options={PickupPoint}
            defaultValue={isEdit ? PickupPoint[0] : undefined}
          />
        </div>
      </div>
    </div>
  </div>
</div>

                {/* /Transport Information */}
                {/* Hostel Information */}
                <div className="card">
  <div className="card-header bg-light d-flex align-items-center justify-content-between">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-building-fortress fs-16" />
      </span>
      <h4 className="text-dark">Informations sur l'Auberge</h4>
    </div>
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
      />
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Auberge</label>
          <CommonSelect
            className="select"
            options={Hostel}
            defaultValue={isEdit ? Hostel[0] : undefined}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Numéro de Chambre</label>
          <CommonSelect
            className="select"
            options={roomNO}
            defaultValue={isEdit ? roomNO[0] : undefined}
          />
        </div>
      </div>
    </div>
  </div>
</div>

                {/* /Hostel Information */}
                <>
                  {/* Social Media Links */}
                  <div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-building fs-16" />
      </span>
      <h4 className="text-dark">Liens vers les Réseaux Sociaux</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row rows-cols-xxl-5">
      <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Facebook</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "www.facebook.com" : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Instagram</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "www.instagram.com" : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">LinkedIn</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "www.linkedin.com" : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">YouTube</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "www.youtube.com" : undefined}
          />
        </div>
      </div>
      <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
        <div className="mb-3">
          <label className="form-label">Twitter URL</label>
          <input
            type="text"
            className="form-control"
            defaultValue={isEdit ? "www.twitter.com" : undefined}
          />
        </div>
      </div>
    </div>
  </div>
</div>

                  {/* /Documents */}
                  {/* Password */}
                  <div className="card">
  <div className="card-header bg-light">
    <div className="d-flex align-items-center">
      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
        <i className="ti ti-file fs-16" />
      </span>
      <h4 className="text-dark">Mot de Passe</h4>
    </div>
  </div>
  <div className="card-body pb-1">
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Nouveau Mot de Passe</label>
          <input type="password" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">Confirmer le Mot de Passe</label>
          <input type="password" className="form-control" />
        </div>
      </div>
    </div>
  </div>
</div>

                  {/* /Password */}
                </>

                <div className="text-end">
                  <button type="button" className="btn btn-light me-3">
                    Annuler
                  </button>
                  <Link to={routes.teacherList} className="btn btn-primary">
                    Ajouter Enseignant
                  </Link>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default TeacherForm;
