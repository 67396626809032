import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { useLocation } from "react-router-dom";

const AddEtablissement = () => {
  const routes = all_routes;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [type, setType] = useState<string>(""); // Type (centre, école, université)
  const [cadre, setCadre] = useState<string>(""); // Cadre (privé, public, exotic)
  const [nom, setNom] = useState<string>(""); // Nom de l'établissement
  const [localisation, setLocalisation] = useState<string>(""); // Localisation géographique
  const [details, setDetails] = useState<string>(""); // Autres détails (facultatif)

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === routes.editStudent) {
      setIsEdit(true);
      setType("École");
      setCadre("Privé");
      setNom("École Saint Michel");
      setLocalisation("Brazzaville, Poto-Poto");
      setDetails("École avec programme spécialisé en sciences et technologies.");
    } else {
      setIsEdit(false);
      setType("");
      setCadre("");
      setNom("");
      setLocalisation("");
      setDetails("");
    }
  }, [location.pathname, routes.editStudent]);

  return (
    <div className="page-wrapper">
      <div className="content content-two">
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <div className="my-auto mb-2">
            <h3 className="mb-1">
              {isEdit ? "Modifier" : "Ajouter"} un Établissement
            </h3>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={routes.adminDashboard}>Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={routes.etablissementList}>Établissements</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {isEdit ? "Modifier" : "Ajouter"} un Établissement
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="card">
                <div className="card-header bg-light">
                  <div className="d-flex align-items-center">
                    <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                      <i className="ti ti-building-bank fs-16" />
                    </span>
                    <h4 className="text-dark">Informations de l'Établissement</h4>
                  </div>
                </div>
                <div className="card-body pb-1">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Nom de l'Établissement</label>
                        <input
                          type="text"
                          className="form-control"
                          value={nom}
                          onChange={(e) => setNom(e.target.value)}
                          placeholder="Entrez le nom de l'établissement"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Type d'Établissement</label>
                        <select
                          className="form-select"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Sélectionnez le type
                          </option>
                          <option value="centre">Centre</option>
                          <option value="école">École</option>
                          <option value="université">Université</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Cadre</label>
                        <select
                          className="form-select"
                          value={cadre}
                          onChange={(e) => setCadre(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Sélectionnez le cadre
                          </option>
                          <option value="privé">Privé</option>
                          <option value="public">Public</option>
                          <option value="exotic">Exotic</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Localisation</label>
                        <input
                          type="text"
                          className="form-control"
                          value={localisation}
                          onChange={(e) => setLocalisation(e.target.value)}
                          placeholder="Entrez la localisation"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Autres Détails</label>
                        <textarea
                          className="form-control"
                          rows={3}
                          value={details}
                          onChange={(e) => setDetails(e.target.value)}
                          placeholder="Ajoutez des détails supplémentaires (facultatif)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end">
                <button type="button" className="btn btn-light me-3">
                  Annuler
                </button>
                <Link to={routes.etablissementList} className="btn btn-primary">
                  {isEdit ? "Modifier" : "Ajouter"} l'Établissement
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEtablissement;
