import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import Select from "react-select";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";

const Calendar = () => {
  const [startDate, setDate] = useState(new Date()),
    [showCategory, setshowCategory] = useState(false),
    [showmodel, setshowmodel] = useState(false),
    [showEvents, setshowEvents] = useState(false),
    [show, setshow] = useState(false),
    [iseditdelete, setiseditdelete] = useState(false),
    [addneweventobj, setaddneweventobj] = useState(null),
    [isnewevent, setisnewevent] = useState(false),
    [event_title, setevent_title] = useState(""),
    [category_color, setcategory_color] = useState(""),
    [calenderevent, setcalenderevent] = useState(""),
    [weekendsVisible, setweekendsVisible] = useState(true),
    [currentEvents, setscurrentEvents] = useState([]),
    defaultEvents = [
      {
        title: "Nom de l'événement 4",
        start: Date.now() + 148000000,
        className: "bg-purple",
      },
      {
        title: "Événement Test 1",
        start: Date.now(),
        end: Date.now(),
        className: "bg-success",
      },
      {
        title: "Événement Test 2",
        start: Date.now() + 168000000,
        className: "bg-info",
      },
      {
        title: "Événement Test 3",
        start: Date.now() + 338000000,
        className: "bg-primary",
      },
    ];
  useEffect(() => {
    let elements = Array.from(
      document.getElementsByClassName("react-datepicker-wrapper")
    );
    elements.map((element) => element.classList.add("width-100"));
  }, []);

  const addEvent = () => {
    setshowEvents(true);
  };
  const categoryHandler = () => {
    setshowCategory(true);
  };

  const handleClose = () => {
    setisnewevent(false);
    setiseditdelete(false);
    setshow(false);
    setshowCategory(false);
    setshowEvents(false);
    setshowmodel(false);
  };

  const handleEventClick = (clickInfo: any) => {
    setiseditdelete(false);
    setevent_title(clickInfo.event.title);
    setcalenderevent(clickInfo.event);
  };

  const handleDateSelect = (selectInfo: any) => {
    setisnewevent(true);
    setaddneweventobj(selectInfo);
  };

  const onupdateModalClose = () => {
    setiseditdelete(false);
    setevent_title("");
  };

  const handleClick = () => {
    setshow(true);
  };
  const routes = all_routes
  const options1 = [
    { value: "Success", label: "Succès" },
    { value: "Danger", label: "Danger" },
    { value: "Info", label: "Information" },
    { value: "Primary", label: "Primaire" },
    { value: "Warning", label: "Avertissement" },
    { value: "Inverse", label: "Inverse" },
  ];

  const defaultValue = options1[0];

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <>
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Calendrier</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">Application</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Calendrier
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                <div className="mb-2">
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_event"
                  >
                    Créer un événement
                  </Link>
                </div>
              </div>
            </div>
            {/* /Page Header */}
          </>

          <div className="row">
            <div className="col-lg-3 col-md-4">
              <h4 className="card-title">Glisser &amp; Déposer un événement</h4>
              <div id="calendar-events" className="mb-3">
                <div className="calendar-events" data-class="bg-info">
                  <i className="fas fa-circle text-info" /> Mon événement un
                </div>
                <div className="calendar-events" data-class="bg-success">
                  <i className="fas fa-circle text-success" /> Mon événement deux
                </div>
                <div className="calendar-events" data-class="bg-danger">
                  <i className="fas fa-circle text-danger" /> Mon événement trois
                </div>
                <div className="calendar-events" data-class="bg-warning">
                  <i className="fas fa-circle text-warning" /> Mon événement quatre
                </div>
              </div>
              <div className="checkbox  mb-3">
                <input id="drop-remove" className="me-1" type="checkbox" />
                <label htmlFor="drop-remove">Supprimer après dépôt</label>
              </div>
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#add_new_event"
                className="btn mb-3 btn-primary btn-block w-100"
              >
                <i className="fas fa-plus" /> Ajouter une catégorie
              </Link>
            </div>
            <div className="col-lg-9 col-md-8">
              <div className="card bg-white">
                <div className="card-body">
                  <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    initialView="dayGridMonth"
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={weekendsVisible}
                    initialEvents={defaultEvents} // alternatively, use the `events` setting to fetch from a feed
                    select={handleDateSelect}
                    eventClick={(clickInfo: any) => handleEventClick(clickInfo)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Add Event Modal */}
      <div className="modal fade" id="add_event">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Ajouter un événement</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form >
              <div className="modal-body pb-0">
                <div className="mb-3">
                  <label className="form-label">
                    Nom de l'événement <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text" />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Date de l'événement <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <input className="form-control " type="date" />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                  Annuler
                </Link>
                <button type="submit" className="btn btn-primary">
                  Ajouter un événement
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* /Add Event Modal */}
      {/* Add Event Modal */}
      <div className="modal custom-modal fade none-border" id="my_event">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Ajouter un événement</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              ></button>
            </div>
            <div className="modal-body" />
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-success save-event submit-btn"
              >
                Créer l'événement
              </button>
              <button
                type="button"
                className="btn btn-danger delete-event submit-btn"
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Event Modal */}
      {/* Add Category Modal */}
      <div className="modal custom-modal fade" id="add_new_event">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Ajouter une catégorie</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>
                    Nom de la catégorie <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    placeholder="Nom de la catégorie"
                  />
                </div>
                <div className="form-group">
                  <label>
                    Couleur de la catégorie <span className="text-danger">*</span>
                  </label>
                  <select className="form-control" required>
                    {options1.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="text-center mt-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Ajouter
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category Modal */}
    </>
  );
};

export default Calendar;