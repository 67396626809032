import React from 'react'
import { Link } from 'react-router-dom'
import { all_routes } from '../router/all_routes'

const Email = () => {
  const routes = all_routes
  return (
    <div className="page-wrapper">
      <div className="content">
        {/* Page Header */}
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <div className="my-auto mb-2">
            <h3 className="mb-1">Boîte de réception</h3>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={routes.adminDashboard}>Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">Application</li>
                <li className="breadcrumb-item active" aria-current="page">
                  Boîte de réception
                </li>
              </ol>
            </nav>
          </div>
        </div>
        {/* /Page Header */}
        <div className="row">
          {/* Inbox Menu */}
          <div className="col-lg-3 col-md-12">
            <div className="mb-4">
              <Link to="#" className="btn btn-primary w-100">
                Composer
              </Link>
            </div>
            <div className="list-group list-group-flush mb-4">
              <Link to="#" className="list-group-item list-group-item-action active">
                <i className="ti ti-download me-2" />
                Boîte de réception (5)
              </Link>
              <Link to="#" className="list-group-item list-group-item-action">
                <i className="ti ti-star me-2" />
                Important
              </Link>
              <Link to="#" className="list-group-item list-group-item-action">
                <i className="ti ti-send me-2" />
                Messages envoyés
              </Link>
              <Link to="#" className="list-group-item list-group-item-action">
                <i className="ti ti-file-database me-2" />
                Brouillons
              </Link>
              <Link to="#" className="list-group-item list-group-item-action">
                <i className="ti ti-trash me-2" />
                Corbeille
              </Link>
            </div>
          </div>
          {/* /Inbox Menu */}
          {/* Inbox */}
          <div className="col-lg-9 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="email-header">
                  <div className="row">
                    <div className="col-xl-9 col-sm-12">
                      <div className="float-left">
                        <div className="btn-group me-1 mb-2">
                          <button
                            type="button"
                            className="btn btn-outline-light dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            Sélectionner
                          </button>
                          <div className="dropdown-menu">
                            <Link className="dropdown-item" to="#">
                              Tout
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Aucun
                            </Link>
                            <div className="dropdown-divider" />
                            <Link className="dropdown-item" to="#">
                              Lu
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Non lu
                            </Link>
                          </div>
                        </div>
                        <div className="btn-group me-1 mb-2">
                          <button
                            type="button"
                            className="btn btn-outline-light dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            Actions
                          </button>
                          <div className="dropdown-menu">
                            <Link className="dropdown-item" to="#">
                              Répondre
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Transférer
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Archiver
                            </Link>
                            <div className="dropdown-divider" />
                            <Link className="dropdown-item" to="#">
                              Marquer comme lu
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Marquer comme non lu
                            </Link>
                            <div className="dropdown-divider" />
                            <Link className="dropdown-item" to="#">
                              Supprimer
                            </Link>
                          </div>
                        </div>
                        <div className="btn-group me-1 mb-2">
                          <button
                            type="button"
                            className="btn btn-outline-light dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            <i className="fas fa-folder" />
                          </button>
                          <div role="menu" className="dropdown-menu">
                            <Link className="dropdown-item" to="#">
                              Réseaux sociaux
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Forums
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Mises à jour
                            </Link>
                            <div className="dropdown-divider" />
                            <Link className="dropdown-item" to="#">
                              Spam
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Corbeille
                            </Link>
                            <div className="dropdown-divider" />
                            <Link className="dropdown-item" to="#">
                              Nouveau
                            </Link>
                          </div>
                        </div>
                        <div className="btn-group me-1 mb-2">
                          <button
                            type="button"
                            data-bs-toggle="dropdown"
                            className="btn btn-outline-light dropdown-toggle"
                          >
                            <i className="fas fa-tags" />
                          </button>
                          <div role="menu" className="dropdown-menu">
                            <Link className="dropdown-item" to="#">
                              Travail
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Famille
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Réseaux sociaux
                            </Link>
                            <div className="dropdown-divider" />
                            <Link className="dropdown-item" to="#">
                              Principal
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Promotions
                            </Link>
                            <Link className="dropdown-item" to="#">
                              Forums
                            </Link>
                          </div>
                        </div>
                        <div className="btn-group mb-2">
                          <input
                            type="text"
                            placeholder="Rechercher des messages"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-12">
                      <div className="text-xl-end ">
                        <button
                          type="button"
                          title="Rafraîchir"
                          data-toggle="tooltip"
                          className="btn btn-outline-light d-none d-md-inline-block me-1 mb-2"
                        >
                          <i className="fas fa-sync-alt" />
                        </button>
                        <div className="btn-group mb-2">
                          <Link to='' className="btn btn-white">
                            <i className="fas fa-angle-left" />
                          </Link>
                          <Link to="" className="btn btn-white">
                            <i className="fas fa-angle-right" />
                          </Link>
                        </div>
                        <span className="text-muted d-none d-md-inline-block mb-2">
                          Affichage de 10 sur 112
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="email-content">
                  <div className="table-responsive">
                    <table className="table table-inbox table-hover">
                      <thead>
                        <tr>
                          <th colSpan={6}>
                            <div className="form-check form-check-md">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="select-all"
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="clickable-row">
                          <td>
                            <div className="form-check form-check-md">
                              <input className="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <span className="mail-important">
                              <i className="fas fa-star starred" />
                            </span>
                          </td>
                          <td className="text-dark fw-semibold">John Doe</td>
                          <td className="text-dark fw-semibold">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                          </td>
                          <td>
                            <i className="fas fa-paperclip" />
                          </td>
                          <td className="text-dark fw-semibold">13:14</td>
                        </tr>
                        <tr className="clickable-row">
                          <td>
                            <div className="form-check form-check-md">
                              <input className="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <span className="mail-important">
                              <i className="far fa-star" />
                            </span>
                          </td>
                          <td className="text-dark fw-semibold">Compte Envato</td>
                          <td className="text-dark fw-semibold">
                            Mise à jour importante de la sécurité du compte Envato
                          </td>
                          <td />
                          <td className="text-dark fw-semibold">8:42</td>
                        </tr>
                        <tr className="clickable-row">
                          <td>
                            <div className="form-check form-check-md">
                              <input className="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <span className="mail-important">
                              <i className="far fa-star" />
                            </span>
                          </td>
                          <td>Twitter</td>
                          <td>Modèle Admin Bootstrap HRMS</td>
                          <td />
                          <td>30 Nov</td>
                        </tr>
                        <tr className="clickable-row">
                          <td>
                            <div className="form-check form-check-md">
                              <input className="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <span className="mail-important">
                              <i className="far fa-star" />
                            </span>
                          </td>
                          <td>Richard Parker</td>
                          <td>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                          </td>
                          <td />
                          <td>18 Sep</td>
                        </tr>
                        <tr className="clickable-row">
                          <td>
                            <div className="form-check form-check-md">
                              <input className="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <span className="mail-important">
                              <i className="far fa-star" />
                            </span>
                          </td>
                          <td>John Smith</td>
                          <td>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                          </td>
                          <td />
                          <td>21 Aug</td>
                        </tr>
                        <tr className="clickable-row">
                          <td>
                            <div className="form-check form-check-md">
                              <input className="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <span className="mail-important">
                              <i className="far fa-star" />
                            </span>
                          </td>
                          <td>moi, Robert Smith (3)</td>
                          <td>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                          </td>
                          <td />
                          <td>1 Aug</td>
                        </tr>
                        <tr className="clickable-row">
                          <td>
                            <div className="form-check form-check-md">
                              <input className="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <span className="mail-important">
                              <i className="far fa-star" />
                            </span>
                          </td>
                          <td className="text-dark fw-semibold">Codecanyon</td>
                          <td className="text-dark fw-semibold">
                            Bienvenue sur Codecanyon
                          </td>
                          <td />
                          <td className="text-dark fw-semibold">13 Jul</td>
                        </tr>
                        <tr className="clickable-row">
                          <td>
                            <div className="form-check form-check-md">
                              <input className="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <span className="mail-important">
                              <i className="far fa-star" />
                            </span>
                          </td>
                          <td>Richard Miles</td>
                          <td>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                          </td>
                          <td>
                            <i className="fas fa-paperclip" />
                          </td>
                          <td>14 May</td>
                        </tr>
                        <tr className="clickable-row">
                          <td>
                            <div className="form-check form-check-md">
                              <input className="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <span className="mail-important">
                              <i className="far fa-star" />
                            </span>
                          </td>
                          <td className="text-dark fw-semibold">John Smith</td>
                          <td className="text-dark fw-semibold">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                          </td>
                          <td />
                          <td className="text-dark fw-semibold">11/11/16</td>
                        </tr>
                        <tr className="clickable-row">
                          <td>
                            <div className="form-check form-check-md">
                              <input className="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <span className="mail-important">
                              <i className="far fa-star starred" />
                            </span>
                          </td>
                          <td className="text-dark fw-semibold">Mike Litorus</td>
                          <td className="text-dark fw-semibold">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                          </td>
                          <td />
                          <td className="text-dark fw-semibold">31/10/16</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Inbox */}
        </div>
      </div>
    </div>
  )
}


export default Email
