import React from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import ImageWithBasePath from "../../core/common/imageWithBasePath";
import {
  internetCategory,
  markAs,
  priority,
  priorityList,
  staffName,
  statusOption,
  studentName,
  ticketDate,
} from "../../core/common/selectoption/selectoption";
import CommonSelect from "../../core/common/commonSelect";
import PredefinedDateRanges from "../../core/common/datePicker";
import TicketsSidebar from "./tickets-sidebar";
import TooltipOption from "../../core/common/tooltipOption";

const TicketGrid = () => {
  const route = all_routes;

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content pb-lg-4 pb-2">
        <div className="row">
  {/* Page Header */}
  <div className="col-md-12">
    <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
      <div className="my-auto mb-2">
        <h3 className="page-title mb-1">Tickets</h3>
        <nav>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to={route.adminDashboard}>Tableau de bord</Link>
            </li>
            <li className="breadcrumb-item">Support</li>
            <li
              className="breadcrumb-item active"
              aria-current="page"
            >
              Tickets
            </li>
          </ol>
        </nav>
      </div>
      <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
        <TooltipOption />
        <div className="mb-2">
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#add_ticket"
            className="btn btn-primary"
          >
            <i className="ti ti-square-rounded-plus me-2" />
            Ajouter un nouveau ticket
          </Link>
        </div>
      </div>
    </div>
  </div>
  {/* /Page Header */}
</div>
<div className="d-flex align-items-center justify-content-between flex-wrap p-3 bg-white pb-0 mb-4">
  <div className="dropdown me-2 mb-3">
    <Link
      to="#"
      className="dropdown-toggle text-default fw-medium d-inline-flex align-items-center p-1 border-0 fs-18 fw-semibold"
      data-bs-toggle="dropdown"
    >
      Tous les tickets
    </Link>
    <ul className="dropdown-menu p-3">
      <li>
        <Link to="#" className="dropdown-item rounded-1">
          Ouverts
        </Link>
      </li>
      <li>
        <Link to="#" className="dropdown-item rounded-1">
          En cours
        </Link>
      </li>
      <li>
        <Link to="#" className="dropdown-item rounded-1">
          Fermés
        </Link>
      </li>
      <li>
        <Link to="#" className="dropdown-item rounded-1">
          Rouverts
        </Link>
      </li>
    </ul>
  </div>
  <div className="d-flex align-items-center flex-wrap">
    <div className="input-icon-start mb-3 me-2 position-relative">
      <PredefinedDateRanges />
    </div>
    <div className="dropdown mb-3 me-2">
      <Link
        to="#"
        className="btn btn-outline-light bg-white dropdown-toggle"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
      >
        <i className="ti ti-filter me-2" />
        Filtrer
      </Link>
      <div className="dropdown-menu drop-width">
        <form action="tickets">
          <div className="d-flex align-items-center border-bottom p-3">
            <h4>Filtrer</h4>
          </div>
          <div className="p-3 pb-0 border-bottom">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Attribué à</label>
                  <CommonSelect
                    className="select"
                    options={studentName}
                    defaultValue={undefined}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Sélectionner la date</label>
                  <CommonSelect
                    className="select"
                    options={ticketDate}
                    defaultValue={undefined}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Statut</label>
                  <CommonSelect
                    className="select"
                    options={priorityList}
                    defaultValue={undefined}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-3 d-flex align-items-center justify-content-end">
            <Link to="#" className="btn btn-light me-3">
              Réinitialiser
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Appliquer
            </Link>
          </div>
        </form>
      </div>
    </div>
    <div className="d-flex align-items-center bg-white border rounded-2 p-1 mb-3 me-2">
      <Link
        to={route.tickets}
        className="btn btn-icon bg-light btn-sm me-1 primary-hover"
      >
        <i className="ti ti-list-tree" />
      </Link>
      <Link
        to={route.ticketGrid}
        className="active btn btn-icon btn-sm primary-hover"
      >
        <i className="ti ti-grid-dots" />
      </Link>
    </div>
    <div className="dropdown mb-3">
      <Link
        to="#"
        className="btn btn-outline-light bg-white dropdown-toggle"
        data-bs-toggle="dropdown"
      >
        <i className="ti ti-sort-ascending-2 me-2" />
        Trier de A à Z
      </Link>
      <ul className="dropdown-menu p-3">
        <li>
          <Link to="#" className="dropdown-item rounded-1 active">
            Croissant
          </Link>
        </li>
        <li>
          <Link to="#" className="dropdown-item rounded-1">
            Décroissant
          </Link>
        </li>
        <li>
          <Link to="#" className="dropdown-item rounded-1">
            Vu récemment
          </Link>
        </li>
        <li>
          <Link to="#" className="dropdown-item rounded-1">
            Ajouté récemment
          </Link>
        </li>
      </ul>
    </div>
  </div>
</div>


<div className="row">
  {/* Tickets */}
  <div className="col-xl-8 col-xxl-9">
    {/* Ticket List */}
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between flex-wrap p-3 pb-0">
        <h5 className="text-primary mb-3">Support IT</h5>
        <div className="d-flex align-items-center mb-1">
          <span className="badge badge-danger d-inline-flex align-items-center mb-2 me-4">
            <i className="ti ti-circle-filled fs-5 me-1" />
            Élevé
          </span>
          <div className="mb-2">
            <CommonSelect
              className="select"
              options={markAs}
              defaultValue={markAs[1]}
            />
          </div>
        </div>
      </div>
      <div className="card-body p-3 pb-0">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center flex-wrap">
            <span className="avatar avatar-xxl me-2 mb-3">
              <ImageWithBasePath
                src="assets/img/students/student-11.jpg"
                alt="img"
              />
            </span>
            <div className="mb-3">
              <span className="badge bg-pending rounded-pill mb-1">
                #TK0003
              </span>
              <div className="d-flex align-items-center mb-2">
                <h5 className="fw-semibold me-2">
                  <Link to={route.ticketDetails}>Problème d'ordinateur portable</Link>
                </h5>
                <span className="badge bg-outline-danger d-flex align-items-center ms-1">
                  <i className="ti ti-circle-filled fs-5 me-1" />
                  Ouvert
                </span>
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <p className="d-flex align-items-center me-2 mb-1">
                  <ImageWithBasePath
                    src="assets/img/teachers/teacher-02.jpg"
                    className="avatar avatar-xs rounded-circle me-2"
                    alt="img"
                  />
                  Assigné à
                  <span className="text-dark ms-1">Daniel</span>
                </p>
                <p className="d-flex align-items-center mb-1 me-2">
                  <i className="ti ti-calendar-bolt me-1" />
                  Mis à jour il y a 22 heures
                </p>
                <p className="d-flex align-items-center mb-1">
                  <i className="ti ti-message-share me-1" />9 Commentaires
                </p>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <Link
              to="#"
              className="btn btn-outline-danger d-flex align-items-center lh-1"
            >
              <i className="ti ti-trash-x me-1" />
              Supprimer
            </Link>
          </div>
        </div>
        <div className="bg-light-300 p-3 rounded mb-3">
          <p className="mb-1">
            Dernier commentaire de l'agent de support
            <span className="text-dark"> Angio</span>
          </p>
          <p className="mb-2">
            Allumez le panneau latéral et mettez à jour le système d'exploitation, connectez-vous au gestionnaire d'appareils et mettez à jour le mot de passe
          </p>
          <Link
            to="#"
            className="d-inline-flex align-items-center text-primary fw-medium"
            data-bs-toggle="offcanvas"
            data-bs-target="#ticket-reply"
          >
            <i className="ti ti-reload me-1" />
            Répondre
          </Link>
        </div>
      </div>
    </div>
    {/* /Ticket List */}
    {/* Ticket List */}
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between flex-wrap p-3 pb-0">
        <h5 className="text-primary mb-3">Maintenance</h5>
        <div className="d-flex align-items-center mb-1">
          <span className="badge badge-success d-inline-flex align-items-center mb-2 me-4">
            <i className="ti ti-circle-filled fs-5 me-1" />
            Faible
          </span>
          <div className="mb-2">
            <CommonSelect
              className="select"
              options={markAs}
              defaultValue={markAs[0]}
            />
          </div>
        </div>
      </div>
      <div className="card-body p-3 pb-0">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center flex-wrap">
            <span className="avatar avatar-xxl me-2 mb-3">
              <ImageWithBasePath
                src="assets/img/students/student-01.jpg"
                alt="img"
              />
            </span>
            <div className="mb-3">
              <span className="badge bg-pending rounded-pill mb-1">
                #TK0004
              </span>
              <div className="d-flex align-items-center mb-2">
                <h5 className="fw-semibold me-2">
                  <Link to={route.ticketDetails}>
                    Problème de climatisation dans la salle de classe
                  </Link>
                </h5>
                <span className="badge bg-outline-warning d-flex align-items-center ms-1">
                  <i className="ti ti-circle-filled fs-5 me-1" />
                  En cours
                </span>
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <p className="d-flex align-items-center me-2 mb-1">
                  <ImageWithBasePath
                    src="assets/img/teachers/teacher-01.jpg"
                    className="avatar avatar-xs rounded-circle me-2"
                    alt="img"
                  />
                  Assigné à
                  <span className="text-dark ms-1"> Teresa</span>
                </p>
                <p className="d-flex align-items-center mb-1 me-2">
                  <i className="ti ti-calendar-bolt me-1" />
                  Mis à jour il y a 20 heures
                </p>
                <p className="d-flex align-items-center mb-1">
                  <i className="ti ti-message-share me-1" />
                  15 Commentaires
                </p>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <Link
              to="#"
              className="btn btn-outline-danger d-flex align-items-center lh-1"
            >
              <i className="ti ti-trash-x me-1" />
              Supprimer
            </Link>
          </div>
        </div>
        <div className="bg-light-300 p-3 rounded mb-3">
          <p className="mb-1">
            Dernier commentaire de l'agent de support
            <span className="text-dark"> Angio</span>
          </p>
          <p className="mb-2">
            Allumez le panneau latéral et mettez à jour le système d'exploitation, connectez-vous au gestionnaire d'appareils et mettez à jour le mot de passe
          </p>
          <Link
            to="#"
            className="d-inline-flex align-items-center text-primary fw-medium"
            data-bs-toggle="offcanvas"
            data-bs-target="#ticket-reply"
          >
            <i className="ti ti-reload me-1" />
            Répondre
          </Link>
        </div>
      </div>
    </div>
    {/* /Ticket List */}
    {/* Ticket List */}
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between flex-wrap p-3 pb-0">
        <h5 className="text-primary mb-3">Problème de transport</h5>
        <div className="d-flex align-items-center mb-1">
          <span className="badge badge-warning d-inline-flex align-items-center mb-2 me-4">
            <i className="ti ti-circle-filled fs-5 me-1" />
            Moyen
          </span>
          <div className="mb-2">
            <CommonSelect
              className="select"
              options={markAs}
              defaultValue={markAs[1]}
            />
          </div>
        </div>
      </div>
      <div className="card-body p-3 pb-0">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center flex-wrap">
            <span className="avatar avatar-xxl me-2 mb-3">
              <ImageWithBasePath
                src="assets/img/students/student-05.jpg"
                alt="img"
              />
            </span>
            <div className="mb-3">
              <span className="badge bg-pending rounded-pill mb-1">
                #TK0003
              </span>
              <div className="d-flex align-items-center mb-2">
                <h5 className="fw-semibold me-2">
                  <Link to={route.ticketDetails}>
                    Problème de parcours de bus scolaire
                  </Link>
                </h5>
                <span className="badge bg-outline-success d-flex align-items-center ms-1">
                  <i className="ti ti-circle-filled fs-5 me-1" />
                  Clôturé
                </span>
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <p className="d-flex align-items-center me-2 mb-1">
                  <ImageWithBasePath
                    src="assets/img/teachers/teacher-06.jpg"
                    className="avatar avatar-xs rounded-circle me-2"
                    alt="img"
                  />
                  Assigné à
                  <span className="text-dark ms-1"> James</span>
                </p>
                <p className="d-flex align-items-center mb-1 me-2">
                  <i className="ti ti-calendar-bolt me-1" />
                  Mis à jour il y a 15 heures
                </p>
                <p className="d-flex align-items-center mb-1">
                  <i className="ti ti-message-share me-1" />
                  12 Commentaires
                </p>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <Link
              to="#"
              className="btn btn-outline-danger d-flex align-items-center lh-1"
            >
              <i className="ti ti-trash-x me-1" />
              Supprimer
            </Link>
          </div>
        </div>
        <div className="bg-light-300 p-3 rounded mb-3">
          <p className="mb-1">
            Dernier commentaire de l'agent de support
            <span className="text-dark"> Angio</span>
          </p>
          <p className="mb-2">
            Allumez le panneau latéral et mettez à jour le système d'exploitation, connectez-vous au gestionnaire d'appareils et mettez à jour le mot de passe
          </p>
          <Link
            to="#"
            className="d-inline-flex align-items-center text-primary fw-medium"
            data-bs-toggle="offcanvas"
            data-bs-target="#ticket-reply"
          >
            <i className="ti ti-reload me-1" />
            Répondre
          </Link>
        </div>
      </div>
    </div>
    {/* /Ticket List */}
    {/* Ticket List */}
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between flex-wrap p-3 pb-0">
        <h5 className="text-primary mb-3">Bibliothèque</h5>
        <div className="d-flex align-items-center mb-1">
          <span className="badge badge-warning d-inline-flex align-items-center mb-2 me-4">
            <i className="ti ti-circle-filled fs-5 me-1" />
            Moyen
          </span>
          <div className="mb-2">
            <CommonSelect
              className="select"
              options={markAs}
              defaultValue={markAs[1]}
            />
          </div>
        </div>
      </div>
      <div className="card-body p-3 pb-0">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center flex-wrap">
            <span className="avatar avatar-xxl me-2 mb-3">
              <ImageWithBasePath
                src="assets/img/students/student-09.jpg"
                alt="img"
              />
            </span>
            <div className="mb-3">
              <span className="badge bg-pending rounded-pill mb-1">
                #TK0002
              </span>
              <div className="d-flex align-items-center mb-2">
                <h5 className="fw-semibold me-2">
                  <Link to={route.ticketDetails}>
                    Demande de livre à la bibliothèque
                  </Link>
                </h5>
                <span className="badge bg-outline-info d-flex align-items-center ms-1">
                  <i className="ti ti-circle-filled fs-5 me-1" />
                  Rouverte
                </span>
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <p className="d-flex align-items-center me-2 mb-1">
                  <ImageWithBasePath
                    src="assets/img/teachers/teacher-09.jpg"
                    className="avatar avatar-xs rounded-circle me-2"
                    alt="img"
                  />
                  Assigné à
                  <span className="text-dark ms-1"> Rouverte</span>
                </p>
                <p className="d-flex align-items-center mb-1 me-2">
                  <i className="ti ti-calendar-bolt me-1" />
                  Mis à jour il y a 05 heures
                </p>
                <p className="d-flex align-items-center mb-1">
                  <i className="ti ti-message-share me-1" />
                  13 Commentaires
                </p>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <Link
              to="#"
              className="btn btn-outline-danger d-flex align-items-center lh-1"
            >
              <i className="ti ti-trash-x me-1" />
              Supprimer
            </Link>
          </div>
        </div>
        <div className="bg-light-300 p-3 rounded mb-3">
          <p className="mb-1">
            Dernier commentaire de l'agent de support
            <span className="text-dark"> Angio</span>
          </p>
          <p className="mb-2">
            Allumez le panneau latéral et mettez à jour le système d'exploitation, connectez-vous au gestionnaire d'appareils et mettez à jour le mot de passe
          </p>
          <Link
            to="#"
            className="d-inline-flex align-items-center text-primary fw-medium"
            data-bs-toggle="offcanvas"
            data-bs-target="#ticket-reply"
          >
            <i className="ti ti-reload me-1" />
            Répondre
          </Link>
        </div>
      </div>
    </div>
    {/* /Ticket List */}
    <div className="text-center mb-xl-0 mb-4">
      <Link to="#" className="btn btn-primary">
        <i className="ti ti-loader-3 me-2" />
        Charger Plus
      </Link>
    </div>
  </div>
  {/* /Tickets */}
  {/* Categories List */}
  <TicketsSidebar />
  {/* /Categories List */}
</div>

        </div>
      </div>
      {/* /Page Wrapper */}

      {/* Ticket View */}
      <div
  className="offcanvas offcanvas-end custom-offcanvas"
  tabIndex={-1}
  id="ticket-reply"
>
  <div className="offcanvas-header border-bottom d-flex align-items-center justify-content-between p-3">
    <div className="d-flex align-items-center">
      <h5 className="me-2 mb-2">Problème d'ordinateur portable</h5>
      <div className="dropdown me-1 mb-2 ms-1">
        <Link
          to="#"
          className="dropdown-toggle  badge bg-outline-danger fs-12 text-danger d-inline-flex align-items-center p-1"
          data-bs-toggle="dropdown"
        >
          Ouvert
        </Link>
        <ul className="dropdown-menu p-3">
          <li>
            <Link to="#" className="dropdown-item rounded-1">
              Ouvert
            </Link>
          </li>
          <li>
            <Link to="#" className="dropdown-item rounded-1">
              Clôturé
            </Link>
          </li>
          <li>
            <Link to="#" className="dropdown-item rounded-1">
              Rouverte
            </Link>
          </li>
        </ul>
      </div>
    </div>
    <div className="d-flex align-items-center">
      <h5 className="text-primary me-2">Support IT</h5>
      <button
        type="button"
        className="close-btn"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <i className="ti ti-x" />
      </button>
    </div>
  </div>
  <div className="offcanvas-body p-0">
    <div className="d-flex align-items-center justify-content-between flex-wrap border-bottom p-3 pb-0">
      <div className="d-block mb-3">
        <div className="d-flex align-items-center mb-2">
          <span className="badge bg-pending rounded-pill me-3">
            #TK0003
          </span>
          <span className="badge badge-success d-inline-flex align-items-center">
            <i className="ti ti-circle-filled fs-5 me-1" />
            Faible
          </span>
        </div>
        <div className="d-flex align-items-center flex-wrap">
          <p className="d-flex align-items-center me-2 mb-1">
            <ImageWithBasePath
              src="assets/img/teachers/teacher-01.jpg"
              className="avatar avatar-xs rounded-circle me-2"
              alt="img"
            />
            Assigné à <span className="text-dark ms-1"> Teresa</span>
          </p>
          <p className="d-flex align-items-center mb-1 me-2">
            <i className="ti ti-calendar-bolt me-1" />
            Mis à jour il y a 22 heures
          </p>
        </div>
      </div>
      <div className="mb-3">
        <CommonSelect
          className="select"
          options={markAs}
          defaultValue={markAs[1]}
        />
      </div>
    </div>
    <div className="border-bottom p-3 pb-0">
      <div className="d-flex">
        <span className="avatar avatar-xxl flex-shrink-0 me-4 mb-3">
          <ImageWithBasePath
            src="assets/img/students/student-11.jpg"
            alt="Img"
          />
        </span>
        <div>
          <div
            className="alert bg-dark rounded d-flex align-items-center justify-content-between mb-3"
            role="alert"
          >
            <p className="mb-0">
              Remarque !&nbsp;Ce ticket est clôturé. Si vous souhaitez le rouvrir, il suffit de poster une réponse ci-dessous.
            </p>
            <button
              type="button"
              className="btn-close opacity-100 text-white p-0"
              data-bs-dismiss="alert"
              aria-label="Close"
            >
              <span>
                <i className="ti ti-x" />
              </span>
            </button>
          </div>
          <div className="mb-3">
            <div className="summernote">
              Rédigez un nouveau commentaire, envoyez une notification à votre équipe en tapant @ suivi de leur nom
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <Link to="#" className="btn btn-light">
                <i className="ti ti-pin" />
                Pièce jointe
              </Link>
              <Link to="#" className="btn btn-primary">
                Poster le commentaire
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="border-bottom p-3 pb-0">
      <div className="d-flex align-items-center mb-3">
        <span className="avatar avatar-xl me-2 flex-shrink-0">
          <ImageWithBasePath
            src="assets/img/students/student-11.jpg"
            alt="Img"
          />
        </span>
        <div>
          <h6 className="mb-1">James Hendriques</h6>
          <p>
            <i className="ti ti-calendar-bolt me-1" />
            Mis à jour il y a 5 heures
          </p>
        </div>
      </div>
      <div>
        <div className="mb-3">
          <h5 className="mb-1">Impact sur le travail</h5>
          <p>
            Ce problème perturbe les réunions, retarde l'achèvement des tâches et affecte ma productivité globale.
          </p>
        </div>
        <span className="badge bg-light">
          Capture d'écran.png
          <i className="ti ti-download ms-1" />
        </span>
        <div className="d-flex align-items-center my-3">
          <Link
            to="#"
            className="d-inline-flex align-items-center text-primary fw-medium me-3"
          >
            <i className="ti ti-reload me-1" />
            Répondre
          </Link>
          <p className="d-flex align-items-center">
            <i className="ti ti-message-share me-1" />9 Commentaires
          </p>
        </div>
        <div className="mb-3">
          <div className="summernote">
            Rédigez un nouveau commentaire, envoyez une notification à votre équipe en tapant @ suivi de leur nom
          </div>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <Link to="#" className="btn btn-light">
              <i className="ti ti-pin" />
              Pièce jointe
            </Link>
            <Link to="#" className="btn btn-primary">
              Poster le commentaire
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="border-bottom p-3 pb-0">
      <div className="d-flex align-items-center mb-3">
        <span className="avatar avatar-xl me-2 flex-shrink-0">
          <ImageWithBasePath
            src="assets/img/profiles/avatar-19.jpg"
            alt="Img"
          />
        </span>
        <div>
          <h6 className="mb-1">Agent de support Angio</h6>
          <p>
            <i className="ti ti-calendar-bolt me-1" />
            Mis à jour il y a 5 heures
          </p>
        </div>
      </div>
      <div>
        <div className="mb-3">
          <p>
            Allumez le panneau latéral et mettez à jour le système d'exploitation, connectez-vous au gestionnaire d'appareils et mettez à jour le mot de passe
          </p>
        </div>
        <div className="d-flex align-items-center my-3">
          <Link
            to="#"
            className="d-inline-flex align-items-center text-primary fw-medium me-3"
          >
            <i className="ti ti-reload me-1" />
            Répondre
          </Link>
          <p className="d-flex align-items-center">
            <i className="ti ti-message-share me-1" />5 Commentaires
          </p>
        </div>
      </div>
    </div>
    <div className="border-bottom p-3 pb-0">
      <div className="d-flex align-items-center mb-3">
        <span className="avatar avatar-xl me-2 flex-shrink-0">
          <ImageWithBasePath
            src="assets/img/profiles/avatar-01.jpg"
            alt="Img"
          />
        </span>
        <div>
          <h6 className="mb-1">Marilyn Siegle</h6>
          <p>
            <i className="ti ti-calendar-bolt me-1" />
            Mis à jour il y a 6 heures
          </p>
        </div>
      </div>
      <div>
        <div className="mb-3">
          <p>
            Vérifiez les journaux système et application dans l'Observateur d'événements pour des avertissements ou des erreurs qui coïncident avec les moments où les gels se produisent.
          </p>
        </div>
        <div className="d-flex align-items-center my-3">
          <Link
            to="#"
            className="d-inline-flex align-items-center text-primary fw-medium me-3"
          >
            <i className="ti ti-reload me-1" />
            Répondre
          </Link>
          <p className="d-flex align-items-center">
            <i className="ti ti-message-share me-1" />7 Commentaires
          </p>
        </div>
      </div>
    </div>
    <div className="p-3 pb-0">
      <div className="d-flex align-items-center mb-3">
        <span className="avatar avatar-xl me-2 flex-shrink-0">
          <ImageWithBasePath
            src="assets/img/profiles/avatar-22.jpg"
            alt="Img"
          />
        </span>
        <div>
          <h6 className="mb-1">Brian Foust</h6>
          <p>
            <i className="ti ti-calendar-bolt me-1" />
            Mis à jour il y a 8 heures
          </p>
        </div>
      </div>
      <div>
        <div className="mb-3">
          <p>
            Vérifiez les journaux système et application dans l'Observateur d'événements pour des avertissements ou des erreurs qui coïncident avec les moments où les gels se produisent.
          </p>
        </div>
        <div className="d-flex align-items-center my-3">
          <Link
            to="#"
            className="d-inline-flex align-items-center text-primary fw-medium me-3"
          >
            <i className="ti ti-reload me-1" />
            Répondre
          </Link>
          <p className="d-flex align-items-center">
            <i className="ti ti-message-share me-1" />9 Commentaires
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

      {/* /Ticket View */}
      {/* Add Ticket */}
      <div className="modal fade" id="add_ticket">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Ajouter un Ticket</h4>
        <button
          type="button"
          className="btn-close custom-btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i className="ti ti-x" />
        </button>
      </div>
      <form>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Titre</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez le titre"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Catégorie d'Événement</label>
                <CommonSelect
                  className="select"
                  options={internetCategory}
                  defaultValue={undefined}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Sujet</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez le sujet"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Attribuer à</label>
                <CommonSelect
                  className="select"
                  options={staffName}
                  defaultValue={undefined}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Description du Ticket</label>
                <textarea
                  className="form-control"
                  placeholder="Ajouter une question"
                  defaultValue={""}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Priorité</label>
                <CommonSelect
                  className="select"
                  options={priority}
                  defaultValue={undefined}
                />
              </div>
              <div className="mb-0">
                <label className="form-label">Statut</label>
                <CommonSelect
                  className="select"
                  options={statusOption}
                  defaultValue={undefined}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Link
            to="#"
            className="btn btn-light me-2"
            data-bs-dismiss="modal"
          >
            Annuler
          </Link>
          <Link
            to="#"
            data-bs-dismiss="modal"
            className="btn btn-primary"
          >
            Ajouter le Ticket
          </Link>
        </div>
      </form>
    </div>
  </div>
</div>

      {/* /Add Ticket */}
    </>
  );
};

export default TicketGrid;
