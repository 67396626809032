import React, { useRef, useState } from 'react'
import { teacherAttendance } from '../../../core/data/json/teacher_attendance';
import { TableData } from '../../../core/data/interface';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../core/common/imageWithBasePath';
import Table from "../../../core/common/dataTable/index";
import PredefinedDateRanges from '../../../core/common/datePicker';
import CommonSelect from '../../../core/common/commonSelect';
import { attendance, studentclass, studentName, teacherId } from '../../../core/common/selectoption/selectoption';
import { all_routes } from '../../router/all_routes';
import TooltipOption from '../../../core/common/tooltipOption';

const TeacherAttendance = () => {
  const routes = all_routes;
  const data = teacherAttendance;
  const [selectedOptions, setSelectedOptions] = useState(
    data.map(() => 'Présent') // Par défaut à 'Présent' pour chaque ligne
  );
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };

  // Gérer le changement d'état pour chaque ligne
  const handleOptionChange = (index: any, value: any) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = value;
    setSelectedOptions(newSelectedOptions);
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "admissionNo",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },
    {
      title: "Nom",
      dataIndex: "name",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <Link to="#" className="avatar avatar-md">
            <ImageWithBasePath
              src={record.img}
              className="img-fluid rounded-circle"
              alt="img"
            />
          </Link>
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">{text}</Link>
            </p>
          </div>
        </div>
      ),
      sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    {
      title: "Classe",
      dataIndex: "class",
      sorter: (a: TableData, b: TableData) => a.class.length - b.class.length,
    },
    {
      title: "Présence",
      dataIndex: "attendance",
      render: (text: string, record: any, index: any) => (
        <div className="d-flex align-items-center check-radio-group flex-nowrap">
          <label className="custom-radio">
            <input
              type="radio"
              name={`attendance-${index}`}
              value="Présent"
              checked={selectedOptions[index] === 'Présent'}
              onChange={() => handleOptionChange(index, 'Présent')}
            />
            <span className="checkmark" />
            Présent
          </label>
          <label className="custom-radio">
            <input
              type="radio"
              name={`attendance-${index}`}
              value="En retard"
              checked={selectedOptions[index] === 'En retard'}
              onChange={() => handleOptionChange(index, 'En retard')}
            />
            <span className="checkmark" />
            En retard
          </label>
          <label className="custom-radio">
            <input
              type="radio"
              name={`attendance-${index}`}
              value="Absent"
              checked={selectedOptions[index] === 'Absent'}
              onChange={() => handleOptionChange(index, 'Absent')}
            />
            <span className="checkmark" />
            Absent
          </label>
          <label className="custom-radio">
            <input
              type="radio"
              name={`attendance-${index}`}
              value="Vacances"
              checked={selectedOptions[index] === 'Vacances'}
              onChange={() => handleOptionChange(index, 'Vacances')}
            />
            <span className="checkmark" />
            Vacances
          </label>
          <label className="custom-radio">
            <input
              type="radio"
              name={`attendance-${index}`}
              value="Demi-journée"
              checked={selectedOptions[index] === 'Demi-journée'}
              onChange={() => handleOptionChange(index, 'Demi-journée')}
            />
            <span className="checkmark" />
            Demi-journée
          </label>
        </div>
      ),
      sorter: (a: TableData, b: TableData) =>
        a.attendance.length - b.attendance.length,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      render: (text: string, record: any) => (
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Entrez le nom"
          />
        </div>
      ),
      sorter: (a: TableData, b: TableData) => a.notes.length - b.notes.length,
    },
  ];
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Présence des enseignants</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">Rapport</Link>
                    </li>
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      Présence des enseignants
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                <TooltipOption />
              </div>
            </div>
            {/* /Page Header */}
            {/* Teacher Attendence List */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3">Liste de présence des enseignants</h4>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="input-icon-start mb-3 me-2 position-relative">
                    <PredefinedDateRanges />
                  </div>
                  <div className="dropdown mb-3 me-2">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      <i className="ti ti-filter me-2" />
                      Filtrer
                    </Link>
                    <div
                      className="dropdown-menu drop-width"
                      ref={dropdownMenuRef}
                    >
                      <form>
                        <div className="d-flex align-items-center border-bottom p-3">
                          <h4>Filtrer</h4>
                        </div>
                        <div className="p-3 border-bottom">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">ID</label>
                                <CommonSelect
                                  className="select"
                                  options={teacherId}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Nom</label>
                                <CommonSelect
                                  className="select"
                                  options={studentName}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-0">
                                <label className="form-label">Classe</label>
                                <CommonSelect
                                  className="select"
                                  options={studentclass}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-0">
                                <label className="form-label">Présence</label>
                                <CommonSelect
                                  className="select"
                                  options={attendance}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-3 d-flex align-items-center justify-content-end">
                          <Link to="#" className="btn btn-light me-3">
                            Réinitialiser
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={handleApplyClick}
                          >
                            Appliquer
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="dropdown mb-3">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-sort-ascending-2 me-2" />
                      Trier par A-Z
                    </Link>
                    <ul className="dropdown-menu p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1 active">
                          Ascendant
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Descendant
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Vu récemment
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Ajouté récemment
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body p-0 py-3">
                {/* Liste du personnel */}
                <Table dataSource={data} columns={columns} Selection={true} />
                {/* /Liste du personnel */}
              </div>
            </div>
            {/* /Teacher Attendence List */}
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </div>
  );
};


export default TeacherAttendance