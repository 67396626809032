import React from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Notificationssettings = () => {
  const routes = all_routes;
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="d-md-flex d-block align-items-center justify-content-between border-bottom pb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Paramètres généraux</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="index">Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Paramètres</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Paramètres généraux
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <div className="pe-1 mb-2">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Rafraîchir</Tooltip>}
                >
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white btn-icon me-1"
                  >
                    <i className="ti ti-refresh" />
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-2 col-xl-3">
              <div className="pt-3 d-flex flex-column list-group mb-4">
                <Link
                  to={routes.profilesettings}
                  className="d-block rounded p-2"
                >
                  Paramètres du profil
                </Link>
                <Link
                  to={routes.securitysettings}
                  className="d-block rounded p-2"
                >
                  Paramètres de sécurité
                </Link>
                <Link
                  to={routes.notificationssettings}
                  className="d-block rounded active p-2"
                >
                  Notifications
                </Link>
                <Link to={routes.connectedApps} className="d-block rounded p-2">
                  Applications connectées
                </Link>
              </div>
            </div>
            <div className="col-xxl-10 col-xl-9">
              <div className="flex-fill border-start ps-3">
                <div className="d-flex align-items-center justify-content-between flex-wrap border-bottom mb-3 pt-3">
                  <div className="mb-3">
                    <h5>Notifications</h5>
                    <p>
                      Recevez des notifications sur ce qui se passe en ce moment, vous pouvez les désactiver à tout moment.
                    </p>
                  </div>
                </div>
                <div className="d-block">
                  <div className="card border-0 p-3 pb-0 mb-3 rounded">
                    <div className="d-flex justify-content-between align-items-center flex-wrap border-bottom mb-3">
                      <div className="mb-3">
                        <h6>Notifications par e-mail</h6>
                        <p>
                          Substance peut vous envoyer des notifications par e-mail pour tout nouveau message direct.
                        </p>
                      </div>
                      <div className="mb-3">
                        <div className="form-check form-check-md">
                          <input className="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center flex-wrap border-bottom mb-3">
                      <div className="mb-3">
                        <h6>Paramètres des actualités et mises à jour</h6>
                        <p>
                          Les dernières actualités sur les nouvelles fonctionnalités et les mises à jour du logiciel.
                        </p>
                      </div>
                      <div className="mb-3">
                        <div className="form-check form-check-md">
                          <input className="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center flex-wrap border-bottom mb-3">
                      <div className="mb-3">
                        <h6>Conseils &amp; Tutoriels</h6>
                        <p>
                          Conseils &amp; astuces pour améliorer l'efficacité de vos performances.
                        </p>
                      </div>
                      <div className="mb-3">
                        <div className="form-check form-check-md">
                          <input className="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-0">
                      <div className="mb-3">
                        <h6>Offres &amp; Promotions</h6>
                        <p>
                          Promotions sur les tarifs des forfaits et leurs dernières réductions.
                        </p>
                      </div>
                      <div className="mb-3">
                        <div className="form-check form-check-md">
                          <input className="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card border-0 p-3 pb-0 mb-3 rounded">
                    <div className="d-flex justify-content-between align-items-center flex-wrap border-bottom mb-3">
                      <div className="mb-3">
                        <h6>Plus d'activités</h6>
                        <p>
                          Substance peut vous envoyer des notifications par e-mail pour toute nouvelle activité directe.
                        </p>
                      </div>
                      <div className="mb-3">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center flex-wrap border-bottom mb-3">
                      <div className="mb-3">
                        <h6>Rappels &amp; Activités</h6>
                        <p>
                          Recevez des notifications sur toutes les activités et rappels créés dans le système.
                        </p>
                      </div>
                      <div className="mb-3">
                        <div className="form-check form-check-md">
                          <input className="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center flex-wrap border-bottom mb-3">
                      <div className="mb-3">
                        <h6>Activités uniquement</h6>
                        <p>
                          Recevez uniquement les notifications des dernières mises à jour d'activités concernant les données en hausse ou en baisse.
                        </p>
                      </div>
                      <div className="mb-3">
                        <div className="form-check form-check-md">
                          <input className="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-0">
                      <div className="mb-3">
                        <h6>Rappels importants uniquement</h6>
                        <p>
                          Recevez uniquement les notifications concernant les rappels créés.
                        </p>
                      </div>
                      <div className="mb-3">
                        <div className="form-check form-check-md">
                          <input className="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Notificationssettings;
