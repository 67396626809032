import React from "react";
import { Link } from "react-router-dom";
import PredefinedDateRanges from "../../../core/common/datePicker";
import CommonSelect from "../../../core/common/commonSelect";
import {
  category4,
  date,
  publish,
  status,
  tags,
} from "../../../core/common/selectoption/selectoption";
import { blog_comments_data } from "../../../core/data/json/blog_comments_data";
import { TableData } from "../../../core/data/interface";
import Table from "../../../core/common/dataTable/index";
import { all_routes } from "../../router/all_routes";
import TooltipOption from "../../../core/common/tooltipOption";

const BlogComments = () => {
  const data = blog_comments_data;
  const routes = all_routes;
  const columns = [
    {
      title: "Commentaire",
      dataIndex: "comment",
      key: "comment",
      sorter: (a: TableData, b: TableData) => a.comment.length - b.comment.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a: TableData, b: TableData) => a.date.length - b.date.length,
      className: "text-gray-9",
    },
    {
      title: "Évaluation",
      dataIndex: "review",
      key: "review",
      sorter: (a: TableData, b: TableData) => a.review.length - b.review.length,
      render: (rating: any) => (
        <div className="rating">
          <i className="ti ti-star-filled filled" />
          <i className="ti ti-star-filled filled" />
          <i className="ti ti-star-filled filled" />
          <i className="ti ti-star-filled filled" />
          <i className="ti ti-star-filled filled" />
          <span className="rating-rate">5.0</span>
        </div>
      ),
    },
    {
      title: "Blog",
      dataIndex: "blog",
      key: "blog",
      sorter: (a: TableData, b: TableData) => a.blog.length - b.blog.length,
      className: "text-gray-9 text-content",
    },
    {
      title: "Par",
      dataIndex: "author",
      key: "author",
      sorter: (a: TableData, b: TableData) => a.author.length - b.author.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      sorter: (a: TableData, b: TableData) => a.action.length - b.action.length,
      render: (text: any, record: any) => (
        <CommonSelect
          className="select"
          options={publish}
          defaultValue={publish[0]}
        />
      ),
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      render: (text: any, record: any) => (
        <Link
          to="#"
          data-bs-toggle="modal"
          data-bs-target="#delete-modal"
          className="delete-icon"
        >
          <i className="ti ti-trash-x" />
        </Link>
      ),
    },
  ];
  

  return (
    <div>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Commentaires</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Pages</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Commentaires
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#add_blog"
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Ajouter un blog
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Liste des commentaires</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filtrer
                  </Link>
                  <div className="dropdown-menu drop-width">
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filtrer</h4>
                      </div>
                      <div className="p-3 border-bottom">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-0">
                              <label className="form-label">Date</label>
                              <CommonSelect
                                className="select"
                                options={date}
                                defaultValue={date[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Réinitialiser
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Appliquer
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Trier de A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Croissant
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Décroissant
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Consulté récemment
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Ajouté récemment
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* /Comments List */}
                <Table dataSource={data} columns={columns} Selection={true} />
              {/* /Comments List */}
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* Add Blog */}
      <div className="modal fade" id="add_blog">
        <div className="modal-dialog modal-dialog-centered  modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Nouveaux Blogs</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Titre du blog</label>
                      <input type="text" className="form-control" />
                    </div>
                    <label className="form-label">Image à la une</label>
                    <div className="d-flex align-items-center upload-pic flex-wrap row-gap-3 mb-3">
                      <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                        <i className="ti ti-photo-plus fs-16" />
                      </div>
                      <div className="profile-upload">
                        <div className="profile-uploader d-flex align-items-center">
                          <div className="drag-upload-btn mb-3">
                            Télécharger
                            <input
                              type="file"
                              className="form-control image-sign"
                              multiple
                            />
                          </div>
                          <Link to="#" className="btn btn-primary mb-3">
                            Supprimer
                          </Link>
                        </div>
                        <p>Taille de l'image à télécharger 4 Mo, Format JPG, PNG, SVG</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Catégorie</label>
                      <CommonSelect
                        className="select"
                        options={category4}
                        defaultValue={category4[0]}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Étiquettes</label>
                      <CommonSelect
                        className="select"
                        options={tags}
                        defaultValue={tags[0]}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Statut</label>
                      <CommonSelect
                        className="select"
                        options={status}
                        defaultValue={status[0]}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-0">
                      <label className="form-label">Description</label>
                      <div className="summernote" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </Link>
                <button type="submit" className="btn btn-primary">
                  Ajouter le blog
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Add Blog */}
      {/* Edit Blog */}
      <div className="modal fade" id="edit_blog">
        <div className="modal-dialog modal-dialog-centered  modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Modifier les Blogs</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Titre du blog</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le titre"
                        defaultValue="Développement personnel et amélioration de soi"
                      />
                    </div>
                    <label className="form-label">Image à la une</label>
                    <div className="d-flex align-items-center upload-pic flex-wrap row-gap-3 mb-3">
                      <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                        <i className="ti ti-photo-plus fs-16" />
                      </div>
                      <div className="profile-upload">
                        <div className="profile-uploader d-flex align-items-center">
                          <div className="drag-upload-btn mb-3">
                            Télécharger
                            <input
                              type="file"
                              className="form-control image-sign"
                              multiple
                            />
                          </div>
                          <Link to="#" className="btn btn-primary mb-3">
                            Supprimer
                          </Link>
                        </div>
                        <p>Taille de l'image à télécharger 4 Mo, Format JPG, PNG, SVG</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Catégorie</label>
                      <CommonSelect
                        className="select"
                        options={category4}
                        defaultValue={category4[0]}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Étiquettes</label>
                      <CommonSelect
                        className="select"
                        options={tags}
                        defaultValue={tags[0]}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Statut</label>
                      <CommonSelect
                        className="select"
                        options={status}
                        defaultValue={status[0]}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-0">
                      <label className="form-label">Description</label>
                      <div className="summernote" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </Link>
                <button type="submit" className="btn btn-primary">
                  Mettre à jour le blog
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Edit Blog */}
    </div>
  );
  
};

export default BlogComments;
