import React from "react";
import { Link } from "react-router-dom";
import { driverName, PickupPoint2, routesList, VehicleNumber } from "../../../core/common/selectoption/selectoption";
import CommonSelect from "../../../core/common/commonSelect";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import dayjs from "dayjs";
import { DatePicker } from "antd";

const TransportModal = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0') // Month is zero-based, so we add 1
    const day = String(today.getDate()).padStart(2, '0')
    const formattedDate = `${month}-${day}-${year}`
    const defaultValue = dayjs(formattedDate);
    const getModalContainer = () => {
     const modalElement = document.getElementById('modal-datepicker');
     return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
   };
    const getModalContainer2 = () => {
     const modalElement = document.getElementById('modal-datepicker2');
     return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
   };
  return (
    <>
<>
  {/* Ajouter une Route */}
  <div className="modal fade" id="add_routes">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Ajouter une Route</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Nom de la Route</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="modal-satus-toggle d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>Statut</h5>
                  <p>Changez le statut avec le commutateur</p>
                </div>
                <div className="status-toggle modal-status">
                  <input type="checkbox" id="user1" className="check" />
                  <label htmlFor="user1" className="checktoggle">
                    {" "}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Ajouter la Route
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Ajouter une Route */}
  {/* Modifier la Route */}
  <div className="modal fade" id="edit_routes">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Modifier la Route</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Nom de la Route</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez le Nom de la Route"
                    defaultValue="Seattle"
                  />
                </div>
              </div>
              <div className="modal-satus-toggle d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>Statut</h5>
                  <p>Changez le statut avec le commutateur</p>
                </div>
                <div className="status-toggle modal-status">
                  <input
                    type="checkbox"
                    id="user2"
                    className="check"
                    defaultChecked
                  />
                  <label htmlFor="user2" className="checktoggle">
                    {" "}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Sauvegarder les Modifications
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Modifier la Route */}
</>
<>
  {/* Ajouter un Véhicule */}
  <div className="modal fade" id="add_assign_vehicle">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Assigner un Nouveau Véhicule</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Sélectionner une Route</label>

                  <CommonSelect
                    className="select"
                    options={routesList}
                    defaultValue={undefined}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Sélectionner un Point de Ramassage
                  </label>
                  <CommonSelect
                    className="select"
                    options={PickupPoint2}
                    defaultValue={undefined}
                  />
                </div>
                <div className="mb-0">
                  <label className="form-label">Sélectionner un Véhicule</label>
                  <CommonSelect
                    className="select"
                    options={VehicleNumber}
                    defaultValue={undefined}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Assigner Maintenant
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Ajouter un Véhicule */}
  {/* Modifier l'Attribution du Véhicule */}
  <div className="modal fade" id="edit_assign_vehicle">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Modifier l'Attribution du Véhicule</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Sélectionner une Route</label>
                  <CommonSelect
                    className="select"
                    options={routesList}
                    defaultValue={routesList[0]}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Sélectionner un Point de Ramassage
                  </label>
                  <CommonSelect
                    className="select"
                    options={PickupPoint2}
                    defaultValue={PickupPoint2[0]}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Sélectionner un Véhicule</label>
                  <CommonSelect
                    className="select"
                    options={VehicleNumber}
                    defaultValue={VehicleNumber[0]}
                  />
                </div>
                <div className="assigned-driver">
                  <h6>Conducteur Assigné</h6>
                  <div className="assigned-driver-info">
                    <span className="driver-img">
                      <ImageWithBasePath
                        src="assets/img/parents/parent-01.jpg"
                        alt="Img"
                      />
                    </span>
                    <div>
                      <h5>Thomas</h5>
                      <span>+1 64044 748904</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Assigner Maintenant
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Modifier l'Attribution du Véhicule */}
</>
<>
  {/* Ajouter un Point de Ramassage */}
  <div className="modal fade" id="add_pickup">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Ajouter un Point de Ramassage</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Nom du Point de Ramassage</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="modal-satus-toggle d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>Statut</h5>
                  <p>Changez le statut avec le commutateur</p>
                </div>
                <div className="status-toggle modal-status">
                  <input type="checkbox" id="user3" className="check" />
                  <label htmlFor="user3" className="checktoggle">
                    {" "}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Ajouter le Point de Ramassage
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Ajouter un Point de Ramassage */}
  {/* Modifier le Point de Ramassage */}
  <div className="modal fade" id="edit_pickup">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Modifier le Point de Ramassage</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Nom du Point de Ramassage</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez le Nom du Point de Ramassage"
                    defaultValue="Point A"
                  />
                </div>
              </div>
              <div className="modal-satus-toggle d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>Statut</h5>
                  <p>Changez le statut avec le commutateur</p>
                </div>
                <div className="status-toggle modal-status">
                  <input
                    type="checkbox"
                    id="user4"
                    className="check"
                    defaultChecked
                  />
                  <label htmlFor="user4" className="checktoggle">
                    {" "}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Sauvegarder les Modifications
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Modifier le Point de Ramassage */}
</>

<>
  {/* Ajouter un Conducteur */}
  <div className="modal fade" id="add_driver">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Ajouter un Nouveau Conducteur</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Nom</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="mb-3">
                  <label className="form-label">Numéro de Téléphone</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Numéro de Permis de Conduire
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez le Numéro de Permis de Conduire"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Adresse</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="modal-satus-toggle d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>Statut</h5>
                  <p>Changez le Statut avec le Commutateur</p>
                </div>
                <div className="status-toggle modal-status">
                  <input type="checkbox" id="user1" className="check" />
                  <label htmlFor="user1" className="checktoggle">
                    {" "}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Ajouter le Conducteur
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Ajouter un Conducteur */}
  {/* Modifier le Conducteur */}
  <div className="modal fade" id="edit_driver">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Modifier le Conducteur</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Nom</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez le Nom"
                    defaultValue="Thomas"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Numéro de Téléphone</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez le Numéro de Téléphone"
                    defaultValue="+1 64044 74890"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Numéro de Permis de Conduire
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez le Numéro de Permis de Conduire"
                    defaultValue="LC7899456689"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Adresse</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez l'Adresse"
                    defaultValue="2233 Wood Street, Slidell, LA"
                  />
                </div>
              </div>
              <div className="modal-satus-toggle d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <h5>Statut</h5>
                  <p>Changez le Statut avec le Commutateur</p>
                </div>
                <div className="status-toggle modal-status">
                  <input
                    type="checkbox"
                    id="user2"
                    className="check"
                    defaultChecked
                  />
                  <label htmlFor="user2" className="checktoggle">
                    {" "}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Sauvegarder les Modifications
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Modifier le Conducteur */}
</>

<>
  {/* Ajouter un Nouveau Véhicule */}
  <div className="modal fade" id="add_vehicle">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Ajouter un Nouveau Véhicule</h4>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body" id='modal-datepicker'>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Numéro du Véhicule</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Modèle du Véhicule</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Année de Fabrication</label>
                      <div className="date-pic">
                        <DatePicker
                          className="form-control datetimepicker"
                          format={{
                            format: "DD-MM-YYYY",
                            type: "mask",
                          }}
                          getPopupContainer={getModalContainer}
                          defaultValue=""
                          placeholder="16 Mai 2024"
                        />
                        <span className="cal-icon">
                          <i className="ti ti-calendar" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Numéro d'Immatriculation</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Numéro de Châssis</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Numéro de Châssis"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Capacité d'Assise</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">ID de Suivi GPS</label>
                  <input type="text" className="form-control" />
                </div>
                <hr />
                <div className="mb-3">
                  <h4>Détails du Conducteur</h4>
                </div>
                <div className="mb-3">
                  <label className="form-label">Sélectionner le Conducteur</label>
                  <CommonSelect
                    className="select"
                    options={driverName}
                    defaultValue={undefined}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Permis de Conduire</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Numéro de Contact du Conducteur</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-0">
                <label className="form-label">Adresse du Conducteur</label>
                <input type="text" className="form-control" />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Ajouter Nouveau Véhicule
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Ajouter un Nouveau Véhicule */}
  {/* Modifier le Véhicule */}
  <div className="modal fade" id="edit_vehicle">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header align-items-center">
          <div className="d-flex align-items-center">
            <h4 className="modal-title">Modifier le Véhicule</h4>
            <span className="badge badge-soft-primary ms-2">
              ID : BB0482
            </span>
          </div>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body" id='modal-datepicker2'>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Numéro du Véhicule</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Numéro du Véhicule"
                        defaultValue={8930}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Modèle du Véhicule</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Modèle du Véhicule"
                        defaultValue="Scania"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Année de Fabrication</label>
                      <div className="date-pic">
                        <DatePicker
                          className="form-control datetimepicker"
                          format={{
                            format: "DD-MM-YYYY",
                            type: "mask",
                          }}
                          getPopupContainer={getModalContainer2}
                          defaultValue={defaultValue}
                          placeholder="16 Mai 2024"
                        />
                        <span className="cal-icon">
                          <i className="ti ti-calendar" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Numéro d'Immatriculation</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Numéro d'Immatriculation"
                        defaultValue="US1A3545"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Numéro de Châssis</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={32546665456}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Capacité d'Assise</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez la Capacité d'Assise"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">ID de Suivi GPS</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez l'ID de Suivi GPS"
                    defaultValue="GPS7899456689"
                  />
                </div>
                <hr />
                <div className="mb-3">
                  <h4>Détails du Conducteur</h4>
                </div>
                <div className="mb-3">
                  <label className="form-label">Sélectionner le Conducteur</label>
                  <CommonSelect
                    className="select"
                    options={driverName}
                    defaultValue={driverName[1]}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Permis de Conduire</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Permis de Conduire"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Numéro de Contact du Conducteur</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Entrez le Numéro de Contact"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-0">
                <label className="form-label">Adresse du Conducteur</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrez l'Adresse du Conducteur"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-primary"
            >
              Enregistrer les Modifications
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Modifier le Véhicule */}
  {/* Supprimer le Véhicule */}
  <div
    className="modal fade"
    id="delete_vehicle"
    aria-labelledby="delete_vehicle"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header align-items-center">
          <div className="d-flex align-items-center">
            <h5 className="modal-title">Supprimer le Véhicule</h5>
            <span className="badge badge-soft-danger ms-2">ID : BB0482</span>
          </div>
          <button
            type="button"
            className="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <form>
          <div className="modal-body">
            <div className="mb-3">
              <h4 className="text-center">
                Voulez-vous vraiment supprimer ce véhicule ?
              </h4>
            </div>
            <div className="mb-3 text-center">
              <span className="badge badge-soft-danger">Cette action est irréversible</span>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              to="#"
              className="btn btn-light me-2"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              data-bs-dismiss="modal"
              className="btn btn-danger"
            >
              Supprimer
            </Link>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Supprimer le Véhicule */}
</>


      {/* Delete Modal */}
      <div className="modal fade" id="delete-modal">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <form>
        <div className="modal-body text-center">
          <span className="delete-icon">
            <i className="ti ti-trash-x" />
          </span>
          <h4>Confirmer la Suppression</h4>
          <p>
            Vous souhaitez supprimer tous les éléments marqués, cette action est irréversible.
          </p>
          <div className="d-flex justify-content-center">
            <Link
              to="#"
              className="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Annuler
            </Link>
            <Link
              to="#"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Oui, Supprimer
            </Link>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

      {/* /Delete Modal */}
    </>
  );
};

export default TransportModal;
